import { Button, Grid } from '@material-ui/core'
import React, { Component } from 'react'
import DatePickerRange from '../../../commons/components/DatePickerRange'
import { initialFilters, generateReport } from "./actions";
import { connect } from "react-redux";
const mapDispatchToProps = {
  generateReport
};
class PtHud extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: initialFilters,
    };
  }
  handleDate = (field, date) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [field]: date
      }
    });
  };

  generate = async (e) => {
    this.setState({
      reportGenerated: false
    })
    var fromDate = this.state.filters.fromDate
    var toDate = this.state.filters.toDate
    this.props.generateReport({ fromDate, toDate })
  };

  isDateRangeValid = () => {
    const { fromDate, toDate } = this.state.filters;
    if (fromDate && toDate) {
      return fromDate <= toDate;
    }

    return false;
  }
  render() {
    const isDateRangeValid = this.isDateRangeValid();
    return (
      <Grid
        container
        spacing={2}
        style={{ margin: 2, padding: 15, width: "40%", textAlign: "center" }}
      >
        <Grid item xs={12}>
          <DatePickerRange
            label={"PT-HUD Date"}
            fromDate={this.state.filters.fromDate}
            fromDateId="fromDate"
            toDate={this.state.filters.toDate}
            toDateId="toDate"
            onDatesChange={this.handleDate}
          />
        </Grid>
        <Grid container wrap="nowrap" justifyContent="space-evenly" >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.generate(this)}
              disabled={!isDateRangeValid}
            >
              DOWNLOAD REPORT
            </Button>

          </Grid>
        </Grid>
      </Grid>
    )
  }
};
export default connect(
  null,
  mapDispatchToProps,
)(PtHud);