import React from "react";
import PropTypes from "prop-types";
import NetworkButton from "../../../commons/components/NetworkButton";
import TextField from "@material-ui/core/TextField";
import { KeyboardDatePicker } from "@material-ui/pickers";

import { ValidatorForm, TextValidator} from "react-material-ui-form-validator";
import MultiSelect from "../../../commons/components/MultiSelect";
import { FormControlLabel, Switch, Grid } from "@material-ui/core";
import ChipInputField from "../../../commons/components/ChipInputField";
import Moment from "moment";

const styles = {
	buttons: {
		marginTop: 30,
		float: "right",
		marginLeft: 5
	},
	saveButton: {
		marginLeft: 5
	},
	chip: {
		margin: 4
	},
	wrapper: {
		display: "flex",
		flexWrap: "wrap"
	}
};

const nameFieldfocus = input => { input && input.focus(); }
class LenderForm extends React.Component {
	state = {
		selectedLoanTypes: [],
		selectedProducts: [],
		selectedStates: [],
	};

	componentDidMount() {
		nameFieldfocus();
		this.updateBlockedProductsAndStates();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.productList !== this.props.productList) {
			this.updateBlockedProductsAndStates();
		}
	}

	updateBlockedProductsAndStates = () => {
		const { item, loanTypeList, productList, statesList } = this.props;
		const loanTypeBlocks = item.loanTypeBlocks instanceof Array && loanTypeList instanceof Array ? loanTypeList.filter(ap => item.loanTypeBlocks.find(block => block.loanTypeID === ap.id) !== undefined) : [];
		const productBlocks = item.productBlocks instanceof Array && productList instanceof Array ? productList.filter(p => item.productBlocks.find(block => block.productID === p.id) !== undefined) : [];
		const stateBlocks = item.stateBlocks instanceof Array && statesList instanceof Array ? statesList.filter(p => item.stateBlocks.find(block => block.stateID === p.id) !== undefined) : [];
		this.setState({
			selectedLoanTypes: loanTypeBlocks,
			selectedProducts: productBlocks,
			selectedStates: stateBlocks,
		});
	}

	handleChangeInput = event => {
		this.props.changeInput( event.target.name, event.target.value );
	};

	handleSelectChange = ( field, value ) => {
		this.props.changeInput( field, value );
	};

	handleUpdateDate = (event, field, value) => {
		if(event.isValid()){
			this.props.changeInput( field, Moment(value, "MM-DD-YYYY"));
		}
	};

	handleChangeMultiSelect = (name, options) => {
		this.setState({ [name]: options });
	}

	handleChangeBlockedLoanTypes = (name, options) => {
		this.handleChangeMultiSelect(name, options);
		this.props.changeInput("loanTypeBlocks", options.map(o => ({ lenderID: this.props.item.id, loanTypeID: o.id })));
	}

	handleChangeBlockedProducts = (name, options) => {
		this.handleChangeMultiSelect(name, options);
		this.props.changeInput("productBlocks", options.map(o => ({ lenderID: this.props.item.id, productID: o.id })));
	}

	handleChangeBlockedStates = (name, options) => {
		this.handleChangeMultiSelect(name, options);
		this.props.changeInput("stateBlocks", options.map(o => ({ lenderID: this.props.item.id, stateID: o.id })));
	}

	handleSubmit = () => {
		this.props.saveItem( this.props.item );
	};

	handleCancel = () => {
		this.props.cleanForm();
	};

	handleDeleteChip = chip => {
		let numbers = this.props.item.numbers;
		numbers = numbers.filter( c => c !== chip );
		if ( numbers.length === 0 ) numbers = [];
		this.props.changeInput( "numbers", numbers );
	};

	handleAddChip = ( ...chips ) => {
		const numbers = this.props.item.numbers;
		numbers.push( ...chips );
		this.props.changeInput( "numbers", numbers );
	};

	handleSwitch = ( event, checked ) => {
		this.props.changeInput( "acceptRetail", checked );
	};

	render() {
		return (
			<Grid container style={{marginTop:10}}>
				<ValidatorForm onSubmit={ this.handleSubmit } noValidate style={{width:"100%"}}>
					<Grid container item xs={12} spacing={3}>
						<Grid item xs={9}>
							<TextValidator
								required
								ref={ input => {
									this.name = input;
								} }
								name="name"
								label="Name"
								validators={ ["required", "minStringLength:3"] }
								errorMessages={ ["The name is mandatory", "The name must be at least 3 characters long."] }
								value={ this.props.item.name }
								onChange={ this.handleChangeInput }
								fullWidth
							/>
						</Grid>
						<Grid item xs={3}>
							<TextValidator
								required
								ref={ input => {
									this.code = input;
								} }
								name="code"
								label="Code"
								validators={ ["required", "minStringLength:3"] }
								errorMessages={ ["The code is mandatory", "The code must be at least 3 characters long."] }
								value={ this.props.item.code }
								onChange={ this.handleChangeInput }
								fullWidth
							/>
						</Grid>
					</Grid>
					<Grid container item xs={12} spacing={3}>
						<ChipInputField
							required
							ref={ input => {
								this.numbers = input;
							} }
							name="lenderNumber"
							label="Lender Number"
							value={ this.props.item.numbers }
							onAdd={ chip => this.handleAddChip( chip ) }
							onDelete={ ( chip, index ) => this.handleDeleteChip( chip, index ) }
							validators={ ["required"] }
							errorMessages={ ["It's mandatory"] }
							fullWidth
						/>
					</Grid>
					<Grid container item xs={12} spacing={3}>
						<TextField
							ref="emailPreFundReport"
							name="emailPreFundReport"
							label="Email Pre Fund Report"
							value={ this.props.item.emailPreFundReport || "" }
							onChange={ this.handleChangeInput }
							fullWidth
							style={{marginTop:40}}
						/>
					</Grid>
					<Grid container item xs={12} spacing={3}>
						<TextField
							ref="address1"
							name="address1"
							label="Address1"
							value={ this.props.item.address1 || "" }
							onChange={ this.handleChangeInput }
							fullWidth
						/>
					</Grid>
					<Grid container item xs={12} spacing={3}>
						<TextField
							ref="address2"
							name="address2"
							label="Address2"
							value={ this.props.item.address2 || "" }
							onChange={ this.handleChangeInput }
							fullWidth
						/>
					</Grid>
					<Grid container item xs={12} spacing={3}>
						<Grid item xs={3}>
							<TextField
								ref="wirebank"
								name="wirebank"
								label="Wire Bank"
								value={ this.props.item.wirebank || "" }
								onChange={ this.handleChangeInput }
								fullWidth
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								ref="wireAba"
								name="wireAba"
								label="Wire Aba"
								value={ this.props.item.wireAba || "" }
								onChange={ this.handleChangeInput }
								fullWidth
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								ref="wireAccountNumber"
								name="wireAccountNumber"
								label="Wire Account Nº"
								value={ this.props.item.wireAccountNumber || "" }
								onChange={ this.handleChangeInput }
								fullWidth
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								ref="wireAttention"
								name="wireAttention"
								label="Wire Attention"
								value={ this.props.item.wireAttention || "" }
								onChange={ this.handleChangeInput }
								fullWidth
							/>
						</Grid>
					</Grid>
					<Grid container item xs={12} spacing={3}>
						<Grid item xs={4}>
							<KeyboardDatePicker
								name="agreementDate"
								label="Agreement Date"
								value={this.props.item.agreementDate}
								onChange={ (event, date) => this.handleUpdateDate( event, "agreementDate", date)}
								InputAdornmentProps={{ position: "start" }}
								variant="inline"
								style={{width:"100%"}}
								format={"MM-DD-YYYY"}
								autoOk
							/>
						</Grid>
						<Grid item xs={2}>
							<FormControlLabel
								control={
									<Switch
									checked={ this.props.item.acceptRetail }
									onChange={ this.handleSwitch }
									value="acceptRetail"
									color={ "primary" }
									/>
								}
								label="Accept Retail"
							/>
						</Grid>
					</Grid>
					<Grid container item xs={12} spacing={3}>
						<Grid item xs={4}>
							<MultiSelect 
								label="Block Loan Type"
								options={ this.props.loanTypeList }
								values={ this.state.selectedLoanTypes }
								name={ "selectedLoanTypes" }
								changeInputSelect={ this.handleChangeBlockedLoanTypes }
								height={300}
							/>
						</Grid>
						<Grid item xs={4}>
							<MultiSelect 
								label="Block Product"
								options={ this.props.productList }
								values={ this.state.selectedProducts }
								name={ "selectedProducts" }
								changeInputSelect={ this.handleChangeBlockedProducts }
								height={300}
							/>
						</Grid>
						<Grid item xs={4}>
							<MultiSelect 
								label="Block States"
								options={ this.props.statesList }
								values={ this.state.selectedStates }
								name={ "selectedStates" }
								changeInputSelect={ this.handleChangeBlockedStates }
								height={300}
							/>
						</Grid>
					</Grid>
					<Grid container item xs={12} spacing={3}>
						<NetworkButton text={ "Cancel" } onClick={ this.handleCancel } />
						<NetworkButton
							text={ "Save" }
							primary={ true }
							type="submit"
							style={ styles.saveButton }
						/>
					</Grid>
				</ValidatorForm>
			</Grid>
		);
	}
}

LenderForm.propTypes = {
	loanTypeList: PropTypes.array,
	changeInput: PropTypes.func,
	clearForm: PropTypes.func,
	item: PropTypes.object,
	productList: PropTypes.array,
	saveItem: PropTypes.func,
	statesList: PropTypes.array
};

export default LenderForm;
