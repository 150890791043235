import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageBase from "../../../../commons/components/PageBase";
import {
	saveItem,
	loadList,
	loadAllOptionsForFilters,
	deleteAvailableFund,
	model,
	initialFilters
} from "./actions";
import AvailableFundsForm from "./AvailableFundsForm";
import AvailableFundsTable from "./AvailableFundsTable";

const mapDispatchToProps = {
	saveItem,
	deleteAvailableFund,
	loadList,
	loadAllOptionsForFilters
};

class AvailableFunds extends React.Component {
	state = {
		item: model,
		dataSource: [],
		lenderOptions: [],
		filters: initialFilters
	};

	constructor(props) {
		super(props);
		this.props.loadList(this, initialFilters);
		this.props.loadAllOptionsForFilters(this);
	}

	handleUpdate = (field, value) => {
		this.setState({
			item:{
				...this.state.item,
				[field]: value
			}
		});
	};

	handleSaveItem = (item) => {
		this.props.saveItem(this, item);
	};

	handleDeleteItem = (id) => {
		this.props.deleteAvailableFund(this, id);
	};

	handleSubmitInfinity = (params) => {
		this.props.loadList(this, params);
	};

	render() {

		return (
			<PageBase title="Available Funds" showActions={ false } >
				<div>
					<AvailableFundsForm
						item = { this.state.item }
						lenderOptions = { this.state.lenderOptions }
						saveItem = { this.handleSaveItem.bind(this) }
						changeField = { this.handleUpdate.bind(this) }
					/>
					<br />
					<AvailableFundsTable
						deleteItem = { this.handleDeleteItem.bind(this) }
						list = { this.state.dataSource }
						filters={this.state.filters}
						handleSubmit={this.handleSubmitInfinity.bind(this)}
					/>
				</div>
			</PageBase>
		);
	};
}

AvailableFunds.propTypes = {
	deleteAvailableFund: PropTypes.func,
	loadAllOptionsForFilters: PropTypes.func,
	loadList: PropTypes.func,
	saveItem: PropTypes.func,
};

export default connect(
	null,
	mapDispatchToProps,
)( AvailableFunds );
