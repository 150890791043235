import { apiGet, apiPost } from "../../../actions";
import fileDownload from "js-file-download";
import Moment from "moment";

export const initialFilters = {
	dealerAssociation: [],
	sfcCultivation: [],
	sfcSalesperson:[],
	state:[],
	companyRegions:[],
	enrollmentStatus:[],
	assets:[],
	dealerApproved:'All',
	fhaApproved:'All',
	financeProgram:'All',
	welcomeLTTR:'All',
	fromSubmitDate: null,
	toSubmitDate: null,
	startIndex: 0,
	stopIndex: 100
};

const defaultPath = "api/DealerManagement/DealerReport";

export function loadFilters(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: "api/CommonFilter/loadDealerFilters",
			onSuccess: response => {
				props.setState({
					dealerAssociationList: response.data.dealerAssociations,
					sfcCultivationList:response.data.sfcCultivations,
					sfcSalespersonList:response.data.sfcSalesperson,
					stateList:response.data.states,
					companyRegionsList:response.data.companyRegions,
					enrollmentStatusList:response.data.enrollmentStatus,
					assetsList:response.data.assets,
					dealerApprovedList:response.data.dealerApproved,
					fhaApprovedList:response.data.fhaApproved,
					financeProgramList:response.data.financeProgram,
					welcomeLTTRList:response.data.welcomeLTTR
				});
			}
		} );
	}
}

export function submit( props, filters ) {
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath,
			body: convertFilterToParams(filters),
			onSuccess: response => {
				props.setState({
					listGrid: response.data,
					isSubmitted: true,
				});
			}
		} );
	};
}

export function downloadExcelReport( filters ) {
	return dispatch => {
		apiPost( dispatch, {
			responseType: "blob",
			url: defaultPath + '/downloadExcel',
			body: convertFilterToParams(filters),
			onSuccess: response => {
				var now = new Date();
				fileDownload( response.data, `DealerReport-${now.getMonth() + 1}-${now.getDate()}-${now.getFullYear()}.xlsx` );
			}
		} );
	};
}

export function loadDealerDetails( props, dealerID ) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath + `/loadDealerDetails?dealerID=${dealerID}`,
			onSuccess: response => {
				props.setState({
					dealerDetails: response.data,
					openDetailsModal: true
				});
			}
		} );
	};
}

function convertFilterToParams(filters) {
	return {
		dealerAssociationIds: filters.dealerAssociation.map(a => a.id),
		sfcCultivationIds: filters.sfcCultivation.map(a => a.id),
		sfcSalespersonIds: filters.sfcSalesperson.map(a => a.id),
		state: filters.state.map(a => a.id),
		companyRegion: JSON.stringify(filters.companyRegions.map(a => a.id)),
		enrollmentStatus: JSON.stringify(filters.enrollmentStatus.map(a => a.id)),
		assets: JSON.stringify(filters.assets.map(a => a.id)),
		dealerApproved: filters.dealerApproved,
		fhaApproved: filters.fhaApproved,
		financeProgram: filters.financeProgram,
		welcomeLTTR: filters.welcomeLTTR,
		fromSubmitDate: filters.fromSubmitDate !== null ? Moment( new Date( filters.fromSubmitDate ) ).format( "MM-DD-YYYY" ) : null,
		toSubmitDate: filters.toSubmitDate !== null ? Moment( new Date( filters.toSubmitDate ) ).format( "MM-DD-YYYY" ) : null,
		startIndex: filters.startIndex,
		stopIndex: filters.stopIndex
	}
}
