import React from "react";
import PropTypes from "prop-types";
import NetworkButton from "../../../commons/components/NetworkButton";
import { renderMenuItems } from "../../../commons/utils";
import { ValidatorForm, TextValidator, SelectValidator} from "react-material-ui-form-validator";

const lenderFieldFocus = input => { input && input.focus(); };

class LenderLoanTypeForm extends React.Component {
	componentDidMount() {
		lenderFieldFocus();
	}

	handleChangeInput = event => {
		this.props.changeInput( event );
	};

	handleSubmit = () => {
		this.props.saveItem(this.props.item );
	};

	handleCancel = () => {
		this.props.cleanForm();
	};

	handleSelectChange = ( event ) => {
		this.props.changeInput( event );
	};

	render() {
		const styles = {
			buttons: {
				marginTop: 30,
				float: "right",
				marginLeft: 5
			},
			saveButton: {
				marginLeft: 5
			},
			chip: {
				margin: 4
			},
			wrapper: {
				display: "flex",
				flexWrap: "wrap"
			}
		};

		return (
			<div>
				<ValidatorForm onSubmit={ this.handleSubmit } noValidate>
					<SelectValidator
						label="Lender"
						name="lender"
						value={ this.props.item.lender }
						onChange={ this.handleSelectChange }
						disabled={ this.props.item.id > 0 }
						fullWidth
					>
						{renderMenuItems(this.props.lenderOptions, false)}
					</SelectValidator>
					<SelectValidator
						ref={ input => {
							this.lenderID = input;
						} }
						label="Loan Type"
						name="loanType"
						value={ this.props.item.loanType }
						onChange={ this.handleSelectChange }
						validators={ ["required"] }
						errorMessages={ ["It is mandatory."] }
						disabled={ this.props.item.id > 0 }
						fullWidth
					>
						{renderMenuItems(this.props.loanTypeOptions, false)}
					</SelectValidator>
					<TextValidator
						ref={ input => {
							this.lenderPrice = input;
						} }
						name="lenderPrice"
						label={ "Lender Price" }
						value={ this.props.item.lenderPrice }
						onChange={ this.handleChangeInput }
						fullWidth
					/>
					<TextValidator
						ref={ input => {
							this.min = input;
						} }
						name="min"
						label="Min"
						value={ this.props.item.min }
						onChange={ this.handleChangeInput }
						fullWidth
					/>
					<TextValidator
						ref={ input => {
							this.max = input;
						} }
						name="max"
						label="Max"
						value={ this.props.item.max }
						onChange={ this.handleChangeInput }
						fullWidth
					/>

					<br />
					<div style={ styles.buttons }>
						<NetworkButton text={ "Cancel" } onClick={ this.handleCancel } />
						<NetworkButton
							text={ "Save" }
							primary={ true }
							type="submit"
							style={ styles.saveButton }
						/>
					</div>
				</ValidatorForm>
			</div>
		);
	}
}

LenderLoanTypeForm.propTypes = {
	loanTypeOptions: PropTypes.array,
	changeInput: PropTypes.func,
	cleanForm: PropTypes.func,
	item: PropTypes.object,
	lenderOptions: PropTypes.array,
	saveItem: PropTypes.func
};

export default LenderLoanTypeForm;
