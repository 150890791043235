import React from "react";
import PropTypes from "prop-types";
import { Tabs, Tab } from "@material-ui/core";
import RiskBasedPrincingNoticeTable from "./RiskBasedPrincingNoticeTable"
import SearchNotData from "../../../commons/components/SearchNotData";

const fontSize = 11;
const loanTypeTab = "LoanType";
const fhaUnsecuredTab = "FHAUnsecured";
const fhaSecuredTab = "FHASecured";

class RiskBasedPrincingNoticeTabs extends React.Component {

	state = {
		listGrid: [],
		selectedTab: loanTypeTab
	};

	componentDidUpdate(prevProps, prevState)  {
		let newList = this.state.listGrid;
		if(this.state.selectedTab === loanTypeTab)
		{
			if(prevProps.loanTypeList !== this.props.loanTypeList)
				newList = this.props.loanTypeList;
		}
		if(this.state.selectedTab === fhaUnsecuredTab)
		{
			if(prevProps.fhaUnsecuredList !== this.props.fhaUnsecuredList)
				newList = this.props.fhaUnsecuredList;
		}
		if(this.state.selectedTab === fhaSecuredTab)
		{
			if(prevProps.fhaSecuredList !== this.props.fhaSecuredList)
				newList = this.props.fhaSecuredList;
		}

		if(newList !== this.state.listGrid)
			this.setState({ listGrid: newList });
	}

	handleChangeTab = (event,value) => {
		const { loanTypeList, fhaUnsecuredList, fhaSecuredList } = this.props;
		let listGrid = 
			value === loanTypeTab ?
				loanTypeList :
				( value === fhaUnsecuredTab ?
					fhaUnsecuredList :
					fhaSecuredList
				);

		this.setState({
			listGrid: listGrid,
			selectedTab: value
		});
	}
    
    render(){
        return(
			<div style={{marginTop:20}}>
				<Tabs
					value={this.state.selectedTab}
					onChange={this.handleChangeTab}
					indicatorColor="primary"
					textColor="primary"
					style={{ width:1375, padding:0 }}
				>
					<Tab label="Loan Types" style={{fontSize:fontSize}} value={loanTypeTab}/>
					<Tab label="FHA Unsecured(Original balance <= 7500)" style={{fontSize:fontSize}} value={fhaUnsecuredTab}/>
					<Tab label="FHA Secured(Original balance > 7500)" style={{fontSize:fontSize}} value={fhaSecuredTab}/>
				</Tabs>
				<SearchNotData
					isSubmitted={this.props.isSubmitted}
					listGrid={this.state.listGrid}
				>
					<RiskBasedPrincingNoticeTable
						listGrid={this.state.listGrid}
						filters={this.props.filters}
						handleSubmit={this.props.handleSubmit}
					/>
				</SearchNotData>
			</div>
        );
    }
}

RiskBasedPrincingNoticeTabs.propTypes = {
	isSubmitted: PropTypes.bool,
	loanTypeList: PropTypes.array,
	filters: PropTypes.object,
	fhaUnsecuredList: PropTypes.array,
	fhaSecuredList: PropTypes.array,
	handleSubmit: PropTypes.func
};

export default RiskBasedPrincingNoticeTabs;