import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { loadGrid,
    downloadPrepayExcel,
    downloadStratReport
} from "./actions";
import { Table, TableHead, TableCell, TableBody, TableRow, Paper, TablePagination } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Moment from "moment";

const mapDispatchToProps = {
    loadGrid,
    downloadPrepayExcel,
    downloadStratReport
};

const tableProps = {
    paddingLeft:0
};

class InventorySummary extends React.Component{
    state = {
        list: [],
        groupLoad: PropTypes.array,
        group: PropTypes.array,
        page: 0,
        rowsPerPage: 0,
        rowsPerPageOptions:PropTypes.array,
        total: 0,
        expanded: PropTypes.bool
    };

    constructor(props){
        super(props);
        this.props.loadGrid(this);
    }

    static getDerivedStateFromProps(props, state) {
        const {list} = state;

		if(list.length === 0 || state.group !== list)
			return {
				group: list,
                page: 0,
                rowsPerPage: 15,
                rowsPerPageOptions: [5, 15],
                groupLoad: list.slice(0,15),
                total: list.length > 0 ? list.length : 0
			};

		return state;
    }

    handleExcelDownload = (item) => {
        const {reportName} = item;
        return this.props.downloadPrepayExcel(reportName);
    };

    handleStratDownload = (item) => {
        const { reportName, asOfDate } = item;
        return this.props.downloadStratReport(reportName, asOfDate);
    };

    getStratLink(item){
        if(item.type === "Origination" || item.type === "Tranche") return <div/>;
        
        return (
            <Link
                component="button"
                style={{color:"blue"}}
                onClick={this.handleStratDownload.bind(this,item)}
            >
                Strat
            </Link>
        );
    }

    getRow(summaryItem, id){
            
        return(
            <TableRow key = {id}>
                <TableCell>{summaryItem["reportName"]}</TableCell>
                <TableCell>{Moment( new Date( summaryItem["asOfDate"] ) ).format( "MM/DD/YYYY" )}</TableCell>
                <TableCell>{this.getStratLink(summaryItem)}</TableCell>
                <TableCell><Link component="button" style={{color:"blue"}} onClick={this.handleExcelDownload.bind(this, summaryItem)}>Prepay</Link></TableCell>
                <TableCell>{summaryItem["currentBalanceFormula"].toLocaleString('en-US', {style:'currency', currency: 'USD'})}</TableCell>
            </TableRow>
        );
    }

    getSummaryTable( summary ){
        return(
            <Paper>
                <Table style={{minWidth:1575}}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={tableProps}>Report Name</TableCell>
                            <TableCell style={tableProps}>As of Date</TableCell>
                            <TableCell style={tableProps}>Strat</TableCell>
                            <TableCell style={tableProps}>Prepay</TableCell>
                            <TableCell style={tableProps}>UPB</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {summary.map( (summaryItem, id) => this.getRow(summaryItem, id))}
                    </TableBody>
                </Table>
            </Paper>
        );
    }

    getExpandablePanelForItem( item, index ){
        const panelTItle = `${item["reportKey"]}`;

        return(
            <TableRow key = {index}>
                <TableCell style={{padding:0}} >
                    <Accordion style={{padding:0}} TransitionProps={{unmountOnExit:true}}>
                        <AccordionSummary expandIcon = {<ExpandMoreIcon />}  >
                            {panelTItle}
                        </AccordionSummary>
                        <AccordionDetails>
                            {this.getSummaryTable(item.report)}
                        </AccordionDetails>
                    </Accordion>
                </TableCell>
            </TableRow>
        );
    }

    handleSelectLabel(event){
        this.setState({groupName:event.target.value});
    }

    loadNextGroup(event, newPage){
        const {list, rowsPerPage} = this.state;
        const nextList =  list.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage);
        this.setState({
            groupLoad: nextList,
            page:newPage
        });
    }

    handleChangePage(event, newPage) {
        this.setState({
            page:newPage,
            expanded:false
        });
    }

    handleChangeRowsPerPage(event) {
        const rowsPerPage = parseInt(event.target.value, 10);
        const groupToShow = this.state.group.slice(0,rowsPerPage);
        this.setState({
            rowsPerPage,
            page:0,
            groupLoad: groupToShow
        });
    }

    createVirtualTable(){
        const {groupLoad: groupToLoad} = this.state; 
        return(
            <Table>
                <TableHead>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={this.state.rowsPerPageOptions}
                            count={this.state.total}
                            onPageChange={this.loadNextGroup.bind(this)}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onRowsPerPageChange={this.handleChangeRowsPerPage.bind(this)}
                        />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {groupToLoad.map((item, index) => this.getExpandablePanelForItem(item, index))}
                </TableBody>
            </Table>
        );
    }

    render(){
        return (
            <div>
                {this.createVirtualTable()}
            </div>
        );
    }
}

InventorySummary.propTypes = {
    downloadPrepayExcel: PropTypes.func,
    downloadStratReport: PropTypes.func,
    loadGrid: PropTypes.func
};

export default connect(
    null,
    mapDispatchToProps,
)( InventorySummary ); 