import { FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import fileDownload from "js-file-download";

import IsolateEvent from "../../../commons/components/IsolateEvent";
import MultiSelect from "../../../commons/components/MultiSelect";
import DatePickerRange from "../../../commons/components/DatePickerRange";
import { loadFilters, handleExcel } from "./actions";
import NetworkButton from "../../../commons/components/NetworkButton";

const mapDispatchToProps = {
	loadFilters,
	handleExcel,
};

class DealerPerformanceReportFilter extends React.Component {
	initialState = {
		filters: {
			typeOptions: [],
			typeSelected: "",
			dealerAssociationOptions: [],
			dealerAssociationsSelected: [],
			dealerOptions: [],
			dealersSelected: [],
			sfcCultivationOptions: [],
			sfcCultivationsSelected: [],
			sfcSalespersonOptions: [],
			sfcSalespeopleSelected: [],
			stateOptions: [],
			statesSelected: [],
			loanTypeOptions: [],
			loanTypesSelected: [],
			productOptions: [],
			productsSelected: [],
			fromDate: new Date(),
			toDate: new Date(),
			tu144Override: "all",
			ignoreSkeps: "no",
		}
	};

	state = JSON.parse(JSON.stringify(this.initialState));

	mapToFilter = (list) => (list || []).map(i => ({ id: i, value: i }));

	mapFiltersToRequest = () => ({
		UseWholeLoan: true,
		FromDate: this.state.filters.fromDate,
		ToDate: this.state.filters.toDate,
		Types: [this.state.filters.typeSelected],
		DealerNumbers: this.state.filters.dealersSelected.map(d => d.number),
		DealerAssociations: this.state.filters.dealerAssociationsSelected.map(d => d.id),
		ProductTypes: this.state.filters.productsSelected.map(d => d.id),
		SFCCultivations: this.state.filters.sfcCultivationsSelected.map(d => d.id),
		SFCSalespeople: this.state.filters.sfcSalespeopleSelected.map(d => d.id),
		States: this.state.filters.statesSelected.map(d => d.id),
		LoanTypes: this.state.filters.loanTypesSelected.map(d => d.id),
		IsTU144Override: this.mapToBoolean(this.state.filters.tu144Override),
		IgnoreSkepsFromApprovalsAndFunded: this.mapToBoolean(this.state.filters.ignoreSkeps),
	});

	mapToBoolean = (filter) => {
		if (filter === "yes") return true;
		if (filter === "no") return false;
		return null;
	};

	handleMultiSelectFilter = (field, value) => {
		this.setState({ filters: { ...this.state.filters, [field]: value } });
	};

	handleSelectChange = (event) => {
		this.setState({ filters: { ...this.state.filters, [event.target.name]: event.target.value } });
	};

	handleToggle = (event) => {
		this.setState({ filters: { ...this.state.filters, [event.target.name]: event.target.value } });
	};

	handleDate = (field, date) => {
		this.setState({ filters: { ...this.state.filters, [field]: date } });
	};

	resetFilters = () => {
		this.loadFilters();
	};

	submitExcel = () => {
		this.props.handleExcel(this.mapFiltersToRequest(), (blob, filename) => fileDownload(blob, filename));
	};

	loadFilters = () => {
		this.props.loadFilters(data => {
			this.setState({
				filters: {
					...this.state.filters,
					typeOptions: data.types || [],
					typeSelected: "Dealer",
					dealerAssociationOptions: this.mapToFilter(data.dealerAssociations),
					dealerAssociationsSelected: [],
					dealerOptions: data.dealers,
					dealersSelected: [],
					sfcCultivationOptions: this.mapToFilter(data.sfcCultivations),
					sfcCultivationsSelected: [],
					sfcSalespersonOptions: this.mapToFilter(data.sfcSalespeople),
					sfcSalespeopleSelected: [],
					stateOptions: this.mapToFilter(data.states),
					statesSelected: [],
					loanTypeOptions: this.mapToFilter(data.loanTypes),
					loanTypesSelected: [],
					productOptions: this.mapToFilter(data.products),
					productsSelected: [],
					tu144Override: "all",
					ignoreSkeps: "no",
				},
			});
		});
	};

	componentDidMount = () => {
		this.loadFilters();
	};

	render() {
		var { filters } = this.state;
		return (
			<Grid container style={{marginTop:10}}>
				<Grid container item xs={12} style={{marginBottom:20}}>
					<Grid item xs={3}>
						<IsolateEvent wheel scroll>
							<InputLabel id="label-type-options">Type</InputLabel>
							<Select name="typeSelected" labelId="label-type-options" value={filters.typeSelected} onChange={this.handleSelectChange} fullWidth>
								{filters.typeOptions?.map(t => (
									<MenuItem key={t} value={t}>{t}</MenuItem>
								))}
							</Select>
						</IsolateEvent>
					</Grid>
					<Grid item xs={3}>
						<FormControl component="fieldset" style={{marginLeft:20}}>
							<FormLabel component="legend">TU 144 Override</FormLabel>
							<RadioGroup name="tu144Override" value={filters.tu144Override} row onChange={this.handleToggle} >
								<FormControlLabel value="all" control={<Radio color="primary"/>} label="All" />
								<FormControlLabel value="yes" control={<Radio color="primary"/>} label="Yes" />
								<FormControlLabel value="no" control={<Radio color="primary"/>} label="No" />
							</RadioGroup>
						</FormControl>
					</Grid>
					<Grid item xs={6}>
						<FormControl component="fieldset" style={{marginLeft:20}}>
							<FormLabel component="legend">Ignore Skeps From Approvals & Funded</FormLabel>
							<RadioGroup name="ignoreSkeps" value={filters.ignoreSkeps} row onChange={this.handleToggle} >
								<FormControlLabel value="yes" control={<Radio color="primary"/>} label="Yes" />
								<FormControlLabel value="no" control={<Radio color="primary"/>} label="No" />
							</RadioGroup>
						</FormControl>
					</Grid>
				</Grid>
				<Grid container item xs={12} spacing={3}>
					<Grid item xs={3}>
						<IsolateEvent wheel scroll>
							<MultiSelect name={"dealerAssociationsSelected"} label={"Dealer Association"} options={filters.dealerAssociationOptions} values={filters.dealerAssociationsSelected} changeInputSelect={this.handleMultiSelectFilter} height={300}/>
						</IsolateEvent>
					</Grid>
					<Grid item xs={3}>
						<IsolateEvent wheel scroll>
							<MultiSelect name={"dealersSelected"} label={"Dealer"} options={filters.dealerOptions} values={filters.dealersSelected} idProperty="number" valueProperty="name" changeInputSelect={this.handleMultiSelectFilter} height={300} />
						</IsolateEvent>
					</Grid>
					<Grid item xs={3}>
						<IsolateEvent wheel scroll>
							<MultiSelect name={"sfcCultivationsSelected"} label={"SFC Cultivation"} options={filters.sfcCultivationOptions} values={filters.sfcCultivationsSelected} changeInputSelect={this.handleMultiSelectFilter} height={300}/>
						</IsolateEvent>
					</Grid>
					<Grid item xs={2}>
						<IsolateEvent wheel scroll>
							<MultiSelect name={"sfcSalespeopleSelected"} label={"SFC Salesperson"} options={filters.sfcSalespersonOptions} values={filters.sfcSalespeopleSelected} changeInputSelect={this.handleMultiSelectFilter} height={300}/>
						</IsolateEvent>
					</Grid>
				</Grid>
				<Grid container item xs={12} spacing={3} style={{marginTop:20}}>
					<Grid item xs={3}>
						<IsolateEvent wheel scroll>
							<MultiSelect name={"statesSelected"} label={"State"} options={filters.stateOptions} values={filters.statesSelected} changeInputSelect={this.handleMultiSelectFilter} height={300}/>
						</IsolateEvent>
					</Grid>
					<Grid item xs={3}>
						<IsolateEvent wheel scroll>
							<MultiSelect name={"loanTypesSelected"} label={"Dealer Name"} options={filters.loanTypeOptions} values={filters.loanTypesSelected} changeInputSelect={this.handleMultiSelectFilter} height={300} />
						</IsolateEvent>
					</Grid>
					<Grid item xs={3}>
						<IsolateEvent wheel scroll>
							<MultiSelect name={"productsSelected"} label={"Product"} options={filters.productOptions} values={filters.productsSelected} changeInputSelect={this.handleMultiSelectFilter} height={300}/>
						</IsolateEvent>
					</Grid>
					<Grid item xs={2}>
					</Grid>
				</Grid>
				<Grid container item xs={12} spacing={3} style={{marginTop:20}}>
					<Grid item xs={4}>
						<DatePickerRange
							label={"Date"}
							fromDate={filters.fromDate}
							fromDateId="fromDate"
							toDate={filters.toDate}
							toDateId="toDate"
							onDatesChange={this.handleDate}
							onSubmit={this.props.handleSubmit}
						/>
					</Grid>
					<Grid item xs={6} alignItems="flex-end">
						{/* <NetworkButton text={ "Submit" } primary={ true } style={{margin: 10, height: 36}} onClick={ this.props.handleSubmit } /> */}
						<NetworkButton text={"Excel"} primary style={{margin: 10, height: 36}} onClick={this.submitExcel} />
						<NetworkButton text={"Reset"} primary style={{margin: 10, height: 36, backgroundColor:"#4444F9"}} onClick={this.resetFilters} />
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

export default connect(null, mapDispatchToProps)(DealerPerformanceReportFilter);
