import React from "react";
import PropTypes from "prop-types";
import TableInfinityLoader, { TableInfinityRow, TableInfinityCell } from "../../../commons/components/TableInfinityLoader"

const columnState = 30;
const columnDealerName = 350; 
const defaltColumn = 100;

class DealerTrackingTable extends React.Component {
	currencyTableCell(value="0.0"){
		return <TableInfinityCell style={{width:defaltColumn}}>{value.toLocaleString("en-US", { style: "currency", currency: "USD" })}</TableInfinityCell>;
	}

	Row( event, row, parent ) {
		return (
			<TableInfinityRow event={event} variant="body">
				<TableInfinityCell style={{width:defaltColumn}} >{row["sfcSalesperson"]}</TableInfinityCell>
				<TableInfinityCell style={{width:columnDealerName}}>{row["name"]}</TableInfinityCell>
				<TableInfinityCell style={{width:defaltColumn}}>{row["number"]}</TableInfinityCell>
				<TableInfinityCell style={{width:columnState}}>{row["state"]}</TableInfinityCell>
				<TableInfinityCell style={{width:defaltColumn}}>{row["appsDay"]}</TableInfinityCell>
				<TableInfinityCell style={{width:defaltColumn}}>{row["appsDayApproved"]}</TableInfinityCell>
				<TableInfinityCell style={{width:defaltColumn}}>{ (row["appsDayApprovedPercentage"]* 100).toFixed(2) + "%" }</TableInfinityCell>
				{ parent.currencyTableCell(row["amountAppsDayApproved"]) }
				<TableInfinityCell style={{width:defaltColumn}}>{row["appsMonth"]}</TableInfinityCell>
				<TableInfinityCell style={{width:defaltColumn}}>{row["appsMonthApproved"]}</TableInfinityCell>
				<TableInfinityCell style={{width:defaltColumn}}>{ (row["appsMonthApprovedPercentage"]* 100).toFixed(2) + "%" }</TableInfinityCell>
				{ parent.currencyTableCell(row["amountAppsMonthApproved"]) }
				<TableInfinityCell style={{width:defaltColumn}}>{row["appsYear"]}</TableInfinityCell>
				<TableInfinityCell style={{width:defaltColumn}}>{row["appsYearApproved"]}</TableInfinityCell>
				<TableInfinityCell style={{width:defaltColumn}}>{ (row["appsYearApprovedPercentage"]* 100).toFixed(2) + "%" }</TableInfinityCell>
				{ parent.currencyTableCell(row["amountAppsYearApproved"]) }
				<TableInfinityCell style={{width:defaltColumn}}>{row["appsDayFunded"]}</TableInfinityCell>
				{ parent.currencyTableCell(row["appsDayFundedAmount"]) }
				<TableInfinityCell style={{width:defaltColumn}}>{row["appsMonthFunded"]}</TableInfinityCell>
				{ parent.currencyTableCell(row["appsMonthFundedAmount"]) }
				<TableInfinityCell style={{width:defaltColumn}}>{row["appsYearFunded"]}</TableInfinityCell>
				{ parent.currencyTableCell(row["appsYearFundedAmount"]) }
			</TableInfinityRow>
		);
	}

	Header(event){
		return (
			<TableInfinityRow event={event} variant="head">
				<TableInfinityCell style={{width:defaltColumn}} >Sales Person Name</TableInfinityCell>
				<TableInfinityCell style={{width:columnDealerName}}>Dealer Name</TableInfinityCell>
				<TableInfinityCell style={{width:defaltColumn}}>Dealer Number</TableInfinityCell>
				<TableInfinityCell style={{width:columnState}}>State</TableInfinityCell>
				<TableInfinityCell style={{width:defaltColumn}}>Prior Date # of Apps Submitted</TableInfinityCell>
				<TableInfinityCell style={{width:defaltColumn}}>Prior Date # of Apps Approved</TableInfinityCell>
				<TableInfinityCell style={{width:defaltColumn}}>Prior Date # of Apps Approved %</TableInfinityCell>
				<TableInfinityCell style={{width:defaltColumn}}>Prior date Amount of Apps Approved</TableInfinityCell>
				<TableInfinityCell style={{width:defaltColumn}}>MTD # of Apps Submitted</TableInfinityCell>
				<TableInfinityCell style={{width:defaltColumn}}>MTD # of Apps Approved</TableInfinityCell>
				<TableInfinityCell style={{width:defaltColumn}}>MTD # of Apps Approved %</TableInfinityCell>
				<TableInfinityCell style={{width:defaltColumn}}>MTD Amount of Apps Approved</TableInfinityCell>
				<TableInfinityCell style={{width:defaltColumn}}>YTD # of Apps Submitted</TableInfinityCell>
				<TableInfinityCell style={{width:defaltColumn}}>YTD # of Apps Approved</TableInfinityCell>
				<TableInfinityCell style={{width:defaltColumn}}>YTD # of Apps Approved %</TableInfinityCell>
				<TableInfinityCell style={{width:defaltColumn}}>YTD Amount of Apps Approved</TableInfinityCell>
				<TableInfinityCell style={{width:defaltColumn}}>Prior Day Apps Funded</TableInfinityCell>
				<TableInfinityCell style={{width:defaltColumn}}>Prior Day Funded</TableInfinityCell>
				<TableInfinityCell style={{width:defaltColumn}}>MTD Apps Funded</TableInfinityCell>
				<TableInfinityCell style={{width:defaltColumn}}>MTD Funded</TableInfinityCell>
				<TableInfinityCell style={{width:defaltColumn}}>YTD Apps Funded</TableInfinityCell>
				<TableInfinityCell style={{width:defaltColumn}}>YTD Funded</TableInfinityCell>
			</TableInfinityRow>
		);
	}

	render(){
		return(
			<TableInfinityLoader
				listGrid={this.props.listGrid}
				drawRow={this.Row}
				drawHeader={this.Header}
				handleSubmit={this.props.handleSubmit}
				params={this.props.filters}
				parent={this}
				forSingleScroll={this.props.forSingleScroll}
			/>
		);
	}
}

DealerTrackingTable.propTypes = {
	filters: PropTypes.object,
	handleSubmit: PropTypes.func,
	listGrid: PropTypes.array,
	forSingleScroll: PropTypes.string
};

export default DealerTrackingTable;