import React from "react";
import PropTypes from "prop-types";
import { Link } from "@material-ui/core";
import Moment from "moment";
import TableInfinityLoader, { TableInfinityRow, TableInfinityCell } from "../../../commons/components/TableInfinityLoader"

class DealerReportTable extends React.Component {

	Row( event, row, parent ) {
		return (
			<TableInfinityRow event={event} variant="body">
				<TableInfinityCell style={{width:200}} >{row["dealerAssociation"]}</TableInfinityCell>
				<TableInfinityCell style={{width:400}} >
					<Link
						component="button"
						style={{color:"blue", fontSize: 11, textAlign:"left"}}
						onClick={parent.props.handleLoadDetails.bind(parent, row["id"])}
					>
						{row["name"]}
					</Link>
				</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >{row["number"]}</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >{row["lennoxNumber"]}</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >{row["approved"]}</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >{ row["wpdiApprovalDate"] === null ? "" : Moment( new Date( row["wpdiApprovalDate"] ) ).format( "MM-DD-YYYY" )}</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >{row["enrollStatus"]}</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >{row["tradeReferences"]}</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >{row["financials"]}</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >{row["state"]}</TableInfinityCell>
				<TableInfinityCell style={{width:200}} >{row["city"]}</TableInfinityCell>
				<TableInfinityCell style={{width:300}} >{row["address"]}</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >{row["zip"]}</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >{ row["enrollFollowupDate"] === null ? "" : Moment( new Date( row["enrollFollowupDate"] ) ).format( "MM-DD-YYYY" )}</TableInfinityCell>
				<TableInfinityCell style={{width:150}} >{row["companyRegion"]}</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >{row["districtNumber"]}</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >{row["districtName"]}</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >{row["territoryNumber"]}</TableInfinityCell>
				<TableInfinityCell style={{width:150}} >{row["tmName"]}</TableInfinityCell>
				<TableInfinityCell style={{width:200}} >{row["capPackage"]}</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >{row["dlpd"]}</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >{row["type"]}</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >{row["warranty"]}</TableInfinityCell>
				<TableInfinityCell style={{width:150}} >{row["sfcSalesperson"]}</TableInfinityCell>
				<TableInfinityCell style={{width:300}} >{row["email"]}</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >{row["phone"]}</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >{row["fax"]}</TableInfinityCell>
				<TableInfinityCell style={{width:150}} >{row["contLicNumber"]}</TableInfinityCell>
				<TableInfinityCell style={{width:150}} >{row["bbbReport"]}</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >{row["credit"]}</TableInfinityCell>
				<TableInfinityCell style={{width:150}} >{row["sfcCultivation"]}</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >{ row["setupDate"] === null ? "" : Moment( new Date( row["setupDate"] ) ).format( "MM-DD-YYYY" )}</TableInfinityCell>
				<TableInfinityCell style={{width:200}} >{row["asset"]}</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >{row["paceApproved"] === "True" ? "Yes" : "No"}</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >{row["active"] === "True" ? "Yes" : "No"}</TableInfinityCell>
				<TableInfinityCell style={{width:250}} >{row["contact"]}</TableInfinityCell>
				<TableInfinityCell style={{width:150}} >{row["contact1"]}</TableInfinityCell>
				<TableInfinityCell style={{width:150}} >{row["contact2"]}</TableInfinityCell>
				<TableInfinityCell style={{width:150}} >{row["decryptedFein"]}</TableInfinityCell>
			</TableInfinityRow>
		);
	};

	Header(event){
		return (
			<TableInfinityRow event={event} variant="head">
				<TableInfinityCell style={{width:200}} >Dealer Association</TableInfinityCell>
				<TableInfinityCell style={{width:400}} >Dealer Name</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >SFC Dealer Number</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >Lennox Dealer Number</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >Dealer Apprv</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >WPDI Apprv Date</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >Enrollment Status</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >Trade References</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >Financials</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >Dealer State</TableInfinityCell>
				<TableInfinityCell style={{width:200}} >Dealer City</TableInfinityCell>
				<TableInfinityCell style={{width:300}} >Dealer Address</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >Dealer Zip Code</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >Enrollment Followup Date</TableInfinityCell>
				<TableInfinityCell style={{width:150}} >Company Region</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >Dist#</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >DistrictName</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >Territory Number</TableInfinityCell>
				<TableInfinityCell style={{width:200}} >TM Name</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >Cap Package</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >DLPD</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >Dealer Type</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >Dealer Warranty</TableInfinityCell>
				<TableInfinityCell style={{width:300}} >SFC Salesman</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >Dealer Email</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >Dealer Phone</TableInfinityCell>
				<TableInfinityCell style={{width:150}} >Dealer Fax</TableInfinityCell>
				<TableInfinityCell style={{width:150}} >Cont Lic Number</TableInfinityCell>
				<TableInfinityCell style={{width:150}} >BBB Report</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >Dealer Credit</TableInfinityCell>
				<TableInfinityCell style={{width:150}} >SFC Cultivation</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >Setup Date</TableInfinityCell>
				<TableInfinityCell style={{width:200}} >Asset</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >Pace Approved</TableInfinityCell>
				<TableInfinityCell style={{width:100}} >Dealer Active</TableInfinityCell>
				<TableInfinityCell style={{width:250}} >Dealer Contact</TableInfinityCell>
				<TableInfinityCell style={{width:150}} >Contact1</TableInfinityCell>
				<TableInfinityCell style={{width:150}} >Contact2</TableInfinityCell>
				<TableInfinityCell style={{width:150}} >FEIN</TableInfinityCell>
			</TableInfinityRow>
		);
	}

	render() {
		return (
			<TableInfinityLoader
				listGrid={this.props.listGrid}
				drawRow={this.Row}
				drawHeader={this.Header}
				handleSubmit={this.props.handleSubmit}
				params={this.props.filters}
				parent={this}
				forSingleScroll={this.props.forSingleScroll}
			/>
		);
	}
}

DealerReportTable.propTypes = {
	filters: PropTypes.object,
	handleLoadDetails: PropTypes.func,
	handleSubmit: PropTypes.func,
	listGrid: PropTypes.array,
	forSingleScroll: PropTypes.string
};

export default DealerReportTable;

