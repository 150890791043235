import { apiGet, apiPost } from "../../../actions";
import fileDownload from "js-file-download";
import Moment from "moment";

export const initialFilters = {
	lender: 7,
	type: "Advanced Rate",
	contactMethod: "All",
	ignoreFico: "yes",
	ignoreDuplicatedDeclined: "no",
	ignoreSkeps: "no",
	stageLoan: "all",
	loanType: [],
	dealerAssociation: [],
	state: [],
	sfcCultivation: [],
	sfcSalesperson: [],
	regions: [],
	product: [],
	dealerId: [],
	fromLoanFundingDate: null,
	toLoanFundingDate: null,
	fromContractValidatedDate: null,
	toContractValidatedDate: null,
	fromSubmitDate: null,
	toSubmitDate: null,
	groupBy: null,
	detailsResult: {},
	detailsStartIndex: 0,
	detailsStopIndex: 100,
	applicationType: null,
	skepLoans: "0",
	tu144Override: "all",
	startIndex: 0,
	stopIndex: 100,
	orderBy: "",
	order: "asc"
};

const defaultPath = "api/FundingManagement/OriginationSummary";

export function loadFilters(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: "api/CommonFilter/loadOriginationSummaryFilters",
			onSuccess: response => {
				let { lenders, loanTypes, dealerAssociations, states, sfcCultivations, sfcSalesperson, regions, products, dealerNames, contactMethods } = response.data;
				props.setState({
					lendersList: lenders,
					loanTypesList: loanTypes,
					dealerAssociationList: dealerAssociations,
					statesList: states,
					sfcCultivationList: sfcCultivations,
					sfcSalespersonList: sfcSalesperson,
					regionsList: regions,
					productList: products,
					dealerNameList: dealerNames,
					contactMethodList: contactMethods,
				});
			}
		});
	}
}

export function loadFilterType(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath + "/loadFilterType",
			onSuccess: response => {
				props.setState({ typesList: response.data });
			}
		} );
	}
}

export function submit( props, filters ) {
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath,
			body: convertFilterToParams(filters),
			onSuccess: response => {
				props.setState({
					listGrid: response.data,
					isSubmitted: true
				});
			}
		} );
	};
}

export function downloadExcelReport( filters ) {
	return dispatch => {
		apiPost( dispatch, {
			responseType: "blob",
			url: defaultPath + '/downloadExcel',
			body: convertFilterToParams(filters),
			onSuccess: response => {
				fileDownload( response.data, "OriginationSummary.xlsx" );
			}
		} );
	};
}

export function submitDetails( props, filters ) {
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath + "/details",
			body: convertFilterToParams(filters),
			onSuccess: response => {
				props.setState({
					listDetailsGrid: response.data,
					openDetailsModal: true
				});
			}
		} );
	};
}

export function downloadDetailExcelReport( filters ) {
	return dispatch => {
		apiPost( dispatch, {
			responseType: "blob",
			url: defaultPath + '/downloadDetailExcel',
			body:convertFilterToParams(filters),
			onSuccess: response => {
				fileDownload( response.data, "OriginationSummaryDetail.xlsx" );
			}
		} );
	};
}

function convertFilterToParams(filters) {
	return {
		lenders: [filters.lender],
		states: filters.state.map(a => a.id),
		loanTypes: filters.loanType.map(a => a.id),
		dealerAssociationIDs: filters.dealerAssociation.map(a => a.id),
		dealerIDs: filters.dealerId.map(a => a.id),
		productIDs: filters.product.filter(a => a !== "All").map(a => a.id),
		regionsList: filters.regions,
		typesList: [filters.type],
		sfcCultivationIDs: filters.sfcCultivation.filter(a => a !== "All").map(s => s.id),
		sfcSalespersonIds: filters.sfcSalesperson.filter(a => a !== "All").map(a => a.id),
		fromLoanFundingDate: filters.fromLoanFundingDate !== null ? Moment( new Date( filters.fromLoanFundingDate ) ).format( "MM-DD-YYYY" ) : null,
		toLoanFundingDate: filters.toLoanFundingDate !== null ? Moment( new Date( filters.toLoanFundingDate ) ).format( "MM-DD-YYYY" ) : null,
		fromContractValidatedDate: filters.fromContractValidatedDate !== null ? Moment( new Date( filters.fromContractValidatedDate ) ).format( "MM-DD-YYYY" ) : null,
		toContractValidatedDate: filters.toContractValidatedDate !== null ? Moment( new Date( filters.toContractValidatedDate ) ).format( "MM-DD-YYYY" ) : null,
		fromSubmitDate: filters.fromSubmitDate !== null ? Moment( new Date( filters.fromSubmitDate ) ).format( "MM-DD-YYYY" ) : null,
		toSubmitDate: filters.toSubmitDate !== null ? Moment( new Date( filters.toSubmitDate ) ).format( "MM-DD-YYYY" ) : null,
		stageLoan: filters.stageLoan,
		ignoreFico: filters.ignoreFico === "yes",
		ignoreDuplicatedDeclined: filters.ignoreDuplicatedDeclined === "yes",
		ignoreSkeps: filters.ignoreSkeps === "yes",
		groupBy: filters.groupBy,
		applicationType: filters.applicationType,
		detailsStartIndex: filters.detailsStartIndex,
		detailsStopIndex: filters.detailsStopIndex,
		skepLoans: filters.skepLoans,
		tu144Override: stringToNullableBoolean(filters.tu144Override),
		startIndex: filters.startIndex,
		stopIndex: filters.stopIndex,
		orderBy: filters.orderBy,
		order: filters.order,
		contactMethod: filters.contactMethod
	}
}

function stringToNullableBoolean(value) {
	if (!value || value === "all") {
		return null;
	} else if (value === "yes") {
		return true;
	} else {
		return false;
	}
}
