import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import IconUpdate from "@material-ui/icons/Create";
import IconDelete from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React from "react";
import DialogYesNo from "../../../commons/components/DialogYesNo";
import TableInfinityLoader, { TableInfinityCell, TableInfinityRow } from "../../../commons/components/TableInfinityLoader";
import tableStyle from "../../../commons/components/TableStyle";

class UserAccountTable extends React.Component {
	state = {
		statusShowDeleteDialog: false,
		idToDelete: 0,
		search: "",
		filteredList: [],
	};

	componentDidMount() {
		this.setState({
			filteredList: this.props.list,
		});
	}

	componentDidUpdate(previousProps) {
		if (previousProps.list !== this.props.list) {
			this.setState({
				filteredList: this.props.list,
			});
		}
	}

	handleSearchChange = event => {
		this.setState({
			search: event.target.value,
			filteredList: this.filterList(event.target.value, this.props.list),
		});
	};

	filterList = (searchString, filteredList) => {
		searchString = searchString.toLowerCase();
		searchString = searchString.trim().replace(/[ ]{2,}/g, " ").split(" ");
		return filteredList.filter(user => {
			return searchString.every(searchString => {
				if (this.isNotNull(user.email).includes(searchString) || this.isNotNull(user.firstName).includes(searchString) || this.isNotNull(user.lastName).includes(searchString) || this.isNotNull(user.loginName).includes(searchString)) {
					return true;
				}
				return false;
			});
		});
	};

	isNotNull = (userString) => {
		if(userString) {
			return userString.toLowerCase();
		}
		return "";
	}

	handleEditItem = item => {
		this.props.loadItem( item );
	};

	handleSetIdToDelete = (id) => {
		this.setState({
			statusShowDeleteDialog: true,
			idToDelete: id
		});
	};

	handleShowDeleteDialog = () => {
		this.setState({
			statusShowDeleteDialog: false,
			idToDelete: 0
		});
	};

	handleDeleteItem = () => {
		this.props.deleteItem( this.state.idToDelete );
		this.handleShowDeleteDialog();
	};

	Row( event, row, parent ) {
		return (
			<TableInfinityRow event={event} variant="body">
				<TableInfinityCell style={{width:50}}>
					<IconButton style={ tableStyle.cmdButton } tooltip="Edit" onClick={ () => parent.handleEditItem( row ) } >
						<IconUpdate />
					</IconButton>
					<IconButton style={ tableStyle.cmdButton } tooltip="Delete" onClick={ () => parent.handleSetIdToDelete( row.id ) } >
						<IconDelete />
					</IconButton>
				</TableInfinityCell>
				<TableInfinityCell style={{width:150}}>{row.firstName}</TableInfinityCell>
				<TableInfinityCell style={{width:150}}>{row.lastName}</TableInfinityCell>
				<TableInfinityCell style={{width:150}}>{row.loginName}</TableInfinityCell>
				<TableInfinityCell style={{width:170}}>{row.email}</TableInfinityCell>
				<TableInfinityCell style={{width:50}}>{row.isLocked ? "Yes" : "No"}</TableInfinityCell>
			</TableInfinityRow>
		);
	}

	Header(event){
		return (
			<TableInfinityRow event={event} variant="head">
				<TableInfinityCell style={{width:50}}>{""}</TableInfinityCell>
				<TableInfinityCell style={{width:150}}>First Name</TableInfinityCell>
				<TableInfinityCell style={{width:150}}>Last Name</TableInfinityCell>
				<TableInfinityCell style={{width:150}}>Login Name</TableInfinityCell>
				<TableInfinityCell style={{width:170}}>Email</TableInfinityCell>
				<TableInfinityCell style={{width:50}}>Locked</TableInfinityCell>
			</TableInfinityRow>
		);
	};

	render() {
		return (
			<div>
				<Input type="text" placeholder="Search" value={this.state.search} onChange={this.handleSearchChange} style={{ width: "750px", maxWidth:"100%" , marginTop: 15, marginBottom: 15 }} />
				<TableInfinityLoader
					listGrid={this.state.filteredList}
					drawRow={this.Row}
					drawHeader={this.Header}
					parent={this}
					rowSize={24}
					rowSizeHeader={24}
				/>
				<DialogYesNo
					onShow = { this.handleShowDeleteDialog }
					onHide = { this.handleShowDeleteDialog }
					onConfirmation={ this.handleDeleteItem }
					status={ this.state.statusShowDeleteDialog }
					text={ "Are you sure to delete this item?" }/>
			</div>
		);
	}
}

UserAccountTable.propTypes = {
	deleteItem: PropTypes.func,
	idDelete: PropTypes.number,
	list: PropTypes.array,
	loadItem: PropTypes.func,
	setIDDelete: PropTypes.func,
	showCancelButton: PropTypes.bool,
	showDeleteDialog: PropTypes.func,
	statusShowDeleteDialog: PropTypes.bool
};

export default UserAccountTable;
