import React, { Component } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import TableInfinityLoader, { TableInfinityRow, TableInfinityCell } from "../../../../commons/components/TableInfinityLoader"
import DialogYesNo from "../../../../commons/components/DialogYesNo";
import IconDelete from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import NetworkButton from "../../../../commons/components/NetworkButton";

const styles = {
	stylePaperResult: {
		margin: 2,
		padding: 10
	},
	cmdButton: {
		padding: 0
	}
};

class DeleteFundingPackageResult extends Component {
	constructor( props ) {
		super( props );
		this.state = { statusShowDeleteDialog: false, idDelete: -1 };
	}

	handleShowDeleteDialog = () => {
		this.setState( {  statusShowDeleteDialog: false, idDelete: -1 } );
	};

	handleDeleteItem = () => {
		let { idDelete } = this.state;
		if(idDelete > 0)
			this.props.onDeleteItem( this.state.idDelete );
		else
			this.props.onDeleteAll();

		this.handleShowDeleteDialog();
	};

	handleSetIdToDelete = (id) => {
		this.setState({
			idDelete:id,
			statusShowDeleteDialog: true
		});
	};

	handleOpenDeleteDialog = () => {
		this.setState({statusShowDeleteDialog: true});
	}

	Row( event, row, parent ) {
		return (
			<TableInfinityRow event={event} variant="body">
				<TableInfinityCell style={{width:25}}>
					<IconButton
						style={ styles.cmdButton }
						tooltip="Delete"
							onClick={ () => parent.handleSetIdToDelete( row.id ) }>
						<IconDelete />
					</IconButton>
				</TableInfinityCell>
				<TableInfinityCell style={{width:60}}>{ row.applicationNumber }</TableInfinityCell>
				<TableInfinityCell style={{width:300}}>{ row.borrowerName }</TableInfinityCell>
			</TableInfinityRow>
		);
	}

	Header(event){
		return (
			<TableInfinityRow event={event} variant="head">
				<TableInfinityCell style={{width:25}}>{""}</TableInfinityCell>
				<TableInfinityCell style={{width:60}}>Application Number</TableInfinityCell>
				<TableInfinityCell style={{width:300}}>Borrower Name</TableInfinityCell>
			</TableInfinityRow>
		)
	}

	render() {
		return (
			<div>
				<Paper style={ styles.stylePaperResult } elevation={1}>
					<NetworkButton text={ "Delete" } primary={ true } onClick={ this.handleOpenDeleteDialog }/>
					<TableInfinityLoader
						listGrid={this.props.dataSource}
						drawRow={this.Row}
						drawHeader={this.Header}
						parent={this}
						rowSize={24}
						rowSizeHeader={24}
					/>
					<DialogYesNo
						onShow = { this.handleShowDeleteDialog }
						onHide = { this.handleShowDeleteDialog }
						onConfirmation={ this.handleDeleteItem }
						status={ this.state.statusShowDeleteDialog }
						text={ "Are you sure to delete this item?" }/>
				</Paper>
			</div>
		);
	}
}

DeleteFundingPackageResult.propTypes = {
	dataSource: PropTypes.array,
	onDeleteAll: PropTypes.func,
	onDeleteItem: PropTypes.func,
};

export default DeleteFundingPackageResult;
