import React from "react";
import PropTypes from "prop-types";
import { renderMenuItems } from "../../../commons/utils";
import { SelectValidator } from "react-material-ui-form-validator";
import Grid from "@material-ui/core/Grid";
import NetworkButton from "../../../commons/components/NetworkButton";
import { ValidatorForm } from "react-form-validator-core";

class LenderLoanTypeFilters extends React.Component {
    render(){
        const { filters, lenderOptions, loanTypeOptions, showFilters } = this.props;
        if(showFilters)
            return (
                <ValidatorForm onSubmit={ this.props.handleSubmitForm } noValidate>
                    <Grid container style={{margin: 2, padding: 15, width: "50%"}}>
                        <Grid item xs style={ { marginRight: 15 } }>
                            <SelectValidator
                                name="lender"
                                label="Select a lender"
                                value={ filters.lender }
                                onChange={ this.props.handleSelectFilter }
                                validators={ ["required"] }
                                errorMessages={ ["It is mandatory."] }
                                fullWidth
                            >
                                {renderMenuItems( lenderOptions, true )}
                            </SelectValidator>
                        </Grid>
                        <Grid item xs>
                            <SelectValidator
                                name="loanType"
                                label="Select a Loan Type"
                                value={ filters.loanType }
                                onChange={ this.props.handleSelectFilter }
                                fullWidth
                            >
                                {renderMenuItems( loanTypeOptions, "description" )}
                            </SelectValidator>
                        </Grid>
                        <Grid item xs={1}>
                            <NetworkButton text={ "Submit" } primary={ true } type="submit" />
                        </Grid>
                    </Grid>
                </ValidatorForm>
            );
        
        return(<div/>);
    }
}

LenderLoanTypeFilters.propTypes = {
	loanTypeOptions: PropTypes.array,
	filters: PropTypes.object,
	handleSelectFilter: PropTypes.func,
	handleSubmitForm: PropTypes.func,
    lenderOptions: PropTypes.array,
    showFilters: PropTypes.bool
};

export default LenderLoanTypeFilters;