import { apiGet, apiPost } from "../../../actions";
import fileDownload from "js-file-download";
import Moment from "moment";

export const initialFilters = {
	dealerAssociation: [],
	sfcCultivation: [],
	sfcSalesperson:[],
	state:[],
	fromSubmitDate: null,
	toSubmitDate: null,
	startIndex: 0,
	stopIndex: 100
};

const defaultPath = "api/DealerManagement/DealerTrackingReport";

export function loadFilters(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: "api/CommonFilter/loadDealerTrackingFilters",
			onSuccess: response => {
				props.setState({
					dealerAssociationList: response.data.dealerAssociations,
					sfcCultivationList:response.data.sfcCultivations,
					sfcSalespersonList:response.data.sfcSalesperson,
					stateList:response.data.states
				});
			}
		} );
	}
}

export function submit( props, filters ) {
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath,
			body: convertFilterToParams(filters),
			onSuccess: response => {
				props.setState({
					listGrid: response.data,
					isSubmitted: true,
				});
			}
		} );
	};
}

export function downloadExcelReport( filters ) {
	return dispatch => {
		apiPost( dispatch, {
			responseType: "blob",
			url: defaultPath + '/downloadExcel',
			body: convertFilterToParams(filters),
			onSuccess: response => {
				var now = new Date();
				fileDownload( response.data, `DealerTrackingReport-${now.getMonth() + 1}-${now.getDate()}-${now.getFullYear()}.xlsx` );
			}
		} );
	};
}

function convertFilterToParams(filters) {
	return {
		dealerAssociation: JSON.stringify(filters.dealerAssociation.map(a => a.id)),
		sfcCultivationIds: JSON.stringify(filters.sfcCultivation.map(a => a.id)),
		sfcSalespersonIds: JSON.stringify(filters.sfcSalesperson.map(a => a.id)),
		state: JSON.stringify(filters.state.map(a => a.id)),
		fromSubmitDate: filters.fromSubmitDate !== null ? Moment( new Date( filters.fromSubmitDate ) ).format( "MM-DD-YYYY" ) : null,
		toSubmitDate: filters.toSubmitDate !== null ? Moment( new Date( filters.toSubmitDate ) ).format( "MM-DD-YYYY" ) : null,
		startIndex: filters.startIndex,
		stopIndex: filters.stopIndex
	}
}