/**
 * Dispatches and action: { type, payload: { field: data.name, value: data.value } }
 *  Assumes it is binded with the react component and it is passed the action type.
 * Ex: ... onChange: { onFieldChanged.bind( this, "Some.Module.FieldChaned") }
 *
 * @param {*} type the type of the action
 * @param {*} event onChange event
 * @param {*} data onChange data
 */
// export function dispatchFieldChanged(type, event) {
// 	let name = event.target.name;
// 	let value = event.target.value;
// 	this.props.dispatch( createUpdateFieldAction( type, name, value ) );
// };

export function dispatchFieldChanged( type, event ) {
	const name = event.target.name;
	const value = event.target.value;

	return createUpdateFieldAction( type, name, value );
}

export function dispatchFieldChangedSelect( type, name, value ) {
	return createUpdateFieldAction( type, name, value );
}

export function createUpdateFieldAction( type, name, value ) {
	return { type, payload: { field: name, value: value } };
}

export function dispatchFieldChangedDate( type, name, value ) {
	if ( value === "Invalid date" ) value = "";
	return createUpdateFieldAction( type, name, value );
}

export function dispatchMultipleFormFieldChanged( type, entityId, event, data ) {
	this.props.dispatch( createMultipleFormUpdateFieldAction( type, entityId, data.name, data.value ) );
}

export function createMultipleFormUpdateFieldAction( type, entityId, name, value ) {
	return { type, payload: { id: entityId, field: name, value: value } };
}
