import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageBase from "../../../../commons/components/PageBase";
import NetworkButton from "../../../../commons/components/NetworkButton";
import { renderMenuItems } from "../../../../commons/utils";
import DatePickerRange from "../../../../commons/components/DatePickerRange";
import { Grid } from "@material-ui/core";
import {
	downloadReconciliationReportExcel,
	loadLenders
} from "./actions";

import { ValidatorForm } from "react-material-ui-form-validator";
import SelectValidator from "react-material-ui-form-validator/lib/SelectValidator";

const mapDispatchToProps = {
	downloadReconciliationReportExcel,
	loadLenders
};

class Reconciliation extends Component {

	state={
		lender: "",
		fromDate: null,
		toDate: null,
		lenders: []
	};

	constructor(props) {
		super(props);
		this.props.loadLenders(this);
    }

	handleSelectLender = ( event ) => {
		this.setState({ lender: event.target.value });
	};

	handleDate= (field, date) => {
        this.setState({[field]: date});
    }

	handleSubmitForm = () => {
		const { lender, fromDate, toDate } = this.state;
		this.props.downloadReconciliationReportExcel( lender, fromDate, toDate )
	};

	render() {
		const { lenderList, lender, fromDate, toDate } = this.state;
		return (
			<PageBase title="Reconciliation" showActions={ false }>
				<Grid container style={{marginTop:10}}>
					<ValidatorForm onSubmit={ this.handleSubmitForm } noValidate style={{width:"100%"}}>
						<Grid container item xs={6} spacing={3}>
							<Grid item xs={3}>
								<SelectValidator
									name="lender"
									label="Select a lender"
									value={ lender }
									onChange={ this.handleSelectLender }
									validators={ ["required"] }
									errorMessages={ ["It is mandatory."] }
									fullWidth
									style={{marginTop:30}}
								>
									{ renderMenuItems( lenderList, false ) }
								</SelectValidator>
							</Grid>
							<Grid item>
								<DatePickerRange
									label={"Date"}
									fromDate={fromDate} 
									fromDateId="fromDate" 
									toDate={toDate} 
									toDateId="toDate" 
									onDatesChange={this.handleDate}
									/>
							</Grid>
						</Grid>
						<br />
						<NetworkButton
							text={ "Excel" }
							primary={ true }
							type="submit"
						/>
					</ValidatorForm>
				</Grid>
			</PageBase>
		);
	}
}

Reconciliation.propTypes = {
	downloadReconciliationReportExcel: PropTypes.func,
	loadLenders: PropTypes.func
};

export default connect(
	null,
	mapDispatchToProps,
)( Reconciliation );
