import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";

const styles = {
	margin: {
		margin: 5
	},
	stylePaperResult: {
		margin: 2,
		padding: 10
	},
	whiteColorStyle: { 
		color: "#FFFFFF"
	}
};

class ViewerFundingPackageResult extends Component {
	componentDidMount() {
		if (this.props.lender !== "") {
			this.props.checkIfAllFormsTemplateExists();
		}
	}
	
	componentDidUpdate(prevProps) {
		if (this.props.lender !== prevProps.lender || this.props.date !== prevProps.date) {
			this.props.checkIfAllFormsTemplateExists();
		}
	}

	render() {
		const { handleDownloadAllForms, downloadExhibitAToAssignmentAndConveyance, downloadExhibitAtoFormOfSaleNotice, downloadCustodialLoanSchedule, downloadLenderPreFundReport, downloadFundingSchedule } = this.props;
		const icon = <Icon
						className="fa fa-download material-icons"
						style={ { verticalAlign: "middle", color: "white", marginRight: 5 } }
					/>
		let hiddenStyle = {display: "none"};

		const disableFormsButton = !this.props.hasFormsTemplate || false;

		return (
			<div>
				<Paper style={ styles.stylePaperResult } elevation={1}>
					<Button
						variant="contained"
						style={ handleDownloadAllForms ? styles.margin : hiddenStyle }
						onClick={ handleDownloadAllForms }
						color={ "primary" }
						type="submit"
						disabled={ disableFormsButton }
					>
						{icon}
						<Typography style={ styles.whiteColorStyle } title={ disableFormsButton ? "This lender does not have a template registered. Please provide it to the support team." : undefined }>
							Print All Forms
						</Typography>
					</Button>
					<Button
						variant="contained"
						style={ downloadExhibitAToAssignmentAndConveyance ? styles.margin : hiddenStyle  }
						onClick={ downloadExhibitAToAssignmentAndConveyance }
						color={ "primary" }
						type="submit"
					>
						{icon}
						<Typography style={ styles.whiteColorStyle }>
							Exhibit A to Assignment and Conveyance
						</Typography>
					</Button>
					<Button
						variant="contained"
						style={ downloadExhibitAtoFormOfSaleNotice ? styles.margin : hiddenStyle  }
						onClick={ downloadExhibitAtoFormOfSaleNotice }
						color={ "primary" }
						type="submit"
					>
						{icon}
						<Typography style={ styles.whiteColorStyle }>
							Exhibit A to Form Of Sale Notice
						</Typography>
					</Button>
					<Button
						variant="contained"
						style={ downloadCustodialLoanSchedule ? styles.margin : hiddenStyle  }
						onClick={ downloadCustodialLoanSchedule }
						color={ "primary" }
						type="submit"
					>
						{icon}
						<Typography style={ styles.whiteColorStyle }>
							Custodial Loan Schedule
						</Typography>
					</Button>
					<Button
						variant="contained"
						style={ downloadLenderPreFundReport ? styles.margin : hiddenStyle  }
						onClick={ downloadLenderPreFundReport }
						color={ "primary" }
						type="submit"
					>
						{icon}
						<Typography style={ styles.whiteColorStyle }>
							Lender Pre-Fund report
						</Typography>
					</Button>
					<Button
						variant="contained"
						style={ downloadFundingSchedule ? styles.margin : hiddenStyle  }
						onClick={ downloadFundingSchedule }
						color={ "primary" }
						type="submit"
					>
						{icon}
						<Typography style={ styles.whiteColorStyle }>
							Funding Schedule
						</Typography>
					</Button>
				</Paper>
			</div>
		);
	}
}

ViewerFundingPackageResult.propTypes = {
	downloadCustodialLoanSchedule: PropTypes.func,
	downloadExhibitAToAssignmentAndConveyance: PropTypes.func,
	downloadExhibitAtoFormOfSaleNotice: PropTypes.func,
	downloadLenderPreFundReport: PropTypes.func,
	downloadFundingSchedule: PropTypes.func,
	handleDownloadAllForms: PropTypes.func
};

export default ViewerFundingPackageResult;
