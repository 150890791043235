import React from "react";
import PropTypes from "prop-types";
import {Paper, Typography, Icon} from '@material-ui/core';

class SearchNotData extends React.Component {
    render(){
        const { isSubmitted, listGrid, children } = this.props;
		return(
			isSubmitted ?
				listGrid.length === 0 ?
					<Paper>
						<div style={{textAlign:"center"}}><Icon color="secondary" style={{fontSize:"15rem"}}>search</Icon></div>
						<Typography variant="h3" component="h2" align="center" color="secondary">No Data Found</Typography>
					</Paper>
				:
					children
			:
			<div/>
		);
    };
}

SearchNotData.propTypes = {
    isSubmitted: PropTypes.bool,
    children: PropTypes.object,
	listGrid: PropTypes.array
};

export default SearchNotData;