import { apiGet } from "../../../actions";
import Moment from "moment";
import fileDownload from "js-file-download";
export const initialFilters = {
	reportDate: new Date(),
};

const defaultPath = "api/DealerManagement/FinalDealerPackageReport"

export function generateReport(reportDate) {
	var date =  Moment(new Date(reportDate))
	return dispatch => {
			apiGet(dispatch , {
				responseType: "blob",
				url: defaultPath+ "/DownloadReport",
				params: {
					reportDate:date.format( "MM-DD-YYYY" ),
				},
				onSuccess: response => {
					fileDownload(response.data,  `FinalDealerPackageReport${date.format("YYYYMMDDHHmmss")}.xlsx`);
				},

			});
	};
}