import React, { Component } from 'react'
import PageBase from '../../../commons/components/PageBase'
import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { connect } from 'react-redux';
import { initialFilters, generateReport } from './actions';
const mapDispatchToProps = {
    generateReport
};
class ConcentrationLimits extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportDate: initialFilters.reportDate,
            lenderCode: initialFilters.lenderCode
        };
    }

    handleDate = (date) => {
        this.setState({
            reportDate: date,
        });
    }

    generate = async () => {
        const { reportDate, lenderCode } = this.state;
        this.props.generateReport(reportDate, lenderCode);
    }
    handleUpdateFilter = (e) => {
        this.setState({
            lenderCode: e.target.value
        })
    }

    render() {
        return (
            <PageBase title="Concentration Limits BL5">
                <Grid
                    container
                    spacing={2}
                    style={{ margin: 2, padding: 15, width: "40%" }}
                >
                    <FormControl style={{ width: '100%' }}>
                        <InputLabel>Lender</InputLabel>
                        <Select inputProps={{ name: "lender" }} value={this.state.lenderCode} onChange={this.handleUpdateFilter}>
                            <MenuItem value="BL5">BL5</MenuItem>
                        </Select></FormControl>
                    <Grid item xs={12}>
                        <KeyboardDatePicker
                            label="Report Date"
                            value={this.state.reportDate}
                            onChange={this.handleDate}
                            format="MM-DD-YYYY"
                            inputVariant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid container wrap="nowrap" justifyContent="space-evenly">
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.generate}
                            >
                                DOWNLOAD REPORT
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </PageBase>
        )
    }
}


export default connect(null, mapDispatchToProps)(ConcentrationLimits);