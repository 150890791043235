import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageBase from "../../../commons/components/PageBase";
import StylizedModal from "../../../commons/components/StylizedModal";
import SearchNotData from "../../../commons/components/SearchNotData";
import DealerReportTable from "./DealerReportTable";
import DealerReportFilters from "./DealerReportFilters";
import DealerDetail from "./DealerDetail"
import {
	loadFilters,
	submit,
	downloadExcelReport,
	loadDealerDetails,
	initialFilters
 } from "./actions";
import SingleScroll from "../../../commons/components/SingleScroll";

const singleScrollName = "DealerReport";

const mapDispatchToProps = {
	loadFilters,
	submit,
	downloadExcelReport,
	loadDealerDetails
};

class DealerReport extends React.Component {
	constructor(props) {
		super(props);
		this.props.loadFilters(this);
		this.state = {
			isSubmitted: false,
			openDetailsModal: false,
			dealers:[],
			filters: initialFilters,
			currentFilters: initialFilters,
			listGrid: [],
			dealerDetails:null,
			dealerAssociationList:[],
			sfcCultivationList:[],
			sfcSalespersonList:[],
			stateList:[],
			companyRegionsList:[],
			enrollmentStatusList:[],
			assetsList:[],
			dealerApprovedList:['All'],
			fhaApprovedList:['All'],
			financeProgramList:['All'],
			welcomeLTTRList:['All']
		};
	}

	componentWillUnmount(){
		this.handleReset();
	}

	handleSelectFilter = ( event ) => {
		this.setState({
			filters: {
				...this.state.filters,
				[event.target.name]:event.target.value
			}
		});
	};

	handleMultiSelectFilter = (field, value) => {
		this.setState({
			filters: {
				...this.state.filters,
				[field]:value
			}
		});
	};

	handleDate(field, date){
		this.setState({
			filters: {
				...this.state.filters,
				[field]:date
			}
		});
	};

	handleSubmit = () => {
		this.props.submit(this, this.state.filters);
		this.setState({
			currentFilters: this.state.filters
		});
	};

	handleSubmitInfinity = (params) => {
		this.props.submit(this, params);
	};

	handleReset = () => {
		this.setState({
			openDetailsModal: false,
			filters: initialFilters,
			currentFilters: initialFilters,
			listGrid: [],
			isSubmitted: false
		});
	};

	handleCloseDetailsModal() {
		this.setState({
			openDetailsModal:false,
			dealerDetails: null
		});
	}

	handleExcel = () => {
		this.props.downloadExcelReport(this.getFiltersForDetails());
	};

	handleLoadDealerDetails = (dealerID) => {
		this.props.loadDealerDetails(this, dealerID);
	};

	getFilters = () => this.state.filters;
	getFiltersForDetails = () => this.state.currentFilters;

	createTable = () => {
		const { listGrid, isSubmitted } = this.state;
		return(
			<SearchNotData
				isSubmitted={isSubmitted}
				listGrid={listGrid}
			>
				<DealerReportTable
					listGrid={listGrid}
					handleLoadDetails={this.handleLoadDealerDetails}
					handleSubmit={this.handleSubmitInfinity}
					filters={this.getFiltersForDetails()}
					forSingleScroll={singleScrollName}
				/>
			</SearchNotData>
		);
	}

	CreateModalAnddealerDetail = () => {
		return(
			<StylizedModal
				openModal={this.state.openDetailsModal}
				onClose={this.handleCloseDetailsModal.bind(this)}
			>
				<DealerDetail
					detailsResult={this.state.dealerDetails}
					onClose={this.handleCloseDetailsModal.bind(this)}
				/>
			</StylizedModal>
		);
	}

	createFilters = () => {
		return(
			<DealerReportFilters
				filters={this.state.filters}
				companyRegionsList={this.state.companyRegionsList}
				dealerApprovedList={this.state.dealerApprovedList}
				dealerAssociationList={this.state.dealerAssociationList}
				enrollmentStatusList={this.state.enrollmentStatusList}
				financeProgramList={this.state.financeProgramList}
				fhaApprovedList={this.state.fhaApprovedList}
				sfcCultivationList={this.state.sfcCultivationList}
				sfcSalespersonList={this.state.sfcSalespersonList}
				stateList={this.state.stateList}
				welcomeLTTRList={this.state.welcomeLTTRList}
				assetsList={this.state.assetsList}
				handleMultiSelectFilter={this.handleMultiSelectFilter}
				handleSelectFilter={this.handleSelectFilter}
				handleDate={this.handleDate.bind(this)}
				handleSubmit={this.handleSubmit}
				handleReset={this.handleReset}
				handleExcel={this.handleExcel}
			/>
		);
	}

	render() {
		return (
			<PageBase title="Dealer Report" >
				<SingleScroll name={singleScrollName}>
					<SingleScroll.Scrollable for={singleScrollName}>
						{this.createFilters()}
						<div style={{height:20}} />
						{this.createTable()}
						{this.CreateModalAnddealerDetail()}
					</SingleScroll.Scrollable>
				</SingleScroll>
			</PageBase>
		);
	}
}

DealerReport.propTypes = {
	downloadExcelReport: PropTypes.func,
	loadFilters: PropTypes.func,
	submit: PropTypes.func,
	loadDealerDetails: PropTypes.func
};

export default connect(
	null,
	mapDispatchToProps,
)( DealerReport );