import React, {Component} from "react";
import PropTypes from "prop-types";
import PageBase from "../../../commons/components/PageBase";
import SearchNotData from "../../../commons/components/SearchNotData";
import ModalTableApplication from "../../../commons/components/ModalTableApplication";
import StylizedModal from "../../../commons/components/StylizedModal";
import RollforwardFilters from "./RollforwardFilters";
import RollforwardTable from "./RollforwardTable";
import Paper from '@material-ui/core/Paper';
import {
    loadFilters,
    submit,
    submitDetails,
    downloadExcelReport,
    downloadDetailExcelReport,
    initialFilters
} from "./actions";
import {connect} from "react-redux";

import {	
	loadApplicationDetails,
	saveApplicationNote
} from "../../assetManager/inventoryQuery/actions";

const mapDispatchToProps = {
    loadFilters,
    submit,
    submitDetails,
    downloadExcelReport,
    downloadDetailExcelReport,
    loadApplicationDetails,
	saveApplicationNote
};

class RollforwardReport extends Component {
    state = {
        isSubmitted: false,
        filters: initialFilters,
        currentFilters: initialFilters,
        listGrid: [],
        lenderList:["All"],
        loanTypeList:["All"],
        asOfDateList:[],
        listDetailsGrid:[],
        openDetailsModal: false
    };

    constructor(...props){
        super(...props);
        this.props.loadFilters(this);
    }

    handleSelectFilter = ( event ) => {
        this.setState({
            filters: {
                ...this.state.filters,
                [ event.target.name ]: event.target.value
            }
        });
    };

    handleChangeFilter = ( field, value ) => {
		this.setState({
			filters:{
				...this.state.filters,
				[field]: value
			}
		});
	};

    handleSubmit = () => {
        this.setState({currentFilters: this.getFilters()});
		this.props.submit(this, this.getFilters());
	};

	handleReset = () => {
		this.setState({
            isSubmitted: false,
            filters: {
                ...initialFilters,
                asOfDate: this.state.asOfDateList[0],
            },
            currentFilters: {
                ...initialFilters,
                asOfDate: this.state.asOfDateList[0],
            },
            listGrid: []
        });
	};

	handleExcel = () => {
		this.props.downloadExcelReport(this.getCurrentFilters());
    };

    handleCloseDetailsModal = () => {
		this.setState({
			openDetailsModal:false,
            listDetailsGrid: [],
            currentFilters:{
                ...this.state.currentFilters,
                previousDelinquecy: null
            }
		});
    }
    
    handleLoadDetails = (previousDelinquecy) => {
		const filters = this.getCurrentFilters();
        filters.previousDelinquecy = previousDelinquecy;
		filters.startIndex = 0;
		filters.stopIndex = 100;
		this.props.submitDetails(this, filters);
    }

	handleLoadDetailsPage = ({ startIndex, stopIndex }) => {
		const filters = this.getCurrentFilters();
		filters.startIndex = startIndex;
		filters.stopIndex = stopIndex;
		this.props.submitDetails(this, filters);
	}
    
    handleExcelDetail = () => {
        const filters = this.getCurrentFilters();
		this.props.downloadDetailExcelReport(filters);
    };

    getFilters = () => this.state.filters;
    getCurrentFilters = () => this.state.currentFilters;

    createModaldetail(){

		const { listDetailsGrid, openDetailsModal } = this.state;

		if(listDetailsGrid.length === 0)
			return <div/>;
            
            return(
                <StylizedModal
				openModal={openDetailsModal}
				onClose={this.handleCloseDetailsModal}
                >
				<ModalTableApplication
					detailsResult={ {label: "Rollforward Detail", list: listDetailsGrid } }
                    onDownloadExcel = {this.handleExcelDetail}
					onClose={this.handleCloseDetailsModal}
					onLoadPage={this.handleLoadDetailsPage}
					onLoadDetails={this.props.loadApplicationDetails}
					saveApplicationNote={this.props.saveApplicationNote}
				/>
			</StylizedModal>
		);
	}

    render() {
        const { filters, lenderList, loanTypeList, asOfDateList, listGrid, isSubmitted } = this.state;
        return (
            <PageBase title="Rollforward Report">
                <div>
                    <RollforwardFilters
                        filters={filters}
                        lenderList={lenderList}
                        loanTypeList={loanTypeList}
                        asOfDateList={asOfDateList}
                        handleSubmit={this.handleSubmit}
                        handleExcel={this.handleExcel}
                        handleReset={this.handleReset}
                        handleUpdateFilter={this.handleSelectFilter}
                        changeFilter={this.handleChangeFilter}
                    />
                    <Paper style={{maxHeight:"900px", position:"relative", overflowX:"auto"}}>
                        <SearchNotData listGrid={listGrid} isSubmitted={isSubmitted}>
                            <RollforwardTable listGrid={listGrid} loadDetails={this.handleLoadDetails.bind(this)} />
                        </SearchNotData>
                        {this.createModaldetail()}
                    </Paper>
                </div>
            </PageBase>
        );
    }
}

RollforwardReport.propTypes = {
    createActionResetFilters: PropTypes.func,
    downloadDetailExcelReport: PropTypes.func,
    downloadExcelReport: PropTypes.func,
    loadApplicationDetails: PropTypes.func,
	loadFilters: PropTypes.func,
	saveApplicationNote: PropTypes.func,
    submit: PropTypes.func,
    submitDetails: PropTypes.func
};

export default connect(
    null,
    mapDispatchToProps,
)( RollforwardReport );