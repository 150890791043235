import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import NetworkButton from "../../../commons/components/NetworkButton";
import Button from "@material-ui/core/Button";
import grey from "@material-ui/core/colors/grey";
import { ValidatorForm, TextValidator} from "react-material-ui-form-validator";
import { withStyles } from '@material-ui/core/styles';
import { Help, Lock as LockIcon } from "@material-ui/icons";

import { postLogin, getAccess, getUserIdByToken } from "./actions";
import { dispatchFieldChanged } from "../../fields";
import { Grid, Typography } from "@material-ui/core";

const white = "#FFFFFF";
const grey500 = grey[500];

const styles = {
	loginContainer: {
		minWidth: 320,
		maxWidth: 400,
		height: "auto",
		position: "absolute",
		top: "20%",
		left: 0,
		right: 0,
		margin: "auto"
	},
	paper: {
		padding: 20,
		overflow: "auto"
	},
	buttonsDiv: {
		textAlign: "center",
		padding: 10
	},
	button: {
		color: grey500,
		fontWeight: "bold"
	},
	rightBtn: {
		float: "right",
		fontFamily: "Cochin",
		fontSize: 20,
		fontWeight: "Heavy"
	},
	btn: {
		background: "#4f81e9",
		color: white,
		padding: 7,
		borderRadius: 2,
		margin: 2,
		fontFamily: "Cochin",
		fontSize: 20,
		fontWeight: "Heavy"
	},
	btnSpan: {
		marginLeft: 5
	},
	link: {
		color: "#9e9e9e",
		position: "relative",
		paddingLeft: 8,
		paddingRight: 16,
		verticalAlign: "middle",
		letterSpacing: 0,
		textTransform: "uppercase",
		fontWeight: 500,
		fontSize: 14
	}
};

const avatarClassStyle = {
	avatar: {
		margin: "1em",
    	width: 60,
    	height: 60
	}
}
 
const mapStateToProps = ( store ) => ({
	login: store.auth.login,
	routing: store.routing.locationBeforeTransitions,
	message: store.auth.message,
	authenticated: store.auth.authenticated
} )

const mapDispatchToProps = {
	postLogin,
	getAccess,
	dispatchFieldChanged,
	getUserIdByToken
}
class Login extends Component {
	state = {
		loginName: "",
		password: "",
	}

	handleSubmit = () => {
		const { loginName, password } = this.state;
		this.props.postLogin( loginName, password, () => {
			this.props.getUserIdByToken( token => {
				this.props.getAccess( token );
			})
		} );
	};

	onFieldChanged = e => {
		this.setState(state => ({
			...state,
			[e.target.name]: e.target.value
		}))
	}

	render() {
		const { classes } = this.props;

		return (
			<div>
				<div style={ styles.loginContainer }>
					<Paper style={ styles.paper }>
						<Grid container justifyContent="center" alignItems="center">
							<Avatar className={ classes.avatar } >
								<LockIcon fontSize="large"/>
							</Avatar>
						</Grid>
						<ValidatorForm
							onSubmit={this.handleSubmit}
						>
							<TextValidator
								autoFocus
								name="loginName"
								label="Login Name"
								fullWidth={ true }
								value={ this.state.loginName }
								onChange={ this.onFieldChanged }
								validators={ ["required"] }
								errorMessages={ ["Login Name cannot be blank."] }
							/>
							<TextValidator
								name="password"
								label="Password"
								fullWidth={ true }
								value={ this.state.password }
								onChange={ this.onFieldChanged }
								type="password"
								validators={ ["required"] }
								errorMessages={ ["Password cannot be blank."] }
							/>
							<div>
							<Typography variant="body2" style={{fontSize:11}}>
								Use your Windows credentials to login.
							</Typography>
								<br />
								<NetworkButton
									text={ "Login" }
									primary={ true }
									type="submit"
									style={ styles.rightBtn }
								/>
							</div>
						</ValidatorForm>
					</Paper>

					<div style={{ ...styles.buttonsDiv, display: "none" }}>
						<Button 
							style={ styles.button } 
							onClick={ () => {
								this.props.history.push( "/ForgotPassword" );
								this.setState();
							} }
						>
							<Help />
							<Typography style={ styles.link }>
								FORGOT PASSWORD?
							</Typography>
						</Button>
					</div>
				</div>
			</div>
		);
	}
}

Login.propTypes = {
	classes: PropTypes.object.isRequired,
	dispatchFieldChanged: PropTypes.func,
	errorMessage: PropTypes.string,
	history: PropTypes.object,
	login: PropTypes.object,
	postLogin: PropTypes.func,
	router: PropTypes.object,
	showErrorMessage: PropTypes.bool
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter( withStyles(avatarClassStyle)(Login) ))
