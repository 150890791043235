import { apiPost } from "../../../actions";
import fileDownload from "js-file-download";
import Moment from "moment";

export const initialFilters = {
	fromSubmitDate: null,
	toSubmitDate: null,
	fromLoanFundingDate: null,
	toLoanFundingDate: null,
	fromDealerAdvancedDate: null,
	toDealerAdvancedDate: null,
	fromSkepsDate: null,
	toSkepsDate: null,
	fromDepositDate: null,
	toDepositDate: null
};

const defaultPath = "api/FundingManagement/SearchFundingManagement";

export function downloadExcelReport( filters ) {
	
	var today = new Date();
	var day = String(today.getDate()).padStart(2, '0');
	var month = String(today.getMonth() + 1).padStart(2, '0');
	var year = today.getFullYear();

	var reportDate = month + '/' + day + '/' + year

	return dispatch => {
		apiPost( dispatch, {
			responseType: "blob",
			url: defaultPath + '/downloadExcel',
			body: convertFilterToParams(filters),
			onSuccess: response => {
				fileDownload( response.data, `Search Funding Management ${reportDate}.xlsx` );
			}
		} );
	};
}

function convertFilterToParams(filters) {
	return {
		fromSubmitDate: filters.fromSubmitDate !== null ? Moment( new Date( filters.fromSubmitDate ) ).format( "MM-DD-YYYY" ) : null,
		toSubmitDate: filters.toSubmitDate !== null ? Moment( new Date( filters.toSubmitDate ) ).format( "MM-DD-YYYY" ) : null,
		fromLoanFundingDate: filters.fromLoanFundingDate !== null ? Moment( new Date( filters.fromLoanFundingDate ) ).format( "MM-DD-YYYY" ) : null,
		toLoanFundingDate: filters.toLoanFundingDate !== null ? Moment( new Date( filters.toLoanFundingDate ) ).format( "MM-DD-YYYY" ) : null,
		fromDealerAdvancedDate: filters.fromDealerAdvancedDate !== null ? Moment( new Date( filters.fromDealerAdvancedDate ) ).format( "MM-DD-YYYY" ) : null,
		toDealerAdvancedDate: filters.toDealerAdvancedDate !== null ? Moment( new Date( filters.toDealerAdvancedDate ) ).format( "MM-DD-YYYY" ) : null,
		fromSkepsDate: filters.fromSkepsDate !== null ? Moment( new Date( filters.fromSkepsDate ) ).format( "MM-DD-YYYY" ) : null,
		toSkepsDate: filters.toSkepsDate !== null ? Moment( new Date( filters.toSkepsDate ) ).format( "MM-DD-YYYY" ) : null,
		fromDepositDate: filters.fromDepositDate !== null ? Moment( new Date( filters.fromDepositDate ) ).format( "MM-DD-YYYY" ) : null,
		toDepositDate: filters.toDepositDate !== null ? Moment( new Date( filters.toDepositDate ) ).format( "MM-DD-YYYY" ) : null
	}
}
