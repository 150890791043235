import React, { Component } from "react";

/**
 * @typedef { Object } Props
 * @prop { boolean } wheel
 * @prop { boolean } scroll
 */

class IsolateEvent extends Component {
	/** @param { Props } props */
	constructor(props) {
		super(props);

		/* eslint-disable */
		/** @type { Props } */
		this.props;
		/* eslint-enable */
	}

	/** @type { React.UIEventHandler<HTMLDivElement> } */
	onScroll = (e) => {
		if (this.props.scroll) e.stopPropagation();
	}

	/** @type { React.WheelEventHandler<HTMLDivElement> } */
	onWheel = (e) => {
		if (this.props.wheel) e.stopPropagation();
	}

	render() {
		return (
			<div onWheel={this.onWheel} onScroll={this.onScroll}>
				{this.props.children}
			</div>
		);
	}
}

export default IsolateEvent;
