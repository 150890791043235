import React from "react";

export default class Home extends React.Component {
	render() {
		return (
			<div style={ { backgroundColor: "gray", background: "gray" } }>
			</div>
		);
	}
}
