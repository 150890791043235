import React from "react";
import PropTypes from "prop-types";
import TableInfinityLoader, { TableInfinityRow, TableInfinityCell } from "../../../../../commons/components/TableInfinityLoader"
import Moment from "moment";

class ServicingAdviceReportResult extends React.Component {

	currencyTableCell(value="0.0"){
		if(!value) return "";
		return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
	}

 	Row( event, row, parent ) {
		return (
			<TableInfinityRow event={event} variant="body">
				<TableInfinityCell style={{width:70}}>{ Moment( new Date( row.date ) ).format( "MM/DD/YYYY" ) }</TableInfinityCell>			
				<TableInfinityCell style={{width:100}}>{ parent.currencyTableCell(row.totalFunds) }</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>{ parent.currencyTableCell(row.principal) }</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>{ parent.currencyTableCell(row.interest) }</TableInfinityCell>
				<TableInfinityCell style={{width:300}}>{ parent.currencyTableCell(row.createdBy) } </TableInfinityCell>
			</TableInfinityRow>
		);
	}

	Header(event){
		return (
			<TableInfinityRow event={event} variant="head">
				<TableInfinityCell style={{width:70}}>Date</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>Total Funds</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>Principal</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>Interest</TableInfinityCell>
				<TableInfinityCell style={{width:300}}>CreateBy</TableInfinityCell>
			</TableInfinityRow>
		);
	}

	render(){
		return (
			<TableInfinityLoader
				listGrid={this.props.list}
				drawRow={this.Row}
				drawHeader={this.Header}
				parent={this}
			/>
		);
	}
}

ServicingAdviceReportResult.propTypes = {
	list: PropTypes.array
}

export default ServicingAdviceReportResult;