import React, { Component } from "react";
import PropTypes from "prop-types";
import TableInfinityLoader, { TableInfinityRow, TableInfinityCell } from "../../../../commons/components/TableInfinityLoader"
import NetworkButton from "../../../../commons/components/NetworkButton";
import Icon from "@material-ui/core/Icon";
import SingleScroll from "../../../../commons/components/SingleScroll";

const applicationID = 65;
const dealer = 250;
const origBal = 75;
const fundAmt = 75;

const styles = {
	title: {
		fontSize: 15,
		textAlign: "center"
	}
};

class TabCreatePackage extends Component {

	currencyTableCell(value="0.0"){
		return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
	}

	Row( event, row, parent ) {
		return (
			<TableInfinityRow event={event} variant="body">
				<TableInfinityCell style={{width:applicationID}}>{row.applicationNumber}</TableInfinityCell>
				<TableInfinityCell style={{width:dealer}}>{row.dealerName}</TableInfinityCell>
				<TableInfinityCell style={{width:origBal}}>{parent.currencyTableCell(row.originalBalance)}</TableInfinityCell>
				<TableInfinityCell style={{width:fundAmt}}>{parent.currencyTableCell(row.fundingAmount)}</TableInfinityCell>
			</TableInfinityRow>
		);
	};

	Header(event){
		return (
			<TableInfinityRow event={event} variant="head">
				<TableInfinityCell style={{width:applicationID}}>App. ID</TableInfinityCell>
				<TableInfinityCell style={{width:dealer}}>Dealer Name</TableInfinityCell>
				<TableInfinityCell style={{width:origBal}}>Orig. Bal.</TableInfinityCell>
				<TableInfinityCell style={{width:fundAmt}}>Fund. Amt.</TableInfinityCell>
			</TableInfinityRow>
		);
	}

	getTotalOriginalBalance() {
		let totalAmt = 0;
		if ( this.props.dataSource.length > 0 ) {
			this.props.dataSource.map( item => ( totalAmt += item.originalBalance ) );
		}
		return this.currencyTableCell(totalAmt);
	}

	getTotalFundingAmount() {
		let totalAmt = 0;
		if ( this.props.dataSource.length > 0 ) {
			this.props.dataSource.map( item => ( totalAmt += item.fundingAmount ) );
		}
		return this.currencyTableCell(totalAmt);
	}

	getTotalLenderPurchasePriceAmt() {
		let totalAmt = 0;
		if ( this.props.dataSource.length > 0 ) {
			this.props.dataSource.map( item => ( totalAmt += item.lenderRequestAmount ) );
		}
		return this.currencyTableCell(totalAmt);
	}

	downloadFundingSchedule = (event) => {
		event.preventDefault();
		event.stopPropagation();
		this.props.downloadFundingScheduleReport( this.props.lender, this.props.initialDate, this.props.finalDate );
	};

	downloadLenderPreFundReport = (event) => {
		event.preventDefault();
		event.stopPropagation();
		this.props.downloadLenderPreFundReport( this.props.lender, this.props.initialDate, this.props.finalDate );
	};

	downloadNachaFile = (event) => {
		event.preventDefault();
		event.stopPropagation();
		this.props.downloadNachaFile( this.props.lender, this.props.initialDate, this.props.finalDate );
	};

	downloadACHHomeDepotFile = (event) => {
		event.preventDefault();
		event.stopPropagation();
		this.props.downloadACHHomeDepotFile( this.props.lender, this.props.initialDate, this.props.finalDate );
	};

	render() {
		const singleScrollName = "CreateFundingPackage";
		return (
			<SingleScroll name={singleScrollName}>
				<SingleScroll.Scrollable for={singleScrollName}>
					<div style={ styles.title }>
						These Loans will be added in the {this.props.lender} Package: <br />
						Number of applications: {this.props.dataSource.length} <br />
						Total Original Bal. (UPB):{ this.getTotalOriginalBalance() }
						<br />
						Total Funding Amount:{ this.getTotalFundingAmount() }
						<br />
						Total Lender Purchase Price:{ this.getTotalLenderPurchasePriceAmt() }
						<br />
						<br />
						<NetworkButton
							text={ "Funding Schedule" }
							primary={ true }
							onClick={ this.downloadFundingSchedule }
							style={ styles.saveButton }
							type="submit"
							icon={
								<Icon
									className="fa fa-download material-icons"
									style={ { verticalAlign: "middle", color: "white" } }
								/>
							}
						/>
						&nbsp;&nbsp;
						<NetworkButton
							text={ "Lender Pre-Fund Report" }
							primary={ true }
							onClick={ this.downloadLenderPreFundReport }
							style={ styles.saveButton }
							type="submit"
							icon={
								<Icon
									className="fa fa-download material-icons"
									style={ { verticalAlign: "middle", color: "white" } }
								/>
							}
						/>
						&nbsp;&nbsp;
						<NetworkButton
							text={ "NACHA File" }
							primary={ true }
							onClick={ this.downloadNachaFile }
							style={ styles.saveButton }
							type="submit"
							icon={
								<Icon
									className="fa fa-download material-icons"
									style={ { verticalAlign: "middle", color: "white" } }
								/>
							}
						/>
						&nbsp;&nbsp;
						{this.props.lender === "TFH" &&
							<NetworkButton
								text={ "ACH-Home Depot" }
								primary={ true }
								onClick={ this.downloadACHHomeDepotFile }
								style={ styles.saveButton }
								type="submit"
								icon={
									<Icon
										className="fa fa-download material-icons"
										style={ { verticalAlign: "middle", color: "white" } }
									/>
								}
							/>
						}
					</div>
					<br />
					<TableInfinityLoader
						listGrid={this.props.dataSource}
						drawRow={this.Row}
						drawHeader={this.Header}
						parent={this}
						rowSizeHeader={25}
						forSingleScroll={singleScrollName}
					/>
				</SingleScroll.Scrollable>
			</SingleScroll>
		);
	}
}

TabCreatePackage.propTypes = {
	dataSource: PropTypes.array.isRequired,
	downloadFundingScheduleReport: PropTypes.func.isRequired,
	downloadLenderPreFundReport: PropTypes.func.isRequired,
	downloadNachaFile: PropTypes.func.isRequired,
	downloadACHHomeDepotFile: PropTypes.func.isRequired,
	finalDate: PropTypes.object,
	initialDate: PropTypes.object,
	lender: PropTypes.string.isRequired
};

export default TabCreatePackage;
