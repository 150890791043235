import React from "react";
import PropTypes from "prop-types";
import {InputLabel, FormControl, FormControlLabel, FormLabel, Select, Grid, Radio, RadioGroup} from '@material-ui/core';
import MultiSelectChip from "../../../commons/components/MultiSelectChip";
import NetworkButton from "../../../commons/components/NetworkButton";
import DatePickerRange from "../../../commons/components/DatePickerRange";
import MultiSelect from "../../../commons/components/MultiSelect";
import { renderMenuItems, renderMenuItemsWithID } from "../../../commons/utils";
import IsolateEvent from "../../../commons/components/IsolateEvent";

class OriginationSummaryFilters extends React.Component {

	handleMultiSelectFilter = (field, value) => {
		if (value instanceof Array && value.includes("All")) {
			this.props.changeFilter(field, ["All"]);
		} else {
			this.props.changeFilter(field, value);
		}
	}

	handleSelectFilter = (event) => {
		this.props.changeFilter(event.target.name, event.target.value);
	}

	handleToggle = (field, event) => {
		this.props.changeFilter(field, event.target.value);
	}

	handleDate= (field, date) => {
		this.props.changeFilter(field, date);
	}

	getOptionLabel = item => typeof item === "string" ? item : item.value;

	render(){
		const { filters, lendersList, typesList, loanTypesList, dealerAssociationList, statesList, sfcCultivationList, sfcSalespersonList, regionsList, productList, dealerNameList, contactMethodList } = this.props;
		return (
			<Grid container style={{marginTop:10}}>
				<Grid container item xs={12} spacing={3}>
					<Grid item xs={2}>
						<FormControl style={{width:'100%'}}>
							<InputLabel>Lender</InputLabel>
							<Select inputProps={{ name: "lender" }} value={filters.lender} onChange={this.handleSelectFilter}>
								{ renderMenuItemsWithID(lendersList, false) }
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={2}>
						<FormControl style={{width:'100%'}}>
							<InputLabel>Type</InputLabel>
							<Select inputProps={{ name: "type" }} value={filters.type} onChange={this.handleSelectFilter} displayEmpty>
								{ renderMenuItems(typesList, false) }
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={2}>
						<FormControl style={{width:'100%'}}>
							<InputLabel>Contact Method</InputLabel>
							<Select inputProps={{ name: "contactMethod" }} value={filters.contactMethod} onChange={this.handleSelectFilter}>
								{ renderMenuItems(contactMethodList, true) }
							</Select>
						</FormControl>
					</Grid>
				</Grid>
				<Grid container item xs={12} spacing={3}>
					<Grid item>
						<FormControl component="fieldset">
							<FormLabel component="legend">{"Ignore fico < 600"}</FormLabel>
							<RadioGroup name="ignoreFico" value={filters.ignoreFico} row onChange={ this.handleToggle.bind(this, "ignoreFico")} >
								<FormControlLabel value="yes" control={<Radio color="primary"/>} label="Yes" />
								<FormControlLabel value="no" control={<Radio color="primary"/>} label="No" />
							</RadioGroup>
						</FormControl>
					</Grid>
					<Grid item>
						<FormControl component="fieldset">
							<FormLabel component="legend">Ignore duplicated declined</FormLabel>
							<RadioGroup name="ignoreDuplicatedDeclined" value={filters.ignoreDuplicatedDeclined} row onChange={ this.handleToggle.bind(this, "ignoreDuplicatedDeclined")} >
								<FormControlLabel value="yes" control={<Radio color="primary"/>} label="Yes" />
								<FormControlLabel value="no" control={<Radio color="primary"/>} label="No" />
							</RadioGroup>
						</FormControl>
					</Grid>
					<Grid item>
						<FormControl component="fieldset">
							<FormLabel component="legend">Ignore Skeps</FormLabel>
							<RadioGroup name="ignoreSkeps" value={filters.ignoreSkeps} row onChange={ this.handleToggle.bind(this, "ignoreSkeps")} >
								<FormControlLabel value="yes" control={<Radio color="primary"/>} label="Yes" />
								<FormControlLabel value="no" control={<Radio color="primary"/>} label="No" />
							</RadioGroup>
						</FormControl>
					</Grid>
					<Grid item>
						<FormControl component="fieldset">
							<FormLabel component="legend">Stage Loans</FormLabel>
							<RadioGroup name="stageLoan" value={filters.stageLoan} row onChange={ this.handleToggle.bind(this, "stageLoan")} >
								<FormControlLabel value="all" control={<Radio color="primary"/>} label="All" />
								<FormControlLabel value="stageLoans" control={<Radio color="primary"/>} label="Stage Loans Only" />
								<FormControlLabel value="NoStageLoans" control={<Radio color="primary"/>} label="Stage Loans Exclude" />
							</RadioGroup>
						</FormControl>
					</Grid>
					<Grid item>
						<FormControl component="fieldset">
							<FormLabel component="legend">TU 144 Override</FormLabel>
							<RadioGroup name="tu144Override" value={filters.tu144Override} row onChange={ this.handleToggle.bind(this, "tu144Override")} >
								<FormControlLabel value="all" control={<Radio color="primary"/>} label="All" />
								<FormControlLabel value="yes" control={<Radio color="primary"/>} label="Yes" />
								<FormControlLabel value="no" control={<Radio color="primary"/>} label="No" />
							</RadioGroup>
						</FormControl>
					</Grid>
					<Grid item>
						<FormControl component="fieldset">
							<FormLabel component="legend">Skep Loans</FormLabel>
							<RadioGroup name="skepLoans" value={filters.skepLoans} row onChange={ this.handleToggle.bind(this, "skepLoans")} >
								<FormControlLabel value="0" control={<Radio color="primary"/>} label="All" />
								<FormControlLabel value="1" control={<Radio color="primary"/>} label="Skep Loans Only" />
								<FormControlLabel value="2" control={<Radio color="primary"/>} label="Skep Loans Exclude" />
							</RadioGroup>
						</FormControl>
					</Grid>
				</Grid>
				<Grid container item xs={12} spacing={3}>
					<Grid item xs>
						<IsolateEvent wheel scroll>
							<MultiSelect options={ loanTypesList} label={"Loan Type"} values={ filters.loanType } name={ "loanType" } changeInputSelect={ this.handleMultiSelectFilter } height={300} />
						</IsolateEvent>
					</Grid>
					<Grid item xs>
						<IsolateEvent wheel scroll>
							<MultiSelect options={ dealerAssociationList} label={"Dealer Association"} values={ filters.dealerAssociation } name={ "dealerAssociation" } changeInputSelect={ this.handleMultiSelectFilter } height={300} />
						</IsolateEvent>
					</Grid>
					<Grid item xs>
						<IsolateEvent wheel scroll>
							<MultiSelect options={ dealerNameList} label={"Dealer Name"} values={ filters.dealerId } name={ "dealerId" } changeInputSelect={ this.handleMultiSelectFilter } height={300} />
						</IsolateEvent>
					</Grid>
					<Grid item xs>
						<IsolateEvent wheel scroll>
							<MultiSelect options={ statesList} label={"State"} values={ filters.state } name={ "state" } changeInputSelect={ this.handleMultiSelectFilter } height={300} />
						</IsolateEvent>
					</Grid>
				</Grid>				
				
				<Grid container item xs={12} spacing={3}>
					<Grid item xs>
						<MultiSelectChip name={"sfcSalesperson"} label={"SFC Salesperson"} options={sfcSalespersonList} values={filters.sfcSalesperson} onChange={ this.handleMultiSelectFilter.bind(this, "sfcSalesperson") } getOptionLabel={this.getOptionLabel} />
					</Grid>
					<Grid item xs>
						<MultiSelectChip name={"regions"} label={"Regions"} options={regionsList} values={filters.regions} onChange={ this.handleMultiSelectFilter.bind(this, "regions") }/>
					</Grid>
					<Grid item xs>
						<MultiSelectChip name={"product"} label={"Product"} options={productList} values={filters.product} getOptionLabel={this.getOptionLabel} onChange={ this.handleMultiSelectFilter.bind(this, "product") }/>
					</Grid>
					<Grid item xs>
						<MultiSelectChip name={"sfcCultivation"} label={"SFC Cultivation"} options={sfcCultivationList} values={filters.sfcCultivation} onChange={ this.handleMultiSelectFilter.bind(this, "sfcCultivation") } getOptionLabel={this.getOptionLabel}/>
					</Grid>
				</Grid>
				<Grid container item xs={12} spacing={3}>
					<Grid container item xs={6}>
						<Grid item xs>
							<DatePickerRange
								label={"Funding Date"}
								fromDate={filters.fromLoanFundingDate} 
								fromDateId="fromLoanFundingDate" 
								toDate={filters.toLoanFundingDate} 
								toDateId="toLoanFundingDate" 
								onDatesChange={this.handleDate}
								onSubmit={this.props.handleSubmit}
							/>
						</Grid>
						<Grid item xs>
							<DatePickerRange
								label={"Contract Validation Date"}
								fromDate={filters.fromContractValidatedDate} 
								fromDateId="fromContractValidatedDate" 
								toDate={filters.toContractValidatedDate} 
								toDateId="toContractValidatedDate" 
								onDatesChange={this.handleDate}
								onSubmit={this.props.handleSubmit}
							/>
						</Grid>
						<Grid item xs>
							<DatePickerRange
								label={"Submit Date"}
								fromDate={filters.fromSubmitDate} 
								fromDateId="fromSubmitDate" 
								toDate={filters.toSubmitDate} 
								toDateId="toSubmitDate" 
								onDatesChange={this.handleDate}
								onSubmit={this.props.handleSubmit}
							/>
						</Grid>
					</Grid>
					<Grid container item xs={6} alignItems="flex-end">
						<NetworkButton text={ "Submit" } primary={ true } style={{margin: 10, height: 36}} onClick={ this.props.handleSubmit } />
						<NetworkButton text={ "Reset" }  primary={ true } style={{margin: 10, height: 36}} onClick={ this.props.handleReset } />
						<NetworkButton text={ "Excel" }  primary={ true } style={{margin: 10, height: 36}} onClick={ this.props.handleExcel } />
					</Grid>
				</Grid>
			</Grid>
		);
	};
};

OriginationSummaryFilters.propTypes = {
	loanTypesList: PropTypes.array,
	changeFilter: PropTypes.func,
	dealerAssociationList: PropTypes.array,
	dealerNameList: PropTypes.array,
	filters: PropTypes.object,
	handleSubmit: PropTypes.func,
	handleReset: PropTypes.func,
	handleExcel: PropTypes.func,
	lendersList: PropTypes.array,
	productList: PropTypes.array,
	regionsList: PropTypes.array,
	sfcCultivationList: PropTypes.array,
	sfcSalespersonList: PropTypes.array,
	statesList: PropTypes.array,
	typesList: PropTypes.array,
	contactMethodList: PropTypes.array
};

export default OriginationSummaryFilters;