import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import ApplicationDetailTable from "../../../../commons/components/ApplicationDetailTable";
import { Grid, Link, Typography, Icon, Divider, Card, CardContent, Tooltip, Fade, Modal, Backdrop } from '@material-ui/core';
import NetworkButton from "../../../../commons/components/NetworkButton";
import DialogYesNo from "../../../../commons/components/DialogYesNo";
import { FixedSizeGrid as GridWindow, VariableSizeList  as List } from 'react-window';

const heightCard = 160;
const heightHeaderIsseu = 52;
const heightListMax = 500;
const columnsByRow = 5;

const useStyles = theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	card:{
		marginRight: 10,
		marginBottom: 10,
		padding: "0px !important"
	},
	cardContent: {	
		padding: "0px !important"
	},
	tooltip: {
		width:500
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #7cb342',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	}
});

class TabIssues extends Component {

	constructor(props){
		super(props);
		this.state = {
			openModal: false,
			applicationDetails: undefined,
			statusShowSkipDialog: false
		};
	}

	static getDerivedStateFromProps(props, state) {
		if(state.applicationDetails !== undefined){
			state.openModal = true;
		}
		return state;
	}

	handleClose() {
		this.setState({
			applicationDetails: undefined,
			openModal: false
		});
	};

	handleDownloadReport = (event) => {
		event.preventDefault();
		event.stopPropagation();
		this.props.downloadReport(this.props.selectLender, this.props.initialDate, this.props.finalDate);
	};

	handleShowSkipDialog(){
		this.setState({statusShowSkipDialog : !this.state.statusShowSkipDialog});
	};

	handleLoadApplication(applicationNumber) {
		this.props.loadApplicationDetails(this, applicationNumber);
	}

	Row = ({ index, style }) => {
		const { issues } = this.props;
		const issue = issues[index];
		return(
			<div key={issue.type} style={style}>
				<Divider />
				<Grid item style={{marginBottom:10}}>
					<div style={{backgroundColor: issue.color, margin:10, display:"inline-flex"}}>
						<Typography variant="h5">Issue: {issue.type}</Typography>
						<Tooltip title={issue.description}>
							<Icon style={{marginTop:4}} color="disabled">help</Icon>
						</Tooltip>
					</div>
					{this.getContentGridListIssue(issue.value)}
				</Grid>
			</div>
		);
	}

	getItemSize = (index) => {
		const totalItems = this.props.issues[index].value.length ;
		const height = Math.ceil(totalItems / columnsByRow) * heightCard + heightHeaderIsseu;
		return Math.min(height, heightListMax);
	};

	createIsseuLayout = () =>
	{
		const count = this.props.issues.length;
		return(
			<Grid container direction="column" justifyContent="center">
				<List
					height={heightListMax}
					itemCount={count}
					itemSize={this.getItemSize}
				>
					{this.Row}
				</List>
			</Grid>
		);
	}

	CreateCard = ({ columnIndex, rowIndex, style, data }) => {
		const { classes  } = this.props;
		const index = rowIndex * columnsByRow + columnIndex;
		if(index >= data.length)
			return <div/>;

		const value = data[index];
		return (
			<div style={style}>
				<Card key={value[0]} className={classes.card}>
					<CardContent className={classes.cardContent}>
						<Typography style={{display:"inline"}}>
							{<Link key={value[0]} onClick={this.handleLoadApplication.bind(this, value[0])} style={{cursor: 'pointer'}}>{value[0] }</Link>}
						</Typography>
						<Typography variant="body2" component="p" style={{display:"inline"}}> - {value[1]}</Typography>
						<Typography variant="body2" component="p">{value[3]}</Typography>
						<Typography variant="body2" component="p">{value[2]}</Typography>
						{
							value.slice(4).map(v => (
								<Typography variant="body2" component="p">{v}</Typography>
							))
						}
					</CardContent>
				</Card>
			</div>
		);
	}

	getContentGridListIssue(issues){
		const total = issues.length;
		const rows = Math.ceil(total / columnsByRow);
		const height = Math.min(rows * heightCard, 448);
		return(
			<GridWindow
				columnCount={columnsByRow}
				columnWidth={305}
				height={height}
				rowCount={rows}
				rowHeight={heightCard}
				width={1542}
				itemData={issues}
			>
				{this.CreateCard}
			</GridWindow>
		);
	}
	
	getContentDialogAskIssue = () => {
		return (
			<div>
				<DialogYesNo
					onShow = { this.handleShowSkipDialog.bind(this) }
					onHide = { this.handleShowSkipDialog.bind(this) }
					onConfirmation={ this.getConfirmDialog }
					status={ this.state.statusShowSkipDialog }
					text={ "Are you sure want to continue even with issues?" }/>
			</div>
		);
	};

	getConfirmDialog = () => {
		this.handleShowSkipDialog();
		this.props.handleNext();
	};
    
    render(){
        const { classes } = this.props;
		return(
			<div>
				<NetworkButton
					text={ "Download" }
					primary={ true }
					onClick={ this.handleDownloadReport }
					type="submit"
					style={{marginBottom:10}}
				/>
				{ this.createIsseuLayout() }
				<Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					className={classes.modal}
					open={this.state.openModal}
					onClose={this.handleClose.bind(this)}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
					timeout: 500,
					}}
				>
					<Fade in={this.state.openModal}>
					<div className={classes.paper}>
						<ApplicationDetailTable onClose={this.handleClose.bind(this)} applicationDetails={ this.state.applicationDetails }/>
					</div>
					</Fade>
				</Modal>
				<div style={{marginTop:"-20px"}}/>
				{this.getContentDialogAskIssue()}
			</div>
		);
    }
};

TabIssues.propTypes = {
	classes: PropTypes.object.isRequired,
	finalDate: PropTypes.object,
	initialDate: PropTypes.object,
	issues: PropTypes.array,
	loadApplicationDetails: PropTypes.func,
	downloadReport: PropTypes.func,
	handleNext: PropTypes.func,
	selectLender: PropTypes.string
}

export default withStyles( useStyles, {withTheme : true})(TabIssues);