import React from "react";
import PropTypes from "prop-types";
import { Grid } from '@material-ui/core';
import NetworkButton from "../../../commons/components/NetworkButton";
import DatePickerRange from "../../../commons/components/DatePickerRange";
import MultiSelect from "../../../commons/components/MultiSelect";
import IsolateEvent from "../../../commons/components/IsolateEvent";

class DealerBDRatioFilters extends React.Component {

	handleMultiSelectFilter = (field, value) => {
        this.props.changeFilter(field, value);
    }

    handleSelectFilter = (event) => {
        this.props.changeFilter(event.target.name, event.target.value);
    }

    handleDate= (field, date) => {
        this.props.changeFilter(field, date);
    }

    render(){
        const { filters, dealerAssociationList, sfcSalespersonList, dealerNameList } = this.props;
		return (
			<Grid container style={{marginTop:10}}>
				<Grid container item xs={12} spacing={3}>
					<Grid item xs>
						<IsolateEvent wheel scroll>
							<MultiSelect options={ dealerAssociationList} label={"Dealer Association"} values={ filters.dealerAssociation } name={ "dealerAssociation" } changeInputSelect={ this.handleMultiSelectFilter } height={300} />
						</IsolateEvent>
					</Grid>
					<Grid item xs>
						<IsolateEvent wheel scroll>
							<MultiSelect name={"dealerName"} label={"Dealer Name"} options={dealerNameList} values={filters.dealerName} changeInputSelect={ this.handleMultiSelectFilter } height={300}/>
						</IsolateEvent>
					</Grid>
					<Grid item xs>
						<IsolateEvent wheel scroll>
							<MultiSelect name={"sfcSalesperson"} label={"SFC Salesperson"} options={sfcSalespersonList} values={filters.sfcSalesperson} changeInputSelect={ this.handleMultiSelectFilter } height={300}/>
						</IsolateEvent>
					</Grid>
				</Grid>
				<Grid container item xs={12} spacing={3}>
					<Grid container item xs={3}>
						<Grid item xs>
							<DatePickerRange
								label={"Application Submit Date"}
								fromDate={filters.fromSubmitDate} 
								fromDateId="fromSubmitDate" 
								toDate={filters.toSubmitDate} 
								toDateId="toSubmitDate" 
								onDatesChange={this.handleDate}
								onSubmit={this.props.handleSubmit}
							/>
						</Grid>
					</Grid>
					<Grid container item xs={3} alignItems="flex-end">
						<NetworkButton text={ "Submit" } primary={ true } style={{margin: 10, height: 36}} onClick={ this.props.handleSubmit } />
						<NetworkButton text={ "Reset" }  primary={ true } style={{margin: 10, height: 36}} onClick={ this.props.handleReset } />
						<NetworkButton text={ "Excel" }  primary={ true } style={{margin: 10, height: 36}} onClick={ this.props.handleExcel } disabled={!this.props.isSubmitted} />
					</Grid>
				</Grid>
			</Grid>
		);
    };
}

DealerBDRatioFilters.propTypes = {
    dealerAssociationList: PropTypes.array,
    dealerNameList: PropTypes.array,
	handleDate: PropTypes.func,
	handleExcel: PropTypes.func,
	handleMultiSelectFilter: PropTypes.func,
	handleSubmit: PropTypes.func,
	handleReset: PropTypes.func,
	isSubmitted: PropTypes.bool,
	filters: PropTypes.object,
	sfcSalespersonList: PropTypes.array
};

export default DealerBDRatioFilters;