import React, { Component } from "react";
import PropTypes from "prop-types";
import { InputAdornment, TextField } from "@material-ui/core";
import { TextValidator } from 'react-material-ui-form-validator';

class AmountField extends Component {
	state = {
		value:"",
		initValue: false
	};

	static getDerivedStateFromProps(props, state) {
		return {
			value: state.initValue ? state.value : props.value,
			initValue: true
		};
	}

	isNullOrUndefined(value){
		return value === null || value === undefined;
	}

	separateWithDecimalScale(value, decimalScale){
		if(value.length >= decimalScale && !this.isNullOrUndefined(decimalScale)){
			const decimals = value.slice(value.length - decimalScale, value.length);
			const integers = value.slice(0, value.length - decimalScale);
			const newValue = integers + "." + decimals;
			return newValue;
		};
		return value;	
	}

	applythousandSeparator(num) {
		let withCommas = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return withCommas.replace(/^0+(?=\d)/, '');
	}

	formatStringToAmount(value){
		const {decimalScale, thousandSeparator} = this.props;
		const onlyNums = value.replace(/[^0-9]|/g, '');
		const numberWithFixedDecimals  = this.separateWithDecimalScale(onlyNums, decimalScale);

		if(thousandSeparator){
			return this.applythousandSeparator(numberWithFixedDecimals);
		}
		else{
			return numberWithFixedDecimals;
		}
	}

	handleChange = event => {
		event.target.value = this.formatStringToAmount( event.target.value );
		this.setState({value: event.target.value});

		if(!!this.props.onChange)
			this.props.onChange(event);
	}

	render(){
		const { prefix, decimalScale, thousandSeparator, inputStyle, ...rest } = this.props;
		const { value } = this.state;
		const { errorMessages, validators } = this.props;
		const hasValidator  = errorMessages || validators;

		const props = {
			value: value,
			onChange: this.handleChange,
			style : {width:"100%"},
			InputProps:{
				startAdornment: <InputAdornment position="start">{prefix ? prefix : ""}</InputAdornment>,
				style:{...{marginTop:"16px"}, ...inputStyle}
			}
		};

		if(hasValidator){
			return (
				<TextValidator
					{...rest}
					{...props}
				/>
			);
		} else {
			return (
				<TextField
					{...rest}
					{...props}
				/>
			);
		}

	}
}

AmountField.propTypes = {
	decimalScale: PropTypes.number,
	inputStyle: PropTypes.object,
	label: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func,
	prefix: PropTypes.string,
	value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
	thousandSeparator: PropTypes.bool
};

AmountField.defaultProps = {
	thousandSeparator: false,
	decimalScale: 2,
	prefix: ""
};

export default AmountField;
