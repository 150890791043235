import { apiGet, apiPost } from "../../../actions";
import fileDownload from "js-file-download";
import Moment from "moment";

export const initialFilters = {
	fromFundingDate: new Date(),
	toFundingDate: new Date(),
	ignoreSents: true,
	startIndex: 0,
	stopIndex: 100
};

const defaultPath = "api/FundingManagement/FundingReport";

export function submit( props, filters ) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath,
			params: {
				fromFundingDate:  Moment( new Date( filters.fromFundingDate ) ).format( "MM/DD/YYYY" ),
				toFundingDate:  Moment( new Date( filters.toFundingDate ) ).format( "MM/DD/YYYY" ),
				ignoreSents: filters.ignoreSents,
				startIndex: filters.startIndex,
				stopIndex: filters.stopIndex
			},
			onSuccess: response => {
				props.setState({
					listGrid: response.data,
					isSubmitted: true
				});
			}
		} );
	};
}

export function sendEmail( list, ignoreSent ) {
	let select = list.filter(item => item !== undefined).map(item => ({
		dealerNumber: item.dealerNumber,
		date: item.date,
		ignoreSent,
	}));
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath + '/email',
			body: select
		} );
	};
}

export function downloadDealerReport( dealerName, dealerNumber, date, ignoreSent ) {
	return dispatch => {
		apiGet( dispatch, {
			responseType: "blob",
			url: defaultPath + `/file/dealerreport?dealerNumber=${escape(dealerNumber)}&reportDate=${date}&ignoreSent=${ignoreSent}`,
			onSuccess: response => {
				fileDownload( response.data, `FundingReport_${dealerName}.zip` );
			}
		} );
	};
}

export function downloadDealerAssociationReport( dealerAssociation, date, ignoreSent ) {
	return dispatch => {
		apiGet( dispatch, {
			responseType: "blob",
			url: defaultPath + `/file/dealerassociationreport?dealerAssociation=${escape(dealerAssociation)}&reportDate=${date}&ignoreSent=${ignoreSent}`,
			onSuccess: response => {
				fileDownload( response.data, `DealerAssociationReport_${dealerAssociation}.zip` );
			}
		} );
	};
}
