import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageBase from "../../../commons/components/PageBase";
import StylizedModal from "../../../commons/components/StylizedModal";
import SearchNotData from "../../../commons/components/SearchNotData";
import InventoryQueryFilters from "./InventoryQueryFilters";
import { dispatchFieldChanged, dispatchFieldChangedSelect, dispatchFieldChangedDate } from "../../fields";
import {
	loadFilters,
	loadTypes,
	loadApplicationDetails,
	submit,
	submitDetails,
	downloadExcelReport,
	downloadDetailExcelReport,
	saveApplicationNote,
	initialFilters
 } from "./actions";
import InventoryQueryTable from "./InventoryQueryTable";
import ModalTableApplication from "../../../commons/components/ModalTableApplication";

const mapDispatchToProps = {
	loadFilters,
	loadTypes,
	loadApplicationDetails,
	dispatchFieldChanged,
	dispatchFieldChangedSelect,
	dispatchFieldChangedDate,
	submit,
	submitDetails,
	downloadExcelReport,
	downloadDetailExcelReport,
	saveApplicationNote
};

class InventoryQuery extends React.Component {

	

	constructor(props) {
		super(props);
		this.props.loadFilters(this);
		this.props.loadTypes(this);
		this.state = {
			openDetailsModal: false,
			detailsResult: {},
			isSubmitted: false,
			filters: initialFilters,
			currentFilters: initialFilters,
			listGrid: [],
			lendersList: ["All"],
			loanTypesList:[],
			dealerNameList:[],
			asOfDateList: [],
			typesList: [{label: "Lender", value: "Lender"}]
		};
	}

	componentWillUnmount(){
		this.handleReset();
	};

	handleUpdateFilter = (field, value) => {
		this.setState({
			filters: {
				...this.state.filters,
				[field]:value
			}
		});
	};

	handleSubmit = () => {
		this.props.submit(this, this.getFilters());
		this.setState({
			currentFilters: JSON.parse(JSON.stringify(this.state.filters))
		});
	};

	handleSubmitInfinity = (params) => {
		this.props.submit(this, params);
	};

	handleReset = () => {
		this.setState({
			openDetailsModal: false,
			filters: {
				...initialFilters,
				asOfDate: this.state.asOfDateList[0]
			},
			currentFilters: {
				...initialFilters,
				asOfDate: this.state.asOfDateList[0]
			},
			listGrid: [],
			detailsResult: {},
			isSubmitted: false
		});
	};

	handleExcel = () => {
		const filters = this.getFiltersForDetails();
		this.props.downloadExcelReport(filters);
	};

	handleExcelDetail = () => {
		const filters = this.getFiltersForDetails();
		this.props.downloadDetailExcelReport(filters);
	};

	handleLoadDetails = details => {
		const filters = this.getFiltersForDetails();
		filters.rangeDelinquecy = details.range;
		filters.detailTypeName = details.name;
		filters.detailsStartIndex = 0;
		filters.detailsStopIndex = 100;
		this.props.submitDetails(this, filters);
	}

	handleLoadDetailsPage = ({ startIndex, stopIndex }) => {
		const filters = this.getFiltersForDetails();
		filters.detailsStartIndex = startIndex;
		filters.detailsStopIndex = stopIndex;
		this.props.submitDetails(this, filters);
	}

	handleLoadApplicationDetails = applicationNumber => {
		this.props.loadApplicationDetails(this, applicationNumber);
	}

	getFilters = () => this.state.filters;
	getFiltersForDetails = () => this.state.currentFilters;

	createTable()
	{
		const { listGrid, filters, isSubmitted } = this.state;
		return(
			<div style={{marginTop:15}}>
				<SearchNotData
					isSubmitted={isSubmitted}
					listGrid={listGrid}
				>
					<InventoryQueryTable
						listGrid={ listGrid }
						handleLoadDetails={ this.handleLoadDetails }
						filters={filters}
						handleSubmit={this.handleSubmitInfinity.bind(this)}
					/>
				</SearchNotData>
			</div>
		);
	}

	handleCloseDetailsModal() {
		this.setState({
			openDetailsModal:false,
			currentFilters: {
				...this.state.currentFilters,
				rangeDelinquecy: "",
				detailTypeName: ""
			}
		});
	}

	render() {
		const { filters, detailsResult, lendersList, loanTypesList, typesList, dealerNameList, asOfDateList } = this.state;
	
		return (
			<PageBase title="Inventory Query" >
				<div>
					<InventoryQueryFilters
						filters={filters}
						asOfDateList={asOfDateList}
						loanTypesList={loanTypesList}
						dealerNameList={dealerNameList}
						lendersList={lendersList}
						typesList={typesList}
						handleUpdateFilter={this.handleUpdateFilter}
						handleSubmit={this.handleSubmit}
						handleReset={this.handleReset}
						handleExcel={this.handleExcel}
					/>
					{this.createTable()}
					<StylizedModal
						openModal={this.state.openDetailsModal}
						onClose={this.handleCloseDetailsModal.bind(this)}
					>
						<ModalTableApplication
							detailsResult={ detailsResult }
							onDownloadExcel = {this.handleExcelDetail}
							onClose={this.handleCloseDetailsModal.bind(this)}
							onLoadPage={this.handleLoadDetailsPage}
							onLoadDetails={this.props.loadApplicationDetails}
							saveApplicationNote={this.props.saveApplicationNote}
						/>
					</StylizedModal>
				</div>
			</PageBase>
		);
	}
}

InventoryQuery.propTypes = {
	downloadExcelReport: PropTypes.func,
	downloadDetailExcelReport: PropTypes.func,
	loadApplicationDetails: PropTypes.func,
	loadFilters: PropTypes.func,
	loadTypes: PropTypes.func,
	saveApplicationNote: PropTypes.func,
	submit: PropTypes.func,
	submitDetails: PropTypes.func
};

export default connect(
	null,
	mapDispatchToProps,
)( InventoryQuery );
