import React from "react";
import PropTypes from "prop-types";
import NetworkButton from "../../../commons/components/NetworkButton";
import { ValidatorForm, TextValidator} from "react-material-ui-form-validator";
import { FormControlLabel, Grid, Switch } from "@material-ui/core";
import MultiSelect from "../../../commons/components/MultiSelect";

const styles = {
	buttons: {
		marginTop: 30,
		float: "right",
		marginLeft: 5
	},
	saveButton: {
		marginLeft: 5
	}
};

const descriptionFieldFocus = input => { input && input.focus(); }

class LenderForm extends React.Component {
	componentDidMount() {
		descriptionFieldFocus();
	}

	handleChangeInput = event => {
		this.props.changeInput( event.target.name, event.target.value );
	};

	handleChecked = ( event, checked ) => {
		this.props.changeInput( "isReadOnly", checked );
	};

	handleSubmit = () => {
		this.props.saveItem( this.props.item );
	};

	handleCancel = () => {
		this.props.cleanForm();
	};

	render() {
		const { item, list } = this.props;
		return (
			<Grid container style={{marginTop:10}}>
				<ValidatorForm onSubmit={ this.handleSubmit } noValidate style={{width:"100%"}}>
					<Grid container item xs={12} spacing={3}>
						<Grid item xs={2}>
							<FormControlLabel
								control={
									<Switch
										checked={ this.props.item.isReadOnly }
										onChange={ this.handleChecked }
										color={ "primary" }
									/>
								}
								label="Is it ReadOnly?"
							/>
						</Grid>
						<Grid item xs={10}>
							<TextValidator
								required
								ref={ input => {
									this.description = input;
								} }
								name="description"
								label="Description"
								validators={ ["required", "minStringLength:3"] }
								errorMessages={ ["The description is mandatory", "The description must be at least 3 characters long."] }
								fullWidth
								value={ this.props.item.description }
								onChange={ this.handleChangeInput }
								style={ { marginBottom: 25 } }
							/>
						</Grid>
					</Grid>
					<Grid container item xs={12} spacing={3}>
						<Grid item xs={9}>
							<MultiSelect
								label="Description"
								options={ list }
								values={ item.access }
								name={ "access" }
								changeInputSelect={ this.props.changeInput }
								idProperty="id"
								valueProperty="description"
								height={300}
							/>
						</Grid>
						<Grid item xs={3}>
							<NetworkButton text={ "Cancel" } onClick={ this.handleCancel } />
							<NetworkButton
								text={ "Save" }
								primary={ true }
								type="submit"
								style={ styles.saveButton }
							/>
						</Grid>
					</Grid>
				</ValidatorForm>
			</Grid>
		);
	}
}

LenderForm.propTypes = {
	changeInput: PropTypes.func,
	cleanForm: PropTypes.func,
	item: PropTypes.object,
	list: PropTypes.array,
	saveItem: PropTypes.func
};

export default LenderForm;
