import React from "react";
import PropTypes from "prop-types";

const If = ( { condition, children } ) => ( condition === true ? <React.Fragment>{children}</React.Fragment> : null );

If.propTypes = {
	condition: PropTypes.bool
};

export default If;
