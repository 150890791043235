import React from "react";
import PropTypes from "prop-types";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
import { withRouter } from "react-router";
import { ListItemIcon, ListItemText, Collapse, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import { getRequestedPage } from "../../pages/routes";
const white = "#FFFFFF";

const listStyles = {
	innerDivStyle: {
		padding: 0,
		paddingRight: 13,
		mariginLeft: 30,
		display: "inline-flex"
	},
	menuTextStyle:{
		fontSize: 14
	},
	nestedListStyle: {
		paddingRight: 13,
		backgroundColor: "#efefef"
	},
	list: {
		width: 230
	}
};

const paperClassStyle = {
	paperDrawer:{
		padding: 0,
		background: "#f9fafc",
		boxShadow: "#00000029 0px 3px 10px, #0000003b 0px 3px 10px",
		borderRight: 0
	}
}

class NavBar extends React.Component {
	constructor( props ) {
		super( props );
		this.state = {
			menus: this.props.menus,
			selectedIndex: 0,
		};
	}

	componentDidMount() {
		this.ensureMenusAreProperlyExpandedAndSelected();
	}

	componentDidUpdate(prevProps) {
		if (this.state.menus !== this.props.menus) {
			this.setState(state => ({
				...state,
				menus: this.props.menus,
			}));
		}
	}

	ensureMenusAreProperlyExpandedAndSelected = () => {
		let { menus } = this.props;

		const selectedMenu = this.getSelectedMenu( menus );

		if( selectedMenu == null ) {
			return;
		}

		let reachedSelectedMenu = false;
		while( !reachedSelectedMenu ) {
			for( const menu of menus ) {
				if( this.hasMenu( selectedMenu, menu ) ) {
					if( selectedMenu.id === menu.id ) {
						reachedSelectedMenu = true;
						this.handleRequestChange( null, selectedMenu.id );
						break;
					}

					menu.open = true;
					menus = menu.subAccess;
					break;
				}
			}
		}
	}

	getSelectedMenu( menus ) {
		const { location } = this.props;
		const currentPage = getRequestedPage( location );
		for( const menu of menus ) {
			if( menu.url === currentPage.name ) {
				return menu;
			}

			if( menu.subAccess ) {
				const selectedMenu = this.getSelectedMenu( menu.subAccess );
				if( selectedMenu != null ) {
					return selectedMenu;
				}
			}
		}
		return null;
	}

	/** Checks if sourceMenu is or contains the targetMenu */
	hasMenu( targetMenu, sourceMenu ) {
		if( sourceMenu.id === targetMenu.id ) {
			return true;
		}
		
		if( sourceMenu.subAccess == null || sourceMenu.subAccess.length === 0 ) {
			return false;
		}

		return sourceMenu.subAccess.some( subMenu => this.hasMenu( targetMenu, subMenu ) );
	}

	handleRequestChange( event, index ) {
		this.setState( 
			{ 
				menus: this.updateMenus( this.state.menus, index ),
				selectedIndex: index 
			} );
	}

	updateMenus( menus, index) {
		for( let i = 0; i< menus.length; i++){
			if(menus[i].id === index){
				menus[i].open = !menus[i].open;
				return menus;
			}
			if(menus[i].subAccess != null && menus[i].subAccess.length > 0)
				this.updateMenus(menus[i].subAccess, index);
		}
		return menus;
	}

	createMenu( menus, level ) {
		const who = this;
		if( menus === undefined )
			return undefined;

		let menusToShow = menus.filter(item => item.showInMenu);
		let menusOrdered = menusToShow.sort((a, b) => a.indexOrder < b.indexOrder ? -1 : (a.indexOrder === b.indexOrder ? 0: 1));
		return menusOrdered.map( item => {
			let subMenus = undefined;
			let expand = undefined;
			if ( item.subAccess != null && item.subAccess.length !== 0 ) {
				subMenus = <Collapse  in={item.open} > { who.createMenu( item.subAccess, level+1 ) } </Collapse>;
				expand = item.open ? <ExpandLess /> : <ExpandMore />;
			}
				
			return (
				<div key={ item.id }>
					<ListItem 
						button
						key={ item.id }
						selected={this.state.selectedIndex === item.id}
						style={ level === 0 ?  listStyles.innerDivStyle : listStyles.nestedListStyle }
						onClick={ () => {
								this.handleRequestChange(this, item.id);
								if(item.url !== "") {
									// this.props.history.push( "/" );
									this.props.history.push( `/${item.url}` );
								}
							}
						}
					>
						<ListItemIcon style={ { minWidth: 0 } }>
						{
							item.icon !== "" ? (
								<Icon
									className={ "fa fa-" + item.icon }
									style={ { fontSize: 17, margin: 9 } }
								/>
							) : (
								<div/>
							)
						}
						</ListItemIcon>
						<ListItemText inset style={{ padding: 0, paddingLeft: 15*level }} primary={
							<Typography
								variant="subtitle1"
								style={listStyles.menuTextStyle }>
									{ item.description }
							</Typography> }
						/>
						{ expand }
					</ListItem>
					{ subMenus }
				</div>
			);
		} );
	}
	
	render() {
		const { classes } = this.props;
		const styles = {
			title: {
				cursor: "pointer",
				fontSize: 22,
				color: white,
				lineHeight: "64px",
				fontWeight: 500,
				backgroundColor: this.props.theme.palette.primary.main,
				height: 57,
				textAlign: "center",
			},
			textTitle: {
				color: white, 
				fontSize: 22, 
				textAlign: "center", 
				fontWeight: 500, 
				lineHeight: "64px"
			},
			link: {
				textDecoration: "none"
			}
		};

		return (
			<Drawer variant="persistent" open={ this.props.navDrawerOpen } classes={{paper: classes.paperDrawer }}>
				<div style={ styles.title }>
					<Button onClick={ () => { this.props.history.push( "/" ); } }  style={ styles.link }>
						<Typography variant="subtitle1" style={ styles.textTitle }>
							LMS
						</Typography>
					</Button>
				</div>
				<div>
				<List component="nav" style={ listStyles.list } dense={true}>
					{this.createMenu( this.state.menus, 0 )}
				</List>
				</div>
			</Drawer>
		);
	}
}

NavBar.propTypes = {
	classes: PropTypes.object.isRequired,
	menus: PropTypes.array,
	navDrawerOpen: PropTypes.bool,
	history: PropTypes.object,
	username: PropTypes.string
};

const withRouterTemp = withRouter( NavBar );
export default withStyles( paperClassStyle, {withTheme : true})( withRouterTemp );