import { apiGet, apiPost, apiDelete } from "../../../actions";

export const model = {
	ID: 0,
	description: "",
	url: "",
	icon: "",
	indexOrder: 1,
	accessMasterId: "",
	showInMenu: true
};

const defaultPath = "api/access";

export function loadList(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath,
			onSuccess: response => {
				props.setState({dataList: response.data});
			}
		} );
	};
}

export function saveItem( props, item ) {
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath,
			body: item,
			onSuccess: () => {
				props.setState({
					item: model,
					typeOfActionButton: "ADD"
				});
				dispatch( loadList(props) );
			}
		} );
	};
}

export function deleteItem( props, obj ) {
	return dispatch => {
		apiDelete( dispatch, {
			url: defaultPath + `/${ obj }`,
			body: obj,
			onSuccess: () => {
				dispatch( loadList(props) );
			}
		} );
	};
}
