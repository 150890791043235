import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageBase from "../../../commons/components/PageBase";
import ModalTableApplication from "../../../commons/components/ModalTableApplication";
import StylizedModal from "../../../commons/components/StylizedModal";
import OriginationSummaryTable from "../../../commons/components/OriginationSummaryTable";
import OriginationSummaryFilters from "./OriginationSummaryFilters";
import SearchNotData from "../../../commons/components/SearchNotData";

import {
	loadFilters,
	loadFilterType,
	submit,
	submitDetails,
	downloadExcelReport,
	downloadDetailExcelReport,
	initialFilters
 } from "./actions";

 import {	
	loadApplicationDetails,
	saveApplicationNote
} from "../../assetManager/inventoryQuery/actions";
import SingleScroll from "../../../commons/components/SingleScroll";

const mapDispatchToProps = {
	loadFilters,
	loadFilterType,
	submit,
	submitDetails,
	downloadExcelReport,
	loadApplicationDetails,
	saveApplicationNote,
	downloadDetailExcelReport
};

class OriginationSummary extends React.Component {
	state = {
		openDetailsModal: false,
		listDetailsGrid: [],
		filters: initialFilters,
		currentFilters: initialFilters,
		listGrid: [],
		isSubmitted: false,
		lendersList: ["SFC"],
		typesList:["Advanced Rate"],
		contactMethodList:[],
		loanTypesList:[],
		dealerAssociationList:[],
		statesList:[],
		sfcCultivationList:[],
		sfcSalespersonList:[],
		regionsList:[],
		productList:[],
		dealerNameList:[]
	};

	constructor(props) {
		super(props);
		this.props.loadFilters(this);
		this.props.loadFilterType(this);
	}

	handleChangeFilter = ( field, value ) => {
		this.setState({
			filters:{
				...this.state.filters,
				[field]: value
			}
		});
	};

	handleSubmit = () => {
		const filters = this.getFilters();
		filters.startIndex = 0;
		filters.stopIndex = 100;
		this.setState({ currentFilters: filters });
		this.props.submit(this, filters);
	};

	handleNewPageSubmit = ({ startIndex, stopIndex, orderBy, order }) => {
		const filters = this.getFilters();
		filters.startIndex = startIndex;
		filters.stopIndex = stopIndex;
		filters.orderBy = orderBy;
		filters.order = order;
		this.setState({ currentFilters: filters });
		this.props.submit(this, filters);
	};

	handleReset = () => {
		this.setState({
			filters: initialFilters,
			currentFilters: initialFilters,
			listGrid: [],
			listDetailsGrid: [],
			detailsResult: {},
			isSubmitted: false
		});
	};

	handleExcel = () => {
		this.props.downloadExcelReport(this.getFiltersForDetails());
	};

	handleCloseDetailsModal = () => {
		this.setState({
			openDetailsModal:false,
			listDetailsGrid: []
		});
	}

	handleLoadDetails = (type, column) => {
		const filters = this.getFiltersForDetails();
		filters.groupBy = type;
		filters.applicationType = column;
		filters.detailsStartIndex = 0;
		filters.detailsStopIndex = 100;
		this.props.submitDetails(this, filters);
	}

	handleLoadDetailsPage = ({ startIndex, stopIndex }) => {
		const filters = this.getFiltersForDetails();
		filters.detailsStartIndex = startIndex;
		filters.detailsStopIndex = stopIndex;
		this.props.submitDetails(this, filters);
	}

	handleExcelDetail = () => {
		const filters = this.getFiltersForDetails();
		this.props.downloadDetailExcelReport(filters);
	};

	getFilters = () => this.state.filters;
	getFiltersForDetails = () => this.state.currentFilters;

	createFilters() {
        const { filters, lendersList, typesList, loanTypesList, dealerAssociationList, statesList, sfcCultivationList, sfcSalespersonList, regionsList, productList, dealerNameList, contactMethodList } = this.state;
		return (
			<OriginationSummaryFilters
				filters={filters}
				lendersList={lendersList}
				typesList={typesList}
				loanTypesList={loanTypesList}
				dealerAssociationList={dealerAssociationList}
				statesList={statesList}
				sfcCultivationList={sfcCultivationList}
				sfcSalespersonList={sfcSalespersonList}
				regionsList={regionsList}
				productList={productList}
				dealerNameList={dealerNameList}
				contactMethodList={contactMethodList}
				changeFilter={this.handleChangeFilter}
				handleSubmit={this.handleSubmit.bind(this)}
				handleReset={this.handleReset}
				handleExcel={this.handleExcel}
			/>
		);
	}

	createModaldetail(){
		const { listDetailsGrid, openDetailsModal } = this.state;

		if(listDetailsGrid.length === 0)
			return <div/>;

		return(
			<StylizedModal
				openModal={openDetailsModal}
				onClose={this.handleCloseDetailsModal}
			>
				<ModalTableApplication
					detailsResult={ {label: "Application Detail", list: listDetailsGrid } }
					onDownloadExcel = {this.handleExcelDetail}
					onClose={this.handleCloseDetailsModal}
					onLoadPage={this.handleLoadDetailsPage}
					onLoadDetails={this.props.loadApplicationDetails}
					saveApplicationNote={this.props.saveApplicationNote}
				/>
			</StylizedModal>
		);
	}
	
	render() {
		const {listGrid, isSubmitted} = this.state;
		const singleScrollName = "OriginationSummary";
		return (
			<PageBase title="Origination Summary">
				<SingleScroll name={singleScrollName}>
					<SingleScroll.Scrollable for={singleScrollName}>
						{this.createFilters()}
						<SearchNotData
							isSubmitted={isSubmitted}
							listGrid={listGrid}
						>
							<OriginationSummaryTable
								listGrid={listGrid}
								loadDetails={this.handleLoadDetails}
								filters={this.getFiltersForDetails()}
								handleSubmit={this.handleNewPageSubmit}
								forSingleScroll={singleScrollName}
							/>
						</SearchNotData>
						{this.createModaldetail()}
					</SingleScroll.Scrollable>
				</SingleScroll>
			</PageBase>
		);
	}
}

OriginationSummary.propTypes = {
	downloadDetailExcelReport: PropTypes.func,
	downloadExcelReport: PropTypes.func,
	loadApplicationDetails: PropTypes.func,
	loadFilters: PropTypes.func,
	loadFilterType: PropTypes.func,
	saveApplicationNote: PropTypes.func,
	submit: PropTypes.func,
	submitDetails: PropTypes.func
};

export default connect(
	null,
	mapDispatchToProps,
)( OriginationSummary );