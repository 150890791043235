import { apiGet, apiPost, apiDelete } from "../../../actions";

export const model = {
	id: 0,
	lender: "",
	loanType: "",
	lenderPrice: 0.0,
	min: 0.0,
	max: 0.0
};

export const initialFilters = {
	lender: "All",
	loanType: "All",
	startIndex: 0,
	stopIndex: 100
}

const defaultPath = "api/FundingManagement/LenderLoanType";

export function loadList( props, filters ) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath,
			params: {
				lender: filters.lender,
				loanType: filters.loanType,
				startIndex: filters.startIndex,
				stopIndex: filters.stopIndex
			},
			onSuccess: response => {
				props.setState({
					dataList: response.data,
					item: model,
					typeOfActionButton: "ADD",
					isSubmitted: true
				});
			}
		} );
	};
}

export function saveItem(props, item, filters ) {
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath + "/save",
			body: item,
			onSuccess: () => {
				dispatch( loadList(props, filters) );
			}
		} );
	};
}

export function deleteItem( props, obj, filters ) {
	return dispatch => {
		apiDelete( dispatch, {
			url: `${ defaultPath }/${ obj }`,
			body: obj,
			onSuccess: () => {
				props.setState({
					dataList: [],
					isSubmitted: false
				});
				dispatch( loadList(props, filters) );
			}
		} );
	};
}

export function loadAllOptionsForFilters(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: "api/CommonFilter/loadLenderLoanTypeFilters",
			onSuccess: response => {
				props.setState({
					lenderOptions: response.data.lender,
					loanTypeOptions: response.data.loanType
				});
			}
		} );
	};
}