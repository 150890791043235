import React from "react";
import PropTypes from "prop-types";
import TableInfinityLoader, { TableInfinityRow, TableInfinityCell } from "../../../commons/components/TableInfinityLoader"
import DialogYesNo from "../../../commons/components/DialogYesNo";
import IconDelete from "@material-ui/icons/Delete";
import IconUpdate from "@material-ui/icons/Create";
import IconButton from "@material-ui/core/IconButton";
import tableStyle from "../../../commons/components/TableStyle";

class LenderLoanTypeTable extends React.Component {

	state = {
		idDelete: 0,
		openDeleteModal: false
	};

	handleEditItem = item => {
		this.props.loadItem( item );
	}

	handleSetIdDelete = id => {
		this.setState({
			idDelete: id,
			openDeleteModal: true
		});
	}

	handleShowDeleteDialog = () => {
		this.setState({
			idDelete: 0,
			openDeleteModal: !this.state.openDeleteModal
		});
	}

	handleDeleteItem = () => {
		this.handleShowDeleteDialog();
		this.props.deleteItem( this.state.idDelete );
	}

	isNumeric( n ) {
		return !isNaN( parseFloat( n ) ) && isFinite( n );
	}

	currencyTableCell(value="0.0"){
		if(value === null)
			return "";
		return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
	}

	Row( event, row, parent ) {
		return (
			<TableInfinityRow event={event} variant="body">
				<TableInfinityCell style={{width:80}}>
					<IconButton
						style={ tableStyle.cmdButton }
						tooltip="Edit"
						onClick={ () => parent.handleEditItem( row ) }
					>
						<IconUpdate />
					</IconButton>
					<IconButton
						style={ tableStyle.cmdButton }
						tooltip="Delete"
						onClick={ () => parent.handleSetIdDelete( row.id ) }
					>
						<IconDelete />
					</IconButton>
				</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>{row.lender}</TableInfinityCell>
				<TableInfinityCell style={{width:200}}>{row.loanType}</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>{row.lenderPrice}</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>{parent.currencyTableCell(row.min)}</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>{parent.currencyTableCell(row.max)}</TableInfinityCell>
			</TableInfinityRow>
		);
	}

	Header(event){
		return (
			<TableInfinityRow event={event} variant="head">
				<TableInfinityCell style={{width:80}}>{""}</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>Lender</TableInfinityCell>
				<TableInfinityCell style={{width:200}}>Loan Type</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>Lender Price</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>Min</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>Max</TableInfinityCell>
			</TableInfinityRow>
		);
	}

	render(){
		return (
			<div>
				<TableInfinityLoader
					listGrid={this.props.list}
					drawRow={this.Row}
					drawHeader={this.Header}
					handleSubmit={this.props.handleSubmit}
					params={this.props.filters}
					parent={this}
					rowSize={24}
				/>
				<DialogYesNo
					onShow = { this.handleShowDeleteDialog }
					onHide = { this.handleShowDeleteDialog }
					onConfirmation={ this.handleDeleteItem }
					status={ this.state.openDeleteModal }
					text={ "Are you sure to delete this item?" }/>
			</div>
		);
    }
}

LenderLoanTypeTable.propTypes = {
	deleteItem: PropTypes.func,
	filters: PropTypes.object,
	handleSubmit: PropTypes.func,
	list: PropTypes.array,
	loadItem: PropTypes.func
};

export default LenderLoanTypeTable;