import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel, FormControl, FormLabel, RadioGroup, Radio, InputLabel, Select, Grid } from '@material-ui/core';
import MultiSelect from "../../../commons/components/MultiSelect";
import NetworkButton from "../../../commons/components/NetworkButton";
import DatePickerRange from "../../../commons/components/DatePickerRange";
import { ValidatorForm } from "react-material-ui-form-validator";
import { renderMenuItems, renderMenuItemsBy } from "../../../commons/utils";

const styles = {
	gridRoot:{
		marginTop:'10px'
	},
	upperGridWrap:{
		padding:'12px',
		height:'50px'
	},
	gridCell:{
		padding: '12px'
	}
};

class InventoryQueryFilters extends React.Component {

	handleSelectFilter = (event) => {
		this.props.handleUpdateFilter(event.target.name, event.target.value);
	};

	handleUpdateRadio = (field, event) => {
		this.props.handleUpdateFilter(field, event.target.value);
	};

    render() {
		const { filters, lendersList, loanTypesList, typesList, dealerNameList, asOfDateList } = this.props;
		return (
			<ValidatorForm onSubmit={ this.props.handleSubmit } noValidate style={{width:"100%"}}>
            	<Grid container spacing={4} style={styles.gridRoot}>
					<Grid container item xs={12} style={styles.upperGridWrap}>
						<Grid item xs={4} style={styles.gridCell}>
							<FormControl style={{width:'100%'}}>
								<InputLabel>Type</InputLabel>
								<Select inputProps={{ name: "type" }} value={filters.type} onChange={this.handleSelectFilter} displayEmpty>
									{ renderMenuItemsBy(typesList, "label", "value", false) }
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={4} style={styles.gridCell}>
							<FormControl style={ {width:'100%'} }>
								<InputLabel>As of date</InputLabel>
								<Select inputProps={{ name: "asOfDate"}} value={filters.asOfDate} onChange={this.handleSelectFilter}>
									{ renderMenuItems(asOfDateList, false) }
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={4}>
							<FormControl component="fieldset">
								<FormLabel component="legend">Ignore FHA,16.99DB, 6Mth DB, 6Mth DB 12s</FormLabel>
								<RadioGroup name="ignoreFico" value={filters.ignoreFico} row onChange={ this.handleUpdateRadio.bind(this, "ignoreFico") } >
									<FormControlLabel value="yes" control={<Radio color="primary"/>} label="Yes" />
									<FormControlLabel value="no" control={<Radio color="primary"/>} label="No" />
								</RadioGroup>
							</FormControl>
						</Grid>
					</Grid>
					<Grid container item xs={12}>
						<Grid item xs={4} style={styles.gridCell}>
							<MultiSelect 
								name={ "lender" }
								label={"Lender"}
								options={ lendersList }
								values={ filters.lender }
								changeInputSelect={ this.props.handleUpdateFilter }
								height={300}
								idProperty={"key"}
							/>
						</Grid>
						<Grid item xs={4} style={styles.gridCell}>
							<MultiSelect
								name={"loanType"}
								label={"Loan Type"}
								options={loanTypesList}
								values={filters.loanType}
								changeInputSelect={this.props.handleUpdateFilter}
								height={300}
							/>
						</Grid>
						<Grid item xs={4} style={styles.gridCell}>
							<MultiSelect
								name={"dealerName"}
								label={"Dealer Name"}
								options={dealerNameList}
								values={filters.dealerName}
								changeInputSelect={this.props.handleUpdateFilter}
								height={300}
							/>
						</Grid>
					</Grid>
					<Grid container item xs={12} spacing={5}>
						<Grid item xs={3}>
							<DatePickerRange
								label={"Loan Funding Date"}
								fromDate={filters.fromLoanFundingDate} 
								fromDateId="fromLoanFundingDate" 
								toDate={filters.toLoanFundingDate} 
								toDateId= {"toLoanFundingDate"}
								onDatesChange={this.props.handleUpdateFilter}
							/>
						</Grid>
						<Grid container item xs={6} alignItems="flex-end">
							<NetworkButton text={ "Submit" } primary={ true } style={{margin: 10, height: 36}} type="submit" />
							<NetworkButton text={ "Reset" }  primary={ true } style={{margin: 10, height: 36}} onClick={ this.props.handleReset } />
							<NetworkButton text={ "Excel" }  primary={ true } style={{margin: 10, height: 36}} onClick={ this.props.handleExcel } />
						</Grid>
					</Grid>
				</Grid>
			</ValidatorForm>
        );
    };
}

InventoryQueryFilters.propTypes = {
    asOfDateList: PropTypes.array,
    loanTypesList: PropTypes.array,
    dealerNameList: PropTypes.array,
	filters: PropTypes.object,
	handleExcel: PropTypes.func,
	handleUpdateFilter: PropTypes.func,
	handleSubmit: PropTypes.func,
	handleReset: PropTypes.func,
	handleToggle: PropTypes.func,
    lendersList: PropTypes.array,
    typesList: PropTypes.array
};

export default InventoryQueryFilters;