import { apiGet, apiPost, apiDelete } from "../../../actions";

export const model = {
	ID: 0,
	isLocked: false,
	isInternal: false,
	isAdmin: false,
	firstName: "",
	lastName: "",
	password: "",
	email: "",
	roles: [],
	lenders: [],
};

const defaultPath = "api/useraccount";
const lendertPath = "api/lender";
const rolePath = "api/role";

export function loadList(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath,
			onSuccess: response => {
				props.setState({ dataList: response.data });
			}
		} );
	};
}

export function loadLenderList(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: lendertPath,
			onSuccess: response => {
				props.setState({ lenderList: response.data });
			}
		} );
	};
}

export function loadRoleList(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: rolePath,
			onSuccess: response => {
				props.setState({ roleList: response.data });
			}
		} );
	};
}

export function saveItem( props, item ) {
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath,
			body: item,
			onSuccess: () => {
				props.setState({
					item: model,
					typeOfActionButton: "ADD"
				});
				dispatch( loadList(props) );
			}
		} );
	};
}

export function deleteItem( porps, obj ) {
	return dispatch => {
		apiDelete( dispatch, {
			url: defaultPath + `/${ obj }`,
			body: obj,
			onSuccess: () => {
				dispatch( loadList(porps) );
			}
		} );
	};
}