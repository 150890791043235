import React from "react";
import PropTypes from "prop-types";
import NetworkButton from "../../../commons/components/NetworkButton";
import { ValidatorForm, TextValidator} from "react-material-ui-form-validator";
import { FormControlLabel, Switch, Grid } from "@material-ui/core";
import MultiSelect from "../../../commons/components/MultiSelect";

const styles = {
	buttons: {
		marginTop: 15,
		marginBottom: 15,
		marginLeft: 5
	},
	saveButton: {
		marginLeft: 5
	}
};

const descriptionFieldFocus = input => { input && input.focus(); };

class UserAccountForm extends React.Component {
	state = {
		updatePassword: false
	};

	componentDidMount() {
		descriptionFieldFocus();
	};

	handleChangeInput = event => {
		this.props.changeInput( event.target.name, event.target.value );
	};

	handleChecked = ( event, checked ) => {
		this.props.changeInput( event.target.name, checked );
	};

	handleSubmit = () => {
		this.props.saveItem( this.props.item );
	};

	handleCancel = () => {
		this.props.cleanForm();
	};

	handleUpdatePassword = () => {
		this.setState({updatePassword: true})
	};

	getPasswordField(){
		const {isNewItem, item} = this.props;
		if(item.isInternal)
			return <div/>;

		if(this.state.updatePassword || isNewItem)
			return (
				<TextValidator
					required
					ref={ input => {
						this.password = input;
					} }
					name="password"
					label="Password"
					validators={ ["required"] }
					errorMessages={ ["The password cannot be blank."] }
					fullWidth={ true }
					value={ item.password }
					onChange={ this.handleChangeInput }
					type="password"
				/>
			);
		else
			return <NetworkButton text="Update Password" onClick={this.handleUpdatePassword} primary/>;

	}

	render() {
		return (
			<Grid container style={{marginTop:10}}>
				<ValidatorForm onSubmit={ this.handleSubmit } noValidate style={{width:"100%"}}>
					<Grid container item xs={12} spacing={3}>
						<Grid item>
							<FormControlLabel
								control={
									<Switch
										name="isLocked"
										checked={ this.props.item.isLocked }
										onChange={ this.handleChecked }
										color={ "primary" }
									/>
								}
								label="Is Blocked?"
							/>
						</Grid>
						<Grid item>
							<FormControlLabel
								control={
									<Switch
										name="isInternal"
										checked={ this.props.item.isInternal }
										onChange={ this.handleChecked }
										color={ "primary" }
									/>
								}
								label="Is Internal?"
							/>
						</Grid>
						<Grid item>
							<FormControlLabel
								control={
									<Switch
										name="isAdmin"
										checked={ this.props.item.isAdmin }
										onChange={ this.handleChecked }
										color={ "primary" }
									/>
								}
								label="Is Admin?"
							/>
						</Grid>
					</Grid>
					<Grid container item xs={12} spacing={3}>
						<Grid item xs={6}>
							<TextValidator
								required
								ref={ input => {
									this.description = input;
								} }
								name="firstName"
								label="First Name"
								validators={ ["required", "minStringLength:3"] }
								errorMessages={ ["First Name", "The first Name must be at least 3 characters long."] }
								fullWidth={ true }
								value={ this.props.item.firstName }
								onChange={ this.handleChangeInput }
							/>
						</Grid>
						<Grid item xs={6}>
							<TextValidator
								required
								ref={ input => {
									this.lastName = input;
								} }
								name="lastName"
								label="Last Name"
								validators={ ["required", "minStringLength:3"] }
								errorMessages={ ["Last Name", "The last Name must be at least 3 characters long."] }
								fullWidth={ true }
								value={ this.props.item.lastName }
								onChange={ this.handleChangeInput }
							/>
						</Grid>
					</Grid>
					<Grid container item xs={12} spacing={3}>
						<Grid item xs={6}>
							<TextValidator
								required
								ref={ input => {
									this.loginName = input;
								} }
								name="loginName"
								label="Login Name"
								validators={ ["required", "minStringLength:3"] }
								errorMessages={ ["Login Name", "The login Name can not be empty"] }
								fullWidth={ true }
								value={ this.props.item.loginName }
								onChange={ this.handleChangeInput }
							/>
						</Grid>
						<Grid item xs={6}>
							<TextValidator
								required
								ref={ input => {
									this.email = input;
								} }
								name="email"
								label="Email"
								validators={ ["required", "isEmail"] }
								errorMessages={ ["The e-mail address cannot be blank.","The e-mail address is incorrect."] }
								fullWidth={ true }
								value={ this.props.item.email }
								onChange={ this.handleChangeInput }
								style={ { marginBottom: 25 } }
							/>
						</Grid>
					</Grid>
					<Grid container item xs={12} spacing={3}>
						<Grid item xs={6}>
							{this.getPasswordField()}
						</Grid>
					</Grid>
					<Grid container item xs={12} spacing={3}>
						<Grid item xs={6}>
							<MultiSelect
								label="Roles"
								options={ this.props.roleList }
								values={ this.props.item.roles }
								name={ "roles" }
								valueProperty="description"
								changeInputSelect={ this.props.changeInput }
								height={300}
							/>
						</Grid>
						<Grid item xs={6}>
							<MultiSelect
								label="Lenders"
								options={ this.props.lenderList }
								values={ this.props.item.lenders }
								name={ "lenders" }
								valueProperty="name"
								changeInputSelect={ this.props.changeInput }
								height={300}
							/>
						</Grid>
					</Grid>
					<br />
					<Grid container item xs={12} spacing={3}>
						<Grid container justifyContent="flex-end">
						<div style={ styles.buttons }>
							<NetworkButton text={ "Cancel" } onClick={ this.handleCancel } />
							<NetworkButton
								text={ "Save" }
								primary={ true }
								type="submit"
								style={ styles.saveButton }
							/>
						</div>
						</Grid>
					</Grid>
				</ValidatorForm>
			</Grid>
		);
	}
}

UserAccountForm.propTypes = {
	changeInput: PropTypes.func,
	cleanForm: PropTypes.func,
	item: PropTypes.object,
	list: PropTypes.array,
	isNewItem: PropTypes.bool,
	saveItem: PropTypes.func
};

export default UserAccountForm;
