import React from "react";
import PropTypes from "prop-types";
import { Table, TableBody, TableRow, TableHead, TableCell, Checkbox, InputBase, Paper, Typography } from "@material-ui/core";
import { FixedSizeList as List } from "react-window";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";

const ROW_HEIGHT = 25;

const useTableStyles = {
	tableCellHead: {
		padding: 0,
		width: 25
	},
	table: {
		height: "100%",
		width: "100%"
	},
	header: {
		display: "flex",
		height: ROW_HEIGHT,
		backgroundColor: "#ffffff !important"
	},
	row: {
		display: "flex",
		userSelect: "none",
		"&:hover": {
			cursor: "pointer",
		},
	},
	cellHeader: {
		padding:"0px 5px 0px 0px",
		overflow:"hidden",
		fontSize:11,
		width:"100%"
	},
	cell: {
		padding:"0px 5px 0px 0px",
		overflow:"hidden",
		fontSize:11,
		width:"100%",
		marginTop:5
	},
	paperSearch: {
		padding:0,
		height:25,
		width:"50%",
		display:"flex",
		float:"right"
	},
	inputSearch: {
		padding:0,
		fontSize:11
	},
	inputBaseSeach:{
		width:"100%"
	}
};

class MultiSelect extends React.Component {

	idProperty = "id";
	valueProperty = "value";

	constructor(props) {
		super(props);
		this.idProperty = this.props.idProperty || "id";
		this.valueProperty = this.props.valueProperty || "value";
		this.state = {
			allOptions: [],
			renderOptions: [],
		};
		this.isShiftHeld = false;
		this.shiftRange = {
			start: -1,
			end: -1,
		};
		this.lastSelectedIndex = -1;
		this.listRef = React.createRef();
		this.rowRefs = {};
	}

	static getDerivedStateFromProps(props, state) {
		if(JSON.stringify(props.options) !== JSON.stringify(state.allOptions)) {
			return {
				allOptions: props.options,
				renderOptions: props.options
			};
		}

		return state;
	};

	componentDidMount() {
		window.addEventListener("keydown", this.onKeyDown);
		window.addEventListener("keyup", this.onKeyUp);
		window.addEventListener("click", this.onWindowClick);
	}

	componentWillUnmount() {
		window.removeEventListener("keydown", this.onKeyDown);
		window.removeEventListener("keyup", this.onKeyUp);
		window.removeEventListener("click", this.onWindowClick);
	}

	onWindowClick = (e) => {
		if (this.listRef.current?.contains(e.target)) {
			this.hasFocus = true;
		} else {
			this.hasFocus = false;
			this.lastSelectedIndex = -1;
		}
	}

	/** @param {React.KeyboardEvent} e */
	onKeyDown = (e) => {
		if (e.key === "Shift" && !this.isShiftHeld) {
			this.isShiftHeld = true;
			if (this.lastSelectedIndex >= 0) {
				this.shiftRange.start = this.lastSelectedIndex;
				this.shiftRange.end = this.lastSelectedIndex;
			}
		} else if (e.key === "ArrowDown" && this.isShiftHeld && this.lastSelectedIndex >= 0 && this.hasFocus) {
			if (this.state.renderOptions instanceof Array && this.lastSelectedIndex < this.state.renderOptions.length - 1) {
				if (this.shiftRange.start <= this.shiftRange.end && this.shiftRange.end + 1 < this.state.renderOptions.length) {
					this.selectRow(this.shiftRange.end + 1);
				} else {
					this.deselectRow(this.shiftRange.end);
				}

				if (this.shiftRange.end < this.state.renderOptions.length - 1) {
					this.shiftRange.end++;
				}
			}
		} else if (e.key === "ArrowUp" && this.isShiftHeld && this.lastSelectedIndex >= 0 && this.hasFocus) {
			if (this.state.renderOptions instanceof Array && this.lastSelectedIndex > 0) {
				if (this.shiftRange.start < this.shiftRange.end) {
					this.deselectRow(this.shiftRange.end);
				} else if (this.shiftRange.end - 1 >= 0) {
					this.selectRow(this.shiftRange.end - 1);
				}

				if (this.shiftRange.end > 0) {
					this.shiftRange.end--;
				}
			}
		}
	}

	/** @param {React.KeyboardEvent} e */
	onKeyUp = (e) => {
		if (e.key === "Shift" && this.isShiftHeld) {
			this.isShiftHeld = false;
			this.shiftRange.start = -1;
			this.shiftRange.end = -1;
		}
	}

	handleSelectAllClick = event => {
		const { name } = this.props;
		const { allOptions } = this.state;
		if (event.target.checked) {
			this.props.changeInputSelect( name, allOptions );
			return;
		}
		this.props.changeInputSelect( name, [] );
	};

	selectRow = ( index ) => {
		const row = this.state.renderOptions[index];
		const { values, name } = this.props;

		if (!(values instanceof Array)) {
			return;
		}

		const newValues = this.concatUnique( values, [ row ] );
		this.props.changeInputSelect(name, newValues);

		this.scrollIntoViewIfNotVisible(this.rowRefs[index]?.current);

		this.lastSelectedIndex = index;
	}

	deselectRow = ( index ) => {
		const row = this.state.renderOptions[index];
		const { values, name } = this.props;

		if (!(values instanceof Array)) {
			return;
		}

		const selectedIndexInValues = values.findIndex(item => item[this.idProperty] === row[this.idProperty]);
		if (selectedIndexInValues >= 0) {
			const newValues = values.slice();
			newValues.splice(selectedIndexInValues, 1);
			this.props.changeInputSelect(name, newValues)
		}

		this.scrollIntoViewIfNotVisible(this.rowRefs[index]?.current);

		this.lastSelectedIndex = index;
	}

	scrollIntoViewIfNotVisible = (element) => {
		if (!element || !(element instanceof HTMLElement)) {
			return;
		}
		const elementsContainer = element.parentElement;
		if (!elementsContainer || !(elementsContainer instanceof HTMLElement)) {
			return;
		}
		const scrollContainer = elementsContainer.parentElement;
		if (!scrollContainer || !(scrollContainer instanceof HTMLElement)) {
			return;
		}

		const tolerance = 10;
		const elementPosition = element.offsetTop + tolerance;
		const scrollContainerTop = scrollContainer.scrollTop;
		const scrollContainerBottom = scrollContainer.scrollTop + scrollContainer.clientHeight;


		if (scrollContainerTop <= elementPosition && elementPosition <= scrollContainerBottom) {
			return;
		}

		element.scrollIntoView({ block: "nearest" });
	}

	handleChangeRoleSelection = (event, row ) => {
		const { values, name } = this.props;
		const selectedRows = values;
		const selectedIndexInValues = selectedRows.findIndex( i => i[this.idProperty] === row[this.idProperty] );
		const selectedIndexInOptions = this.state.renderOptions.findIndex(i => i[this.idProperty] === row[this.idProperty]);

		let newSelected = [];

		if (this.isShiftHeld && this.lastSelectedIndex >= 0 && selectedIndexInOptions >= 0 && this.lastSelectedIndex !== selectedIndexInOptions) {
			const min = Math.min(this.lastSelectedIndex, selectedIndexInOptions);
			const max = Math.max(this.lastSelectedIndex, selectedIndexInOptions);
			newSelected = this.state.renderOptions.slice(min, max + 1);
			this.shiftRange.end = selectedIndexInOptions;
		}

		if (selectedIndexInValues === -1) {
			newSelected = this.concatUnique( newSelected, [ ...selectedRows, row ] );
		} else if (selectedIndexInValues === 0) {
			newSelected = this.concatUnique( newSelected, selectedRows.slice( 1 ) );
		} else if (selectedIndexInValues === selectedRows.length - 1) {
			newSelected = this.concatUnique( newSelected, selectedRows.slice( 0, -1 ) );
		} else if (selectedIndexInValues > 0) {
			newSelected = this.concatUnique( newSelected, [
				...selectedRows.slice( 0, selectedIndexInValues ),
				...selectedRows.slice( selectedIndexInValues + 1 )
			] );
		}

		this.lastSelectedIndex = selectedIndexInOptions;

		this.props.changeInputSelect( name, newSelected );
	};

	concatUnique = (arr, toConcat) => {
		const map = new Map();
		const uniqueArr = [];
		for (const item of arr) {
			map.set(item[this.idProperty], item);
			uniqueArr.push(item);
		}
		for (const item of toConcat) {
			if (!map.has(item[this.idProperty])) {
				uniqueArr.push(item);
			}
		}
		return uniqueArr;
	}

	handleChangeInput = (event) => {
		this.lastSelectedIndex = -1;
		let value = event.target.value;
		const renderOptions = this.state.allOptions.filter(item => item[this.valueProperty].toLowerCase().includes(value.toLowerCase()));
		this.setState({
			renderOptions: renderOptions
		});
	};

	Row = (event) => {
		const{ values, classes } = this.props
		let row = this.state.renderOptions[event.index];
		if (!this.rowRefs[event.index]) {
			this.rowRefs[event.index] = React.createRef();
		}
		return (
			<TableRow key={event.index} ref={this.rowRefs[event.index]} component="div" className={classes.row} style={event.style} onClick={ this.handleChangeRoleSelection.bind(this, event, row) }>
				<TableCell component="div" variant="body" scope="col" className={ classes.tableCellHead }>
					<Checkbox
						color="primary"
						checked = {values.filter(i => i !== null).findIndex(i => i[this.idProperty] === row[this.idProperty]) !== -1}
						onChange={ this.handleChangeRoleSelection.bind(this, event, row )}
						/>
				</TableCell>
				<TableCell component="div" variant="body" scope="col" className={classes.cell}>{ row[this.valueProperty] }</TableCell>
			</TableRow>
		);
	};

	render() {
		const { label, height, values, classes } = this.props;
		const total = this.state.allOptions.length;
		const numSelected = values.length;
		return (
			<div style={{ maxHeight: height, overflow: "auto"}}>
				<Typography variant="subtitle1">{label}</Typography>
				<Table className={classes.table} component="div"> 
					<TableHead component="div">
						<TableRow component="div" className={classes.header}>
							<TableCell component="div" variant="head" scope="col" className={classes.tableCellHead} >
								<Checkbox
									color="primary"
									indeterminate={numSelected > 0 && numSelected < total}
									checked={numSelected === total}
									onChange={ this.handleSelectAllClick }
								/>
							</TableCell>
							<TableCell component="div" variant="head" scope="col" className={classes.cellHeader}>
								All
								<Paper className={classes.paperSearch}>
									<InputBase placeholder="Search" onChange={this.handleChangeInput.bind(this)} className={classes.inputBaseSeach} classes={{input: classes.inputSearch}}/>
									<SearchIcon />
								</Paper>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody component="div">
						<List
							height={height - ROW_HEIGHT - 28}
							itemCount={this.state.renderOptions.length}
							itemSize={ROW_HEIGHT}
							onScroll={() => {}}
							innerRef={this.listRef}
						>
							{this.Row}
						</List>
					</TableBody>
				</Table>
			</div>
		);
	}
}

MultiSelect.propTypes = {
	classes: PropTypes.object.isRequired,
	changeInputSelect: PropTypes.func,
	idProperty: PropTypes.string,
	height: PropTypes.number,
	label: PropTypes.string,
	name: PropTypes.string,
	options: PropTypes.array,
	values: PropTypes.array,
	valueProperty: PropTypes.string,
};

export default  withStyles( useTableStyles, {withTheme : true})(MultiSelect);
