import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {InputLabel, FormControl, Select, Grid, Typography} from '@material-ui/core';
import PageBase from "../../../commons/components/PageBase";
import NetworkButton from "../../../commons/components/NetworkButton";
import DatePickerRange from "../../../commons/components/DatePickerRange";
import { renderMenuItems } from "../../../commons/utils";
import OriginationSummaryTable from "../../../commons/components/OriginationSummaryTable";
import {
    loadFilters,
    loadDealerDetail,
    submit
 } from "./actions";

const mapDispatchToProps = {
    loadFilters,
    loadDealerDetail,
	submit
};

class SummaryCultivation extends React.Component {

    constructor(props) {
		super(props);
		this.props.loadFilters(this);
		let parameters = this.props.location.search.split("&");
		let dealerID = parameters[0].split("=")[1];
		let fromSubmitDate = parameters[1].split("=")[1];
		let toSubmitDate = parameters[2].split("=")[1];

		this.state = {
            filters: {
				type:"State",
				dealerId : [],
				fromSubmitDate: fromSubmitDate !== "null" ? new Date(fromSubmitDate.replaceAll('-','/')) : null,
				toSubmitDate: toSubmitDate !== "null" ? new Date(toSubmitDate.replaceAll('-','/')) : null,
				fromLoanFundingDate: null
			},
            dealerDetail: {},
			listGrid: [],
			typesList:[]
        };
		this.props.loadDealerDetail(this, dealerID);
	}

	handleSelectTypeFilter = (event) => {
		this.setState({
			filters: {
				...this.state.filters,
				type:event.target.value
			}
		});
	};

	handleDate(field, date){
		this.setState({
			filters: {
				...this.state.filters,
				[field]:date
			}
		});
	};

	handleSubmit = () => {
		this.props.submit(this, this.state.filters);
	};

    createFilters() {
		const { filters, typesList, dealerDetail } = this.state;
		return (
			<Grid container style={{marginTop:10}}>
				<Grid container item xs={12} spacing={3}>
					<Grid item xs={2}>
						<FormControl style={{width:'100%'}}>
							<InputLabel>Type</InputLabel>
							<Select inputProps={{ name: "type" }} value={filters.type} onChange={this.handleSelectTypeFilter} displayEmpty>
								{ renderMenuItems(typesList, false) }
							</Select>
						</FormControl>
						<Grid item xs>
							<DatePickerRange
								label={"Application Submit Date"}
								fromDate={filters.fromSubmitDate} 
								fromDateId="fromSubmitDate" 
								toDate={filters.toSubmitDate} 
								toDateId="toSubmitDate" 
								onDatesChange={this.handleDate.bind(this)}
							/>
						</Grid>
						<NetworkButton text={ "Submit" } primary={ true } style={{margin: 10, height: 36}} onClick={ this.handleSubmit } />
					</Grid>
                    <Grid item xs={4}>
                        <fieldset>
                            <Typography style={{color: 'rgba(0,0,0,0.47)'}}>Company: {dealerDetail["company"]}</Typography>
                            <Typography style={{color: 'rgba(0,0,0,0.47)'}}>Contact: {dealerDetail["contact"]}</Typography>
                            <Typography style={{color: 'rgba(0,0,0,0.47)'}}>Phone: {dealerDetail["phone"]}</Typography>
                            <Typography style={{color: 'rgba(0,0,0,0.47)'}}>Email: {dealerDetail["email"]}</Typography>
                            <Typography style={{color: 'rgba(0,0,0,0.47)'}}>Address: {dealerDetail["address1"]}</Typography>
                            <Typography style={{color: 'rgba(0,0,0,0.47)'}}>{dealerDetail["address2"]}</Typography>
                        </fieldset>
                    </Grid>
				</Grid>
            </Grid>
        );
    };

    createTable = () => {
        const { listGrid } = this.state;
        if(listGrid === undefined || listGrid.length === 0)
            return <div/>;

        return(
            <OriginationSummaryTable
				filters={this.state.filters}
                listGrid={this.state.listGrid}
            />
        );
    }

    render(){
        return(
            <PageBase title="Summary Cultivation" >
				<div>
					{this.createFilters()}
					{this.createTable()}
				</div>
			</PageBase>
        );
    }
}

SummaryCultivation.propTypes = {
    loadDealerDetail: PropTypes.func,
    loadFilters : PropTypes.func,
	submit: PropTypes.func
};

export default connect(
	null,
	mapDispatchToProps,
)( SummaryCultivation );