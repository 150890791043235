import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "@material-ui/core";
import TableInfinityLoader, { TableInfinityRow, TableInfinityCell } from "../../../commons/components/TableInfinityLoader"

const currentCol = 120;
const smallCol = 60;
const dealerCol = 500;
const lenderCol = 160;

class InventoryQueryTable extends React.Component {

	currencyTableCell(value="0.0"){
		return <TableInfinityCell style={{width:currentCol}}>{value.toLocaleString("en-US", { style: "currency", currency: "USD" })}</TableInfinityCell>;
	}

	getCountCellValue(row, delinquecy, showRangeLink = true){
		const cellValues ={
			range: row[delinquecy + "Range"],
			name: row['groupBy']
		};
		showRangeLink = row[delinquecy + "Count"] > 0 && cellValues.range !== "< 30";
		if(showRangeLink) {
			return(
				 <Link 
					component="button"
					style={{color:"blue", fontSize:11}}
					onClick={this.props.handleLoadDetails.bind(this, cellValues)}
				> 
					{row[delinquecy + "Count"]}
				</Link>
			);
		}

		return row[delinquecy + "Count"].toLocaleString();
	}

	getTableValuesForRange(row, dayPeriod, showRangeLink = true){
		const delinquecy = "delinquecy" + dayPeriod;
		return (
			<Fragment>
				<TableInfinityCell style={{width:smallCol}}>
					{this.getCountCellValue(row, delinquecy, showRangeLink)}
				</TableInfinityCell>
				<TableInfinityCell style={{width:smallCol}}> { (row[delinquecy + "Percentage"] * currentCol).toFixed(2) + "%" }</TableInfinityCell>
				{this.currencyTableCell(row[delinquecy + "Amount"])}
			</Fragment>
		);
	}

	getTableValuesForType(type, row){
		if(type === 'DealerName'){
			return (
				<Fragment>
					<TableInfinityCell style={{width:dealerCol}}> {row["groupBy"]} </TableInfinityCell>
					{this.currencyTableCell(row["amountFunded"])}
					<TableInfinityCell style={{width:currentCol}}> {row["totalFunded"]}</TableInfinityCell>
					<TableInfinityCell style={{width:currentCol}}> {row["accounts"]}</TableInfinityCell>
					{this.currencyTableCell(row["currentBalance"])}
					<TableInfinityCell style={{width:currentCol}}> {(row["currentAmount"] * currentCol).toFixed(2) + "%" }</TableInfinityCell>
				</Fragment>
			);

		}

		return (
			<Fragment>
				<TableInfinityCell style={{width:lenderCol}}> {row["groupBy"]} </TableInfinityCell>
				<TableInfinityCell style={{width:currentCol}}> {row["accounts"].toLocaleString()}</TableInfinityCell>
				{this.currencyTableCell(row["originalBalance"])}
				{this.currencyTableCell(row["currentBalance"])}
				{this.getTableValuesForRange(row, "0", false)}
			</Fragment>
		);
	}

	Row( event, row, parent ) {
		return (
			<TableInfinityRow event={event} variant="body">
				{parent.getTableValuesForType(parent.props.filters["type"], row)}
				{parent.getTableValuesForRange(row, "30")}
				{parent.getTableValuesForRange(row, "60")}
				{parent.getTableValuesForRange(row, "90")}
				{parent.getTableValuesForRange(row, "120")}
			</TableInfinityRow>
		);
	};

	Header(event){
		const { filters } = event.parent.props;
		const type = filters["type"];
		const headerTitles = type === 'DealerName' ?
		<Fragment>
			<TableInfinityCell style={{width:dealerCol}} >{type}</TableInfinityCell>
			<TableInfinityCell style={{width:currentCol}} >$ Funded</TableInfinityCell>
			<TableInfinityCell style={{width:currentCol}} ># Funded</TableInfinityCell>
			<TableInfinityCell style={{width:currentCol}} ># Dlq</TableInfinityCell>
			<TableInfinityCell style={{width:currentCol}} >Current Balance</TableInfinityCell>
			<TableInfinityCell style={{width:currentCol}} >(% Current)</TableInfinityCell>
		</Fragment>
		:
		<Fragment>
			<TableInfinityCell style={{width:lenderCol}} >{type}</TableInfinityCell>
			<TableInfinityCell style={{width:currentCol}} >Accounts</TableInfinityCell>
			<TableInfinityCell style={{width:currentCol}} >Original Balance</TableInfinityCell>
			<TableInfinityCell style={{width:currentCol}} >Current Balance</TableInfinityCell>
			<TableInfinityCell style={{width:smallCol}} >{"<30"}</TableInfinityCell>
			<TableInfinityCell style={{width:smallCol}} >(%)</TableInfinityCell>
			<TableInfinityCell style={{width:currentCol}} >($)</TableInfinityCell>
		</Fragment>

		return (
			<TableInfinityRow event={event} variant="head">
				{headerTitles}
				<TableInfinityCell style={{width:smallCol}} >31-60</TableInfinityCell>
				<TableInfinityCell style={{width:smallCol}} >(%)</TableInfinityCell>
				<TableInfinityCell style={{width:currentCol}} >($)</TableInfinityCell>
				<TableInfinityCell style={{width:smallCol}} >61-90</TableInfinityCell>
				<TableInfinityCell style={{width:smallCol}} >(%)</TableInfinityCell>
				<TableInfinityCell style={{width:currentCol}} >($)</TableInfinityCell>
				<TableInfinityCell style={{width:smallCol}} >91-120</TableInfinityCell>
				<TableInfinityCell style={{width:smallCol}} >(%)</TableInfinityCell>
				<TableInfinityCell style={{width:currentCol}} >($)</TableInfinityCell>
				<TableInfinityCell style={{width:smallCol}} >120</TableInfinityCell>
				<TableInfinityCell style={{width:smallCol}} >(%)</TableInfinityCell>
				<TableInfinityCell style={{width:currentCol}} >($)</TableInfinityCell>
			</TableInfinityRow>
		);
	};

	render() {
		return (
			<TableInfinityLoader
				listGrid={this.props.listGrid}
				drawRow={this.Row}
				drawHeader={this.Header}
				handleSubmit={this.props.handleSubmit}
				params={this.props.filters}
				parent={this}
			/>
		);
	}
}

InventoryQueryTable.propTypes = {
	filters: PropTypes.object,
	handleSubmit: PropTypes.func,
	listGrid: PropTypes.array,
	rangeDelinquecy: PropTypes.string,
	handleLoadDetails: PropTypes.func,
};

export default InventoryQueryTable;

