import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageBase from "../../../commons/components/PageBase";
import SearchNotData from "../../../commons/components/SearchNotData";
import DealerProductionReportTable from "./DealerProductionReportTable";
import DealerProductionDetailTable from "./DealerProductionDetailTable";
import DealerProductionReportFilter from "./DealerProductionReportFilter";
import StylizedModal from "../../../commons/components/StylizedModal";
import {
	loadFilters,
	submit,
	loadDetails,
	downloadExcelReport,
	downloadExcelDetails,
	initialFilters
 } from "./actions";
import SingleScroll from "../../../commons/components/SingleScroll";

const mapDispatchToProps = {
	loadFilters,
	submit,
	loadDetails,
	downloadExcelReport,
	downloadExcelDetails
};

const singleScrollName = "DealerProductionReport";

class DealerProductionReport extends React.Component {
	constructor(props) {
		super(props);
		this.props.loadFilters(this);
		this.state = {
			openDetailsModal: false,
			dealerNameModal: "",
			listDetailsGrid: [],
			isSubmitted: false,
			filters: initialFilters,
			currentFilters: initialFilters,
			listGrid: [],
			dealerAssociationList:[],
			dealerIdsList:[],
			sfcCultivationList:[],
			sfcSalespersonList:[]
		};
	}

	handleChange = (field, value) => {
		this.setState({
			filters:{
				...this.state.filters,
				[field]: value
			}
		});
	};

	handleSubmit = () => {
		this.props.submit(this, this.state.filters);
		this.setState({
			isSubmitted: false,
			listGrid: [],
			currentFilters: this.state.filters
		});
	};

	handleSubmitInfinity = (params) => {
		this.props.submit(this, params);
	};

	handleReset = () => {
		this.setState({
			filters: initialFilters,
			currentFilters: initialFilters,
			listGrid: [],
			isSubmitted: false
		});
	};

	handleExcel = () => {
		this.props.downloadExcelReport(this.getFiltersForDetails());
	};

	handleDownloadExcelDetails = () => {
		this.props.downloadExcelDetails(this.getFiltersForDetails());
	};

	handleLoadDetails = (dealerID) => {
		const filters = this.getFiltersForDetails();
		filters.dealerID = dealerID;
		this.props.loadDetails(this, filters);
	}

	handleCloseDetailsModal = () => {
		this.setState({
			openDetailsModal:false,
			dealerNameModal: "",
			listDetailsGrid: []
		});
	}

	getFilters = () => this.state.filters;
	getFiltersForDetails = () => this.state.currentFilters;

	createTable = () => {
		const { isSubmitted, listGrid } = this.state;
		return(
			<SearchNotData
				isSubmitted={isSubmitted}
				listGrid={listGrid}
			>
				<DealerProductionReportTable
					listGrid={listGrid}
					loadDetails={this.handleLoadDetails}
					handleSubmit={this.handleSubmitInfinity.bind(this)}
					filters={this.getFiltersForDetails()}
					forSingleScroll={singleScrollName}
				/>
			</SearchNotData>
		);
	};

	createModaldetail(){
		const { listDetailsGrid, openDetailsModal, dealerNameModal } = this.state;

		if(listDetailsGrid.length === 0)
			return <div/>;

		return(
			<StylizedModal
				openModal={openDetailsModal}
				onClose={this.handleCloseDetailsModal}
			>
				<DealerProductionDetailTable
					dealerName={ dealerNameModal }
					detailsResult={listDetailsGrid}
					onClose={this.handleCloseDetailsModal}
					onDownloadExcel={this.handleDownloadExcelDetails}
				/>
			</StylizedModal>
		);
	}

	render() {
		const { filters, dealerIdsList, dealerAssociationList, sfcCultivationList, sfcSalespersonList } = this.state;
		return (
			<PageBase title="Dealer Production Report" >
				<SingleScroll name={singleScrollName}>
					<SingleScroll.Scrollable for={singleScrollName}>
						<DealerProductionReportFilter 
							filters={filters}
							handleExcel={this.handleExcel}
							handleSubmit={this.handleSubmit}
							handleReset={this.handleReset}
							handleChanged={this.handleChange}
							dealerIdsList={dealerIdsList}
							dealerAssociationList={dealerAssociationList}
							sfcCultivationList={sfcCultivationList}
							sfcSalespersonList={sfcSalespersonList}
						/>
						<div style={{height:20}} />
						{this.createTable()}
						{this.createModaldetail()}
					</SingleScroll.Scrollable>
				</SingleScroll>
			</PageBase>
		);
	}
}

DealerProductionReport.propTypes = {
	downloadExcelDetails: PropTypes.func,
	downloadExcelReport: PropTypes.func,
	loadDetails: PropTypes.func,
	loadFilters: PropTypes.func,
	submit: PropTypes.func
};

export default connect(
	null,
	mapDispatchToProps,
)( DealerProductionReport );