import { apiGet, apiPost } from "../../../actions";
import fileDownload from "js-file-download";
import Moment from "moment";

export const initialFilters = {
	dealerAssociations: [],
	sfcCultivation: [],
	dealers: [],
	fromSubmitDate: null,
	toSubmitDate: null,
	startIndex: 0,
	stopIndex: 100
};

const defaultPath = "api/DealerManagement/DealerTrendReport";

export function loadFilters(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: "api/CommonFilter/loadDealerTrendFilters",
			onSuccess: response => {
				props.setState({
					dealerAssociationList: response.data.dealerAssociations,
					sfcCultivationList:response.data.sfcCultivations,
					dealerList:response.data.dealers,
				});
			}
		} );
	}
}

export function submit( props, filters ) {
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath,
			body: {
				dealerAssociationIds: JSON.stringify(filters.dealerAssociations.map(a => a.id)),
				sfcCultivation: JSON.stringify(filters.sfcCultivation.map(a => a.id)),
				dealerIds: JSON.stringify(filters.dealers.map(a => a.id)),
				fromSubmitDate: filters.fromSubmitDate !== null ? Moment( new Date( filters.fromSubmitDate ) ).format( "MM-DD-YYYY" ) : null,
				toSubmitDate: filters.toSubmitDate !== null ? Moment( new Date( filters.toSubmitDate ) ).format( "MM-DD-YYYY" ) : null,
				startIndex: filters.startIndex,
				stopIndex: filters.stopIndex
			},
			onSuccess: response => {
				props.setState({
					listGrid: response.data,
					isSubmitted: true
				});
			}
		} );
	};
}

export function downloadExcelReport( filters ) {
	return dispatch => {
		apiPost( dispatch, {
			responseType: "blob",
			url: defaultPath + `/downloadExcel`,
			body: {
				dealerAssociationIds: JSON.stringify(filters.dealerAssociations.map(a => a.id)),
				sfcCultivation: JSON.stringify(filters.sfcCultivation.map(a => a.id)),
				dealerIds: JSON.stringify(filters.dealers.map(a => a.id)),
				fromSubmitDate: filters.fromSubmitDate !== null ? Moment( new Date( filters.fromSubmitDate ) ).format( "MM-DD-YYYY" ) : null,
				toSubmitDate: filters.toSubmitDate !== null ? Moment( new Date( filters.toSubmitDate ) ).format( "MM-DD-YYYY" ) : null
			},
			onSuccess: response => {
				var now = new Date();
				fileDownload( response.data, `DealerTrendReport-${now.getMonth() + 1}-${now.getDate()}-${now.getFullYear()}.xlsx` );
			}
		} );
	};
}

export function downloadSaveExcelInPathReport( filters ) {
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath + `/downloadSaveExcelInPath`,
			body: {
				dealerAssociationIds: JSON.stringify(filters.dealerAssociations.map(a => a.id)),
				sfcCultivationIds: JSON.stringify(filters.sfcCultivation.map(a => a.id)),
				dealerIds: JSON.stringify(filters.dealers.map(a => a.id)),
				fromSubmitDate: filters.fromSubmitDate !== null ? Moment( new Date( filters.fromSubmitDate ) ).format( "MM-DD-YYYY" ) : null,
				toSubmitDate: filters.toSubmitDate !== null ? Moment( new Date( filters.toSubmitDate ) ).format( "MM-DD-YYYY" ) : null
			},
		} );
	};
}