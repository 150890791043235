import React from "react";
import PropTypes from "prop-types";
import { Paper, Divider, Fab } from "@material-ui/core";
import ContentAdd from "@material-ui/icons/Add";
import ContentClear from "@material-ui/icons/Clear";
import Typography from "@material-ui/core/Typography";
import { withStyles } from '@material-ui/core/styles';

export const TypeOfActionButton = { ADD: "ADD", CANCEL: "CANCEL" };

const styles = {
	Toolbar: {
		backgroundColor: "#ffffff",
		padding: "0px, 0px",
		height: "10px",
		display: "flex",
		justifyContent: "space-between",
		marginBottom: 15
	},
	ToolbarTitle: {
		fontSize: "25px",
		color: "#9f9f9f",
	},
	Clear: {
		clear: "both"
	},
	svgButton: {
		color: "#FFFFFF"
	}
};

const fabClassStyle ={
	fabAdd: {
		backgroundColor: "#66bb6a"
	},
	fabClear: {
		backgroundColor: "#ff4081"
	}
}

class PageBase extends React.Component {
	actions( condition ) {
		if ( condition ) return <div>{this.getTypeOfAction()}</div>;
		return null;
	}

	getTypeOfAction() {
		const isAdd = this.props.typeOfActionButton === TypeOfActionButton.ADD;
		const { classes } = this.props;
		return (
			<Fab size="small" onClick={ this.props.changeActionButton } className={ !isAdd ? classes.fabClear : classes.fabAdd  }>
				{isAdd ? <ContentAdd style={ styles.svgButton } /> : <ContentClear style={ styles.svgButton } />}
			</Fab>
		);
	}

	getStyles = () => {
		let styles = {};
		if (this.props.relativePositioning) {
			styles.position = "relative";
		}
		return styles;
	}

	render() {
		const { title, showActions } = this.props;
		return (
			<div>
				<Paper style={this.getStyles()}>
					<div style={ styles.Toolbar}>
						<Typography style={ styles.ToolbarTitle }>
							{ title }
						</Typography>
						{this.actions( showActions )}
					</div>
					<br />
					<Divider />
					{this.props.children}
					<div style={ styles.Clear } />
				</Paper>
			</div>
		);
	}
}

PageBase.propTypes = {
	classes: PropTypes.object.isRequired,
	changeActionButton: PropTypes.func,
	children: PropTypes.oneOfType([ PropTypes.object, PropTypes.array ]),
	showActions: PropTypes.bool,
	title: PropTypes.string,
	typeOfActionButton: PropTypes.string,
	relativePositioning: PropTypes.bool,
};

PageBase.defaultProps = {
	showActions: false
};

export default withStyles( fabClassStyle )(PageBase);
