const tableStyle = {
	root:{
		padding:'0px 40px 0px 40px'
	},
	cmdButton: {
		padding: 0
	},
	columns: {
		cmd: {
			paddingLeft: "none",
			textAlign: "center"
		},
		description: {
			width: "50%"
		}
	}
};

export default tableStyle;