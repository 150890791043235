import { FormControl, Grid, InputLabel, Select } from '@material-ui/core';
import PropTypes from "prop-types";
import React from "react";

import DatePickerRange from "../../../commons/components/DatePickerRange";
import IsolateEvent from "../../../commons/components/IsolateEvent";
import MultiSelect from "../../../commons/components/MultiSelect";
import NetworkButton from "../../../commons/components/NetworkButton";
import { renderMenuItems } from "../../../commons/utils";

class DealerReprtFilters extends React.Component {

	render() {
		const { filters, dealerAssociationList, sfcCultivationList, stateList, sfcSalespersonList, companyRegionsList, enrollmentStatusList, dealerApprovedList, fhaApprovedList, financeProgramList, welcomeLTTRList, assetsList } = this.props;
		return (
			<Grid container style={{marginTop:10}}>
				<Grid container item xs={12} spacing={3}>
					<Grid item xs={3}>
						<IsolateEvent wheel scroll>
							<MultiSelect name={"dealerAssociation"} label={"Dealer Association"} options={dealerAssociationList} values={filters.dealerAssociation} changeInputSelect={ this.props.handleMultiSelectFilter } height={300} />
						</IsolateEvent>
					</Grid>
					<Grid item xs={3}>
						<IsolateEvent wheel scroll>
							<MultiSelect name={"sfcCultivation"} label={"SFC Cultivation"} options={sfcCultivationList} values={filters.sfcCultivation} changeInputSelect={ this.props.handleMultiSelectFilter } height={300} />
						</IsolateEvent>
					</Grid>
					<Grid item xs={3}>
						<IsolateEvent wheel scroll>
							<MultiSelect name={"sfcSalesperson"} label={"SFC Salesperson"} options={sfcSalespersonList} values={filters.sfcSalesperson} changeInputSelect={ this.props.handleMultiSelectFilter } height={300} />
						</IsolateEvent>
					</Grid>
					<Grid item xs={2}>
						<IsolateEvent wheel scroll>
							<MultiSelect name={"state"} label={"State"} options={stateList} values={filters.state} changeInputSelect={ this.props.handleMultiSelectFilter } height={300} />
						</IsolateEvent>
					</Grid>
				</Grid>
				<Grid container item xs={12} spacing={3}>
					<Grid item xs={3}>
						<IsolateEvent wheel scroll>
							<MultiSelect name={"companyRegions"} label={"Company Regions"} options={companyRegionsList} values={filters.companyRegions} changeInputSelect={ this.props.handleMultiSelectFilter } height={300} />
						</IsolateEvent>
					</Grid>
					<Grid item xs={3}>
						<IsolateEvent wheel scroll>
							<MultiSelect name={"enrollmentStatus"} label={"Enrollment Status"} options={enrollmentStatusList} values={filters.enrollmentStatus} changeInputSelect={ this.props.handleMultiSelectFilter } height={300} />
						</IsolateEvent>
					</Grid>
					<Grid item xs={3}>
						<IsolateEvent wheel scroll>
							<MultiSelect name={"assets"} label={"Assets"} options={assetsList} values={filters.assets} changeInputSelect={ this.props.handleMultiSelectFilter } height={300} />
						</IsolateEvent>
					</Grid>
					<Grid containeritem xs={3} style={{marginTop:65}}>
						<Grid container item xs={12} spacing={3}>
							<Grid item xs={6}>
								<FormControl style={{width:'100%', marginTop:16}}>
									<InputLabel style={{marginTop:'-16px'}}>Dealer Approved</InputLabel>
									<Select inputProps={{ name: "dealerApproved" }} value={filters.dealerApproved} onChange={this.props.handleSelectFilter}>
										{ renderMenuItems(dealerApprovedList, false) }
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<FormControl style={{width:'100%', marginTop:16}}>
									<InputLabel style={{marginTop:'-16px'}}>FHA Approved</InputLabel>
									<Select inputProps={{ name: "fhaApproved" }} value={filters.fhaApproved} onChange={this.props.handleSelectFilter}>
										{ renderMenuItems(fhaApprovedList, false) }
									</Select>
								</FormControl>
							</Grid>
						</Grid>
						<Grid container item xs={12} spacing={3}>
							<Grid item xs={6}>
								<FormControl style={{width:'100%', marginTop:16}}>
									<InputLabel style={{marginTop:'-16px'}}>welcome LTTR</InputLabel>
									<Select inputProps={{ name: "welcomeLTTR" }} value={filters.welcomeLTTR} onChange={this.props.handleSelectFilter}>
										{ renderMenuItems(welcomeLTTRList, false) }
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<FormControl style={{width:'100%', marginTop:16}}>
									<InputLabel style={{marginTop:'-16px'}}>Finance Program</InputLabel>
									<Select inputProps={{ name: "financeProgram" }} value={filters.financeProgram} onChange={this.props.handleSelectFilter}>
										{ renderMenuItems(financeProgramList, false) }
									</Select>
								</FormControl>
							</Grid>
						</Grid>	
					</Grid>
				</Grid>
				<Grid container item xs={12} spacing={3}>
					<Grid container item xs={3}>
						<Grid item xs>
							<DatePickerRange
								label={"WPDI Approval Date"}
								fromDate={filters.fromSubmitDate}
								fromDateId="fromSubmitDate"
								toDate={filters.toSubmitDate}
								toDateId="toSubmitDate"
								onDatesChange={this.props.handleDate}
								onSubmit={this.props.handleSubmit}
							/>
						</Grid>
					</Grid>
					<Grid container item xs={3} alignItems="flex-end">
						<NetworkButton text={ "Submit" } primary={ true } style={{margin: 10, height: 36}} onClick={ this.props.handleSubmit } />
						<NetworkButton text={ "Reset" }  primary={ true } style={{margin: 10, height: 36}} onClick={ this.props.handleReset } />
						<NetworkButton text={ "Excel" }  primary={ true } style={{margin: 10, height: 36}} onClick={ this.props.handleExcel } />
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

DealerReprtFilters.propTypes = {
	assetsList: PropTypes.array,
	companyRegionsList: PropTypes.array,
	dealerApprovedList: PropTypes.array,
	dealerAssociationList: PropTypes.array,
	enrollmentStatusList: PropTypes.array,
	handleDate: PropTypes.func,
	handleExcel: PropTypes.func,
	handleMultiSelectFilter: PropTypes.func,
	handleLoadDetails: PropTypes.func,
	handleSelectFilter: PropTypes.func,
	handleSubmit: PropTypes.func,
	handleReset: PropTypes.func,
	filters: PropTypes.object,
	financeProgramList: PropTypes.array,
	fhaApprovedList: PropTypes.array,
	sfcCultivationList: PropTypes.array,
	sfcSalespersonList: PropTypes.array,
	stateList: PropTypes.array,
	welcomeLTTRList: PropTypes.array
};

export default DealerReprtFilters;

