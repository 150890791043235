import React from "react";
import PropTypes from "prop-types";
import NetworkButton from "../../../../../commons/components/NetworkButton";
import DatePickerRange from "../../../../../commons/components/DatePickerRange";
import Grid from "@material-ui/core/Grid";
import { SelectValidator, ValidatorForm } from "react-material-ui-form-validator";
import { renderMenuItems } from "../../../../../commons/utils";

const ServicingAdviceReportFilter = ( {
	onSelectLender,
	onChangeDate,
	onSubmitForm,
	lenders,
	item,
	style
} ) => (
	<ValidatorForm onSubmit={ onSubmitForm } noValidate>
		<Grid container spacing={2} style={ style }>
			<Grid item xs>
				<SelectValidator
					name="lender"
					label="Select a lender"
					value={ item.lender }
					onChange={ onSelectLender }
					validators={ ["required"] }
					errorMessages={ ["It is mandatory."] }
					fullWidth
					style={{marginTop:40}}
				>
					{renderMenuItems( lenders, false )}
				</SelectValidator>
			</Grid>
			<Grid item>
				<DatePickerRange
					label={"Date"}
					fromDate={item.initialDate} 
					fromDateId="initialDate" 
					toDate={item.finalDate} 
					toDateId="finalDate" 
					onDatesChange={onChangeDate}
				/>
			</Grid>
		</Grid>
		<br />
		<NetworkButton text={ "Submit" } primary={ true } type="submit" />
	</ValidatorForm>
);

ServicingAdviceReportFilter.propTypes = {
	item: PropTypes.object,
	lenders: PropTypes.array,
	onChangeDate: PropTypes.func,
	onSelectLender: PropTypes.func,
	onSubmitForm: PropTypes.func,
	style: PropTypes.object
};

export default ServicingAdviceReportFilter;
