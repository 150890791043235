import { TextField } from "@material-ui/core";
import React, { Component } from "react";

class DateMonthTextField extends Component {

	state = {
		value: "",
		isValid: true,
	};

	isValidDateMonth = value => {
		if (value == null || value === "") {
			return true;
		}
		return /^[0-1]?[0-9][-/]?[0-9]{4}$/i.test(value);
	}

	/** @param {string} value */
	isValidPartialDateMonth = value => {
		if (value == null || value === "") {
			return true;
		}
		return /^[0-9]{1,2}[-/]?[0-9]{0,4}$/.test(value);
	}

	onChange = event => {
		if (this.isValidPartialDateMonth(event.target.value)) {
			this.setState({
				value: event.target.value,
				isValid: this.isValidDateMonth(event.target.value),
			});
			this.props.onChange?.(event);
		}
	}

	render() {
		var { value, onChange, ...rest } = this.props;
		return (
			<TextField
				{...rest}
				value={this.state.value}
				onChange={this.onChange}
				error={!this.state.isValid}
				helperText={!this.state.isValid ? "Please use the format MM/yyyy, MM-yyyy or MMyyyy" : null}
			/>
		);
	}
}

export default DateMonthTextField;