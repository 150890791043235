import React, { Component } from "react";
import ErrorMessage from "../../../../errors";

const STATES = {
	NORMAL: "NORMAL",
	SUCCESS: "SUCCESS",
	ERROR: "ERROR",
};

class TabStartTimer extends Component {

	state = {
		startTime: null,
		stopTime: null,
		timer: 0,
		state: STATES.NORMAL,
		title: "",
		showError: false,
	}

	styles = {
		container: {
			position: "absolute",
			top: ".8rem",
			right: "1rem",
			textAlign: "right",
		},
		time: {
			fontSize: ".9rem",
			margin: 0,
		},
		estimatedTime: () => ({
			...this.styles.time,
		}),
		elapsedTime: () => ({
			...this.styles.time,
			color: this.state.state === STATES.SUCCESS
				? "green"
				: this.state.state === STATES.ERROR
					? "red"
					: "inherit"
		})
	}

	intervalHandler

	componentWillUnmount() {
		clearInterval(this.intervalHandler);
	}

	start = () => {
		this.setState({
			startTime: Date.now(),
		});
		this.intervalHandler = setInterval(this.increment, 1000);
	}

	stop = (state) => {
		this.setState({
			stopTime: Date.now(),
			state
		});
		clearInterval(this.intervalHandler);
	}

	stopWithSuccess = () => {
		this.stop(STATES.SUCCESS);
		this.setState({
			title: "",
		});
	}

	stopWithError = (errorMessage) => {
		this.stop(STATES.ERROR);
		this.setState({
			title: errorMessage,
			showError: true,
		});
		setTimeout(this.closeError, 5000);
	}

	closeError = () => {
		this.setState({
			showError: false,
		})
	}

	increment = () => {
		this.setState(s => ({
			timer: s.timer + 1000,
		}));
	}

	toTimeString = (timeMs) => {
		if (typeof timeMs !== "number") {
			return "00:00:00";
		}

		const secondMs = 1000;
		const minuteMs = 60 * secondMs;
		const hourMs = 60 * minuteMs;
		
		let hour = 0, minute = 0, second = 0;
		if (timeMs >= hourMs) {
			hour = Math.floor(timeMs / hourMs);
			timeMs %= hourMs;
		}

		if (timeMs >= minuteMs) {
			minute = Math.floor(timeMs / minuteMs);
			timeMs %= minuteMs;
		}

		if (timeMs >= secondMs) {
			second = Math.floor(timeMs / secondMs);
			timeMs %= secondMs;
		}

		const hourPadded = hour.toString().padStart(2, "0");
		const minutePadded = minute.toString().padStart(2, "0");
		const secondPadded = second.toString().padStart(2, "0");

		return `${hourPadded}:${minutePadded}:${secondPadded}`;
	}

	render() {
		return (
			<div style={this.styles.container}>
				<p style={this.styles.estimatedTime()}>Estimated Time: {this.toTimeString(this.props.estimatedTime)}</p>
				<p style={this.styles.elapsedTime()} title={this.state.title}>Elapsed Time: {this.toTimeString(this.state.timer)}</p>
				<ErrorMessage showMessage={this.state.showError} text={this.state.title} onRequestClose={this.closeError} />
			</div>
		);
	}

}

export default TabStartTimer;
