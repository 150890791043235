/*
How to use 
TableInfinityLoader params:
	ListGrid: <Array<object>> - This params load only first load after submit. The Entity needs a field Count<int> that has a value with total
	drawRow: function - function to draw row, must use "TableInfinityRow", "TableInfinityCell" with variant="body"
		this function need 3 params:
		Row( event, row, parent )
			event: object - import to move to TableInfinityRow
			row: object - data with draw row
			parent: ref - use when has event
	drawHeader: function - function to draw header, must use "TableInfinityRow", "TableInfinityCell" with variant="head"
		this function has 2 params:
		Row( row, parent )
			row: object - data with draw row
			parent: ref - use when has event
	handleSubmit: function - function to get data
		handleSubmit(params)
			params: objcect - must include startIndex and StopIndex
	params: <Object> - optional - object with filters/params for use in submit function
	parent: <Ref> - optional - parent reference to use in cell events
	rowSize: <number> - optional - height of row
	rowSizeHeader: <number> - optional - height of header
*/

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, TableHead, TableBody, TableCell, TableRow, TableSortLabel, Link, withStyles } from "@material-ui/core";
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import {synchronizeScroll, unsynchronizeScroll} from "./ScrollHelper"
import SingleScroll from "./SingleScroll";

const useTableStyles = {
	table: {
		height: "100%",
		width: "100%"
	},
	row: {
		display: "flex"
	},
	cell: {
		padding:0,
		overflow:"hidden",
		fontSize:"9px !important",
		lineHeight: "1rem"
	},
	scrollHidden: {
		scrollbarWidth: "none",
		'&::-webkit-scrollbar': {
			display: 'none'
		}
	}
};

let id = 0;

class TableInfinityLoader extends Component {
	constructor(props){
		super(props);
		this.containerRef = React.createRef();
		this.scrollRef = React.createRef();
		this.tableRef = React.createRef();
		this.headerRef = React.createRef();
		this.synchronized = false;
		this.tableId = id++;
		this.state = {
			listGrid: [],
			totalCount: 0,
			params:{},
			width: 0,
			increment: 0,
			needResetScroll: true
		}
	}

	componentDidMount(){
		const { listGrid } = this.props;
		this.setState({
			listGrid: listGrid,
			totalCount: listGrid.length === 0 ? 0 : (!!listGrid[0]["totalCount"] ? listGrid[0]["totalCount"] : listGrid.length),
			params: JSON.parse( JSON.stringify( this.props.params ) )
		});
	};

	componentDidUpdate(){
		if(this.state.needResetScroll && this.tableRef.current && this.tableRef.current.parentElement && this.tableRef.current.parentElement.scrollTop !== 0) {
			this.tableRef.current.parentElement.scrollTop = 0;
			this.setState({needResetScroll: false});
		}

		this.resizeTable();
		if(this.tableRef.current !== null && this.scrollRef.current !== null)
			synchronizeScroll(this.tableId, [this.tableRef.current.parentElement, this.scrollRef.current]);
	};

	componentWillUnmount(){
		unsynchronizeScroll(this.tableId);
	}

	resizeTable(){
		let list = this.headerRef.current?.children[0].children;
		let width = list !== undefined ? [...list].reduce((total, el) => total + el.clientWidth,0) : 0;
		let tolerance = 1;
		let increment = 0;
		if (this.containerRef.current && this.containerRef.current.clientWidth > width) {
			let diff = this.containerRef.current.clientWidth - width;
			increment = diff / list.length;
			width = this.containerRef.current.clientWidth;
		}

		if (this.state.increment === 0 && increment > 0) {
			this.setState({ increment });
		}

		if (width - tolerance <= this.state.width && this.state.width <= width + tolerance) {
			this.setState({ width: width, increment });
		}
	};

	shouldComponentUpdate(nextProps, nextState)
	{
		if(nextProps.params !== this.props.params)
		{
			this.setState({
				listGrid: [],
				totalCount: 0,
				params: JSON.parse( JSON.stringify( nextProps.params ) )
			});
			return false;
		}

		if(nextProps.listGrid !== this.props.listGrid)
		{
			let updateList = this.state.listGrid;
			const { listGrid } = nextProps;
			const { params } = nextState;

			if(!!listGrid[0] && typeof listGrid[0]["indexRow"] === "number") // TODO - remover este if quando todas as telas tiverem o indexRow
			{
				listGrid.forEach(element => {
					updateList[element["indexRow"]] = element;
				});
			}
			else //TODO - Remover este codigo quando todas as telas estiverem sido corregida
			{
				let lastIndex = params.startIndex + listGrid.length;
				for (let index = params.startIndex; index < lastIndex; index++) {
					updateList[index] = listGrid[index - params.startIndex];
				}
			}

			this.setState({
				listGrid: updateList,
				totalCount: listGrid.length === 0 ? 0 : (!!listGrid[0]["totalCount"] ? listGrid[0]["totalCount"] : listGrid.length)
			});
			return true;
		}

		if(nextState.totalCount === 0)
			return false;

		return true;
	}

	isItemLoaded = index => this.state.listGrid[index] !== undefined;
	loadMoreItems = (startIndex, stopIndex) => {
		const { orderBy, order } = this.state;
		if(startIndex === stopIndex)
			stopIndex = startIndex + 1;

		this.setState({
			params: {
				...this.state.params,
				startIndex: startIndex,
				stopIndex: stopIndex
			}
		});
		this.props.handleSubmit({...this.state.params, ...{startIndex, stopIndex, orderBy, order }} );
	};

	cloneAndAddStyle = (event) => {
		return {...event, increment: this.state.increment, style: {...event.style, ...{height: this.props.rowSize}}};
	}

	Row = (event) => {
		const {classes, drawRow, parent} = this.props;
		const { listGrid, params } = this.state;
		let newEvent =  this.cloneAndAddStyle(event);
		newEvent = {...newEvent, ...{orderBy: params.orderBy} };

		if (listGrid[newEvent.index] !== undefined) {
			let row = listGrid[newEvent.index];
			return (drawRow(newEvent, row, parent));
		} else {
			return(
				<TableRow key={newEvent.index} component="div" className={classes.row} style={newEvent.style}>
					<TableCell component="div" variant="body" className={classes.cell} style={{width:400}} scope="col">
						"Loading..."
					</TableCell>
				</TableRow>
			);
		}
	};

	onRequestSort = (event, orderBy) => {
		const { params } = this.state;
		let newOrder = orderBy === params.orderBy ?
			( params.order === 'asc' ? 'desc' : 'asc' ) :
			'asc';
		this.setState({
			params: {
				...this.state.params,
				orderBy: orderBy,
				order: newOrder,
				startIndex: 0,
				stopIndex: 100
			}
		});
		this.props.handleSubmit({...this.state.params, ...{startIndex:0, stopIndex:100, orderBy: orderBy, order: newOrder}} );
	};

	render() {
		const { totalCount, listGrid, width, params } = this.state;
		if(totalCount === 0 || listGrid.length === 0) return <div/>;

		let widthScroll = navigator.userAgent.includes("Firefox") ? 17 : 1;

		const { classes, rowSize, rowSizeHeader, parent, forSingleScroll } = this.props;

		const createSortHandler = (property) => (event) => { this.onRequestSort(event, property); };
		const eventHeader = {style:{height: rowSizeHeader}, increment: this.state.increment, data:listGrid[0], parent: parent, orderBy: params.orderBy, order: params.order, createSortHandler: createSortHandler};

		const headerSize = 65;
		const tableHeight = 500;
		const tableContentHeight = tableHeight - headerSize;

		return (
			<div style={{display:"flex", height: tableHeight}}>
				<div ref={this.containerRef} style={{height: tableHeight, overflowX: "auto", maxWidth:"99%", width:"99%", zIndex:0}}>
					<Table className={classes.table} component="div" style={{width: width}}>
						<TableHead component="div" ref={this.headerRef}>
							{this.props.drawHeader(eventHeader)}
						</TableHead>
						<TableBody component="div">
							<InfiniteLoader
								isItemLoaded={this.isItemLoaded}
								itemCount={totalCount}
								loadMoreItems={this.loadMoreItems}
								minimumBatchSize={100}
							>
								{({ onItemsRendered, ref }) => (
									<SingleScroll.Scrollable for={forSingleScroll}>
										<List
											innerRef={forSingleScroll ? undefined : this.tableRef}
											height={tableContentHeight}
											itemCount={totalCount}
											itemSize={rowSize}
											onScroll={() => {}}
											className={classes.scrollHidden}
											onItemsRendered={onItemsRendered}
											ref={ref}
											style={{ overflowY: forSingleScroll ? "hidden" : undefined }}
										>
											{this.Row}
										</List>
									</SingleScroll.Scrollable>
								)}
							</InfiniteLoader>
						</TableBody>
					</Table>
				</div>
				{
					forSingleScroll ? null : (
						<div
							ref={this.scrollRef}
							style={{overflowX:"hidden", overflowY:"auto", minWidth:17, marginTop: rowSizeHeader, marginBottom: rowSize}}
						>
							<div style={{width:widthScroll, height:totalCount * rowSize}}/>
						</div>
					)
				}
			</div>
		);
	}

}

TableInfinityLoader.propTypes = {
	classes: PropTypes.object,
	drawHeader: PropTypes.func,
	drawRow: PropTypes.func,
	handleSubmit: PropTypes.func,
	listGrid: PropTypes.array,
	params: PropTypes.object,
	parent: PropTypes.object,
	rowSize: PropTypes.number,
	rowSizeHeader: PropTypes.number,
	forSingleScroll: PropTypes.string,
};

TableInfinityLoader.defaultProps = {
	rowSizeHeader: 48,
	rowSize: 16,
	params: {startIndex: 0, stopIndex:0}
};


class PureTableInfinityRow extends Component {
	render(){
		const { variant, classes, style, event, children }  = this.props;

		return(
			<TableRow key={event.index} component="div" className={classes.row} style={{...style, ...event.style}}>
				{
					React.Children.map(children, child => {
						let style = child.props.style || {};
						if (style && typeof style.width === "number") {
							style.width += event.increment || 0;
						}
						return React.cloneElement(child, {style: style, variant: variant, orderBy: event.orderBy, order: event.order, createSortHandler: event.createSortHandler });
					})
				}
			</TableRow>
		);
	};
}

PureTableInfinityRow.propTypes = {
	children: PropTypes.array,
	classes: PropTypes.object,
	event: PropTypes.object,
	style: PropTypes.object,
	variant: PropTypes.string
};

class PureTableInfinityCell extends Component {
	render(){
		const { variant, classes, style, children, orderBy, order, createSortHandler }  = this.props;
		let widthCell = orderBy !== undefined ? style.width + 16 : style.width;
		return(
			<TableCell component="div" variant={variant} scope="col" className={classes.cell} style={{...style, ...{width: widthCell}}}>
				{orderBy !== undefined && variant === "head" ?
					<Link
						style={{...style, ...{maxWidth: style.width, width:"", display:"inline-block", color:"initial", cursor:"pointer"}}}
						onClick={createSortHandler(children)}
					>
						{!!children ? children : ""}
					</Link>
					:
					<div style={{...style, ...{maxWidth: style.width, width:"", display:"inline-block"}}}>{!!children ? children : ""}</div>
				}
				{variant === "head" ?
					<TableSortLabel active={orderBy === children} direction={orderBy === children ? order : 'asc'} onClick={createSortHandler(children)} style={{width:16, display:"inline-block"}}/> :
					null
				}
			</TableCell>
		);
	};
}

PureTableInfinityCell.propTypes = {
	classes: PropTypes.object,
	children: PropTypes.oneOfType([ PropTypes.object, PropTypes.string, PropTypes.number, PropTypes.array ]),
	createSortHandler: PropTypes.func,
	orderBy: PropTypes.string,
	order: PropTypes.string,
	style: PropTypes.object,
	variant: PropTypes.string
};

export default withStyles( useTableStyles, {withTheme : true})(TableInfinityLoader); 

const TableInfinityRow = withStyles(useTableStyles, {withTheme : true})(PureTableInfinityRow);
const TableInfinityCell = withStyles(useTableStyles, {withTheme : true})(PureTableInfinityCell);
export { TableInfinityRow, TableInfinityCell };
