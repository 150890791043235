import { apiGet, apiPost } from "../../../actions";
import fileDownload from "js-file-download";

export const initialFilters = {
	lender: [],
	asOfDate: '',
	fundingMonth: '',
	loanType: [],
	maxPreviousDelinquecy: 3,
	maxCurrentDelinquecy: 4,
	previousDelinquecy: null,
	tu144Override: "all",
	startIndex: 0,
	stopIndex: 100,
};

const defaultPath = "api/AssetManager/RollforwardReport";

export function loadFilters(props) {
    return dispatch => {
        apiGet( dispatch, {
            url: "api/CommonFilter/loadRollforwardFilter",
            onSuccess(response){
				props.setState({
					lenderList: response.data.lender,
					loanTypeList: response.data.loanTypes,
					asOfDateList: response.data.asOfDate,
					filters: {
						...initialFilters,
						asOfDate: response.data.asOfDate[0]
					},
					currentFilters: {
						...initialFilters,
						asOfDate: response.data.asOfDate[0]
					}
				});
            }
		});
    };
}

export function submit( props, filters ) {
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath + "/loadGrid",
			body: convertFilterToParams(filters),
			onSuccess: response => {
				props.setState({
					listGrid: response.data,
					isSubmitted: true
				});
			}
		} );
	};
}

export function submitDetails(props, filters) {
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath + "/loadDetails",
			body: convertFilterToParams(filters),
			onSuccess: response => {
				props.setState({
					listDetailsGrid: response.data,
        			openDetailsModal: true
				});
			}
		} );
	};
}

export function downloadExcelReport( filters ) {
	return dispatch => {
		apiPost( dispatch, {
			responseType: "blob",
			url: defaultPath + '/downloadExcel',
			body: convertFilterToParams(filters),
			onSuccess: response => {
				fileDownload( response.data, "RollforwardReport.xlsx" );
			}
		} );
	};
}

export function downloadDetailExcelReport( filters ) {
	return dispatch => {
		apiPost( dispatch, {
			responseType: "blob",
			url: defaultPath + '/downloadDetailExcel',
			body: convertFilterToParams(filters),
			onSuccess: response => {
				fileDownload( response.data, "RollforwardApplicationDetail.xlsx" );
			}
		} );
	};
}

function convertFilterToParams(filters) {
	return {
		lender: filters.lender.map(a => a.key),
		loanType: filters.loanType.map(a => a.id),
		asOfDate: filters.asOfDate,
		fundingMonth: filters.fundingMonth,
		maxCurrentDelinquecy: filters.maxCurrentDelinquecy,
		maxPreviousDelinquecy: filters.maxPreviousDelinquecy,
		previousDelinquecy: filters.previousDelinquecy,
		tu144Override: radioToNullableBoolean(filters.tu144Override),
		startIndex: filters.startIndex,
		stopIndex: filters.stopIndex,
	};
}

function radioToNullableBoolean(value) {
	if (value === "all") {
		return null;
	} else if (value === "yes") {
		return true;
	} else {
		return false;
	}
}