import { apiGet, apiPost, apiDelete } from "../../../actions";

export const model = {
	ID: 0,
	isReadOnly: false,
	description: "",
	access: []
};

const defaultPath = "api/role";
const accessPath = "api/access";

export function loadList(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath,
			onSuccess: response => {
				props.setState({ dataList: response.data });
			}
		} );
	};
}


export function loadAccessList(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: accessPath,
			onSuccess: response => {
				props.setState({accessList: response.data});
			}
		} );
	};
}

export function saveItem( props, item ) {
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath,
			body: item,
			onSuccess: () => {
				props.setState({
					item: model,
					typeOfActionButton: "ADD"
				});
				dispatch( loadList(props) );
			}
		} );
	};
}

export function deleteItem( props, obj ) {
	return dispatch => {
		apiDelete( dispatch, {
			url: `${ defaultPath }/${ obj }`,
			body: obj,
			onSuccess: () => {
				dispatch( loadList(props) );
			}
		} );
	};
}