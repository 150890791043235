import React from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { withTheme } from "@material-ui/core/styles";

const widthDealer = 300;
const widthMonthly = 55;

const styles = {
	columnDealer: {
		padding: 0,
		width: widthDealer + "px",
		textAlign: "center"
	},
	columnMonthly: {
		padding: 0,
		width: widthMonthly + "px",
		textAlign: "center"
	}
};
const mapData = [
	{ label: "Total Apps", data: "totalApplications" },
	{ label: "Total Approved", data: "totalApproved" },
	{ label: "Approved %", data: "approvedPercentage" },
	{ label: "Total Funded", data: "totalFunded" },
	{ label: "Funded %", data: "fundedPercentage" },
	{ label: "WAF", data: "waf" }
];

class DealerTrendTable extends React.Component {

	getTableBody() {
		
		const { dealer } = this.props;
		return (
			<TableBody>
				<TableRow key={0}>
					<TableCell align="center">
						{dealer["dealerNumber"]}
					</TableCell>
					{dealer.monthly.map( (month, index) => <TableCell key={index}/> )}
				</TableRow>
				{mapData.map( (map, index) => 
					<TableRow key={index}>
						<TableCell key={index} align="center"> {map.label}</TableCell>
						{
							dealer.monthly.map( (values, index) =>
								<TableCell key={index} align="center">{values[map.data]}</TableCell>
						)}
					</TableRow>
				)}
			</TableBody>
		);
	}

	getTableHeader(){
		const { dealer } = this.props; 
		return (
			<TableHead>
				<TableRow key="rowHeader">
					<TableCell style={styles.columnDealer}>
						{ dealer["dealerName"] }
					</TableCell>
					{dealer["monthly"].map( (month, index) =>  <TableCell key={index} style={styles.columnMonthly}>{month["month"]}</TableCell>)}
				</TableRow>
			</TableHead>
		);
	}

	render() {
		const widthTable = widthDealer + this.props.dealer.monthly.length * widthMonthly;
		return (
			<Table style={{width:widthTable}}>
				{this.getTableHeader()}
				{this.getTableBody()}
			</Table>
		);
	}
}

DealerTrendTable.propTypes = {
	dealer: PropTypes.object
};

export default withTheme(DealerTrendTable);
