import React from "react";
import PropTypes from "prop-types";
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import DatePickerRange from "../../../commons/components/DatePickerRange";
import NetworkButton from "../../../commons/components/NetworkButton";

class FundingReportFilters extends React.Component {
	render(){
		const { filters, selectSendDealer } = this.props;
		return(
			<Grid container item xs={12} spacing={3}>
				<Grid container direction="column" justifyContent="center" alignItems="center" item>
					<DatePickerRange
						label={"Funding Date"}
						fromDate={filters.fromFundingDate} 
						fromDateId="fromFundingDate" 
						toDate={filters.toFundingDate} 
						toDateId="toFundingDate" 
						onDatesChange={this.props.handleDate}
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={filters.ignoreSents}
								onChange={this.props.handleCheckbox}
								name="ignoreSents"
								color="primary"
							/>
						}
						label="Ignore sents"
					/>
				</Grid>
				<Grid container justifyContent="center" alignItems="center" item>
						<NetworkButton text={ "Submit" } primary={ true } style={{margin: 10, height: 36}} onClick={ this.props.handleSubmit } />
						<NetworkButton
							text={ "Send email to all selected dealers" }
							primary={ true } style={{margin: 10, height: 36}}
							onClick={ this.props.handleSendEmail }
							disabled={!selectSendDealer || selectSendDealer.filter(item => item && item["selected"]).length === 0} />
						<NetworkButton text={ "Reset" }  primary={ true } style={{margin: 10, height: 36}} onClick={ this.props.handleReset } />
					</Grid>
			</Grid>
		);
	};
}

FundingReportFilters.propTypes = {
	filters: PropTypes.object,
	selectSendDealer: PropTypes.array,
	handleCheckbox: PropTypes.func,
	handleDate: PropTypes.func,
	handleSendEmail: PropTypes.func,
	handleSubmit: PropTypes.func,
	handleReset: PropTypes.func,
	listGrid: PropTypes.array
};

export default FundingReportFilters;