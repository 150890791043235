import axios from "axios";
import { showLoading, hideLoading } from "react-redux-loading-bar";

export const Network_ActionTypes = {
	Start: "Network.Start",
	End: "Network.End",
	LoadMessage: "Network.LoadMessage",
	ClearMessage: "Network.ClearMessage",
};

export function apiGet( dispatch, params ) {
	const get =
		mockMode && ( params.mockSuccess || params.mockError )
			? mockApiGet
			: axiosApiGet;

	get( dispatch, paramsWithDefaultValues( params ) );
}

//TODO: this should check the params and call the axios POST method.
export function apiPost( dispatch, params ) {
	const post =
		mockMode && ( params.mockSuccess || params.mockError )
			? mockApiPost
			: axiosApiPost;
	post( dispatch, paramsWithDefaultValues( params ) );
}

export function apiDelete( dispatch, params ) {
	const deleteApi =
		mockMode && ( params.mockSuccess || params.mockError )
			? mockApiDelete
			: axiosApiDelete;

	deleteApi( dispatch, paramsWithDefaultValues( params ) );
}

export const withTimeout = ( block, miliseconds ) => {
	setTimeout( block, miliseconds || 1 );
};

export const isEnvModeProduction = process.env.NODE_ENV === "production";
const mockMode = false;
const time = 900;
export const serverUrl = isEnvModeProduction
	? window.location.origin + window.location.pathname
	: "http://localhost:5500/";

const createActionNetworkStart = () => {
	return {
		type: Network_ActionTypes.Start,
		payload: undefined
	};
};

const createActionNetworkEnd = message => {
	return {
		type: Network_ActionTypes.End,
		payload: message
	};
};

export const createActionNetworkLoadMessage = message => {
	return {
		type: Network_ActionTypes.LoadMessage,
		payload: message
	};
};

export const createActionNetworkClearMessage = () => {
	return {
		type: Network_ActionTypes.ClearMessage
	};
};

export const createActionCheckNeedsUpdatePassword = message => {
	return {
		type: Network_ActionTypes.LoadMessage,
		payload: message
	};
};

const axiosApiGet = ( dispatch, params ) => {
	dispatch( showLoading() );
	dispatch( createActionNetworkStart( params.url ) );

	axios
		.get( params.url, {
			baseURL: serverUrl,
			headers: params.headers,
			params: params.params,
			responseType: params.responseType,
			withCredentials: true,
		} )
		.then( response => {
			dispatch( createActionNetworkEnd( "" ) );
			params.onSuccess && params.onSuccess( response );
		} )
		.catch( error => {
			processMessage( error, createActionNetworkEnd, dispatch );
			params.onError && params.onError( getMessageError( error ) );
		} )
		.finally( () => {
			dispatch( hideLoading() );
		} );
};

const processMessage = ( message, action, dispatch ) => {
	if ( !isValidError( message ) ) return action( "" );

	const response = message.response;
	if ( !isValidResponse( response ) ) return action( getMessageError(message) );

	getErrorMessageFromResponseStatus( response, action, dispatch );
};

const isValidError = error => {
	return error !== undefined && error !== "";
};

const isValidResponse = response => {
	return response !== undefined;
};

const getError400 = ( response, action, dispatch ) => {
	const data = response.data;
	const reponseType = response.config.responseType;

	if ( data.error !== undefined ) {
		dispatch( action( "Invalid username or password." ) );
	} else {
		if ( reponseType === "blob" || reponseType === "arraybuffer" ) {
			const reader = new window.FileReader();

			reader.onload = function() {
				const jsonResult = JSON.parse( reader.result );
				const error = jsonResult.errors[ 0 ];
				dispatch( action( error.Error ) );
			};
			reader.readAsText( data );
		} else {
			const errorTemp = data.errors[ 0 ];
			dispatch( action( errorTemp.Error ) );
		}
	}
};

const errorTable = {
	400: ( response, action, dispatch ) => {
		getError400( response, action, dispatch );
	},
	401: ( response, action, dispatch ) => {
		dispatch(
			action( "You are not authorized to do this. Please login and try again." )
		);
	},
	403: ( response, action, dispatch ) => {
		dispatch(
			action( "You are not authorized to do this. Please login and try again." )
		);
	},
	404: ( response, action, dispatch ) => {
		dispatch( action( "Connection Failed." ) );
	},
	500: ( response, action, dispatch ) => {
		dispatch( action( getMessageError( { response } ) ) );
	}
};

const getErrorMessageFromResponseStatus = ( response, action, dispatch ) => {
	const errorTableTemp = errorTable[ response.status ];
	if ( errorTableTemp ) {
		errorTableTemp( response, action, dispatch );
	}
};

const mockApiGet = ( dispatch, params ) => {
	dispatch( createActionNetworkStart( params.url ) );
	setTimeout( () => {
		if ( params.mockSuccess ) {
			dispatch( createActionNetworkEnd( "" ) );
			params.onSuccess( params.mockSuccess );
		}
		if ( params.mockError ) {
			dispatch( createActionNetworkEnd( params.mockError ) );
			params.onError( params.mockError );
		}
	}, time );
};

const axiosApiPost = ( dispatch, params ) => {
	dispatch( showLoading() );
	dispatch( createActionNetworkStart( params.url ) );
	axios
		.post( params.url, params.body, {
			responseType: params.responseType,
			headers: params.headers,
			baseURL: serverUrl,
			params: params.query,
			withCredentials: true,
		} )
		.then( response => {
			dispatch( createActionNetworkEnd("") );
			params.onSuccess && params.onSuccess( response );
		} )
		.catch( error => {
			dispatch( createActionNetworkEnd( getMessageError( error ) ) );
			params.onError && params.onError( getMessageError( error ) );
		} )
		.finally( () => {
			dispatch( hideLoading() );
		} );
};

const axiosApiDelete = ( dispatch, params ) => {
	dispatch( showLoading() );
	dispatch( createActionNetworkStart( params.url ) );
	axios
		.delete( serverUrl + params.url, {
			headers: params.headers,
			baseURL: serverUrl,
			params: params.query,
			data: params.body,
			withCredentials: true,
		} )
		.then( response => {
			dispatch( createActionNetworkEnd( "Deleted!" ) );
			params.onSuccess && params.onSuccess( response );
		} )
		.catch( error => {
			dispatch( createActionNetworkEnd( getMessageError( error ) ) );
			params.onError && params.onError( getMessageError( error ) );
		} )
		.finally( () => {
			dispatch( hideLoading() );
		} );
};

const mockApiPost = ( dispatch, params ) => {
	dispatch( createActionNetworkStart( params.url ) );
	setTimeout( () => {
		if ( params.mockSuccess ) {
			dispatch( createActionNetworkEnd( "Success!" ) );
			params.onSuccess( params.mockSuccess );
		}
		if ( params.mockError ) {
			dispatch( createActionNetworkEnd( params.mockError ) );
			params.onError( params.mockError );
		}
	}, time );
};

const mockApiDelete = ( dispatch, params ) => {
	dispatch( createActionNetworkStart( params.url ) );
	setTimeout( () => {
		if ( params.mockSuccess ) {
			dispatch( createActionNetworkEnd( "Deleted!" ) );
			params.onSuccess( params.mockSuccess );
		}
		if ( params.mockError ) {
			dispatch( createActionNetworkEnd( params.mockError ) );
			params.onError( params.mockError );
		}
	}, time );
};

const paramsWithDefaultValues = params => {
	return params;
};

const getMessageError = error => {
	if(error.response === undefined || error.response === "" ) {
		return "";
	} else if (typeof error.response.data === "string") {
		return error.response.data;
	} else if (error.response.data instanceof Blob) {
		var errorMessage = getBlobTextSynchronously(error.response.data);
		/*
			The error is presented as two lines in the following format:
			Code: <error_code>
			Message: <error_message>
		 */
		var messageIndex = errorMessage.indexOf("Message:");
		var message = errorMessage.substring(messageIndex + 9);
		return message;
	}
	return "An error occurred. Please try again later."
};

const getBlobTextSynchronously = blob => {
	var uri = URL.createObjectURL(blob),
	xhr = new XMLHttpRequest();

	xhr.open('GET', uri, false);
	xhr.send();

	URL.revokeObjectURL(uri);
	
	let ui8 = new Uint8Array(xhr.response.length);
	
	for (let i = 0; i < xhr.response.length; ++i) {
		ui8[i] = xhr.response.charCodeAt(i);
	}
	
	return new TextDecoder().decode(ui8);
};
