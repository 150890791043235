import React from "react";
import PropTypes from "prop-types";
import { Grid, FormControl, FormLabel, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import MultiSelect from "../../../commons/components/MultiSelect";
import NetworkButton from "../../../commons/components/NetworkButton";
import DatePickerRange from "../../../commons/components/DatePickerRange";
import IsolateEvent from "../../../commons/components/IsolateEvent";

class DealerProductionReportFilter extends React.Component {

	handleMultiSelectFilter = (field, value) => {
		this.props.handleChanged(field,value);
	};

	handleDate = (field, date) => {
		this.props.handleChanged(field,date);
	};

	handleToggle = (field, event) => {
		this.props.handleChanged(field,event.target.value);
	}

	render(){
		const { filters, dealerAssociationList, sfcCultivationList, dealerIdsList, sfcSalespersonList } = this.props;
		return (
			<Grid container style={{marginTop:10}}>
				<Grid container item xs={12} spacing={3}>
					<Grid item xs={3}>
						<IsolateEvent wheel scroll>
							<MultiSelect name={"dealerAssociation"} label={"Dealer Association"} options={dealerAssociationList} values={filters.dealerAssociation} changeInputSelect={ this.handleMultiSelectFilter } height={300}/>
						</IsolateEvent>
					</Grid>
					<Grid item xs={3}>
						<IsolateEvent wheel scroll>
							<MultiSelect name={ "dealer" } label={"Dealer Name"} options={ dealerIdsList } values={ filters.dealer } changeInputSelect={ this.handleMultiSelectFilter } height={300} />
						</IsolateEvent>
					</Grid>
					<Grid item xs={3}>
						<IsolateEvent wheel scroll>
							<MultiSelect name={"sfcCultivation"} label={"SFC Cultivation"} options={sfcCultivationList} values={filters.sfcCultivation} changeInputSelect={ this.handleMultiSelectFilter } height={300}/>
						</IsolateEvent>
					</Grid>
					<Grid item xs={2}>
						<IsolateEvent wheel scroll>
							<MultiSelect name={"sfcSalesperson"} label={"SFC Salesperson"} options={sfcSalespersonList} values={filters.sfcSalesperson} changeInputSelect={ this.handleMultiSelectFilter } height={300}/>
						</IsolateEvent>
					</Grid>
				</Grid>
				<Grid container item xs={12} spacing={3}>
					<Grid container item xs={6}>
						<Grid item xs>
							<DatePickerRange
								label={"WPDI Approval Date"}
								fromDate={filters.fromWpdiApprovalDate}
								fromDateId="fromWpdiApprovalDate"
								toDate={filters.toWpdiApprovalDate}
								toDateId="toWpdiApprovalDate"
								onDatesChange={this.handleDate}
								onSubmit={this.props.handleSubmit}
							/>
						</Grid>
						<Grid item xs>
							<DatePickerRange
								label={"Application Submit Date"}
								fromDate={filters.fromSubmitDate}
								fromDateId="fromSubmitDate"
								toDate={filters.toSubmitDate}
								toDateId="toSubmitDate"
								onDatesChange={this.handleDate}
								onSubmit={this.props.handleSubmit}
							/>
						</Grid>
					</Grid>
					<Grid item>
						<FormControl component="fieldset">
							<FormLabel component="legend">Webinar Complete Date</FormLabel>
							<RadioGroup name="webinarCompletDate" value={filters.webinarCompletDate} row onChange={ this.handleToggle.bind(this, "webinarCompletDate")} >
								<FormControlLabel value="All" control={<Radio color="primary"/>} label="All" />
								<FormControlLabel value="Yes" control={<Radio color="primary"/>} label="Yes" />
								<FormControlLabel value="No" control={<Radio color="primary"/>} label="No" />
							</RadioGroup>
						</FormControl>
					</Grid>
					<Grid container item xs={3} alignItems="flex-end">
						<NetworkButton text={ "Submit" } primary={ true } style={{margin: 10, height: 36}} onClick={ this.props.handleSubmit } />
						<NetworkButton text={ "Reset" }  primary={ true } style={{margin: 10, height: 36}} onClick={ this.props.handleReset } />
						<NetworkButton text={ "Excel" }  primary={ true } style={{margin: 10, height: 36}} onClick={ this.props.handleExcel } />
					</Grid>
				</Grid>
			</Grid>
		);
	}
};

DealerProductionReportFilter.propTypes = {
	dealerAssociationList: PropTypes.array,
	dealerIdsList: PropTypes.array,
	sfcCultivationList: PropTypes.array,
	sfcSalespersonList: PropTypes.array,
	filters: PropTypes.object,
	handleSubmit: PropTypes.func,
	handleReset: PropTypes.func,
	handleExcel: PropTypes.func,
	handleChanged: PropTypes.func
};

export default DealerProductionReportFilter;