import React from "react";
import Paper from "@material-ui/core/Paper";

const PageNotFound = () => {
	const styles = {
		paper: {
			padding: 20,
			overflow: "auto"
		}
	};

	return (
		<Paper style={ styles.paper }>
			<div>
				<div>
					<h1>Oops!</h1>
					<h2>We can't seem to find the page you're looking for.</h2>
					<h6>Error code: 404</h6>
				</div>
			</div>
		</Paper>
	);
};

export default PageNotFound;
