import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageBase, { TypeOfActionButton } from "../../../commons/components/PageBase";
import RoleFrom from "../role/RoleForm";
import RoleTable from "../role/RoleTable";
import {
	loadList,
	loadAccessList,
	deleteItem,
	saveItem,
	model
} from "./actions";

const mapDispatchToProps = {
	loadAccessList,
	loadList,
	deleteItem,
	saveItem
};

class Role extends React.Component {
	state = {
		item: model,
		dataList: [],
		accessList: [],
		typeOfActionButton: "ADD"
	};
	constructor(props) {
		super(props);
		this.props.loadList(this);
		this.props.loadAccessList(this);
	}

	handleChangeActionButton = () => {
		if ( TypeOfActionButton.ADD === this.state.typeOfActionButton ) {
			this.setState({typeOfActionButton:TypeOfActionButton.CANCEL});
		} else {
			this.cleanFields();
		}
	};

	cleanFields = () => {
		this.setState({
			typeOfActionButton: TypeOfActionButton.ADD,
			item: model
		});
	};

	handleLoadItem = item => {
		this.setState({
			typeOfActionButton: TypeOfActionButton.CANCEL,
			item: item
		});
	};

	handleSaveItem = (item) => {
		this.props.saveItem(this, item);
	};

	handleDeleteItem = (id) => {
		this.props.deleteItem(this, id);
	};

	handleUpdateField =(field, value) => {
		this.setState({
			item:{
				...this.state.item,
				[field]: value
			}
		});
	};

	showPanel = () => {
		const {typeOfActionButton, dataList, accessList, item} = this.state;
		if ( TypeOfActionButton.ADD === typeOfActionButton ) {
			return (
				<RoleTable
					list={ dataList }
					loadItem={ this.handleLoadItem }
					deleteItem={ this.handleDeleteItem.bind(this) }
				/>
			);
		}

		return (
			<RoleFrom
				item={ item }
				list={ accessList }
				changeInput={ this.handleUpdateField }
				saveItem={ this.handleSaveItem.bind(this) }
				cleanForm={ this.cleanFields }
			/>
		);
	};

	render() {
		return (
			<PageBase
				title="Role"
				showActions
				typeOfActionButton={ this.state.typeOfActionButton }
				changeActionButton={ this.handleChangeActionButton }
			>
				{this.showPanel()}
			</PageBase>
		);
	}
}

Role.propTypes = {
	deleteItem: PropTypes.func,
	loadAccessList: PropTypes.func,
	loadList: PropTypes.func,
	saveItem: PropTypes.func,
};

export default connect(
	null,
	mapDispatchToProps,
)( Role )
