import { apiGet, apiPost, apiDelete } from "../../../../actions";

export const initialFilters = {startIndex: 0, stopIndex:100};

export const model = {
	id: 0,
	lenderName: "",
	date: null,
	availableFund: "",
	totalcount:0
};

const defaultPath = "api/FundingManagement/Reconciliation/ReconciliationReport";

export function saveItem( props, item ) {
	const newItem =  JSON.parse( JSON.stringify( item ) );
	newItem.availableFund = parseFloat( newItem.availableFund.replace( "$", "" ).split( "," ).join( "" ) );
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath,
			body: newItem,
			onSuccess: () => {
				props.setState({item: model, dataSource:[]});
				dispatch( loadList(props, initialFilters) );
			}
		} );
	};
}

export function loadList(props, filters) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath,
			params: {
				startIndex: filters.startIndex,
				stopIndex: filters.stopIndex
			},
			onSuccess: response => {
				props.setState({dataSource:response.data});
			}
		} );
	};
}

export function loadAllOptionsForFilters(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: "api/commonFilter/loadReconciliationFilters",
			onSuccess: response => {
				props.setState({lenderOptions: response.data });
			}
		} );
	};
}

export function deleteAvailableFund( props, obj ) {
	return dispatch => {
		apiDelete( dispatch, {
			url: defaultPath + "/" + obj,
			body: obj,
			onSuccess: () => {
				props.setState({dataSource:[]});
				dispatch( loadList(props, initialFilters) );
			}
		} );
	};
}