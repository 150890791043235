import { Button, FormControl, Grid, InputLabel, Select } from "@material-ui/core";
import React, { PureComponent } from "react";
import DescriptionIcon from '@material-ui/icons/Description';
import PageBase from "../../../commons/components/PageBase";
import { renderMenuItems } from "../../../commons/utils";

import {
	loadAsOfDates,
	downloadExcel,
	downloadWord
} from "./actions";
import { connect } from "react-redux";

const mapDispatchToProps = {
	loadAsOfDates,
	downloadExcel,
	downloadWord,
};

class DeterminationDateIssue extends PureComponent {
	state = {
        asOfDates: [],
	    selectedAsOfDate: ""
	};

	componentDidMount() {
		this.props.loadAsOfDates(this);
	}

	downloadWord = () => {
		this.props.downloadWord( this.state.selectedAsOfDate );
	}

	downloadExcel = () => {
		this.props.downloadExcel( this.state.selectedAsOfDate );
	}

	onChangeAsOfDate = (event) => {
		this.setState({ selectedAsOfDate: event.target.value });
	}

	render() {
		const { asOfDates, selectedAsOfDate } = this.state;
		return (
			<PageBase title="Determination Date Issue (STB)">
				<Grid container spacing={4} style={{ marginTop: 10 }}>
					<Grid item xs={12} md={4}>
						<FormControl style={{ width: "100%" }}>
							<InputLabel>AsOfDate</InputLabel>
							<Select inputProps={{ name: "asOfDate" }} value={ selectedAsOfDate } onChange={ this.onChangeAsOfDate }>
								{renderMenuItems( asOfDates, false )}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={3} md={2} container alignItems="center" justifyContent="center">
						<Button variant="contained" fullWidth style={{ backgroundColor: "blue", color: "white" }} startIcon={ <DescriptionIcon /> } onClick={ this.downloadWord }>Word</Button>
					</Grid>
					<Grid item xs={3} md={2} container alignItems="center" justifyContent="center">
						<Button variant="contained" fullWidth color="primary" style={{ color: "white" }} startIcon={ <DescriptionIcon /> } onClick={ this.downloadExcel }>Excel</Button>
					</Grid>
				</Grid>
			</PageBase>
		)
	}
}

export default connect( null, mapDispatchToProps )( DeterminationDateIssue );
