import React from "react"
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TableInfinityLoader, { TableInfinityRow, TableInfinityCell } from "../../../commons/components/TableInfinityLoader"

const numberWidth = 100;

class DealerBDRatioTable extends React.Component {
	percenatageTableCell(value="0.0"){
		return value.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2, maximumFractionDigits:2}); 
	}

	roundTableCell(value=0){
		return value.toFixed(2);
	}

	showAllColumns = () => {
		const { filters } = this.props;
		return filters.fromLoanFundingDate === null;
	}

	placeZeroIfNull = (value = 0) => {
		if(!value) {
			return "0";
		}
		return value;
	}
	Row( event, row, parent ) {
		return (
			<TableInfinityRow event={event} variant="body">
				<TableInfinityCell style={{width:300,textAlign:"left"}}>{parent.placeZeroIfNull(row["dealerName"])}</TableInfinityCell>
				<TableInfinityCell style={{width:numberWidth,textAlign:"center"}}>{parent.placeZeroIfNull(row["totalNumberApplications"])}</TableInfinityCell>
				<TableInfinityCell style={{width:numberWidth,textAlign:"center"}}>{parent.percenatageTableCell(row["percentageApprovalAllPrograms"])}</TableInfinityCell>
				<TableInfinityCell style={{width:numberWidth,textAlign:"center"}}>{parent.placeZeroIfNull(row["fico"])}</TableInfinityCell>
				<TableInfinityCell style={{width:numberWidth,textAlign:"center"}}>{parent.placeZeroIfNull(row["totalNumber1099Applications"])}</TableInfinityCell>
				<TableInfinityCell style={{width:numberWidth,textAlign:"center"}}>{parent.percenatageTableCell(row["percentage1099Ratio"])}</TableInfinityCell>
				<TableInfinityCell style={{width:numberWidth,textAlign:"center"}}>{parent.placeZeroIfNull(row["totalNumber1099BD"])}</TableInfinityCell>
				<TableInfinityCell style={{width:numberWidth,textAlign:"center"}}>{parent.percenatageTableCell(row["percentageBDRatioInclude612BD"])}</TableInfinityCell>
			</TableInfinityRow>
		);
	}
	
	Header(event){
		return (
			<TableInfinityRow event={event} variant="head">
				<TableInfinityCell style={{width:300,textAlign:"center"}}>Dealer Name</TableInfinityCell>
				<TableInfinityCell style={{width:numberWidth,textAlign:"center"}}>Total # Apps</TableInfinityCell>
				<TableInfinityCell style={{width:numberWidth,textAlign:"center"}}>Approval % (All Programs)</TableInfinityCell>
				<TableInfinityCell style={{width:numberWidth,textAlign:"center"}}>FICO</TableInfinityCell>
				<TableInfinityCell style={{width:numberWidth,textAlign:"center"}}>Total # 10.99 Apps</TableInfinityCell>
				<TableInfinityCell style={{width:numberWidth,textAlign:"center"}}>10.99 Ratio(%)</TableInfinityCell>
				<TableInfinityCell style={{width:numberWidth,textAlign:"center"}}>Total # 1099/BD</TableInfinityCell>
				<TableInfinityCell style={{width:numberWidth,textAlign:"center"}}>BD Ratio (include 6/12 BD)</TableInfinityCell>
			</TableInfinityRow>
		);
	}
    
    render () {
        return(
			<TableInfinityLoader
				listGrid={this.props.listGrid}
				drawRow={this.Row}
				drawHeader={this.Header}
				params={this.props.filters}
				parent={this}
				forSingleScroll={this.props.forSingleScroll}
			/>
        );
    }
}

DealerBDRatioTable.propTypes = {
	filters: PropTypes.object,
    listGrid: PropTypes.array,
    loadDetails: PropTypes.func,
	forSingleScroll: PropTypes.string
};

export default connect(
	null,
)( DealerBDRatioTable )
