import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { ValidatorComponent } from "react-material-ui-form-validator";

class ValidatedDatePicker extends ValidatorComponent {
  renderValidatorComponent() {
    const {
      errorMessages,
      validators,
      requiredError,
      helperText,
      validatorListener,
      ...rest
    } = this.props;
    const { isValid } = this.state;
    return (
      <KeyboardDatePicker
        {...rest}
        InputAdornmentProps={{ position: "start" }}
        autoOk
        error={!isValid}
        helperText={(!isValid && this.getErrorMessage()) || helperText}
        style={{width:"100%"}}
      />
    );
  }
}

export default ValidatedDatePicker;