import { apiGet, apiPost } from "../../../actions";
import fileDownload from "js-file-download";
import Moment from "moment";

export const initialFilters = {
	dealerAssociation: [],
	sfcCultivation: [],
	sfcSalesperson:[],
	dealer:[],
	fromWpdiApprovalDate: null,
	toWpdiApprovalDate: null,
	fromSubmitDate: null,
	toSubmitDate: null,
	webinarCompletDate: "All",
	startIndex: 0,
	stopIndex: 100,
	dealerID: 0
};

const defaultPath = "api/DealerManagement/DealerProductionReport";

export function loadFilters(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: "api/CommonFilter/loadDealerProductionReportFilters",
			onSuccess: response => {
				props.setState({
					dealerAssociationList: response.data.dealerAssociations,
					sfcCultivationList:response.data.sfcCultivations,
					sfcSalespersonList:response.data.sfcSalesperson,
					dealerIdsList:response.data.dealerIds
				});
			}
		} );
	}
}

export function submit( props, filters ) {
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath,
			body: convertFilterToParams(filters),
			onSuccess: response => {
				props.setState({
					listGrid: response.data,
					isSubmitted: true,
				});
			}
		} );
	};
}

export function downloadExcelReport( filters ) {
	return dispatch => {
		apiPost( dispatch, {
			responseType: "blob",
			url: defaultPath + `/excel`,
			body: convertFilterToParams(filters),
			onSuccess: response => {
				fileDownload( response.data, "DealerProductionReport.xlsx" );
			}
		} );
	};
}

export function loadDetails( props, filters ) {
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath + `/loadDetail`,
			body: convertFilterToParams(filters),
			onSuccess: response => {
				props.setState({
					listDetailsGrid: response.data,
					openDetailsModal: true
				});
			}
		} );
	};
}

export function downloadExcelDetails( filters ) {
	return dispatch => {
		apiPost( dispatch, {
			responseType: "blob",
			url: defaultPath + `/excelDetail`,
			body: convertFilterToParams(filters),
			onSuccess: response => {
				fileDownload( response.data, "DealerProductionReport - Details.xlsx" );
			}
		} );
	};
}

function convertFilterToParams(filters) {
	return {
		dealerID: filters.dealerID,
		dealerAssociation: JSON.stringify(filters.dealerAssociation.map(a => a.id)),
		sfcCultivation: JSON.stringify(filters.sfcCultivation.map(a => a.id)),
		sfcSalespersonIds: JSON.stringify(filters.sfcSalesperson.map(a => a.id)),
		dealerIds: JSON.stringify(filters.dealer.map(a => a.id)),
		fromWpdiApprovalDate: filters.fromWpdiApprovalDate !== null ? Moment( new Date( filters.fromWpdiApprovalDate ) ).format( "MM-DD-YYYY" ) : null,
		toWpdiApprovalDate: filters.toWpdiApprovalDate !== null ? Moment( new Date( filters.toWpdiApprovalDate ) ).format( "MM-DD-YYYY" ) : null,
		fromSubmitDate: filters.fromSubmitDate !== null ? Moment( new Date( filters.fromSubmitDate ) ).format( "MM-DD-YYYY" ) : null,
		toSubmitDate: filters.toSubmitDate !== null ? Moment( new Date( filters.toSubmitDate ) ).format( "MM-DD-YYYY" ) : null,
		webinarComplete: filters.webinarCompletDate,
		startIndex: filters.startIndex,
		stopIndex: filters.stopIndex
	};
}