import { apiGet } from "../../../actions";
import fileDownload from "js-file-download";
import Moment from "moment";

let curr = new Date();
curr.setDate(curr.getDate() - 1);

export const initialFilters = {
	sfcSalesperson:[],
	selectedRMSGroup: false,
	submitDate: curr,
	startIndex: 0,
	stopIndex: 100
};

const defaultPath = "api/DealerManagement/DealerTrackingSummaryReport";

export function loadFilters(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: "api/CommonFilter/loadDealerTrackingSummaryFilters",
			onSuccess: response => {
				props.setState({
					sfcSalespersonList:response.data.sfcSalesperson
				});
			}
		} );
	}
}

export function loadRMSGroup(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath + "/rmsGroup",
			onSuccess: response => {
				props.setState({
					rmsGroup: response.data
				});
			}
		} );
	}
}

export function submit( props, filters ) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath,
			params: {
				sfcSalespersonIds: JSON.stringify(filters.sfcSalesperson.filter(ssp => ssp !== "All").map(ssp => ssp.id)),
				selectedRMSGroup: filters.selectedRMSGroup,
				submitDate: Moment( new Date( filters.submitDate ) ).format( "MM-DD-YYYY" ),
				startIndex: filters.startIndex,
				stopIndex: filters.stopIndex
			},
			onSuccess: response => {
				props.setState({
					listGrid: response.data,
					isSubmitted: true
				});
			}
		} );
	};
}

export function downloadExcelReport( filters ) {
	return dispatch => {
		apiGet( dispatch, {
			responseType: "blob",
			url: defaultPath + `/downloadExcel`,
			params: {
				sfcSalespersonIds: JSON.stringify(filters.sfcSalesperson.filter(ssp => ssp !== "All").map(ssp => ssp.id)),
				selectedRMSGroup: filters.selectedRMSGroup,
				submitDate: Moment( new Date( filters.submitDate ) ).format( "MM-DD-YYYY" )
			},
			onSuccess: response => {
				var date = new Date(filters.submitDate);
				fileDownload( response.data, `DealerTrackingSummaryReport-${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}.xlsx` );
			}
		} );
	};
}