import React from "react";
import PropTypes from "prop-types";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { createTheme, withStyles } from '@material-ui/core/styles';
import Moment from "moment";
import ValidatedDatePicker from "./ValidatedDatePicker";

const materialTheme = theme => createTheme({
	...theme,
	overrides: {
		MuiIconButton: {
			root:{
				padding: 0
			}
		},
		MuiInput: {
			input: {
				width: 100
			}
		},
		MuiFormControl: {
			root: {
				marginRight: 10
			}
		}
	}
});

const formatDate = "MM-DD-YYYY";

class DatePickerRange extends React.Component {
	state = {
		isDateFromValid: true,
		isDateToValid: true,
		moment: null,
		date: null,
	};
	handleDate( field, e1, e2 ) {
		this.setState({
			moment: e1,
			date: e2
		})
		const { onDatesChange } = this.props;
		const date =  Moment(e2, formatDate, true);
		if(( e1 !== null && e1.isValid()) || date.isValid() ){
			const result = e1.isValid() ? e1._d : date;
			onDatesChange(field, result);
		}
		else if(e1 === null && e2 === null){
			onDatesChange(field, null);
		}
	}

	keyPress = (e) => {
		if(e.keyCode === 13 && this.props.onSubmit){
		   this.props.onSubmit();
		}
	 }

	getDatePicker = (propsFrom, propsTo)  => {
		return (
			<div>
				<KeyboardDatePicker
					{...propsFrom}
					label="From"
					InputAdornmentProps={{ position: "start" }}
					variant="inline"
					error={!this.state.isDateFromValid}
					helperText={!this.state.isDateFromValid ? "invalid date" : null}
					autoOk
					onKeyDown={this.keyPress}
				/>
				<KeyboardDatePicker
					{...propsTo}
					label="To"
					InputAdornmentProps={{ position: "start" }}
					variant="inline"
					error={!this.state.isDateToValid}
					helperText={!this.state.isDateToValid ? "invalid date" : null}
					autoOk
					onKeyDown={this.keyPress}
				/>
			</div>
		);
	}

	getValidator = (propsFrom, propsTo)  => {
		return (
			<div style={{display: "inline-flex"}}>
				<ValidatedDatePicker
					{...propsFrom}
					label="From"
					variant="inline"
					validators={["required"]}
					errorMessages={["date is required"]}
					onKeyDown={this.keyPress}
				/>
				<ValidatedDatePicker
					{...propsTo}
					label="To"
					variant="inline"
					validators={["required"]}
					errorMessages={["date is required"]}
					onKeyDown={this.keyPress}
				/>
			</div>
		);
	}

	validateDate = (field) => {
		if(this.state.moment !== null) {
			const date =  Moment(this.state.date, formatDate, true);
			if(( this.state.moment !== null && this.state.moment.isValid()) || date.isValid() ){
				this.setIsValidDate(field, true);
			}
			else if(!this.state.moment.isValid() && this.state.date !== null){
				this.setIsValidDate(field, false);
			}
		}
		else {
			this.setIsValidDate(field, true);
		}
	}

	setIsValidDate = (field, state) => {
		this.setState({
			[field]: state
		})
	}

	render() {
		const { label, fromDate, fromDateId, toDate, toDateId, validate } = this.props;

		const propsFrom = {
			id: fromDateId,
			format: formatDate,
			onChange: (event, date) => this.handleDate( fromDateId, event, date),
			onBlur: () => this.validateDate('isDateFromValid'),
			value: fromDate
		};

		const propsTo = {
			id: toDateId,
			format: formatDate,
			onChange: (event, date) => this.handleDate( toDateId, event, date),
			onBlur: () => this.validateDate('isDateToValid'),
			value: toDate
		};
		return (
			<ThemeProvider theme={materialTheme}>
				<fieldset style={{borderWidth:1, borderRadius:5, borderColor:"rgba(0, 0, 0, 0.23)"}}>
					<legend><Typography style={{color: 'rgba(0,0,0,0.47)'}}>{label}</Typography></legend>
					{validate ? this.getValidator(propsFrom, propsTo) : this.getDatePicker(propsFrom, propsTo)}
				</fieldset>
			</ThemeProvider>
		);
	}
}

DatePickerRange.propTypes = {
	fromDate: PropTypes.instanceOf(Date),
	fromDateId: PropTypes.string,
	label: PropTypes.string,
	onDatesChange: PropTypes.func,
	onSubmit: PropTypes.func,
	validate: PropTypes.bool,
	toDate: PropTypes.instanceOf(Date),
	toDateId: PropTypes.string,
	theme: PropTypes.object
};

DatePickerRange.defaultProps = {
	validate: false
};

export default withStyles( {withTheme : true})(DatePickerRange);
