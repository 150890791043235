import React from "react";
import PropTypes from "prop-types";
import NetworkButton from "../../../../commons/components/NetworkButton";

import { renderMenuItems } from "../../../../commons/utils";
import ValidatedDatePicker  from "../../../../commons/components/ValidatedDatePicker";
import Grid from "@material-ui/core/Grid";
import SelectValidator from "react-material-ui-form-validator/lib/SelectValidator";
import ValidatorForm from "react-form-validator-core/lib/ValidatorForm";
import CurrencyAmountField from "../../../../commons/components/CurrencyAmountField";

class AvailableFundsForm extends React.Component {
	handleSelectForm = ( event ) => {
		this.props.changeField( event.target.name, event.target.value );
	};

	handleChangeDate = ( value ) => {
		this.props.changeField( "date", value );
	};

	handleSubmit = () => {
		this.props.saveItem( this.props.item );
	};

	render() {
		const style = {
			margin: 2,
			padding: 15,
			with: "50%"
		};
		return (
			<ValidatorForm onSubmit={ this.handleSubmit } noValidate>
				<Grid container style={ style }>
					<Grid item xs style={{marginRight: 15}}>
						<SelectValidator
							name="lenderName"
							label="Select a lender"
							value={ this.props.item.lenderName }
							onChange={ this.handleSelectForm }
							validators={ ["required"] }
							errorMessages={ ["It is mandatory."] }
							fullWidth
						>
							{renderMenuItems( this.props.lenderOptions, false )}
						</SelectValidator>
					</Grid>
					<Grid item xs style={{marginRight: 15}}>
						<ValidatedDatePicker
							label="Date"
							onChange={this.handleChangeDate.bind(this)}
							name="date"
							value={this.props.item.date }
							validators={["required"]}
							errorMessages={["date is required"]}
							format="DD/MM/YYYY"
						/>
					</Grid>
					<Grid item xs style={{marginRight: 15}}>
						<CurrencyAmountField
							onChange={ this.handleSelectForm }
							label="Available Fund"
							name="availableFund"
							value={ this.props.item.availableFund }
							validators={ ["required"] }
							errorMessages={ ["It's mandatory."] }
						/>
					</Grid>
					<Grid item xs={1} style={{marginRight: 15}}>
						<NetworkButton text={ "Submit" } primary={ true } type="submit" />
					</Grid>
				</Grid>
			</ValidatorForm>
		);
	}
}

AvailableFundsForm.propTypes = {
	changeField: PropTypes.func,
	item: PropTypes.object,
	lenderOptions: PropTypes.array,
	saveItem: PropTypes.func
};

export default AvailableFundsForm;
