import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageBase from "../../../commons/components/PageBase";
import { Grid, TextField } from '@material-ui/core';
import NetworkButton from "../../../commons/components/NetworkButton";
import DatePickerRange from "../../../commons/components/DatePickerRange";
import {
	submit,
	downloadReport,
	initialFilters
 } from "./actions";

const mapDispatchToProps = {
	submit,
	downloadReport
};

class AdverseActionLetter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			commercialDownload: false,
			noCommercialDownload: false,
			filters: initialFilters,
			currentFilters: initialFilters
		};
    }

    handleDate(field, date){
		this.setState({
			filters: {
				...this.state.filters,
				[field]:date
			}
		});
    };
    
    handleText(event){
        const onlyNums = event.target.value.replace(/[^0-9]|/g, '');
        this.setState({
			filters: {
				...this.state.filters,
				applicationNumber: onlyNums
			}
		});
    };

	handleSubmit = () => {
		this.props.submit(this, this.state.filters);
		this.setState({
			currentFilters: this.state.filters
		});
    };

    handleDownalodReport = (isCommercial) => {
		this.props.downloadReport(this.state.currentFilters, isCommercial);
	};
    
    createFilters = () => {
		const { filters, commercialDownload, noCommercialDownload } = this.state;
		return (
			<Grid container style={{marginTop:10}}>
				<Grid container item xs={10} spacing={3}>
					<Grid container item xs={4}>
                        <TextField ref="applicationNumber" name="applicationNumber" label="Application Number" value={ filters.applicationNumber } onChange={ this.handleText.bind(this) } variant="outlined" fullWidth/>
					</Grid>
				</Grid>
				<Grid container item xs={10} spacing={3}>
					<Grid container item xs={4}>
						<Grid item xs>
							<DatePickerRange
								label={"Submit Date"}
								fromDate={filters.fromSubmitDate} 
								fromDateId="fromSubmitDate" 
								toDate={filters.toSubmitDate} 
								toDateId="toSubmitDate" 
								onDatesChange={this.handleDate.bind(this)}
								onSubmit={this.handleSubmit.bind(this)}
							/>
						</Grid>
					</Grid>
                </Grid>
				<Grid container item xs={4} spacing={3}>
					<Grid container item xs alignItems="flex-end">
						<NetworkButton text={ "Submit" } primary={ true } style={{margin: 10, height: 36}} onClick={ this.handleSubmit } />
						<NetworkButton text={ "Download" } primary={ true } style={{margin: 10, height: 36}} onClick={ this.handleDownalodReport.bind(this, false) } disabled={!noCommercialDownload} />
						<NetworkButton text={ "Download Commercial" } primary={ true } style={{margin: 10, height: 36}} onClick={ this.handleDownalodReport.bind(this, true) } disabled={!commercialDownload} />
					</Grid>
				</Grid>
			</Grid>
		);
    }

    render() {
		return (
			<PageBase title="Adverse Action Letter" >
				{this.createFilters()}
			</PageBase>
		);
	}
}

AdverseActionLetter.propTypes = {
	downloadReport: PropTypes.func,
	filters: PropTypes.object,
	submit: PropTypes.func
};

export default connect(
	null,
	mapDispatchToProps,
)( AdverseActionLetter );