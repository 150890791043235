import React, { Fragment } from "react";
import PropTypes from "prop-types";
import TableInfinityLoader, { TableInfinityRow, TableInfinityCell } from "../../../commons/components/TableInfinityLoader"
import Moment from "moment";

const widthOthers = 75;

class DealerTrackingLennoxTable extends React.Component {

	currencyTableCell(value="0.0"){
		return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
	}

	Row( event, row, parent ) {
		return (
			<TableInfinityRow event={event} variant="body">
				<TableInfinityCell style={{width:90}}>{row["companyRegion"]}</TableInfinityCell>
				<TableInfinityCell style={{width:60}}>{row["distinctNumber"]}</TableInfinityCell>
				<TableInfinityCell style={{width:60}}>{row["territoryNumber"]}</TableInfinityCell>
				<TableInfinityCell style={{width:150}}>{row["repName"]}</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>{row["lennoxDealerNumber"]}</TableInfinityCell>
				<TableInfinityCell style={{width:350}}>{row["dealerName"]}</TableInfinityCell>
				<TableInfinityCell style={{width:100, textAlign:"right"}}>{row["dealerNumber"]}</TableInfinityCell>
				<TableInfinityCell style={{width:widthOthers, textAlign:"right"}}>{row["totalApps"]}</TableInfinityCell>
				<TableInfinityCell style={{width:widthOthers, textAlign:"right"}}>{parent.currencyTableCell(row["totalOriginal"])}</TableInfinityCell>
				<TableInfinityCell style={{width:widthOthers, textAlign:"right"}}>{parent.currencyTableCell(row["totalFunded"])}</TableInfinityCell>
				{
					Object.entries(row["monthly"]).map(([key, value]) =>
						<Fragment key={Moment( new Date( key ) ).format( "MM/DD/YYYY" )}>
							<TableInfinityCell style={{width:widthOthers, textAlign:"right"}}>{ value["countApp"] }</TableInfinityCell>
							<TableInfinityCell style={{width:widthOthers, textAlign:"right"}}>{ parent.currencyTableCell(value["originalBalance"]) }</TableInfinityCell>
							<TableInfinityCell style={{width:widthOthers, textAlign:"right"}}>{ parent.currencyTableCell(value["fundingAmount"]) }</TableInfinityCell>
						</Fragment>
					)
				}
			</TableInfinityRow>
		);
	}

	Header(event){
		let monthly = event.data["monthly"];
		return (
			<TableInfinityRow event={event} variant="head">
				<TableInfinityCell style={{width:90}} >Company Region</TableInfinityCell>
				<TableInfinityCell style={{width:60}}>Dist#</TableInfinityCell>
				<TableInfinityCell style={{width:60}}>Terr#</TableInfinityCell>
				<TableInfinityCell style={{width:150}}>RepName</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>Lennox Dealer Number</TableInfinityCell>
				<TableInfinityCell style={{width:350}}>Dealer Name</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>Dealer Number</TableInfinityCell>
				<TableInfinityCell style={{width:widthOthers}}>YTD Total Apps</TableInfinityCell>
				<TableInfinityCell style={{width:widthOthers}}>YTD Orig. Volume</TableInfinityCell>
				<TableInfinityCell style={{width:widthOthers}}>YTD Funded Volume</TableInfinityCell>
				{
					Object.entries(monthly).map(([key, value]) =>
						<Fragment key={Moment( new Date( key ) ).format( "MM/DD/YYYY" )}>
							<TableInfinityCell style={{width:widthOthers}}>{Moment( new Date( key ) ).format( "MM/DD/YYYY" ) + " #Apps"}</TableInfinityCell>
							<TableInfinityCell style={{width:widthOthers}}>{Moment( new Date( key ) ).format( "MM/DD/YYYY" ) + " Orig. Volume"}</TableInfinityCell>
							<TableInfinityCell style={{width:widthOthers}}>{Moment( new Date( key ) ).format( "MM/DD/YYYY" ) + " Funding Volume"}</TableInfinityCell>
						</Fragment>
					)
				}
			</TableInfinityRow>
		);
	}

	render(){
		return(
			<TableInfinityLoader
				listGrid={this.props.listGrid}
				drawRow={this.Row}
				drawHeader={this.Header}
				handleSubmit={this.props.handleSubmit}
				params={this.props.filters}
				parent={this}
				forSingleScroll={this.props.forSingleScroll}
			/>
		);
	}
}

DealerTrackingLennoxTable.propTypes = {
	filters: PropTypes.object,
	handleSubmit: PropTypes.func,
	listGrid: PropTypes.array,
	forSingleScroll: PropTypes.string
};

export default DealerTrackingLennoxTable;