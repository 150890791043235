import { apiGet, apiPost } from "../../../actions";
import fileDownload from "js-file-download";
import Moment from "moment";

export const initialFilters = {
	loanType: [],
	lender: [],
	dealerName: [],
	asOfDate: '',
	fromLoanFundingDate: null,
	toLoanFundingDate: null,
	ignoreFico: "no",
	type: "Lender",
	rangeDelinquecy: "",
	detailTypeName: "",
	startIndex: 0,
	stopIndex: 100,
	detailsStartIndex: 0,
	detailsStopIndex: 100,
};

const defaultPath = "api/AssetManager/InventoryQuery";

export function loadFilters(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: "api/CommonFilter/loadInventoryQueryFilters",
			onSuccess: response => {
				props.setState({
					loanTypesList: response.data.loanType,
					asOfDateList: response.data.asOfDate,
					dealerNameList: response.data.dealer,
					lendersList: response.data.lender,
					filters:{
						...props.state.filters,
						asOfDate: response.data.asOfDate[0]
					}
				});
			}
		} );
	};
}

export function loadTypes(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath + "/loadTypes",
			onSuccess: response => {
				props.setState({typesList: response.data });
			}
		} );
	};
}

export function submit( props, filters ) {
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath,
			body: convertFilters(filters),
			onSuccess: response => {
				props.setState({
					listGrid: response.data,
					isSubmitted: true,
				});
			}
		} );
	};
}

export function submitDetails( props, filters ) {
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath + "/details",
			body: convertFilters(filters),
			onSuccess: response => {
				props.setState({
					detailsResult: response.data,
					openDetailsModal: true
				});
			}
		} );
	};
}

export function downloadDetailExcelReport( filters ) {
	return dispatch => {
		apiPost( dispatch, {
			responseType: "blob",
			url: defaultPath + '/downloadDetailExcel',
			body: convertFilters(filters),
			onSuccess: response => {
				fileDownload( response.data, "InventoryQueryDetail.xlsx" );
			}
		} );
	};
}


export function downloadExcelReport( filters ) {
	const date = Moment( new Date() ).format( "MM-DD-YYYY" ) ;
	return dispatch => {
		apiPost( dispatch, {
			responseType: "blob",
			url: defaultPath + '/downloadExcel',
			body: convertFilters(filters),
			onSuccess: response => {
				fileDownload( response.data, "InventoryQuery-" + date + ".xlsx" );
			}
		} );
	};
}

export function loadApplicationDetails(props, applicationNumber){
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath + "/loadApplicationDetails/" + applicationNumber,
			onSuccess: response => {
				props.setState({
					applicationDetails:response.data,
					openModal: true
				});
			}
		});
	};
}

export function saveApplicationNote(props, appNote, applicationNumber){
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath + "/saveApplicationNote",
			body:appNote,
			onSuccess: () => {
				dispatch( loadApplicationDetails( props, applicationNumber ) );
			}
		});
	};
}

function convertFilters(filters){
	return {
		Lender: filters.lender.map(a => a.key),
		Type: filters.type,
		RangeDelinquecy: filters.rangeDelinquecy,
		LoanTypes: filters.loanType.map(a => a.id),
		AsOfDate: filters.asOfDate,
		DealerIds: filters.dealerName.map(a => a.id),
		DetailTypeName: filters.detailTypeName,
		FromDate: filters.fromLoanFundingDate !== null ? Moment( new Date( filters.fromLoanFundingDate ) ).format( "MM-DD-YYYY" ) : null,
		ToDate: filters.toLoanFundingDate !== null ? Moment( new Date( filters.toLoanFundingDate ) ).format( "MM-DD-YYYY" ) : null,
		IgnoreFHA: filters.ignoreFico  === "no" ? false : true,
		StartIndex: filters.startIndex,
		StopIndex: filters.stopIndex,
		DetailsStartIndex: filters.detailsStartIndex,
		DetailsStopIndex: filters.detailsStopIndex,
	};
}