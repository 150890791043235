import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageBase from "../../../../../commons/components/PageBase";
import SearchNotData from "../../../../../commons/components/SearchNotData";
import Paper from "@material-ui/core/Paper";
import ServicingAdviceReportFilter from "./ServicingAdviceReportFilter";
import ServicingAdviceReportResult from "./ServicingAdviceReportResult";
import {
	loadLenders,
	loadList,
	model
} from "./actions";

const styles = {
	marginLeft: {
		marginLeft: 15
	},
	stylePaperFilter: {
		margin: 2,
		padding: 15
	}
};

const mapDispatchToProps = {
	loadLenders,
	loadList
};

class ServicingAdviceReport extends Component {
	state={
		form: model,
		isSubmitted: false,
		dataList: [],
		lenders: []
	};

	constructor(props) {
		super(props);
		this.props.loadLenders(this);
	}

	componentWillUnmount = () => this.clearFields();

	clearFields = () => {
		this.setState({
			form: model,
			dataList: [],
			lenders: []
		});
	};

	handleUpdateField = ( event ) => {
		this.setState({
			form:{
				...this.state.form,
				[event.target.name]: event.target.value
			}
		});
	};

	handleUpdateDate = ( field, date ) => {
		this.setState({
			form:{
				...this.state.form,
				[field]: date
			}
		});
	};

	handleSubmitForm = () => {
		this.props.loadList( this, this.state.form );
	};

	render() {
		const {lenders, dataList, form, isSubmitted} = this.state;
		return (
			<PageBase title="Report Servicing Advice" showActions={ false }>
				<div>
					<Paper style={ styles.stylePaperFilter } elevation={1}>
						<ServicingAdviceReportFilter
							onSelectLender = { this.handleUpdateField }
							onChangeDate = { this.handleUpdateDate }
							onSubmitForm = { this.handleSubmitForm }
							lenders = { lenders } 
							item = { form } 
							style = { { marginLeft: 0, maxWidth: 750 } }
						/>
					</Paper>
					<br />
					<SearchNotData
						isSubmitted={isSubmitted}
						listGrid={dataList}
					>
						<ServicingAdviceReportResult list = { dataList } />
					</SearchNotData>
				</div>
			</PageBase>
		);
	}
}

ServicingAdviceReport.propTypes = {
	loadLenders: PropTypes.func,
	loadList: PropTypes.func
};

export default connect(
	null,
	mapDispatchToProps,
)( ServicingAdviceReport );
