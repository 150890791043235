import React from "react";
import PropTypes from "prop-types";
import { Table, TableHead, TableBody, TableCell, TableRow } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import NetworkButton from '../../../commons/components/NetworkButton';

const styles = {
	data:{
		width: "450px"
	},
	column: {
		width: "200px"
	}
};

const mapDealerData = [
	{ label: "Delare ID", data: "number" },
	{ label: "Name", data: "name" },
	{ label: "Address", data: "address" },
	{ label: "City", data: "city" },
	{ label: "State", data: "state" },
	{ label: "Zip", data: "zip" },
	{ label: "Phone Number", data: "phone" },
	{ label: "Fax Number", data: "fax" },
	{ label: "Email Address", data: "email" }
];

const mapWireData = [
	{ label: "Bank Name", data: "bankName" },
	{ label: "ABA Number", data: "wireABANumber" },
	{ label: "Account Number", data: "accountNumber" }
];

const mapOtherData = [
	{ label: "Dealer Association", data: "dealerAssociation" },
	{ label: "Dealer Contact", data: "dealerContact" }
];

class DealerDetail extends React.Component {
	state = {
		openModal: false
	};

	static getDerivedStateFromProps(props, state) {
		if(!!props.detailsResult){
			return {openModal:true};
		}
		return {openModal:false}
	}

	handleClose() {
		this.setState({ openModal: false });
	};

	getTableBody(mapDatas) {
		const { detailsResult } = this.props;

		return (
			<TableBody>
				{mapDatas.map( (map, index) =>
					<TableRow key={index}>
						<TableCell style={styles.column}> { map.label } </TableCell>
						<TableCell align="right" style={styles.data}> { detailsResult[map.data] } </TableCell>
					</TableRow>
				)}
			</TableBody>
		);
	}

	getTable(title, mapData){
		return (
			<Table>
				<TableHead>
					<TableRow key="rowHeader">
						<TableCell style={{fontSize: 14}}>{title}</TableCell>
					</TableRow>
				</TableHead>
				{ this.getTableBody(mapData)}
			</Table>
		);
	}

	render() {
		const { detailsResult, onClose } = this.props;
		if(detailsResult === null || detailsResult.length === 0)
			return <div/>;

		return (
			<div style={{maxWidth:1350}}>
				<NetworkButton text={ "Close" } onClick={onClose} style={ { float: "right" } }/>
				<Typography variant="h6" align="center">Dealer Details</Typography>
				<div style={{display:"inline-flex"}}>
					<div style={{width:"50%"}}>
						{this.getTable("Dealer Information", mapDealerData)}
					</div>
					<div style={{width:20}} />
					<div>
						{this.getTable("Wire Information", mapWireData)}
						<div style={{width:20}} />
						{this.getTable("Other Information", mapOtherData)}
					</div>
				</div>
			</div>
		);
	}
}

DealerDetail.propTypes = {
	detailsResult: PropTypes.object,
	onClick: PropTypes.func,
	onClose: PropTypes.func,
	rangeDelinquecy: PropTypes.string
};

export default DealerDetail;