import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Moment from "moment";
import TableInfinityLoader, { TableInfinityRow, TableInfinityCell } from "../../../commons/components/TableInfinityLoader"

const widthDealerName = 350;
const widthDealerNumber = 130;
const widthState = 35;
const widthDefault = 75;

class DealerTrackingTable extends React.Component {

	Row( event, row, parent ) {
		return (
			<TableInfinityRow event={event} variant="body">
				<TableInfinityCell style={{width:widthDealerName}}>{row["name"]}</TableInfinityCell>
				<TableInfinityCell style={{width:widthDealerNumber}}>{row["number"]}</TableInfinityCell>
				<TableInfinityCell style={{width:widthState}}>{row["state"]}</TableInfinityCell>
				{
					Object.entries(row["monthly"]).map(([key, value]) =>
					<Fragment key={key}>
						<TableInfinityCell style={{width:widthDefault}}>{ value["countApp"] }</TableInfinityCell>
						<TableInfinityCell style={{width:widthDefault}}>{ (value["approvalPercentage"]* 100).toFixed(2) + "%" }</TableInfinityCell>
						<TableInfinityCell style={{width:widthDefault}}>{ value["averageFico"] }</TableInfinityCell>
					</Fragment>
					)
				}
			</TableInfinityRow>
		);
	}

	Header(event){
		let monthly = event.data["monthly"];
		return (
			<TableInfinityRow event={event} variant="head">
				<TableInfinityCell style={{width:widthDealerName}}>Dealer Name</TableInfinityCell>
				<TableInfinityCell style={{width:widthDealerNumber}}>Dealer Number</TableInfinityCell>
				<TableInfinityCell style={{width:widthState}}>State</TableInfinityCell>
				{
					Object.entries(monthly).map(([key, value]) =>
						<Fragment key={Moment( new Date( key ) ).format( "MM/DD/YYYY" )}>
							<TableInfinityCell style={{width:widthDefault}}>{Moment( new Date( key ) ).format( "MM/DD/YYYY " ) + "#Apps"}</TableInfinityCell>
							<TableInfinityCell style={{width:widthDefault}}>Approval %</TableInfinityCell>
							<TableInfinityCell style={{width:widthDefault}}>Average FICO</TableInfinityCell>
						</Fragment>
					)
				}
			</TableInfinityRow>
		);
	}

	render(){
		return(
			<TableInfinityLoader
				listGrid={this.props.listGrid}
				drawRow={this.Row}
				drawHeader={this.Header}
				handleSubmit={this.props.handleSubmit}
				params={this.props.filters}
				parent={this}
				forSingleScroll={this.props.forSingleScroll}
			/>
		);
	}
}

DealerTrackingTable.propTypes = {
	filters: PropTypes.object,
	handleSubmit: PropTypes.func,
	listGrid: PropTypes.array,
	forSingleScroll: PropTypes.string
};

export default DealerTrackingTable;