import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageBase from "../../../commons/components/PageBase";
import SearchFundingManagementFilters from "./SearchFundingManagementFilters";

import {
	downloadExcelReport,
	initialFilters,
 } from "./actions";

import SingleScroll from "../../../commons/components/SingleScroll";

const mapDispatchToProps = {
	downloadExcelReport,
};

class SearchFundingManagement extends React.Component {
	state = {
		filters: initialFilters,
	};

	handleChangeFilter = ( field, value ) => {
		this.setState({
			filters:{
				...this.state.filters,
				[field]: value
			}
		});
	};

	handleReset = () => {
		this.setState({
			filters: initialFilters,
		});
	};

	handleExcel = () => {
		this.props.downloadExcelReport(this.getFilters());
	};

	getFilters = () => this.state.filters;
	
	createFilters() {
        const { filters } = this.state;
		return (
			<SearchFundingManagementFilters
				filters={filters}
				changeFilter={this.handleChangeFilter}
				handleReset={this.handleReset}
				handleExcel={this.handleExcel}
			/>
		);
	}

	render() {
		const singleScrollName = "SearchFundingManagement";
		return (
			<PageBase title="Search">
				<SingleScroll name={singleScrollName}>
					<SingleScroll.Scrollable for={singleScrollName}>
						{this.createFilters()}
					</SingleScroll.Scrollable>
				</SingleScroll>
			</PageBase>
		);
	}
}

SearchFundingManagement.propTypes = {
	downloadExcelReport: PropTypes.func,
	loadFilters: PropTypes.func,
};

export default connect(
	null,
	mapDispatchToProps,
)( SearchFundingManagement );
