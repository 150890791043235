import React from "react";
import PropTypes from "prop-types";
import { renderMenuItemsBy } from "../../../commons/utils";
import { FormControlLabel, Switch, Grid, Select } from "@material-ui/core";
import NetworkButton from "../../../commons/components/NetworkButton";

import { ValidatorForm, TextValidator} from "react-material-ui-form-validator";
import { FormControl, InputLabel } from "@material-ui/core";

const styles = {
	buttons: {
		marginTop: 30,
		float: "right",
		marginLeft: 5
	},
	saveButton: {
		marginLeft: 5
	}
};

const descriptionFieldFocus = input => { input && input.focus(); }

class AccessForm extends React.Component {
	componentDidMount() {
		descriptionFieldFocus();
	}

	handleChangeInput = event => {
		this.props.changeInput( event.target.name, event.target.value );
	};

	handleSubmit = () => {
		this.props.saveItem( this.props.item );
	};

	handleCancel = () => {
		this.props.cleanForm();
	};

	handleCheckbox = ( event, checked ) => {
		this.props.changeInput( "showInMenu", checked );
	};

	render() {
		const { list } = this.props;

		return (
			<Grid container style={{marginTop:10}}>
				<ValidatorForm onSubmit={ this.handleSubmit } noValidate style={{width:"100%"}}>
					<Grid container item xs={12} spacing={3}>
						<Grid item xs={9}>
							<TextValidator
								required
								ref={ input => {
									this.description = input;
								} }
								name="description"
								label="Description"
								validators={ ["required", "minStringLength:3"] }
								errorMessages={ ["The description is mandatory", "The description must be at least 3 characters long."] }
								value={ this.props.item.description }
								onChange={ this.handleChangeInput }
								fullWidth
							/>
						</Grid>
						<Grid item xs={3}>
							<TextValidator
								required
								ref={ input => {
									this.indexOrder = input;
								} }
								name="indexOrder"
								label="Index Order"
								validators={ ["required", "isNumber"] }
								errorMessages={ ["The index order is mandatory.", "The content must be a number"] }
								fullWidth={ true }
								value={ this.props.item.indexOrder }
								onChange={ this.handleChangeInput }
							/>
						</Grid>
					</Grid>
					<Grid container item xs={12} spacing={3}>
						<Grid item xs={12}>
							<TextValidator
								required
								ref={ input => {
									this.url = input;
								} }
								name="url"
								label="URL"
								validators={ ["required"] }
								errorMessages={ "The URL is mandatory." }
								fullWidth
								value={ this.props.item.url }
								onChange={ this.handleChangeInput }
							/>
						</Grid>
					</Grid>
					<Grid container item xs={12} spacing={3}>
						<Grid item xs={6}>
							<FormControl fullWidth	>
								<InputLabel>Access Master</InputLabel>
								<Select name="accessMasterId" value={ this.props.item.accessMasterId } onChange={ this.handleChangeInput } >
									{ renderMenuItemsBy(list, "description", "id", false) }
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={3}>
							<FormControlLabel
								control={
									<Switch
									checked={ this.props.item.showInMenu }
									onChange={ this.handleCheckbox }
									value="showInMenu"
									color={ "primary" }
									/>
								}
								label="Visible in the menu"
							/>
						</Grid>
						<Grid item xs={3}>
							<NetworkButton text={ "Cancel" } onClick={ this.handleCancel } />
							<NetworkButton
								text={ "Save" }
								primary={ true }
								type="submit"
								style={ styles.saveButton }
							/>
						</Grid>
					</Grid>
				</ValidatorForm>
			</Grid>
		);
	}
}

AccessForm.propTypes = {
	changeInput: PropTypes.func,
	cleanForm: PropTypes.func,
	item: PropTypes.object,
	list: PropTypes.array,
	saveItem: PropTypes.func
};

export default AccessForm;
