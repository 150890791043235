import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageBase, { TypeOfActionButton } from "../../../commons/components/PageBase";
import AccessFrom from "../access/AccessForm";
import AccessTable from "../access/AccessTable";
import {
	loadList,
	deleteItem,
	saveItem,
	model
} from "./actions";

const mapDispatchToProps = {
	loadList,
	deleteItem,
	saveItem
};
class Access extends React.Component {
	state = {
		item: model,
		dataList: [],
		typeOfActionButton: "ADD"
	};

	constructor(props) {
		super(props);
		this.props.loadList(this);
	}

	handleChangeActionButton = () => {
		if (TypeOfActionButton.ADD === this.state.typeOfActionButton) {
			this.setState({typeOfActionButton: TypeOfActionButton.CANCEL});
		} else {
			this.cleanFields();
		}
	};

	handleLoadItem = item => {
		this.setState({
			item: item,
			typeOfActionButton: TypeOfActionButton.CANCEL
		});
	};

	handleSaveItem = (item) => {
		this.props.saveItem(this, item);
	};

	handleDeleteItem = (id) => {
		this.props.deleteItem(this, id);
	};

	handleUpdateField = (field, value) => {
		this.setState({
			item:{
				...this.state.item,
				[field]: value
			}
		});
	};

	cleanFields = () => {
		this.setState({
			typeOfActionButton: TypeOfActionButton.ADD,
			item: model
		});
	}

	showPanel = () => {
		const {dataList, item }  = this.state;
		if ( TypeOfActionButton.ADD === this.state.typeOfActionButton )
			return (
				<AccessTable
					list={ dataList }
					loadItem={ this.handleLoadItem }
					deleteItem={ this.handleDeleteItem.bind(this) }
				/>
			);
		else {
			return (
				<AccessFrom
					list={ dataList }
					item={ item }
					changeInput={ this.handleUpdateField }
					saveItem={ this.handleSaveItem.bind(this) }
					cleanForm={ this.cleanFields }
				/>
			);
		}
	};

	render() {
		return (
			<PageBase
				title="Access"
				showActions={ true }
				typeOfActionButton={ this.state.typeOfActionButton }
				changeActionButton={ this.handleChangeActionButton }
			>
				{this.showPanel()}
			</PageBase>
		);
	}
}

Access.propTypes = {
	deleteItem: PropTypes.func,
	loadList: PropTypes.func,
	saveItem: PropTypes.func
};

export default connect(
	null,
	mapDispatchToProps,
)( Access )
