import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Router } from "react-router-dom"
import { Provider } from "react-redux"
import { store, history } from "./store";
import "./index.css";
import { Layout } from "./layout";

if ( typeof JSON.clone !== "function" ) {
	JSON.clone = o => JSON.parse( JSON.stringify( o ) );
}

export const Routes = () => (
	<Router history={ history } >
		<Layout/>
	</Router>
);

const app = ReactDOM.createRoot(document.getElementById('app'));
app.render(
	<Provider store={ store }>
		<BrowserRouter>
			<Routes/>
		</BrowserRouter>
	</Provider>
);

