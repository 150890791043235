import React from "react";
import PropTypes from "prop-types";
import TableInfinityLoader, { TableInfinityRow, TableInfinityCell } from "../../../commons/components/TableInfinityLoader"
import DialogYesNo from "../../../commons/components/DialogYesNo";
import IconDelete from "@material-ui/icons/Delete";
import IconUpdate from "@material-ui/icons/Create";
import IconButton from "@material-ui/core/IconButton";
import tableStyle from "../../../commons/components/TableStyle";

class RoleTable extends React.Component {
	state = {
		statusShowDeleteDialog: false,
		idToDelete: 0
	};
	handleEditItem = item => {
		this.props.loadItem( item );
	};

	handleSetIdToDelete = (id) => {
		this.setState({
			statusShowDeleteDialog: true,
			idToDelete: id
		});
	};

	handleShowDeleteDialog = () => {
		this.setState({
			statusShowDeleteDialog: false,
			idToDelete: 0
		});
	};

	handleDeleteItem = () => {
		this.props.deleteItem( this.props.idDelete );
		this.handleShowDeleteDialog();
	};

	Row( event, row, parent ) {
		return (
			<TableInfinityRow event={event} variant="body">
				<TableInfinityCell style={{width:50}}>
					<IconButton style={ tableStyle.cmdButton } tooltip="Edit" onClick={ () => parent.handleEditItem( row ) } >
						<IconUpdate />
					</IconButton>
					<IconButton style={ tableStyle.cmdButton } tooltip="Delete" onClick={ () => parent.handleSetIdToDelete( row.id ) } >
						<IconDelete />
					</IconButton>
				</TableInfinityCell>
				<TableInfinityCell style={{width:300}}>{row.description}</TableInfinityCell>
				<TableInfinityCell style={{width:150}}>{row.isReadOnly ? "Yes" : "No"}</TableInfinityCell>
			</TableInfinityRow>
		);
	}

	Header(event){
		return (
			<TableInfinityRow event={event} variant="head">
				<TableInfinityCell style={{width:50}}>{""}</TableInfinityCell>
				<TableInfinityCell style={{width:300}}>Description</TableInfinityCell>
				<TableInfinityCell style={{width:150}}>Is it ReadOnly?</TableInfinityCell>
			</TableInfinityRow>
		);
	}
								
	render(){
		return (
			<div>
				<TableInfinityLoader
					listGrid={this.props.list}
					drawRow={this.Row}
					drawHeader={this.Header}
					parent={this}
					rowSize={24}
					rowSizeHeader={24}
				/>
				<DialogYesNo
					onShow = { this.handleShowDeleteDialog }
					onHide = { this.handleShowDeleteDialog }
					onConfirmation={ this.handleDeleteItem }
					status={ this.state.statusShowDeleteDialog }
					text={ "Are you sure to delete this item?" }/>
			</div>
		);
	}
}

RoleTable.propTypes = {
	deleteItem: PropTypes.func,
	list: PropTypes.array,
	loadItem: PropTypes.func
};

export default RoleTable;
