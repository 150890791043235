import MenuItem from '@material-ui/core/MenuItem';
import React from "react";
import PropTypes from "prop-types";

export const groupBy = ( () => {
	const groupByFun = function( keyFun ) {
		return function( map, currentValue ) {
			const key = keyFun( currentValue );
			let values = map.get( key );
			if ( !values ) {
				values = [];
				map.set( key, values );
			}
			values.push( currentValue );
			return map;
		};
	};
	return ( array, keyFun ) => {
		return array.reduce( groupByFun( keyFun ), new Map() );
	};
} )();

export const nullOrUndefinedToEmptyString = v => ( v === null || v === undefined ? "" : v );

export const yearsForSelect = ( () => {
	const currentYear = new Date().getFullYear();
	const years = [];
	const yearReference = 2008;
	for ( let i = currentYear; i >= yearReference; i-- ) years.push( i );
	return years;
} )();

export function replaceNullKeyValuesToEmptyString( entity ) {
	const obj = {};
	for ( const key in entity ) {
		obj[ key ] = entity[ key ] !== null ? entity[ key ] : "";
	}
	return obj;
}

export function replaceEmptyStringKeyValuesToNull( entity ) {
	const obj = {};
	for ( const key in entity ) {
		obj[ key ] = entity[ key ] !== "" ? entity[ key ] : null;
	}
	return obj;
}

export function renderMenuItemsBy( items, label, value, addAllOption ) {
	const allOption = [<MenuItem key={ "All" } value={ "All" }>ALL</MenuItem>];
	if ( items != null ) {
		if ( items.length > 0 ) {
			let list = [];
			if(label.length === 0)
				list = items.map( item => ( <MenuItem key={ item[ value ] } value={ item[ value ] }>{ item[ value ]} </MenuItem> ) );
			else
				list = items.map( item => ( <MenuItem key={ item[ value ] } value={ item[ value ] }>{ item[ label ]} </MenuItem> ) );
			return addAllOption ? allOption.concat( list ) : list;
		} else return [];
	} else return [];
};

export function renderMenuItems( items, addAllOption ) {
const allOption = [<MenuItem key={ addAllOption? "All": "" } value={ addAllOption? "All": "" } style={{height:"30px"}}>{addAllOption? "All": ""}</MenuItem>];
	if ( items != null ) {
		if ( items.length > 0 ) {
			const list =  items.map( item => ( <MenuItem key={ item } value={ item }>{ item } </MenuItem> ) );
			return allOption.concat( list );
		} else return [];
	} else return [];
};

export function renderMenuItemsWithID( items, addAllOption ) {
	const allOption = [<MenuItem key={ addAllOption? "All": "" } value={ addAllOption? "All": "" } style={{height:"30px"}}>{addAllOption? "All": ""}</MenuItem>];
		if ( items != null ) {
			if ( items.length > 0 ) {
				const list =  items.map( item => ( <MenuItem key={ item["key"] } value={ item["key"] }>{ item["value"] } </MenuItem> ) );
				return allOption.concat( list );
			} else return [];
		} else return [];
	};

renderMenuItems.propTypes = {
    items: PropTypes.array.isRequired,
    label: PropTypes.string,
    value: PropTypes.string.isRequired,
    addAllOption: PropTypes.bool.isRequired,
};
