import { apiPost } from "../../../actions";
import Moment from "moment";
import fileDownload from "js-file-download";
export const initialFilters = {
	fromDate: new Date(),
	toDate: new Date(),

};

const defaultPath = "api/FundingManagement/PtHud"



export function generateReport({ fromDate, toDate }) {
	fromDate = new Date(fromDate)
	toDate = new Date(toDate)
	return dispatch => {
		return new Promise((resolve, reject) => {
			apiPost(dispatch, {
				responseType: "blob",
				url: defaultPath + "/GenerateReport",
				body: {
					FromDate: Moment(new Date(Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()))),
					ToDate: Moment(new Date(Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate()))),
				},
				onSuccess: response => {
					var m = Moment(new Date());
					fileDownload(response.data,  `PT_HUD${m.format("YYYYMMDDHHmmss")}.pdf`);
				},
				onError: error => {
					reject(error);
				}
			});
		});
	};
}