import React from "react";
import PropTypes from "prop-types";
import { Link, Tooltip } from "@material-ui/core";
import TableInfinityLoader, { TableInfinityRow, TableInfinityCell } from "../../../commons/components/TableInfinityLoader"
import Moment from "moment";

const widthColDealerName = 350;
const widthColNumber = 90;
const widthColDate = 60;
const col200px = 200;
const col100px = 100;
const col40px = 40;
const col80px = 80;

class DealerProductionReportTable extends React.Component {

	currencyTableCell(value="0.0"){
		return value === null ? "" : value.toLocaleString("en-US", { style: "currency", currency: "USD" });
	}

	percenatageTableCell(value="0.0"){
		return value === null ? "" : value.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2}); 
	}

	numberTableCell(value="0"){
		return value === null ? "" : value.toString();
	}

	Row( event, row, parent ) {
		return (
			<TableInfinityRow event={event} variant="body">
				<TableInfinityCell style={{width:widthColDate}}>{row["wpdiApprovalDate"]=== null ? "" : Moment( new Date( row["wpdiApprovalDate"] ) ).format( "MM-DD-YYYY" )}</TableInfinityCell>
				<TableInfinityCell style={{width:col100px}}>{row["dealerAssociation"]}</TableInfinityCell>
				<TableInfinityCell style={{width:widthColDealerName}}>
					<Tooltip title={row["dealerName"]}>
						<Link
							component="button"
							style={{color:"blue", fontSize: 11, textAlign:"left"}}
							onClick={parent.props.loadDetails.bind(this,row["dealerID"], row["wpdiApprovalDate"])}
						>
							{row["dealerName"]}
						</Link>
					</Tooltip>
				</TableInfinityCell>
				<TableInfinityCell style={{width:widthColNumber}}>{row["dealerNumber"]}</TableInfinityCell>
				<TableInfinityCell style={{width:col100px}}>{row["productType"]}</TableInfinityCell>
				<TableInfinityCell style={{width:widthColNumber,textAlign:"right"}}>{parent.numberTableCell(row["appsTotalCount"])}</TableInfinityCell>
				<TableInfinityCell style={{width:widthColNumber,textAlign:"right"}}>{parent.currencyTableCell(row["appsTotalAmount"])}</TableInfinityCell>
				<TableInfinityCell style={{width:widthColNumber,textAlign:"right"}}>{parent.currencyTableCell(row["appsApprovedAmount"])}</TableInfinityCell>
				<TableInfinityCell style={{width:widthColNumber,textAlign:"right"}}>{parent.percenatageTableCell(row["appsApprovedPercentage"])}</TableInfinityCell>
				<TableInfinityCell style={{width:widthColNumber,textAlign:"right"}}>{parent.numberTableCell(row["appsFundedCount"])}</TableInfinityCell>
				<TableInfinityCell style={{width:widthColNumber,textAlign:"right"}}>{parent.currencyTableCell(row["appsFundedAmount"])}</TableInfinityCell>
				<TableInfinityCell style={{width:widthColNumber,textAlign:"right"}}>{parent.percenatageTableCell(row["appsFundedPercentage"])}</TableInfinityCell>
				<TableInfinityCell style={{width:col40px,textAlign:"right"}}>{row["averageFico"]}</TableInfinityCell>
				<TableInfinityCell style={{width:col80px}}>{row["sfcCultivation"]}</TableInfinityCell>
				<TableInfinityCell style={{width:col80px}}>{row["sfcSalesperson"]}</TableInfinityCell>
				<TableInfinityCell style={{width:widthColDate}}>{row["webinarCompleteDate"]=== null ? "" : Moment( new Date( row["webinarCompleteDate"] ) ).format( "MM-DD-YYYY" )}</TableInfinityCell>
				<TableInfinityCell style={{width:col200px}}>{row["annualRevenue"]}</TableInfinityCell>
			</TableInfinityRow>
		);
	}

	Header(event){
		return (
			<TableInfinityRow event={event} variant="head">
				<TableInfinityCell style={{width:widthColDate}}>WPDI Approval Date</TableInfinityCell>
				<TableInfinityCell style={{width:col100px}}>Dealer Association</TableInfinityCell>
				<TableInfinityCell style={{width:widthColDealerName}}>Dealer Name</TableInfinityCell>
				<TableInfinityCell style={{width:widthColNumber}}>Dealer Number</TableInfinityCell>
				<TableInfinityCell style={{width:col100px}}>Product type</TableInfinityCell>
				<TableInfinityCell style={{width:widthColNumber}}>Submit Apps Count</TableInfinityCell>
				<TableInfinityCell style={{width:widthColNumber}}>Submit Apps Amount</TableInfinityCell>
				<TableInfinityCell style={{width:widthColNumber}}>Approved Amount</TableInfinityCell> 
				<TableInfinityCell style={{width:widthColNumber}}>Apps Approved Percentage</TableInfinityCell>
				<TableInfinityCell style={{width:widthColNumber}}>TTL Funded Count</TableInfinityCell>
				<TableInfinityCell style={{width:widthColNumber}}>TTL Funded Amount</TableInfinityCell>
				<TableInfinityCell style={{width:widthColNumber}}>TTL Funded Percentage</TableInfinityCell>
				<TableInfinityCell style={{width:col40px}}>Average FICO</TableInfinityCell>
				<TableInfinityCell style={{width:col80px}}>Culitavation Rep</TableInfinityCell>
				<TableInfinityCell style={{width:col80px}}>Sales Person Name</TableInfinityCell>
				<TableInfinityCell style={{width:widthColDate}}>Webinar Complete</TableInfinityCell>
				<TableInfinityCell style={{width:col200px}}>Annual Revenuee</TableInfinityCell>
			</TableInfinityRow>
		);
	}

	render(){
		return(
			<TableInfinityLoader 
				listGrid={this.props.listGrid}
				drawRow={this.Row}
				drawHeader={this.Header}
				handleSubmit={this.props.handleSubmit}
				params={this.props.filters}
				parent={this}
				forSingleScroll={this.props.forSingleScroll}
			/>
		);
	}
}

DealerProductionReportTable.propTypes = {
	filters: PropTypes.object,
	handleSubmit: PropTypes.func,
	listGrid: PropTypes.array,
	loadDetails: PropTypes.func,
	forSingleScroll: PropTypes.string
};

export default DealerProductionReportTable;