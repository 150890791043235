import React from "react";
import PropTypes from "prop-types";
import { Link, Checkbox } from "@material-ui/core";
import TableInfinityLoader, { TableInfinityRow, TableInfinityCell } from "../../../commons/components/TableInfinityLoader"
import Moment from "moment";

const widthDealerName = 200;
const widthCheckBox = 40;
const widthLink = 65;
const widthEmail = 700;

class FundingReportTable extends React.Component {

	Row( event, row, parent ) {
		return (
			<TableInfinityRow event={event}>
				<TableInfinityCell style={{width:widthCheckBox}}>
						<Checkbox checked={row["status"]} color="primary"/>	
				</TableInfinityCell>
				<TableInfinityCell style={{width:widthCheckBox}}>
					<Checkbox
						checked={ parent.props.selectSendDealer[event.index] !== undefined }
						onChange={ ev => parent.props.handleSelectSendDealer(ev, event.index, row) }
						color="primary"
					/>	
				</TableInfinityCell>
				<TableInfinityCell style={{width:widthLink}}>
					<div style={{display:"grid"}}>
						<Link 
							onClick={() => parent.props.handleDownloadDealerReport(row["dealerName"], row["dealerNumber"], row["date"], parent.props.filters.ignoreSents)} 
							style={{cursor: 'pointer'}}>
								Funding
						</Link>
						<Link 
							onClick={() => parent.props.handleDownloadDealerAssociationReport(row["dealerAssociation"], row["date"], parent.props.filters.ignoreSents)} 
							style={{cursor: 'pointer'}}>
								Association
						</Link>
					</div>
				</TableInfinityCell>
				<TableInfinityCell style={{width:widthLink}}>{Moment( new Date( row["date"] ) ).format( "MM/DD/YYYY" )}</TableInfinityCell>
				<TableInfinityCell style={{width:widthDealerName}}>{row["dealerName"]}</TableInfinityCell>
				<TableInfinityCell style={{width:widthDealerName}}>{row["dealerAssociation"]}</TableInfinityCell>
				<TableInfinityCell style={{width:widthEmail}}>{row["emailAddress"].split(";").join(", " )}</TableInfinityCell>
			</TableInfinityRow>
		);
	}

	Header(event){
		return (
			<TableInfinityRow event={event} variant="head">
				<TableInfinityCell style={{width:widthCheckBox}}>Status</TableInfinityCell>
				<TableInfinityCell style={{width:widthCheckBox}}>Send</TableInfinityCell>
				<TableInfinityCell style={{width:widthLink}}>Report</TableInfinityCell>
				<TableInfinityCell style={{width:widthLink}}>Date</TableInfinityCell>
				<TableInfinityCell style={{width:widthDealerName}}>Dealer Name</TableInfinityCell>
				<TableInfinityCell style={{width:widthDealerName}}>Dealer Association</TableInfinityCell>
				<TableInfinityCell style={{width:widthEmail}}>Email Address</TableInfinityCell>
			</TableInfinityRow>
		);
	}

	render(){
		return(
			<TableInfinityLoader
				listGrid={this.props.listGrid}
				drawRow={this.Row}
				drawHeader={this.Header}
				handleSubmit={this.props.handleSubmit}
				params={this.props.filters}
				parent={this}
				rowSize={30}
			/>
		);
	}
}

FundingReportTable.propTypes = {
	filters: PropTypes.object,
	handleDownloadDealerAssociationReport: PropTypes.func,
	handleDownloadDealerReport: PropTypes.func,
	handleSelectSendDealer: PropTypes.func,
	handleSubmit: PropTypes.func,
	listGrid: PropTypes.array,
	selectSendDealer: PropTypes.array
};

export default FundingReportTable;