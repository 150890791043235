import React from "react";
import PropTypes from "prop-types";
import TableInfinityLoader, { TableInfinityRow, TableInfinityCell } from "../../../commons/components/TableInfinityLoader"
import { Typography } from '@material-ui/core';
import NetworkButton from "../../../commons/components/NetworkButton";
import Moment from "moment";

const widthColDate = 60;
const col150px = 150;
const col100px = 100;
const col65px = 65;

class DealerProductionDetailTable extends React.Component {

	currencyTableCell(value="0.0"){
		return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
	}

	Row( event, row, parent ) {
		return (
			<TableInfinityRow event={event} variant="body">
				<TableInfinityCell style={{width:col100px}}>{row["applicationNumber"]}</TableInfinityCell>
				<TableInfinityCell style={{width:widthColDate}}>{row["submitDate"]=== null ? "" : Moment( new Date( row["submitDate"] ) ).format( "MM-DD-YYYY" )}</TableInfinityCell>
				<TableInfinityCell style={{width:col100px}}>{row["customerName"]}</TableInfinityCell>
				<TableInfinityCell style={{width:col100px,textAlign:"right"}}>{parent.currencyTableCell(row["contractPrice"])}</TableInfinityCell>
				<TableInfinityCell style={{width:col150px}}>{row["financeProgram"]}</TableInfinityCell>
				<TableInfinityCell style={{width:col100px}}>{row["status"]}</TableInfinityCell>
				<TableInfinityCell style={{width:col65px}}>{row["cancelled"]}</TableInfinityCell>
				<TableInfinityCell style={{width:col65px,textAlign:"right"}}>{row["highestFico"]}</TableInfinityCell>
			</TableInfinityRow>
		);
	}

	Header(event){
		return (
			<TableInfinityRow event={event} variant="head">
				<TableInfinityCell style={{width:col100px}}>Application Number</TableInfinityCell>
				<TableInfinityCell style={{width:widthColDate}}>Submit Date</TableInfinityCell>
				<TableInfinityCell style={{width:col100px}}>Customer Name</TableInfinityCell>
				<TableInfinityCell style={{width:col100px}}>Contract Price</TableInfinityCell>
				<TableInfinityCell style={{width:col150px}}>Finance Program</TableInfinityCell>
				<TableInfinityCell style={{width:col100px}}>Status</TableInfinityCell>
				<TableInfinityCell style={{width:col65px}}>Cancelled?</TableInfinityCell>
				<TableInfinityCell style={{width:col65px}}>Highest Fico</TableInfinityCell>	
			</TableInfinityRow>
		);
	}

	createTableDetails(){
		const { detailsResult, dealerName } = this.props;
		return (
			<fieldset>
				<legend><Typography style={{color: "rgba(0,0,0,0.47)"}}>{dealerName}</Typography></legend>
				<div style={{overflow: "auto", maxHeight: "600px"}}>
					<TableInfinityLoader
						listGrid={detailsResult}
						drawRow={this.Row}
						drawHeader={this.Header}
						parent={this}
					/>
				</div>
			</fieldset>
		);
	}

	render(){
		if(this.props.detailsResult.length === 0)
			return <div/>;
			
		return (
			<div>
				<div style={{float:"right", marginLeft:"50%"}}>
					<NetworkButton  
						onClick={this.props.onDownloadExcel}
						style={{margin: 10, height: 36}}
						primary={ true }
						type="submit"
						text={"Excel"}
					/>
					<NetworkButton text={ "Close" } onClick={ this.props.onClose} />
				</div>
				{this.createTableDetails()}
			</div>			
		);
	}
}

DealerProductionDetailTable.propTypes = {
	dealerName: PropTypes.string,
	detailsResult: PropTypes.array,
	onDownloadExcel: PropTypes.func,
	onClose: PropTypes.func
};

export default DealerProductionDetailTable;