import React from "react";
import PropTypes from "prop-types";
import { Link, Tooltip } from "@material-ui/core";
import TableInfinityLoader, { TableInfinityRow, TableInfinityCell } from "../../../commons/components/TableInfinityLoader"
import Moment from "moment";

const widthColCount = 75;

class DealerTrackingSubmittedAppTable extends React.Component {

	Row( event, row, parent ) {
		return (
			<TableInfinityRow event={event} variant="body">
				<TableInfinityCell style={{width:350}}>
					<Tooltip title={row["dealerName"]}>
						<Link
							component="button"
							style={{color:"blue", fontSize: 11, textAlign:"left"}}
							onClick={parent.props.openSummaryCultivation.bind(this,row["dealerID"])}
						>
							{row["dealerName"]}
						</Link>
					</Tooltip>
				</TableInfinityCell>
				<TableInfinityCell style={{width:600}}>{row["fundingEmail"]}</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>{row["dealerNumber"]}</TableInfinityCell>
				<TableInfinityCell style={{width:75}}>{row["state"]}</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>{row["dealerAssociation"]}</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>{row["enrolledDate"]=== null ? "" : Moment( new Date( row["enrolledDate"] ) ).format( "MM-DD-YYYY" )}</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>{row["repName"]}</TableInfinityCell>
				{ 
					Object.entries(row["monthly"]).map(([key, value]) =>
						<TableInfinityCell key={key} variant="body" style={{width:widthColCount, textAlign:"right"}}>{ value }</TableInfinityCell>
					)
				}
				<TableInfinityCell style={{width:widthColCount, textAlign:"right"}}>{ row["totalApp"] }</TableInfinityCell>
			</TableInfinityRow>
		);
	}

	Header(event){
		let monthly = event.data["monthly"];
		return (
			<TableInfinityRow event={event} variant="head">
				<TableInfinityCell style={{width:350}}>Dealer Name</TableInfinityCell>
				<TableInfinityCell style={{width:600}}>Funding Email</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>Dealer Number</TableInfinityCell>
				<TableInfinityCell style={{width:75}}>State</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>Dealer Association</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>Enrolled Date</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>Rep. Name</TableInfinityCell> 
				{
					Object.entries(monthly).map(([key, value]) =>
						<TableInfinityCell key={key} variant="head" style={{width:widthColCount}}>{Moment( new Date( key ) ).format( "MM/DD/YYYY" ) + " #Apps"}</TableInfinityCell>
					)
				}
				<TableInfinityCell style={{width:widthColCount}}>Total Apps</TableInfinityCell>
			</TableInfinityRow>
		);
	}

	render(){
		return(
			<TableInfinityLoader 
				listGrid={this.props.listGrid}
				drawRow={this.Row}
				drawHeader={this.Header}
				handleSubmit={this.props.handleSubmit}
				params={this.props.filters}
				parent={this}
				forSingleScroll={this.props.forSingleScroll}
			/>
		);
	}
}

DealerTrackingSubmittedAppTable.propTypes = {
	filters: PropTypes.object,
	handleSubmit: PropTypes.func,
	listGrid: PropTypes.array,
	openSummaryCultivation: PropTypes.func,
	forSingleScroll: PropTypes.string
};

export default DealerTrackingSubmittedAppTable;