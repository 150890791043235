import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageBase from "../../../commons/components/PageBase";
import SearchNotData from "../../../commons/components/SearchNotData";
import DealerBDRatioFilters from "./DealerBDRatioFilters";
import DealerBDRatioTable from "./DealerBDRatioTable";

import {
	loadFilters,
	submit,
	downloadExcelReport,
	initialFilters
 } from "./actions";
import SingleScroll from "../../../commons/components/SingleScroll";

const mapDispatchToProps = {
	loadFilters,
	submit,
	downloadExcelReport,
};

const singleScrollName = "DealerBDRatioReport";

class DealerBDRatioReport extends React.Component {
	state = {
		openDetailsModal: false,
		listDetailsGrid: [],
		filters: initialFilters,
		currentFilters: initialFilters,
		listGrid: [],
		isSubmitted: false,
		dealerAssociationList:[],
		sfcSalespersonList:[],
		dealerNameList:[]
	};

	constructor(props) {
		super(props);
		this.props.loadFilters(this);
	}

	handleMultiSelectFilter = (field, value) => {
		this.setState({
			filters: {
				...this.state.filters,
				[field]:value
			}
		});
	};

	handleChangeFilter = ( field, value ) => {
		this.setState({
			filters:{
				...this.state.filters,
				[field]: value
			}
		});
	};

	handleDate(field, date){
		this.setState({
			filters: {
				...this.state.filters,
				[field]:date
			}
		});
	};

	handleSubmit = () => {
		this.setState({currentFilters: this.getFilters()});
		this.props.submit(this, this.getFilters());
	};

	handleReset = () => {
		this.setState({
			filters: initialFilters,
			currentFilters: initialFilters,
			listGrid: [],
			isSubmitted: false
		});
	};

	handleExcel = () => {
		this.props.downloadExcelReport(this.getFiltersForDetails());
	};

	getFilters = () => this.state.filters;
	getFiltersForDetails = () => this.state.currentFilters;


	createFilters = () => {
        const { filters, dealerAssociationList, sfcSalespersonList, dealerNameList } = this.state;
		return(
			<DealerBDRatioFilters
				filters={filters}
				dealerAssociationList={dealerAssociationList}
				dealerNameList={dealerNameList}
				sfcSalespersonList={sfcSalespersonList}
				handleDate={this.handleDate.bind(this)}
				handleMultiSelectFilter={this.handleMultiSelectFilter}
				handleExcel={this.handleExcel}
				handleSubmit={this.handleSubmit}
				handleReset={this.handleReset}
				changeFilter={this.handleChangeFilter}
				isSubmitted={this.state.isSubmitted}
			/>
		);
	};

	render() {
		const {listGrid, isSubmitted} = this.state;
		return (
			<PageBase title="Dealer BD Ratio Report" >
				<SingleScroll name={singleScrollName}>
					<SingleScroll.Scrollable for={singleScrollName}>
						{this.createFilters()}
						<SearchNotData
							isSubmitted={isSubmitted}
							listGrid={listGrid}
						>
							<DealerBDRatioTable
								listGrid={listGrid}
								loadDetails={this.handleLoadDetails}
								filters={this.getFiltersForDetails()}
								forSingleScroll={singleScrollName}
							/>
						</SearchNotData>
					</SingleScroll.Scrollable>
				</SingleScroll>
			</PageBase>
		);
	}
}

DealerBDRatioReport.propTypes = {
	downloadExcelReport: PropTypes.func,
	loadApplicationDetails: PropTypes.func,
	loadFilters: PropTypes.func,
	loadFilterType: PropTypes.func,
	saveApplicationNote: PropTypes.func,
	submit: PropTypes.func,
	submitDetails: PropTypes.func
};

export default connect(
	null,
	mapDispatchToProps,
)( DealerBDRatioReport );