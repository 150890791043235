import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageBase from "../../../commons/components/PageBase";
import ChuckReportFilters from "./ChuckReportFilters";

import {
	loadFilters,
	downloadExcelReport,
	initialFilters,
	dealersFromWrenchGroup
 } from "./actions";

import SingleScroll from "../../../commons/components/SingleScroll";

const mapDispatchToProps = {
	loadFilters,
	downloadExcelReport,
	dealersFromWrenchGroup
};

class ChuckReport extends React.Component {
	state = {
		filters: initialFilters,
		dealerAssociationList:[],
		dealerNameList:[],
		contactMethodList:[]
	};

	constructor(props) {
		super(props);
		this.props.loadFilters(this);
 	}

	handleChangeFilter = ( field, value ) => {
		this.setState({
			filters:{
				...this.state.filters,
				[field]: value
			}
		});
	};

	handleReset = () => {
		this.setState({
			filters: initialFilters,
		});
	};

	handleCheckbox = ( event, checked ) => {
		var selectedDealerNamesList = this.state.filters.dealerId;
		var selectWrenchGroupList = dealersFromWrenchGroup(this.state.dealerNameList);
		if(checked) {
			for( let index = 0; index < selectWrenchGroupList.length; index++) {
				if(!selectedDealerNamesList.find(a => a.id === selectWrenchGroupList[index].id)) {
					selectedDealerNamesList.push(selectWrenchGroupList[index]);
				}
			}
		}
		else {
			var found;
			for( let index = 0; index < selectWrenchGroupList.length; index++) {
				found = selectedDealerNamesList.findIndex(a => a.id === selectWrenchGroupList[index].id)
				if(found) {
					selectedDealerNamesList.splice(found, 1);
				}
			}
		}
		
		this.setState({
			filters: {
				...this.state.filters,
				[event.target.name]:checked,
				'dealerId':selectedDealerNamesList
			}
		});
	};

	handleExcel = () => {
		this.props.downloadExcelReport(this.getFilters());
	};

	getFilters = () => this.state.filters;
	
	createFilters() {
        const { filters, dealerAssociationList, dealerNameList, contactMethodList } = this.state;
		return (
			<ChuckReportFilters
				filters={filters}
				dealerAssociationList={dealerAssociationList}
				dealerNameList={dealerNameList}
				changeFilter={this.handleChangeFilter}
				handleReset={this.handleReset}
				handleExcel={this.handleExcel}
				handleCheckbox={this.handleCheckbox}
				contactMethodList={contactMethodList}
			/>
		);
	}

	render() {
		const singleScrollName = "ChuckReport";
		return (
			<PageBase title="Chuck Report">
				<SingleScroll name={singleScrollName}>
					<SingleScroll.Scrollable for={singleScrollName}>
						{this.createFilters()}
					</SingleScroll.Scrollable>
				</SingleScroll>
			</PageBase>
		);
	}
}

ChuckReport.propTypes = {
	downloadExcelReport: PropTypes.func,
	dealersFromWrenchGroup: PropTypes.func,
	loadFilters: PropTypes.func,
};

export default connect(
	null,
	mapDispatchToProps,
)( ChuckReport );
