/*
How to use 
ListInfinityLoader params:
	ListGrid: <Array<object>> - This params load only first load after submit. The Entity needs a field Count<int> that has a value with total
	drawRow: function - function to draw row, must use "ListInfinityDiv"
		this function need 3 params:
		Row( event, row, parent )
			event: object - import to move to ListInfinityLoader
			row: object - data with draw row
			parent: ref - use when has event
	handleSubmit: function - function to get data
		handleSubmit(params)
			params: objcect - must include startIndex and StopIndex
	params: <Object> - optional - object with filters/params for use in submit function
	parent: <Ref> - optional - parent reference to use in cell events
	rowHeight: <number> - height of rows
*/

import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import SingleScroll from "./SingleScroll";

const useTableStyles = {
	scrollHidden: {
		scrollbarWidth: "none",
		'&::-webkit-scrollbar': {
			display: 'none'
		}
	}
};

let id = 0;
class ListInfinityLoader extends Component {
	constructor(props){
		super(props);
		this.containerRef = React.createRef();
		this.listId = id++;
		this.state = {
			listGrid: [],
			totalCount: 0,
			params:{},
			width: 0
		}
	}

	componentDidMount(){
		this.setState({
			listGrid: this.props.listGrid,
			totalCount: this.props.listGrid[0]["totalCount"],
			params: JSON.parse( JSON.stringify( this.props.params ) )
		});
	};

	componentDidUpdate(){
		this.resizeList();
	};

	resizeList(){
		let width = this.containerRef.current?.children[0].children[0].children[0].children[0].children[0].clientWidth;
		if(width === undefined)
			width = 0;
		if(this.state.width !== width)
			this.setState({width: width});
	};

	shouldComponentUpdate(nextProps, nextState)
	{
		if(nextProps.params !== this.props.params)
		{
			this.setState({
				listGrid: [],
				totalCount: 0,
				params: JSON.parse( JSON.stringify( nextProps.params ) )
			});
			return false;
		}

		if(nextProps.listGrid !== this.props.listGrid)
		{
			let updateList = this.state.listGrid;
			const { listGrid } = nextProps;

			listGrid.forEach(element => {
				updateList[element["indexRow"]] = element;
			});			

			this.setState({
				listGrid: updateList,
				totalCount: listGrid[0]["totalCount"]
			});
			return true;
		}
		if(nextState.totalCount !== this.state.totalCount)
			return false;

		return true;
	}

	isItemLoaded = index => this.state.listGrid[index] !== undefined;
	loadMoreItems = (startIndex, stopIndex) => {
		if(startIndex === stopIndex)
			stopIndex = startIndex + 1;

		this.setState({
			params: {
				...this.state.params,
				startIndex: startIndex,
				stopIndex: stopIndex
			}
		});
		this.props.handleSubmit({...this.state.params, ...{startIndex, stopIndex}} );
	};

	Row = (event) => {
		const {drawRow, parent} = this.props;
		const { listGrid, width } = this.state;

		if (listGrid[event.index] !== undefined) {
			let row = listGrid[event.index];
			return (drawRow(event, row, parent));
		} else
			return(<div style={event.style}><div style={{width:width}}>Loading...</div></div>);
	};

	render() {
		const { totalCount, listGrid, width }= this.state;
		if(totalCount === 0 || listGrid.length === 0) return <div/>;

		const { classes, rowHeight, forSingleScroll } = this.props;
		return (
			<div style={{display:"flex", height:500}}>
				<div style={{height:500, overflowX: "auto", maxWidth:"99%", zIndex:0}}>
					<div ref={this.containerRef} style={{width:width}}>
						<InfiniteLoader
							isItemLoaded={this.isItemLoaded}
							itemCount={totalCount}
							loadMoreItems={this.loadMoreItems}
							minimumBatchSize={100}
						>
							{({ onItemsRendered, ref }) => (
								<SingleScroll.Scrollable for={forSingleScroll}>
									<List
										height={483}
										itemCount={totalCount}
										itemSize={rowHeight}
										onScroll={() => {}}
										className={classes.scrollHidden}
										onItemsRendered={onItemsRendered}
										ref={ref}
										style={{ overflowY: "hidden" }}
									>
										{this.Row}
									</List>
								</SingleScroll.Scrollable>
							)}
						</InfiniteLoader>
					</div>
				</div>
			</div>
		);
	}

}

ListInfinityLoader.propTypes = {
	classes: PropTypes.object,
	drawRow: PropTypes.func,
	handleSubmit: PropTypes.func,
	listGrid: PropTypes.array,
	params: PropTypes.object,
	parent: PropTypes.object,
	rowHeight: PropTypes.number,
	forSingleScroll: PropTypes.string,
};

class PureListInfinityDiv extends Component {
	render(){
		const { style, event, children }  = this.props;

		return(
			<div key={event.index} style={{...style, ...event.style}}>{children}</div>
		);
	};
}

PureListInfinityDiv.propTypes = {
	children: PropTypes.oneOfType([ PropTypes.object, PropTypes.string, PropTypes.number ]),
	event: PropTypes.object,
	style: PropTypes.object
};

export default withStyles( useTableStyles, {withTheme : true})(ListInfinityLoader); 

const ListInfinityDiv = withStyles(useTableStyles, {withTheme : true})(PureListInfinityDiv);
export { ListInfinityDiv };

