import React, { Component } from "react";
import PropTypes from "prop-types";
import { renderMenuItems } from "../utils"
import Paper from "@material-ui/core/Paper";
import { FormControl, InputLabel } from "@material-ui/core";
import SelectField from "@material-ui/core/Select";

import If from "./If";

const styles = {
	secondFilter: {
		marginLeft: 15,
		width: 256
	},
	stylePaperFilter: {
		margin: 2,
		padding: 15
	}
};

class CascadeTwoFilters extends Component {

	render() {
		return (
			<div>
				<Paper style={ styles.stylePaperFilter } elevation={1}>
					<FormControl style={ {width: 256} }>
						<InputLabel>Select a lender</InputLabel>
						<SelectField
							name="selectedLender"
							value={ this.props.valueSelectedLender }
							onChange={ this.props.handleChangeLender }
						>
							{renderMenuItems( this.props.dataSourceLenders, false )}
						</SelectField>
					</FormControl>

					<If condition={ !this.props.valueSelectedLender?.includes("Dealer Advanced") }>
						<FormControl style={ styles.secondFilter }>
							<InputLabel>Select a date</InputLabel>
							<SelectField
								name="selectedDate"
								value={ this.props.valueSelectedDate }
								onChange={ this.props.handleChangeDate }
								disabled={ this.props.dataSourceDates.length === 0 }
							>
								{renderMenuItems( this.props.dataSourceDates, false )}
							</SelectField>
						</FormControl>
					</If>
					<br />
				</Paper>
			</div>
		);
	}
}

CascadeTwoFilters.propTypes = {
	dataSourceDates: PropTypes.array,
	dataSourceLenders: PropTypes.array,
	handleChangeDate: PropTypes.func,
	handleChangeLender: PropTypes.func,
	valueSelectedDate: PropTypes.string,
	valueSelectedLender: PropTypes.string
};

export default CascadeTwoFilters;
