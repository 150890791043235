import React from "react";
import PropTypes from "prop-types";
import TableInfinityLoader, { TableInfinityRow, TableInfinityCell } from "../../../commons/components/TableInfinityLoader"
import DialogYesNo from "../../../commons/components/DialogYesNo";
import IconDelete from "@material-ui/icons/Delete";
import IconUpdate from "@material-ui/icons/Create";
import IconButton from "@material-ui/core/IconButton";
import { withTheme } from "@material-ui/core/styles";
import tableStyle from "../../../commons/components/TableStyle";

class LenderTable extends React.Component {
	state= {
		idToDelete: 0,
		statusShowDeleteDialog: false
	};

	handleEditItem = item => {
		this.props.loadItem( item );
	};

	handleSetIdToDelete = (id) => {
		this.setState({
			idToDelete: id,
			statusShowDeleteDialog: true
		});
	};

	handleShowDeleteDialog = () => {
		this.setState({
			idToDelete: 0,
			statusShowDeleteDialog: false
		});
	};

	handleDeleteItem = () => {
		this.props.deleteItem( this.state.idToDelete );
		this.handleShowDeleteDialog();
	};

	handleShowMoreClick = event => {
		const element = event.currentTarget;
		if(!this.isRowMenuOpen(element))
			this.setState({anchoredMenu: element, currentMenu:element.id});		
	}

	Row( event, row, parent ) {
		return (
			<TableInfinityRow event={event} variant="body">
				<TableInfinityCell style={{width:50}}>
					<IconButton
						tooltip="Edit"
						onClick={ () => parent.handleEditItem( row ) }
						style={ tableStyle.cmdButton }
					>
						<IconUpdate />
					</IconButton>
					<IconButton
						tooltip="Delete"
						onClick={ () => parent.handleSetIdToDelete( row.id ) }
						style={ tableStyle.cmdButton }
					>
						<IconDelete />
					</IconButton>
				</TableInfinityCell>
				<TableInfinityCell style={{width:300}}>{row.name}</TableInfinityCell>
				<TableInfinityCell style={{width:70, textAlign:"center"}}>{row.code}</TableInfinityCell>
				<TableInfinityCell style={{width:100, textAlign:"right"}}>{row.numbers.join()}</TableInfinityCell>
			</TableInfinityRow>
		);
	}

	Header(event){
		return (
			<TableInfinityRow event={event} variant="head">
				<TableInfinityCell style={{width:50}}>{""}</TableInfinityCell>
				<TableInfinityCell style={{width:300}}>Name</TableInfinityCell>
				<TableInfinityCell style={{width:70}}>Code</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>Lender Nº</TableInfinityCell>
			</TableInfinityRow>
		);
	}

	render(){
		return (
			<div>
			<TableInfinityLoader
				listGrid={this.props.list}
				drawRow={this.Row}
				drawHeader={this.Header}
				parent={this}
				rowSize={24}
				rowSizeHeader={24}
			/>
			<DialogYesNo
				onShow = { this.handleShowDeleteDialog }
				onHide = { this.handleShowDeleteDialog }
				onConfirmation={ this.handleDeleteItem }
				status={ this.state.statusShowDeleteDialog }
				text={ "Are you sure to delete this item?" }
			/>
			</div>
		);
	}
}

LenderTable.propTypes = {
	deleteItem: PropTypes.func,
	list: PropTypes.array,
	loadItem: PropTypes.func
};

export default withTheme(LenderTable);
