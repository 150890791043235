import { apiGet, apiPost } from "../../../../../actions";

export const model = {
	id: 0,
	lender: "",
	totalFunds: "",
	principal: "",
	interest: "",
	date: null
};

const defaultPath = "api/FundingManagement/FundingPackage/ServicingAdvice/ServicingAdviceSend";

export function loadLenders(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath + "/lenders",
			onSuccess: response => {
				props.setState({lenders: response.data });
			}
		} );
	};
}

export function saveItem( props, item ) {
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath,
			body: item,
			onSuccess: () => {
				props.setState({form: model});
			}
		} );
	};
}
