import React, { Component } from "react";
import PropTypes from "prop-types";
import { SelectValidator } from "react-material-ui-form-validator";
import { renderMenuItemsBy } from "../../../../commons/utils";
import Grid from "@material-ui/core/Grid";
import DatePickerRange from "../../../../commons/components/DatePickerRange";
import { Typography, withStyles } from "@material-ui/core";

const useStyles = {
	typography: {
		color: 'rgba(0,0,0,0.47)'
	}
}
class TabSelectLender extends Component {
	handleSelectLender = ( event ) => {
		this.props.parent.setState({selectLender: event.target.value });
		this.changeLenderDescription(event.target.value);
	};

	changeLenderDescription(lender) {
		var lenderDescription = this.props.lendersDescription[lender.toLowerCase()]
		if(lenderDescription === undefined) {
			lenderDescription = this.props.lendersDescription['default']
		}
		this.props.parent.setState({selectedLenderDescription: lenderDescription})
	}

	render() {
		const { classes } = this.props;
		return (
			<div>
				<Grid container item xs={12} spacing={0}>
					<Grid item xs={3}>
						<Grid item xs>
							<SelectValidator
								ref={ input => {
									this.selectLender = input;
								} }
								validators={ ["required"] }
								errorMessages={ ["It is mandatory."] }
								name="selectLender"
								label="Select a lender"
								value={ this.props.selectLender }
								onChange={ this.handleSelectLender }
								style={{width: 250, margin: '5px'}}
							>
								{renderMenuItemsBy( this.props.lenders, "", "code", false )}
							</SelectValidator>
							<DatePickerRange
								label={"Date"}
								fromDate={this.props.initialDate} 
								fromDateId="initialDate" 
								toDate={this.props.finalDate} 
								toDateId="finalDate" 
								onDatesChange={this.props.onChangeDate}
							/>						
						</Grid>
					</Grid>
					{this.props.selectedLenderDescription !== undefined &&
						<Grid item xs={3}>
							<Grid item xs>
								<div className={classes.typography}>
									<fieldset style={{borderWidth:1, borderRadius:5, margin: '5px'}}>
										<legend><Typography>Description of Query</Typography></legend>
										<Typography>Lender: {this.props.selectedLenderDescription.lender !== 'Default' ? this.props.selectedLenderDescription.lender : this.props.selectLender}</Typography>
										<Typography>Status: {this.props.selectedLenderDescription.status}</Typography>
										<Typography>{this.props.selectedLenderDescription.ignore}</Typography>
										<Typography>{this.props.selectedLenderDescription.verifiedLoan}</Typography>
										<Typography>{this.props.selectedLenderDescription.includeCOCDate}</Typography>
										<Typography>{this.props.selectedLenderDescription.ignoreCanceledLoans}</Typography>
										<Typography>{this.props.selectedLenderDescription.ignoreBankingInformation}</Typography>
										<Typography>{this.props.selectedLenderDescription.includeHDNote}</Typography>
									</fieldset>
								</div>
							</Grid>
						</Grid>
					}
				</Grid>
				<br />
			</div>
		);
    }
};

TabSelectLender.propTypes = {
	finalDate: PropTypes.object,
	initialDate: PropTypes.object,
    lenders: PropTypes.array,
	onChangeDate: PropTypes.func,
    parent: PropTypes.object,
    selectLender: PropTypes.string,
	lendersDescription: PropTypes.object,
	selectedLenderDescription: PropTypes.object
};

export default withStyles(useStyles)(TabSelectLender);