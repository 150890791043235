import React from "react";
import PropTypes from "prop-types";
import TableInfinityLoader, { TableInfinityRow, TableInfinityCell } from "../../../commons/components/TableInfinityLoader"
import DialogYesNo from "../../../commons/components/DialogYesNo";
import IconDelete from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Moment from "moment";
import NumberFormat from "react-number-format";

class FacilityLimitPurchaseTable extends React.Component {

	state= {
		statusShowDeleteDialog: false,
		idToDelete: 0
	};

	handleSetIdDelete = id => {
		this.setState({
			idToDelete: id,
			statusShowDeleteDialog: true
		});
	}

	handleShowDeleteDialog = () => {
		this.setState({
			statusShowDeleteDialog: !this.state.statusShowDeleteDialog,
			idToDelete: 0 
		});
	};

	handleDeleteItem = () => {
		this.props.deleteItem( this.state.idToDelete );
		this.handleShowDeleteDialog();
	};

	Row( event, row, parent ) {
		return (
			<TableInfinityRow event={event} variant="body">
				<TableInfinityCell style={{width:25}}>
					<IconButton
						style={ { padding: 0 } }
						tooltip="Delete"
							onClick={ () => parent.handleSetIdDelete( row["id"] ) }
					>
						<IconDelete />
					</IconButton>
				</TableInfinityCell>
				<TableInfinityCell style={{width:70}}>{ Moment( new Date( row.createdDate ) ).format( "MM/DD/YYYY" ) }</TableInfinityCell>
				<TableInfinityCell style={{width:50}}>{row.lenderFromName}</TableInfinityCell>
				<TableInfinityCell style={{width:50}}>{row.lenderToName}</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>
					<NumberFormat value={ row.amount.toLocaleString( "en-US", { minimumFractionDigits: 2 } ) } displayType={ "text" } thousandSeparator={ true } prefix={ "$" } />
				</TableInfinityCell>
			</TableInfinityRow>
		);
	}

	Header(event){
		return (
			<TableInfinityRow event={event} variant="head">
				<TableInfinityCell style={{width:25}}>{""}</TableInfinityCell>
				<TableInfinityCell style={{width:70}}>Created Date</TableInfinityCell>
				<TableInfinityCell style={{width:50}}>Lender From</TableInfinityCell>
				<TableInfinityCell style={{width:50}}>Lender To</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>Amount</TableInfinityCell>
			</TableInfinityRow>
		);
	}


	render() {
		return (
			<div>
				<TableInfinityLoader
					listGrid={this.props.list}
					drawRow={this.Row}
					drawHeader={this.Header}
					parent={this}
					rowSize={24}
					rowSizeHeader={24}
				/>
				<DialogYesNo
					onShow = { this.handleShowDeleteDialog }
					onHide = { this.handleShowDeleteDialog }
					onConfirmation={ this.handleDeleteItem }
					status={ this.state.statusShowDeleteDialog }
					text={ "Are you sure to delete this item?" }/>
			</div>
		);
	}
}

FacilityLimitPurchaseTable.propTypes = {
	deleteItem: PropTypes.func,
	idDelete: PropTypes.number,
	list: PropTypes.array,
	setIDDelete: PropTypes.func,
	showDeleteDialog: PropTypes.func,
	statusShowDeleteDialog: PropTypes.bool
};

export default FacilityLimitPurchaseTable;
