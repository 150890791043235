import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import { Network_ActionTypes } from "./actions";

import { loadingBarReducer } from "react-redux-loading-bar";
import layout from "./layout/reducers";
import auth from "./pages/auth/reducers.js";

const networkInitialState = {
	onNetwork: false,
	url: undefined,
	message: ""
};

const networkReducer = ( state = networkInitialState, action ) => {
	switch ( action.type ) {
	case Network_ActionTypes.Start: {
		return {
			onNetwork: true,
			url: action.payload,
			message: ""
		};
	}
	case Network_ActionTypes.End: {
		return {
			onNetwork: false,
			url: undefined,
			message: action.payload,
		};
	}
	case Network_ActionTypes.LoadMessage: {
		return {
			...state,
			message: action.payload,
		};
	}
	case Network_ActionTypes.ClearMessage: {
		return {
			...state,
			message: ""
		};
	}
	default:
		return state;
	}
};

export default combineReducers( {
	layout,
	routing: routerReducer,
	network: networkReducer,
	auth,
	loadingBar: loadingBarReducer
} );
