import React from "react";
import { ValidatorComponent } from 'react-material-ui-form-validator';
import ChipInput from "material-ui-chip-input";

class ChipInputField extends ValidatorComponent {
	renderValidatorComponent() {
	  const {
		errorMessages,
		validators,
		requiredError,
		helperText,
		validatorListener,
		...rest
	  } = this.props;
	  const { isValid } = this.state;

		return (
			<ChipInput
				ref={(r) => { this.input = r; }}
				{ ...rest }
				fullWidth
				error={!isValid}
				helperText={(!isValid && this.getErrorMessage()) || helperText}
			/>
		);
	}
}

export default ChipInputField;
