import { apiGet, apiPost } from "../../../../actions";
import fileDownload from "js-file-download";
import Moment from "moment";

const defaultPath = "api/CreateFundingPackage";

export function loadImportNLS(props, onSuccess = () => {}, onError) {
	return dispatch => {
		apiPost( dispatch, {
			url: `api/LoadInformation/Application?days=7`,
			onSuccess: response => {
				dispatch(loadLenders(props));
				onSuccess();
			},
			onError
		} );
	};
}


export function loadLenders(props, onSuccess = () => {}) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath + "/lenders",
			onSuccess: response => {
				props.setState({ lenders: response.data } );
				onSuccess();
			}
		} );
	};
}

export function loadLendersDescription(props, onSuccess = () => {}) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath + "/lendersDescription",
			onSuccess: response => {
				props.setState({ lendersDescription: response.data } );
				onSuccess();
			}
		} );
	};
}

export function loadList( props, item, initialDate, finalDate ) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath + `/GetAllForFundingPackageGrid`,
			params: {
				lender: item,
				initialDate: !!initialDate ? Moment( new Date( initialDate ) ).format( "MM-DD-YYYY" ) : null,
				finalDate: !!finalDate ? Moment( new Date( finalDate ) ).format( "MM-DD-YYYY" ) : null
			},
			onSuccess: response => {
				if( response.data.isError )
					props.setState({
						messageError: response.data.message,
						openAlert: true,
						containsError: true
					});
				else
				{
					props.setState({
						dataList: response.data,
						stepIndex: props.state.stepIndex + 1
					});
				}
			}
		} );
	};
}

export function saveFundingPackage( props, lender, initialDate, finalDate ) {
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath + `/finishFundingPackage`,
			body: {
				lender: lender,
				initialDate: !!initialDate ? Moment( new Date( initialDate ) ).format( "MM-DD-YYYY" ) : null,
				finalDate: !!finalDate ? Moment( new Date( finalDate ) ).format( "MM-DD-YYYY" ) : null
			},
			onSuccess: response => {
				props.setState({
					issues: response.data,
					stepIndex: props.state.stepIndex + 1
				});
			}
		} );
	};
}

export function loadApplicationDetails(props, applicationNumber) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath + "/applicationDetails/" + applicationNumber,
			onSuccess: response => {
				props.setState( { applicationDetails: response.data } );
			}
		} );
	};
}

export function downloadFundingScheduleReport( lender, initialDate, finalDate ) {
	return dispatch => {
		apiGet( dispatch, {
			responseType: "blob",
			url: defaultPath + `/DownloadFundingScheduleReport`,
			params: {
				lender: lender,
				initialDate: !!initialDate ? Moment( new Date( initialDate ) ).format( "MM-DD-YYYY" ) : null,
				finalDate: !!finalDate ? Moment( new Date( finalDate ) ).format( "MM-DD-YYYY" ) : null
			},
			onSuccess: response => {
				fileDownload( response.data, "FundingScheduleReport.xlsx" );
			}
		} );
	};
}

export function downloadLenderPreFundReport( lender, initialDate, finalDate ) {
	return dispatch => {
		apiGet( dispatch, {
			responseType: "blob",
			url: defaultPath + `/DownloadLenderPreFundReport`,
			params: {
				lender: lender,
				initialDate: !!initialDate ? Moment( new Date( initialDate ) ).format( "MM-DD-YYYY" ) : null,
				finalDate: !!finalDate ? Moment( new Date( finalDate ) ).format( "MM-DD-YYYY" ) : null
			},
			onSuccess: response => {
				fileDownload( response.data, "LenderPreFundReport.xlsx" );
			}
		} );
	};
}

export function downloadNACHAFile( lender, initialDate, finalDate ) {
	return dispatch => {
		apiGet( dispatch, {
			responseType: "blob",
			url: defaultPath + `/nacha/file`,
			params: {
				lender: lender,
				initialDate: !!initialDate ? Moment( new Date( initialDate ) ).format( "MM-DD-YYYY" ) : null,
				finalDate: !!finalDate ? Moment( new Date( finalDate ) ).format( "MM-DD-YYYY" ) : null
			},
			onSuccess: response => {
				fileDownload( response.data, "NACHA.txt" );
			}
		} );
	};
}

export function downloadValidationReport(lender, initialDate, finalDate) {
	return dispatch => {
		apiGet( dispatch, {
			responseType: "blob",
			url: defaultPath + `/validationReport/file`,
			params: {
				lender: lender,
				initialDate: !!initialDate ? Moment( new Date( initialDate ) ).format( "MM-DD-YYYY" ) : null,
				finalDate: !!finalDate ? Moment( new Date( finalDate ) ).format( "MM-DD-YYYY" ) : null
			},
			onSuccess: response => {
				fileDownload( response.data, "ApplicatioValidationReport.xlsx" );
			}
		} );
	};
}

export function downloadACHHomeDepotFile(lender, initialDate, finalDate) {
	var date = new Date();
	date = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+ date.getDate();

	return dispatch => {
		apiGet( dispatch, {
			responseType: "blob",
			url: defaultPath + `/ACHHomeDepot/file`,
			params: {
				lender: lender,
				initialDate: !!initialDate ? Moment( new Date( initialDate ) ).format( "MM-DD-YYYY" ) : null,
				finalDate: !!finalDate ? Moment( new Date( finalDate ) ).format( "MM-DD-YYYY" ) : null
			},
			onSuccess: response => {
				fileDownload( response.data, `ACH-Home Depot ${date}.txt` );
			}
		} );
	};
}

export function loadEstimatedTimeImportNLS( props ) {
	return dispatch => {
		apiGet( dispatch, {
			url: `api/LoadInformation/Timer/Application?days=7`,
			params: {},
			onSuccess: response => {
				if( response.data.isError ) {
					props.setState({
						messageError: response.data.message,
						openAlert: true,
						containsError: true,
					});
				} else {
					props.setState({
						estimatedTimeImportNLS: response.data,
					});
				}
			}
		} );
	};
}
