import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
  });

const DialogYesNo = ( { onShow, onHide, onConfirmation, status, text } ) => (
	<Dialog
		open={ status }
		TransitionComponent={Transition}
		keepMounted
		onClose={ onShow }
		aria-labelledby="alert-dialog-slide-title"
		aria-describedby="alert-dialog-slide-description"
		maxWidth={ "lg" }
	>
		<DialogContent>
			<DialogContentText id="alert-dialog-slide-description">
				{text}
			</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button
				key="btnActionsNo"
				color="primary"
				onClick={ onHide }
			>
				NO
			</Button>
			<Button
				key="btnActionsYes"
				color="primary"
				onClick={ onConfirmation }
			>
				Yes
			</Button>
		</DialogActions>		
	</Dialog>
);

DialogYesNo.propTypes = {
	onConfirmation: PropTypes.func,
	onHide: PropTypes.func,
	onShow: PropTypes.func,
	status: PropTypes.bool,
	text: PropTypes.string
};

export default DialogYesNo;