import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageBase, {
	TypeOfActionButton
} from "../../../commons/components/PageBase";
import LenderForm from "../lender/LenderForm";
import LenderTable from "../lender/LenderTable";
import {
	loadList,
	loadFilters,
	deleteItem,
	saveItem,
	model
} from "./actions";

const mapDispatchToProps = {
	loadList,
	loadFilters,
	deleteItem,
	saveItem
};

class Lender extends React.Component {
	state = {
		item: model,
		dataList: [],
		typeOfActionButton: "ADD",
		loanTypeList: [],
		productList: [],
		statesList: []
	};
	constructor(props) {
		super(props);
		this.props.loadList(this);
		this.props.loadFilters(this);
	}

	handleChangeActionButton = () => {
		if (TypeOfActionButton.ADD === this.state.typeOfActionButton) {
			this.setState({typeOfActionButton: TypeOfActionButton.CANCEL});
		} else {
			this.cleanForm();
		}
	};

	handleLoadItem = item => {
		this.setState({
			item: item,
			typeOfActionButton: TypeOfActionButton.CANCEL
		});
	};

	handleUpdateField = (field, value) => {
		this.setState({
			item:{
				...this.state.item,
				[field]: value
			}
		});
	};

	cleanForm = () => {
		this.setState({typeOfActionButton: TypeOfActionButton.ADD, item: model});
	}

	handleSaveItem = (item) => {
		this.props.saveItem(this, item);
	};

	handleDeleteItem = (id) => {
		this.props.deleteItem(this, id);
	};

	showPanel = () => {
		const { dataList, item, loanTypeList, productList, statesList } = this.state;
		if (TypeOfActionButton.ADD === this.state.typeOfActionButton) {
			return (
				<LenderTable
					list={ dataList }
					loadItem={ this.handleLoadItem }
					deleteItem={ this.handleDeleteItem.bind(this) }
				/>
			);
		}

		return (
			<LenderForm
				changeInput={ this.handleUpdateField }
				saveItem={ this.handleSaveItem.bind(this) }
				cleanForm={this.cleanForm.bind(this)}
				item={ item }
				loanTypeList={ loanTypeList }
				productList={ productList }
				statesList={ statesList }
			/>
		);
	};

	render() {
		return (
			<PageBase
				title="Lender"
				showActions
				typeOfActionButton={ this.state.typeOfActionButton }
				changeActionButton={ this.handleChangeActionButton }
			>
				{this.showPanel()}
			</PageBase>
		);
	}
}

Lender.propTypes = {
	deleteItem: PropTypes.func,
	loadList: PropTypes.func,
	loadFilters: PropTypes.func,
	saveItem: PropTypes.func
};

export default connect(
	null,
	mapDispatchToProps,
)( Lender )
