import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import IconDelete from "@material-ui/icons/Delete";
import IconUpdate from "@material-ui/icons/Create";
import IconSave from "@material-ui/icons/Save";
import IconCancel from "@material-ui/icons/Cancel";
import AddIcon from '@material-ui/icons/Add';
import { IconButton } from "@material-ui/core";
import { DatePicker } from '@material-ui/pickers';
import PageBase from "../../../../commons/components/PageBase";
import TableInfinityLoader, { TableInfinityRow, TableInfinityCell } from "../../../../commons/components/TableInfinityLoader"
import tableStyle from "../../../../commons/components/TableStyle";
import SearchNotData from "../../../../commons/components/SearchNotData";
import DropdownWithSearch from "../../../../commons/components/DropdownWithSearch";
import AmountField from "../../../../commons/components/AmountField";
import DialogYesNo from "../../../../commons/components/DialogYesNo";
import Moment from "moment";
import {
	loadList,
	loadDealerAssociationLists,
	saveItem,
	deleteItem,
	initialFilter
} from "./actions";

const mapDispatchToProps = {
	loadList,
	loadDealerAssociationLists,
	saveItem,
	deleteItem
};

class DiscountRebatePromotion extends React.Component {
	state = {
		isSubmitted: false,
		statusShowDeleteDialog: false,
		listGrid: [],
		editedRebate: [],
		dealerAssociationList: [],
		emptyEntity: {},
		filters: initialFilter,
		itemToDelete: {}
	};

	constructor(props) {
		super(props);
		this.props.loadList(this);
		this.props.loadDealerAssociationLists(this)
	}

	handleSubmit = ( index, originalItem, newItem) => {
		this.props.saveItem(this, index, originalItem, newItem);
	}

	handleDelete = () => {
		this.props.deleteItem(this, this.state.itemToDelete);
	}

	handleShowDeleteDialog = () => {
		this.setState({
			statusShowDeleteDialog: false,
			itemToDelete: {}
		});
	}

	handleEditItem = (index, item) => {
		const { editedRebate, listGrid } = this.state;

		let updateList = [];
		if(!item["source"] && editedRebate[index] !== undefined) {
			for(let i = 0; i < editedRebate.length; i++){
				if(index > i)
					updateList[i] = editedRebate[i];
				if(index < i)
					updateList[i-1] = editedRebate[i];
			};
			this.setState({ 
				listGrid: listGrid.filter((x,i) => i !== index),
				editedRebate: updateList
			});
		} else {
			updateList = [...editedRebate];
			updateList[index] = !!updateList[index] ? undefined : item;
			this.setState({ editedRebate: updateList });
		}
	};

	handleSetIdDelete = (item) => {
		this.setState({
			itemToDelete: item,
			statusShowDeleteDialog: true
		});
	};

	formatPercentage = (value) => {
		return value.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2}); 
	}

	handleUpdateField = (index, field, value) => {
		let updateFieldList = [...this.state.editedRebate];
		updateFieldList[index] = { ...updateFieldList[index], [field]: value};
		this.setState({editedRebate: updateFieldList});
	};

	handleDate(event, index, field, date){
		const temp =  Moment(date, "MM-DD-YYYY", true);
		if(( event !== null && event.isValid()) || temp.isValid() ){
			const result = event.isValid() ? event._d : date;
			this.handleUpdateField(index, field, result);
		}

		if(event === null && date === null)
		this.handleUpdateField(index, field, null);
	};

	handleUpdateValue = ( index, field, event ) => {
		this.handleUpdateField(index, field, event.target.value);
	};

	handleAddNewItem = () => {
		const {listGrid, emptyEntity, editedRebate} = this.state;

		let newList = [emptyEntity];
		editedRebate.forEach((item, index) =>  newList[index+1]= item );

		this.setState({
			listGrid: [...[emptyEntity], ...listGrid],
			editedRebate: newList
		});
	};

	getTextRow = (event, row, parent) => {
		const {headers} = parent.state;
		return (
			<TableInfinityRow event={event} variant="body">
				<TableInfinityCell style={{width:80}}>
					<IconButton style={ tableStyle.cmdButton } tooltip="Edit" onClick={ () => parent.handleEditItem(event.index, row ) } ><IconUpdate/></IconButton>
					<IconButton style={ tableStyle.cmdButton } tooltip="Delete" onClick={ () => parent.handleSetIdDelete( row["source"] ) } ><IconDelete/></IconButton>
				</TableInfinityCell>
				<TableInfinityCell style={{width:180}}>{row["dealerAssociation"]}</TableInfinityCell>
				<TableInfinityCell style={{width:85}}>{row["initialDate"]}</TableInfinityCell>
				<TableInfinityCell style={{width:85}}>{row["finalDate"]}</TableInfinityCell>
				{
					Object.entries(headers).map(([key, value]) =>
						<Fragment key={key}>
							<TableInfinityCell style={{width:80, textAlign:"right"}}>{ parent.formatPercentage(row[value["id"]]) }</TableInfinityCell>
						</Fragment>
					)
				}
			</TableInfinityRow>
		);
	}

	getEditedRow = (event, row, parent) => {
		const {headers, editedRebate} = parent.state;
		let rowEdited = editedRebate[event.index];
		return (
			<TableInfinityRow event={event} variant="body">
				<TableInfinityCell style={{width:80}}>
					<IconButton style={ tableStyle.cmdButton } tooltip="Save" onClick={ () => parent.handleSubmit( event.index, row["source"], rowEdited ) } ><IconSave/></IconButton>
					<IconButton style={ tableStyle.cmdButton } tooltip="Cancel" onClick={ () => parent.handleEditItem(event.index, rowEdited ) } ><IconCancel/></IconButton>
				</TableInfinityCell>
				<TableInfinityCell style={{width:180}}>
					<DropdownWithSearch options={parent.state.dealerAssociationList} values={rowEdited["dealerAssociation"]} onChange={ parent.handleUpdateField.bind(parent, event.index, "dealerAssociation") } marginInput="dense"/>
				</TableInfinityCell>
				<TableInfinityCell style={{width:85}}>
					<DatePicker
						value={rowEdited["initialDate"]}
						onChange={ (ev, date) => this.handleDate( ev, event.index, "initialDate", date)}
						variant="inline"
						format={"MM-DD-YYYY"}
						autoOk
					/>
				</TableInfinityCell>
				<TableInfinityCell style={{width:85}}>
					<DatePicker
						value={rowEdited["finalDate"]}
						onChange={ (ev, date) => this.handleDate( ev, event.index, "finalDate", date)}
						variant="inline"
						format={"MM-DD-YYYY"}
						autoOk
					/>
				</TableInfinityCell>
				{
					Object.entries(headers).map(([key, value]) =>
						<Fragment key={key}>
							<TableInfinityCell style={{width:80, textAlign:"right"}}>
								<AmountField name={ value["id"] } value={ rowEdited[value["id"]] } decimalScale={ 4 } size={"small"} inputStyle={{marginTop:0}} onBlur={ parent.handleUpdateValue.bind(parent, event.index, value["id"]) }/>
							</TableInfinityCell>
						</Fragment>
					)
				}
			</TableInfinityRow>
		);
	} 

	Row( event, row, parent ) {
		const { editedRebate} = parent.state;
		if(!!editedRebate[event.index])
			return parent.getEditedRow(event, row, parent);
		else
			return parent.getTextRow(event, row, parent);
			
	};
	
	Header(event){
		const {headers} = event.parent.state;
		return (
			<TableInfinityRow event={event} variant="head">
				<TableInfinityCell style={{width:80}}><IconButton style={ tableStyle.cmdButton } tooltip="Add new" onClick={ () => event.parent.handleAddNewItem() } ><AddIcon/></IconButton></TableInfinityCell>
				<TableInfinityCell style={{width:180}}>Dealer Association</TableInfinityCell>
				<TableInfinityCell style={{width:85}}>Initial Date</TableInfinityCell>
				<TableInfinityCell style={{width:85}}>Final Date</TableInfinityCell>
				{
					Object.entries(headers).map(([key, value]) =>
						<Fragment key={key}>
							<TableInfinityCell style={{width:80, textAlign:"right"}}>{ value["value"] }</TableInfinityCell>
						</Fragment>
					)
				}
			</TableInfinityRow>
		);
	};

	render() {
		return (
			<PageBase title="Discount Rebate Promotion">
				<SearchNotData
					isSubmitted={this.state.isSubmitted}
					listGrid={this.state.listGrid}
				>
					<TableInfinityLoader
						listGrid={this.state.listGrid}
						drawRow={this.Row}
						drawHeader={this.Header}
						params={this.state.filters}
						parent={this}
						rowSize={30}
						rowSizeHeader={48}
					/>
				</SearchNotData>
				<DialogYesNo
					onHide = { this.handleShowDeleteDialog }
					onConfirmation={ this.handleDelete }
					status={ this.state.statusShowDeleteDialog }
					text={ "Are you sure to delete this item?" }
				/>
			</PageBase>
		);
	}

}

DiscountRebatePromotion.propTypes = {
	deleteItem: PropTypes.func,
	loadDealerAssociationLists: PropTypes.func,
	loadList: PropTypes.func,
	saveItem: PropTypes.func
};

export default connect(
	null,
	mapDispatchToProps,
)( DiscountRebatePromotion );
