import { apiGet, apiDelete } from "../../../../actions";
import Moment from "moment";

const defaultPath = "api/DeleteFundingPackage";

export function loadLenders(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath + "/lenders",
			onSuccess: response => {
				props.setState({lenders: response.data});
			}
		} );
	};
}

export function loadDates( props, lender ) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath + `/dates`,
			params: {lender: lender},
			onSuccess: response => {
				props.setState({dates: response.data });
			}
		} );
	};
}

export function loadList( props, lender, date ) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath + `/loans`,
			params: {
				lender: lender,
				date: Moment( new Date( date ) ).format( "MM-DD-YYYY" )
			},
			onSuccess: response => {
				props.setState({dataList: response.data });
			}
		} );
	};
}

export function deleteItem( props, id, lender, date ) {
	return dispatch => {
		apiDelete( dispatch, {
			url: defaultPath + `/${ id }`,
			onSuccess: () => {
				props.setState({dataList:[]});
				dispatch( loadList( props, lender, date ) );
			}
		} );
	};
}

export function deleteAll( props, lender, date ) {
	return dispatch => {
		apiDelete( dispatch, {
			url: defaultPath + `/${lender}/${ Moment( new Date( date ) ).format( "MM-DD-YYYY" )}`,
			onSuccess: () => {
				dispatch( loadDates(props, lender) );
				props.setState({
					selectedDate: "",
					dataList:[]
				});
			}
		} );
	};
}