import { apiGet, apiPost, apiDelete } from "../../../actions";

export const model = {
	id: 0,
	name: "",
	code: "",
	address1: "",
	address2: "",
	emailPreFundReport: "",
	numbers: [],
	agreementDate: null,
	wirebank: "",
	wireAba: "",
	wireAccountNumber: "",
	wireAttention: "",
	acceptRetail: false,
	productBlocks: [],
	stateBlocks: []
};

const defaultPath = "api/lender";

export function loadList(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath,
			onSuccess: response => {
				props.setState({dataList: response.data});
			}
		} );
	};
}

export function loadFilters(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: "api/CommonFilter/loadSecurityFilters",
			onSuccess: response => {
				props.setState({
					loanTypeList: response.data.loanType.map(o => ({ id: o.id, value: o.value })),
					productList: response.data.product.map(o => ({ id: o.id, value: o.value })),
					statesList:response.data.state.map(o => ({ id: o.stateID, value: o.value }))
				});
			}
		} );
	};
}

export function saveItem( props, item ) {
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath,
			body: item,
			onSuccess: () => {
				props.setState({
					typeOfActionButton: "ADD",
					item: model
				});
				dispatch( loadList(props) );
			}
		} );
	};
}

export function deleteItem( props, obj ) {
	return dispatch => {
		apiDelete( dispatch, {
			url: `${ defaultPath }/${ obj }`,
			body: obj,
			onSuccess: () => {
				dispatch( loadList(props) );
			}
		} );
	};
}
