import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageBase from "../../../../commons/components/PageBase";
import CascadeTwoFilters from "../../../../commons/components/CascadeTwoFilters";
import ViewerFundingPackageResult from "./ViewerFundingPackageResult";
import ViewerFundingPackageDealerAdvanceResult from "./ViewerFundingPackageDealerAdvanceResult";

import {
	loadLenders,
	loadDates,
	downloadExhibitAToAssignmentAndConveyance,
	downloadExhibitAToFormOfSaleNotice,
	downloadCustodialLoanSchedule,
	downloadLenderPreFundReport,
	checkIfAllFormsTemplateExists,
	downloadForms,
	downloadPendingCompletion,
	downloadDealerFundedSchedule,
	downloadFundingSchedule
} from "./actions";

const mapDispatchToProps = {
	loadLenders,
	loadDates,
	downloadExhibitAToAssignmentAndConveyance,
	downloadExhibitAToFormOfSaleNotice,
	downloadCustodialLoanSchedule,
	downloadLenderPreFundReport,
	checkIfAllFormsTemplateExists,
	downloadForms,
	downloadPendingCompletion,
	downloadDealerFundedSchedule,
	downloadFundingSchedule
};

class ViewerFundingPackage extends Component {
	state = {
		lenders: [],
		dates: [],
		hasFormsTemplate: false,
		selectedLender: "",
		selectedDate: ""
	};
	constructor(props) {
		super(props);
		this.props.loadLenders(this);

		let search = this.props.location.search;
		if(search !== "")
		{
			let lender = search.split("=")[1];
			this.state.selectedLender = lender;
			this.props.loadDates(this, lender );
			
			let newDate = new Date()
			let date = newDate.getDate();
			let month = newDate.getMonth() + 1;
			let year = newDate.getFullYear();
			this.state.selectedDate = `${month}/${date}/${year}`;
		}
	}

	handleSelectLender = ( event ) => {
		this.setState({
			selectedLender: event.target.value,
			selectedDate: "",
			hasFormsTemplate: false,
		});
		this.props.loadDates(this, event.target.value );
	};

	handleSelectDate = ( event ) => {
		this.setState({
			[event.target.name]: event.target.value,
			hasFormsTemplate: false,
		});
	};

	handleCheckIfAllFormsTemplateExists = () => {
		this.props.checkIfAllFormsTemplateExists(
			this,
			this.state.selectedLender
		)
	};

	handleDownloadForms = () => {
		this.props.downloadForms(
			this.state.selectedLender,
			this.state.selectedDate
		);
	};

	handleDownloadExhibitAToAssignmentAndConveyance = () => {
		this.props.downloadExhibitAToAssignmentAndConveyance(
			this.state.selectedLender,
			this.state.selectedDate
		);
	};

	handleDownloadExhibitAToFormOfSaleNotice = () => {
		this.props.downloadExhibitAToFormOfSaleNotice(
			this.state.selectedLender,
			this.state.selectedDate
		);
	};

	handleDownloadCustodialLoanSchedule = () => {
		this.props.downloadCustodialLoanSchedule(
			this.state.selectedLender,
			this.state.selectedDate
		);
	};

	handleDownloadLenderPreFundReport = () => {
		this.props.downloadLenderPreFundReport(
			this.state.selectedLender,
			this.state.selectedDate
		);
	};

	handleDownloadPendingCompletion = () => {
		this.props.downloadPendingCompletion(
			this.state.selectedLender
		);
	};

	handleDownloadDealerFundedSchedule = () => {
		this.props.downloadDealerFundedSchedule(
			this.state.selectedLender,
			this.state.selectedDate
		);
	};

	handleDownloadFundingSchedule = () => {
		this.props.downloadFundingSchedule(
			this.state.selectedLender,
			this.state.selectedDate
		);
	};

	renderFilters = () => {
		const { lenders, dates, selectedLender, selectedDate }  = this.state;
		return (
			<CascadeTwoFilters
				dataSourceLenders={ lenders }
				dataSourceDates={ dates }
				handleChangeLender={ this.handleSelectLender }
				handleChangeDate={ this.handleSelectDate }
				valueSelectedLender={ selectedLender }
				valueSelectedDate={ selectedDate }
			/>
		);
	};

	renderResult = () => {
		const { selectedDate, selectedLender } = this.state;

		if( selectedLender.includes("Dealer Advance") )
		{
			return (
				<ViewerFundingPackageDealerAdvanceResult
					lender={ this.state.selectedLender }
					date={ this.state.selectedDate }
					hasFormsTemplate={ this.state.hasFormsTemplate }
					checkIfAllFormsTemplateExists={ this.handleCheckIfAllFormsTemplateExists }
					downloadPendingCompletion={ this.handleDownloadPendingCompletion }
					downloadDealerFundedSchedule={ this.handleDownloadDealerFundedSchedule }
				/>
			);
		}

		if ( selectedDate === "" ) return <div/>;

		if( selectedLender.includes("Lending Point") || selectedLender.includes("Microf") )
		{
			return (
				<ViewerFundingPackageResult
					lender={ this.state.selectedLender }
					date={ this.state.selectedDate }
					hasFormsTemplate={ this.state.hasFormsTemplate }
					checkIfAllFormsTemplateExists={ this.handleCheckIfAllFormsTemplateExists }
					downloadCustodialLoanSchedule={ this.handleDownloadCustodialLoanSchedule }
				/>
			);
		}

		return (
			<ViewerFundingPackageResult
				lender={ this.state.selectedLender }
				date={ this.state.selectedDate }
				hasFormsTemplate={ this.state.hasFormsTemplate }
				handleDownloadAllForms={ this.handleDownloadForms }
				checkIfAllFormsTemplateExists={ this.handleCheckIfAllFormsTemplateExists }
				downloadExhibitAToAssignmentAndConveyance={ this.handleDownloadExhibitAToAssignmentAndConveyance }
				downloadExhibitAtoFormOfSaleNotice={ this.handleDownloadExhibitAToFormOfSaleNotice }
				downloadCustodialLoanSchedule={ this.handleDownloadCustodialLoanSchedule }
				downloadLenderPreFundReport={ this.handleDownloadLenderPreFundReport }
				downloadFundingSchedule={ this.handleDownloadFundingSchedule }
			/>
		);
	};

	render() {
		return (
			<PageBase title="Viewer" showActions={ false }>
				<div>
					{this.renderFilters()}
					<br />
					{this.renderResult()}
				</div>
			</PageBase>
		);
	}
}

ViewerFundingPackage.propTypes = {
	downloadCustodialLoanSchedule: PropTypes.func,
	downloadExhibitAToAssignmentAndConveyance: PropTypes.func,
	downloadExhibitAToFormOfSaleNotice: PropTypes.func,
	downloadForms: PropTypes.func,
	downloadLenderPreFundReport: PropTypes.func,
	downloadDealerFundedSchedule: PropTypes.func,
	downloadPendingCompletion: PropTypes.func,
	loadDates: PropTypes.func,
	loadLenders: PropTypes.func
};

export default connect(
	null,
	mapDispatchToProps,
)( ViewerFundingPackage );
