import React from "react";
import PropTypes from "prop-types";
import NetworkButton from "../../../commons/components/NetworkButton";
import FacilityLimitTable from "./FacilityLimitTable";
import Typography from "@material-ui/core/Typography";
import { ValidatorForm } from "react-material-ui-form-validator";
import SelectValidator from "react-material-ui-form-validator/lib/SelectValidator";
import { Grid } from "@material-ui/core";
import CurrencyAmountField from "../../../commons/components/CurrencyAmountField";
import { renderMenuItems } from "../../../commons/utils";



class StoreFacilityLimit extends React.Component {

	handleUpdateField = ( event ) => {
		this.props.onFieldChanged(event.target.name, event.target.value);
	};

	handleSubmitForm = () => {
		this.props.saveItem(this.props.newItem)
	};

	storeFacilityLimitForm = () => {
		return (
			<ValidatorForm onSubmit={ this.handleSubmitForm } noValidate>
				<Grid container >
					<Grid item xs style={{marginRight: 15}}>
						<SelectValidator
							name="lender"
							label="Select a lender"
							value={ this.props.newItem.lender }
							onChange={ this.handleUpdateField }
							validators={ ["required"] }
							errorMessages={ ["It is mandatory."] }
							fullWidth
						>
							{renderMenuItems( this.props.lenders, false )}
						</SelectValidator>
					</Grid>
					<Grid item xs style={{marginRight: 15}}>
						<CurrencyAmountField
							required
							onChange={ this.handleUpdateField }
							label="Amount"
							name="amount"
							value={ this.props.newItem.amount }
							validators={ ["required"] }
							errorMessages={ ["It's mandatory"] }
						/>
					</Grid>
					<Grid item xs style={{marginRight: 15}}>
						<NetworkButton
							text={ "Submit" }
							primary={ true }
							type="submit"
						/>
					</Grid>
				</Grid>
			</ValidatorForm>
		);
	};

	render() {
		return (
			<div>
				<Typography style={ this.props.styleTypography }>
					{ this.props.title }
				</Typography>
				{ this.storeFacilityLimitForm() }
				<br/>
				<FacilityLimitTable
					list={ this.props.list }
					deleteItem={ this.props.deleteItem }
				/>
			</div>
		);
	};
}

StoreFacilityLimit.propTypes = {
	deleteItem: PropTypes.func,
	lenders: PropTypes.array,
	list: PropTypes.array,
	onFieldChanged: PropTypes.func,
	newItem: PropTypes.object,
	saveItem: PropTypes.func,
	styleTypography: PropTypes.object,
	title: PropTypes.string
};

export default StoreFacilityLimit;