import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageBase from "../../../../commons/components/PageBase";
import NetworkButton from "../../../../commons/components/NetworkButton";
import AlertDialogField from "../../../../commons/components/AlertDialogField"
import { Step, Stepper, StepLabel, Divider, Button } from "@material-ui/core";
import TabStart from "./TabStart";
import TabStartTimer from "./TabStartTimer";
import TabIssues from "./TabIssues";
import TabSelectLender from "./TabSelectLender"
import TabFinish from "./TabFinish";
import TabCreatePackage from "./TabCreatePackage";
import ValidatorForm from "react-form-validator-core/lib/ValidatorForm"

import {
	loadLenders,
	loadList,
	loadImportNLS,
	loadApplicationDetails,
	loadEstimatedTimeImportNLS,
	loadLendersDescription,
	saveFundingPackage,
	downloadFundingScheduleReport,
	downloadLenderPreFundReport,
	downloadValidationReport,
	downloadNACHAFile,
	downloadACHHomeDepotFile
} from "./actions";


const mapStateToProps = ( store ) => ({
	onNetwork: store.network.onNetwork
} );
const mapDispatchToProps = {
	loadLenders,
	loadList,
	loadImportNLS,
	loadApplicationDetails,
	loadEstimatedTimeImportNLS,
	loadLendersDescription,
	saveFundingPackage,
	downloadFundingScheduleReport,
	downloadLenderPreFundReport,
	downloadValidationReport,
	downloadNACHAFile,
	downloadACHHomeDepotFile
};

const steps = ["indexContentStart", "indexContentLender" , "indexContentCreatePacakge", "indexFinishCreatePackage"];

class CreateFundingPackage extends React.Component {
	state = {
		stepIndex: 0,
		dataList: [],
		issues: [],
		messageError: "",
		openAlert: false,
		containsError: false,
		importNLS: true,
		selectLender: "",
		lenders: [],
		initialDate: null,
		finalDate: null,
		estimatedTimeImportNLS: null,
		lendersDescription: [],
		selectedLenderDescription: undefined,
	};

	tabStartTimerRef = React.createRef();

	componentDidMount() {
		this.props.loadEstimatedTimeImportNLS(this);
		this.props.loadLendersDescription(this);
	}

	handleUpdateStep = (step) => {
		this.setState({stepIndex: step});
	};

	handleClearError = () => {
		this.setState({
			messageError: "",
			openAlert: false,
			containsError: false
		});
	};

	handleIncrementStep = (value = 1) => {
		this.handleUpdateStep(this.state.stepIndex + value);
	};

	handleResetPackage = event => {
		event.preventDefault();
		this.handleUpdateStep(0);
	};

	handleSelectLender = event => {
		this.setState({
			selectLender: "",
			initialDate: null,
			finalDate: null
		});
		this.props.loadLenders(this, () => {} );
		event.preventDefault();
		this.handleUpdateStep(2);
	};

	handleCloseAlert = () => {
		this.setState({openAlert: false});
	};

	handleUpdateDate = ( field, date ) => {
		this.setState({
			[field]: date
		});
	};

	createSteppers() {
		const { stepIndex } = this.state;
		return (
			<Stepper activeStep={ stepIndex }>
				<Step>
					<StepLabel>Start</StepLabel>
				</Step>
				<Step>
					<StepLabel>Lender</StepLabel>
				</Step>
				<Step>
					<StepLabel>Create Package</StepLabel>
				</Step>
			</Stepper>
		);
	}

	getContentLender() {
		const { lenders, selectLender, initialDate, finalDate } = this.state;
		return(
			<TabSelectLender  
				lenders={lenders}
				selectLender={selectLender}
				initialDate={initialDate}
				finalDate={finalDate}
				onChangeDate={ this.handleUpdateDate }
				parent={this}
				lendersDescription={this.state.lendersDescription}
				selectedLenderDescription={this.state.selectedLenderDescription}
			/>
		);
	}

	getContentStart() {
		return(
			<React.Fragment>
				<TabStartTimer
					ref={this.tabStartTimerRef}
					estimatedTime={this.state.estimatedTimeImportNLS}
					importNLS={this.state.importNLS}
				/>
				<TabStart
					mainPage={this}
					importNLS={this.state.importNLS}
				/>
			</React.Fragment>
		);
	}

	getContentIssues() {
		const { issues, selectLender, initialDate, finalDate } = this.state;
		return(
			<TabIssues
				issues={issues}
				loadApplicationDetails={this.props.loadApplicationDetails}
				downloadReport={this.props.downloadValidationReport}
				selectLender={selectLender}
				initialDate={initialDate}
				finalDate={finalDate}
			/>
		);
	}
	
	getContentCreatePackage() {
		const { dataList, selectLender, initialDate, finalDate } = this.state;
		return (
			<div>
				<TabCreatePackage
					dataSource={ dataList }
					downloadFundingScheduleReport={	this.props.downloadFundingScheduleReport }
					downloadLenderPreFundReport={ this.props.downloadLenderPreFundReport }
					downloadNachaFile={this.props.downloadNACHAFile}
					downloadACHHomeDepotFile={this.props.downloadACHHomeDepotFile}
					lender={ selectLender }
					initialDate={ initialDate }
					finalDate={ finalDate }
				/>
				<br />
			</div>
		);
	}

	createFinishStepLayout() {
		return(
			<TabFinish 
				handleResetPackage={this.handleResetPackage}
				handleSelectLender={this.handleSelectLender}
				lender={ this.state.selectLender }
				onNetwork={this.props.onNetwork}
			/>
		);
	}

	getContentAlertModal() {
		const { messageError, openAlert } = this.state;
		return (
			<div>
				<AlertDialogField
					message = { messageError.split( "\r\n" ).map( ( item, i ) => <p key={ i }>{item}</p> ) }
					open={ openAlert }
					onRequestClose={ this.handleCloseAlert }
				/>
			</div>
		);
	}

	isIndexContentStart() {
		return steps.indexOf("indexContentStart");
	}

	isIndexContentLender() {
		return steps.indexOf("indexContentLender");
	}

	isIndexContentCreatePackage() {
		return steps.indexOf("indexContentCreatePacakge");
	}

	isIndexFinishCreatePackage() {
		return steps.indexOf("indexFinishCreatePackage");
	}

	getMainForm(children) {
		const { stepIndex } = this.state;
		return (
			<ValidatorForm onSubmit={ this.handleNext } >
				{children}
				<br />
				<Divider />
				{this.createCommandPanel( stepIndex )}
			</ValidatorForm>
		);
	}

	getMainFormWithBackButton(children) {
		const { stepIndex } = this.state;
		return (
			<ValidatorForm onSubmit={ this.handleNext } >
				{children}
				<br />
				<Divider />
				<div style={ { marginTop: 12 } }>
					<Button
						disabled={ stepIndex === 0 }
						onClick={ this.handlePrev }
						style={ { marginRight: 12 } }
					>
						Back
					</Button>
				</div>
			</ValidatorForm>
		);
	}

	getStepContent() {
		const { stepIndex } = this.state;
		switch ( stepIndex ) {
		case this.isIndexContentStart():
			return this.getMainForm( this.getContentStart() );
		case this.isIndexContentLender():
			return this.getMainForm( this.getContentLender() );
		case this.isIndexContentCreatePackage():
			return this.getMainForm( this.getContentCreatePackage() );
		case this.isIndexFinishCreatePackage():
			return this.createFinishedStep();
		default:
			return "You're a long way from home sonny jim!";
		}
	};

	createFinishedStep = () => {
		const { issues } = this.state;
		return issues.length === 0 ? this.createFinishStepLayout() : this.getMainFormWithBackButton( this.getContentIssues() );
	};

	createCommandPanel = stepIndex => {
		return (
			<div style={ { marginTop: 12 } }>
				<Button
					disabled={ stepIndex === 0 }
					 onClick={ this.handlePrev }
					style={ { marginRight: 12 } }
				>
					Back
				</Button>
				<NetworkButton
					text={ stepIndex === this.isIndexContentCreatePackage() ? "Finish" : "Next" }
					disabled={ this.state.containsError }
					primary={ true }
					type="submit"
				/>
			</div>
		);
	};

	handleNext = () => {
		const { stepIndex, containsError, importNLS, selectLender, initialDate, finalDate } = this.state;
		if( containsError )
			return;

		if ( stepIndex === this.isIndexContentCreatePackage() )
			this.props.saveFundingPackage( this, selectLender, initialDate, finalDate );
		if(stepIndex === this.isIndexContentLender())
			this.props.loadList( this, selectLender, initialDate, finalDate );
		if( stepIndex === this.isIndexContentStart()){
			if(importNLS) {
				this.startTabStartTimer();
				this.props.loadImportNLS( this,
					// onSuccess
					() => {
						this.handleIncrementStep(1);
						this.stopTabStartTimerWithSuccess();
					},
					// onError
					(message) => {
						this.stopTabStartTimerWithError(message || "The system was not able to import data from NLS. Please try again later.");
					}
				);
			} else {
				this.props.loadLenders(this, () => {} );
				this.handleIncrementStep(1)
			}
		}
	};

	startTabStartTimer = () => {
		this.tabStartTimerRef.current && this.tabStartTimerRef.current.start();
	}

	stopTabStartTimerWithSuccess = () => {
		this.tabStartTimerRef.current && this.tabStartTimerRef.current.stopWithSuccess();
	}

	stopTabStartTimerWithError = (message) => {
		this.tabStartTimerRef.current && this.tabStartTimerRef.current.stopWithError(message);
	}

	handlePrev = () => {
		this.handleClearError();
		const { stepIndex } = this.state;
		if ( stepIndex > 0 ) {
			this.handleUpdateStep(stepIndex - 1);
		}
	};

	render() {
		const contentStyle = { margin: "0 5px" };
		return (
			<PageBase title="Create Funding Package" showActions={ false } relativePositioning>
				<div>
					{this.createSteppers()}
					<div style={ contentStyle }>
						{ this.getStepContent() }
						{ this.getContentAlertModal() }
					</div>
				</div>
			</PageBase>
		);
	}
}

CreateFundingPackage.propTypes = {
	downloadFundingScheduleReport: PropTypes.func,
	downloadLenderPreFundReport: PropTypes.func,
	downloadValidationReport: PropTypes.func,
	downloadNACHAFile: PropTypes.func,
	loadApplicationDetails: PropTypes.func,
	loadImportNLS: PropTypes.func,
	loadLenders: PropTypes.func,
	loadList: PropTypes.func,
	onNetwork: PropTypes.bool,
	saveFundingPackage: PropTypes.func
};

const screen = connect(
	mapStateToProps,
	mapDispatchToProps,
)( CreateFundingPackage );

export default screen;