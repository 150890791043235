import React from "react";
import PropTypes from "prop-types";
import TableInfinityLoader, { TableInfinityRow, TableInfinityCell } from "../../../../commons/components/TableInfinityLoader"
import DialogYesNo from "../../../../commons/components/DialogYesNo";
import IconDelete from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Moment from "moment";

const styles = {
	cmdButton: {
		padding: 0
	}
};

class AvailableFundsTable extends React.Component {
	state = {
		idTodelete: 0,
		statusShowDeleteDialog: false
	};

	handleShowDeleteDialog = id => {
		this.setState({
			statusShowDeleteDialog: false,
			idTodelete: 0
		});
	};

	handleDeleteItem = () => {
		this.props.deleteItem( this.state.idTodelete );
		this.handleShowDeleteDialog();
	};

	handleSetIdToDelete = (id) => {
		this.setState({
			idTodelete: id,
			statusShowDeleteDialog: true
		});
	};

	currencyTableCell(value="0.0"){
		return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
	}

	Row( event, row, parent ) {
		return (
			<TableInfinityRow event={event} variant="body">
				<TableInfinityCell style={{width:25}}>			
					<IconButton
						style={ styles.cmdButton }
						tooltip="Delete"
							onClick={ () => parent.handleSetIdToDelete( row.id ) }
					>
						<IconDelete />
					</IconButton>
				</TableInfinityCell>
				<TableInfinityCell style={{width:50}}>{row.lenderName}</TableInfinityCell>
				<TableInfinityCell style={{width:70}}>{ Moment( new Date( row.date ) ).format( "MM/DD/YYYY" ) }</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>{ parent.currencyTableCell(row.availableFund)}</TableInfinityCell>
			</TableInfinityRow>
		);
	}

	Header(event){
		return (
			<TableInfinityRow event={event} variant="head">
				<TableInfinityCell style={{width:25}}>{""}</TableInfinityCell>
				<TableInfinityCell style={{width:50}}>Lender</TableInfinityCell>
				<TableInfinityCell style={{width:70}}>Date</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>Available Fund</TableInfinityCell>
			</TableInfinityRow>
		);
	}

	render() {
		return (
			<div style={{ maxHeight: "500px", overflow: "auto"}}>
				<TableInfinityLoader
					listGrid={this.props.list}
					drawRow={this.Row}
					drawHeader={this.Header}
					handleSubmit={this.props.handleSubmit}
					params={this.props.filters}
					parent={this}
					rowSize={24}
				/>
				<DialogYesNo
					onShow = { this.handleShowDeleteDialog }
					onHide = { this.handleShowDeleteDialog }
					onConfirmation={ this.handleDeleteItem }
					status={ this.state.statusShowDeleteDialog }
					text={ "Are you sure to delete this item?" }/>
			</div>
		);
	}
}

AvailableFundsTable.propTypes = {
	deleteItem: PropTypes.func,
	filters: PropTypes.object,
	idDelete: PropTypes.number,
	handleSubmit: PropTypes.func,
	list: PropTypes.array,
	setIDDelete: PropTypes.func,
	showDeleteDialog: PropTypes.func,
	statusShowDeleteDialog: PropTypes.bool
};

export default AvailableFundsTable;
