import { withStyles } from "@material-ui/core";
import React, { Component } from "react";
import PropTypes from "prop-types";

/**
 * @typedef { Object } ScrollSection
 * @prop { HTMLElement } scrollable
 * @prop { number } start
 * @prop { number } end
 * 
 * @typedef { Object } State
 * @prop { ScrollSection[] } sections
 * @prop { number } scrollTop
 * @prop { number } scrollHeight
 * @prop { boolean } needsScrollbar
 * 
 * @typedef { Object } SingleScrollProps
 * @prop { string } name
 */

const scrollbarWidth = 17;
const tolerance = 11;

/** @type { Map<string, React.RefObject<HTMLElement>[]>} */
const scrollableMap = new Map();

const useStyles = {
	container: {
		position: "relative",
		height: "calc(100vh - 160px)",
		overflowY: "hidden",
	},
	scrollbar: {
		position: "absolute",
		top: 0,
		right: 0,
		width: scrollbarWidth,
		overflowY: "auto",
		height: "calc(100vh - 160px)",
	},
};

class _SingleScroll extends Component {
	/** @type { State } */
	state = {
		sections: [],
		scrollTop: 0,
		scrollHeight: 0,
		needsScrollbar: false,
	};

	/** @type { React.RefObject<HTMLDivElement> } */
	containerRef = React.createRef();

	/** @type { React.RefObject<HTMLDivElement> } */
	scrollbarRef = React.createRef();
	ignoreOneInteraction = 0;

	/** @param { SingleScrollProps } props */
	constructor(props) {
		super(props);

		/* eslint-disable */
		/** @type { SingleScrollProps } */
		this.props;
		/* eslint-enable */
	}

	runSetupWhenIdle = () => {
		clearTimeout(this.timer);
		this.timer = setTimeout(()=>{ this.setup(); }, 0);
	}

	componentDidMount() {
		this.runSetupWhenIdle();
		window.addEventListener("resize", this.setup);
	}
	
	componentDidUpdate(prevProps, prevState) {
		if (this.state === prevState) {
			this.runSetupWhenIdle();
		}
		if(prevState.scrollHeight !== this.state.scrollHeight) {
			this.scrollToAndUpdateScrollbar(0);
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.setup);
		clearTimeout(this.timer);
	}

	setup = () => {
		this.validateHasName();
		this.validateScrollables();
		this.calculateScrollSize();
	}

	/** @type { React.WheelEventHandler<HTMLDivElement> } */
	onWheel = (e) => {
		e.stopPropagation();
		if (e.shiftKey || e.altKey || e.ctrlKey) {
			return;
		}

		const scrollables = this.getScrollables();
		if (!scrollables) {
			return;
		}

		for (const scrollable of scrollables) {
			if (scrollable.current === null) {
				continue;
			}
		}

		const maxScrollTop = this.state.scrollHeight - (this.containerRef.current?.clientHeight || 0)
		const nextScrollTop = Math.min(Math.max(this.state.scrollTop + e.deltaY, 0), maxScrollTop);
		
		// this.scrollToAndUpdateScrollbar(nextScrollTop);

		this.setState({ scrollTop: this.scrollToAndUpdateScrollbar(nextScrollTop) });
	}

	/** @type { React.UIEventHandler<HTMLDivElement> } */
	onScroll = (e) => {
		e.stopPropagation();
		const scrollTop = this.scrollbarRef.current?.scrollTop || 0;
		// this.scrollTo(scrollTop);
		this.setState({ scrollTop: this.scrollTo(scrollTop)});
	}

	/** @param { number } scrollTop */
	scrollToAndUpdateScrollbar = (scrollTop) => {
		const a = this.scrollTo(scrollTop);
		if (this.scrollbarRef.current) {
			this.scrollbarRef.current.scrollTop = scrollTop;
		}
		return a;
	}

	getSectionIndex = (scrollTop) => {
		for (let i = 0; i < this.state.sections.length; i++) {
			const section = this.state.sections[i];
			const previousScrollableHeight = i > 0 ? (this.state.sections[i-1].scrollable?.clientHeight || 0) : 0;
			const scrollableHeight = section.scrollable?.clientHeight || 0;
			const start = Math.max(section.start - previousScrollableHeight, 0);
			const end = section.end - scrollableHeight + tolerance;	
			if (start <= scrollTop && scrollTop <= end) {
				return i;
			}
		}
		return -1;
	}

	/** @param { number } scrollTop */
	scrollTo = (scrollTop) => {
		const currentSectionIndex = this.getSectionIndex(this.state.scrollTop);
		const targetSectionIndex = this.getSectionIndex(scrollTop);
		const isNextSection = currentSectionIndex === targetSectionIndex - 1;
		let firstSectionOverflow = 0;
		for (let i = 0; i < this.state.sections.length; i++) {
			const section = this.state.sections[i];
			if (section.scrollable) {
				const previousScrollableHeight = i > 0 ? (this.state.sections[i-1].scrollable?.clientHeight || 0) : 0;
				const scrollableHeight = section.scrollable?.clientHeight || 0;
				const start = Math.max(section.start - previousScrollableHeight, 0);
				const end = section.end - scrollableHeight + tolerance;
				if (start <= scrollTop && scrollTop <= end) {
					if ((isNextSection && i === targetSectionIndex) || this.ignoreOneInteraction > 0) {
						section.scrollable.scrollTop = 0;
						scrollTop = firstSectionOverflow + 6;
						this.ignoreOneInteraction++;
						if(this.ignoreOneInteraction > 1) {
							this.ignoreOneInteraction = 0;
						}
					} else {
						section.scrollable.scrollTop = scrollTop - start;
					}
				} else if (scrollTop < start) {
					section.scrollable.scrollTop = 0;
					this.ignoreOneInteraction = 0;
				} else if (end < scrollTop) {
					firstSectionOverflow = section.end - section.start - scrollableHeight + tolerance;
					section.scrollable.scrollTop = firstSectionOverflow;
				}
			}
		}
		return scrollTop;
	}

	/** @returns { null | React.RefObject<HTMLElement>[] } */
	getScrollables = () => {
		const { name } = this.props;
		if (!name || !scrollableMap.has(name)) {
			return null;
		}

		return scrollableMap.get(name);
	}

	validateScrollables = () => {
		const scrollables = this.getScrollables();
		if (scrollables === undefined) {
			return;
		}
		if (!(scrollables instanceof Array)) {
			throw new Error("'scrollables' prop of <SingleScroll> must be either undefined or an array of RefObject<HTMLElement> objects.");
		}
		for (const scrollable of scrollables) {
			if (!this.isHTMLRefObject(scrollable)) {
				throw new Error("Invalid element received in 'scrollables' prop of <SingleScroll>: all elements must be RefObject<HTMLElement> objects.");
			}
		}
	}

	validateHasName = () => {
		if (typeof this.props.name === "string" && this.props.name.length > 0) {
			return;
		}
		throw new Error("The <SingleScroll> component must have a 'name' prop set.");
	}

	/** @returns {obj is React.RefObject<HTMLElement>} */
	isHTMLRefObject = (obj) => ("current" in obj) && (obj.current === null || obj.current instanceof HTMLElement);

	calculateScrollSize = () => {
		let sectionStart = 0;
		let scrollHeight = 0;

		const scrollables = this.getScrollables();

		/** @type { ScrollSection[] } */
		const sections = [];

		if (!scrollables) {
			if (this.state.scrollHeight > 0 || this.state.needsScrollbar) {
				this.setState({ sections, scrollHeight, needsScrollbar: false });
			}
		}

		for (const scrollable of scrollables) {
			if (scrollable.current === null) {
				continue;
			}
			const scrollableElement = this.getFirstScrollableElement(scrollable.current);
			if (scrollableElement.scrollHeight > this.containerRef.current.clientHeight) {
				const scrollableElementHeight = scrollableElement.clientHeight;
				const scrollSize = scrollableElement.scrollHeight;
				scrollHeight += scrollSize - scrollableElementHeight;
				sections.push({ scrollable: scrollableElement, start: sectionStart, end: sectionStart + scrollSize });
				sectionStart += scrollSize;
			}
		}

		const containerClientHeight = this.containerRef.current?.clientHeight || 0;
		scrollHeight += containerClientHeight;

		const needsScrollbar = scrollHeight > containerClientHeight;

		if (!needsScrollbar) {
			// use case: user zooms out from middle of page.
			// the container scroll may stay a little bit below
			// the top of the element, causing some top margin
			// or padding to disappear
			this.scrollToAndUpdateScrollbar(0);
		}

		if (this.state.scrollHeight !== scrollHeight || this.state.needsScrollbar !== needsScrollbar) {
			this.setState({
				scrollHeight,
				needsScrollbar,
				scrollTop:0
			});
		}

		// If a rerender happens on a child element,
		// the old sections might retain detached DOM
		// elements, since a rerender might favor
		// reattaching a new DOM element over updating
		// the existing one
		this.setState({ sections });
	}

	/** @param { HTMLElement } element */
	getFirstScrollableElement = (element) => {
		/** @type { HTMLElement[] } */
		const toCheck = [];
		const originalElement = element;
		
		// starts off the parent element,
		// and searches down the HTML tree
		// for a scrollable element
		if (element.parentElement != null) {
			element = element.parentElement;
		}

		do {
			if (element.scrollHeight > element.clientHeight) {
				return element;
			}
			for (const child of element.childNodes) {
				if (child.nodeType === Node.ELEMENT_NODE && child instanceof HTMLElement) {
					toCheck.push(child);
				}
			}
			element = toCheck.shift();
		} while (element != null && element.childNodes.length > 0);

		if (element == null) {
			return originalElement;
		}

		return element;
	}

	render() {
		const { classes, children } = this.props;
		const style = {
			paddingRight: this.state.needsScrollbar ? scrollbarWidth : 0,
		};
		return (
			<div ref={this.containerRef} className={classes.container} onWheel={this.onWheel} style={style}>
				<div style={{ maxHeight: "100%", overflowY: "hidden" }}>
					{children}
					{
						this.state.needsScrollbar ? (
							<div ref={this.scrollbarRef} className={classes.scrollbar} onScroll={this.onScroll}>
								<div style={{ height: this.state.scrollHeight, width: 1, visibility: "hidden" }}></div>
							</div>
						) : null
					}
				</div>
			</div>
		);
	}
}

/**
 * @typedef { Object } ScrollableProps
 * @prop { string } for
 */

class Scrollable extends Component {
	/** @type { React.RefObject<HTMLDivElement> } */
	containerRef = React.createRef();

	/** @param { ScrollableProps } */
	constructor(props) {
		super(props);

		/* eslint-disable */
		/** @type { ScrollableProps } */
		this.props;
		/* eslint-enable */
	}

	componentDidMount() {
		this.addScrollable();
	}

	componentDidUpdate() {
		this.addScrollable();
	}

	componentWillUnmount() {
		this.removeScrollable();
	}

	hasPropFor = () => typeof this.props.for === "string" && this.props.for.length > 0;

	addScrollable = () => {
		if (!this.hasPropFor()) {
			return;
		}

		const scrollables = scrollableMap.get(this.props.for) || [];
		if (scrollables.includes(this.containerRef)) {
			return;
		}
		scrollables.push(this.containerRef);
		scrollableMap.set(this.props.for, scrollables);
	}

	removeScrollable = () => {
		if (!this.hasPropFor()) {
			return;
		}

		const scrollables = scrollableMap.get(this.props.for) || [];
		const index = scrollables.indexOf(this.containerRef);
		if (index < 0) {
			return;
		}
		scrollables.splice(index, 1);
		scrollableMap.set(this.props.for, scrollables);
	}

	render() {
		return (
			<div ref={this.containerRef}>{this.props.children}</div>
		);
	}
}

_SingleScroll.propTypes = {
	name: PropTypes.string,
};

_SingleScroll.Scrollable = Scrollable;

/** @type { typeof _SingleScroll } */
const SingleScroll = withStyles( useStyles )( _SingleScroll );

export default SingleScroll;
