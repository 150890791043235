import { apiGet, apiPost } from "../../../../actions";

export const initialFilter = {
	startIndex: 0,
	stopIndex: 100
}

const defaultPath = "api/FundingManagement/DiscountRebate";

export function loadList(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath + "/discountRebatePromotionDealer",
			onSuccess: response => {
				const emptyEntity = {
					dealerName: "",
					...response.data.headers.reduce( (acc, ap) => {	acc[ap.id] = 0.0; return acc; }, {})
				};

				const rows = response.data.data.map( (row) =>
					({
						dealerName: row.dealerName,
						...row.loanTypeValues.reduce( (acc, ap) => {
							acc[ap.loanType] = ap.value;
							return acc;
						}, {}),
						source: row
					})
				);

				props.setState({
					isSubmitted: true,
					listGrid: rows,
					headers: response.data.headers,
					emptyEntity: emptyEntity
				});
			}
		} );
	};
}

export function loadDealerLists(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: "api/CommonFilter/loadDealerNames",
			onSuccess: response => {
				props.setState({dealerList: response.data});
			}
		} );
	};
}

export function saveItem( props, index, source, newItem ) {
	const loanTypeValues = Object.keys(newItem)
		.filter( key => key.indexOf("loanType") !== -1)
		.map(key => {
			return { loanType: key, value: newItem[key] }
	});
	let dealerName = null;

	if(source !== undefined) {
		dealerName = source.dealerName;

		source.dealerName = newItem.dealerName;
		source.loanTypeValues = source.loanTypeValues.map( app => {
			const appTemp = loanTypeValues.filter( t => t.loanType === app.loanType)[0];
			app.value = appTemp.value;
			return app;
		});
	} 
	else{
		source = {
			dealerName: newItem.dealerName,
			loanTypeValues: loanTypeValues
		}
	}
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath + "/discountRebatePromotionDealer",
			body: { source, dealerName },
			onSuccess: () => {
				let temp = [...props.editedRebate];
				temp[index] = null;
				props.setState({
					listGrid:[],
					editedRebate: temp
				});
				dispatch( loadList(props) );
			}
		} );
	};
}

export function deleteItem( props, item ) {
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath+ "/discountRebatePromotionDealer/delete",
			body: item,
			message: "Deleted!",
			onSuccess: () => {
				props.setState({
					listGrid: [],
					itemToDelete: {},
					statusShowDeleteDialog: false
				});
				dispatch( loadList(props) );
			}
		} );
	};
}