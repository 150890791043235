import React, { Component } from "react";
import PropTypes from "prop-types";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class TabStart extends Component {

	handleChange = event => {
		this.props.mainPage.setState({importNLS: event.target.checked });
	};

	render() {
		return (
			<div style={ {fontSize: 15, textAlign: "center"} }>
				<p>
					This step-by-step wizard will help you create a new funding package.<br />
					We are loading the loan information and getting the lenders with
					Contract Validated at this moment.
				</p>
				<div style={ { maxWidth: 300, textAlign: "left", paddingLeft: 30, margin: "auto" } }>
					<FormControlLabel
						control={
							<Switch
							checked={ this.props.importNLS }
							onChange={ this.handleChange }
							value="importNLS"
							color={ "primary" }
							/>
						}
						label="Should I import data from NLS?"
					/>
				</div>
				<p>
					Please wait the button NEXT be available.
				</p>
			</div>
		);
    }
};

TabStart.propTypes = {
	importNLS: PropTypes.bool,
	mainPage: PropTypes.object
};

export default TabStart;