import { createTheme } from "@material-ui/core/styles";

const themeDefault = createTheme( {
	fontFamily: "Roboto, sans-serif",
	palette: {
		primary: { main:"#66BB6A" },
		secondary: { main: "#DCEDC8" }
	},
	typography: {
		fontFamily: [
			"Roboto", 
			"sans-serif"
			],
		fontWeight: 500,
	},
	overrides: {
		MuiTableCell: {
			root:{
				fontSize:"11px !important",
				checkbox: {
					padding: 0
				}
			},
			head: {
				backgroundColor: "#ffffff"
			},
			body: {
				padding: 0,
				fontSize: "0.85rem"
			}
		},
		MuiTableRow: {
			root:{
				height: 15,
				'&:nth-of-type(odd)': {
					backgroundColor: "#DCEDC8"
				},
				'&:nth-of-type(even)': {
					backgroundColor: "#F8F8F8"
				}
			},
			head: {
				height: 15
			}
		},
		MuiPaper:{
			root: {
				padding: 15
			}
		},
		MuiCheckbox:{
			root:{
				padding: 0
			}
		},
		MuiFormControl:{
			root:{
				fontSize: 16,
				lineHeight: "24px",
				height: 72
			}
		},
		MuiListItem:{
			"root": {
				"&$selected": {
					"backgroundColor": "#66bb6a !important"
				}
			}
		},
		MuiTableSortLabel: {
			icon: {
				marginLeft:0,
				marginRight:0
			}
		}
	}
} );

export default themeDefault;
