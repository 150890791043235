import { apiGet, apiPost } from "../../../actions";
import Querystring from "querystring-es3";
import { createActionClearURL } from "../../../layout/actions";
import { store } from "../../../store";

export const Auth_Login_ActionTypes = {
	UpdateError: "Auth.Login.UpdateError",
	UpdateField: "Auth.Login.UpdateField",
	Login: "Auth.Login.Login",
	Logout: "Auth.Login.Logout",
	SetCompanyProfileCompleted: "Auth.Login.SetCompanyProfileCompleted",
	SetUsername: "Auth.Login.SetUsername",
	ClearToken: "Auth.Login.ClearToken",
	SetMenus: "Auth.Login.SetMenus",
	SetUserID: "Auth.Login.SetUserID",
	SetIsLoggedIn: "Auth.Login.SetIsLoggedIn",
	CheckPasswordIsValid: "Auth.Login.CheckPasswordIsValid"
};

export function postLogin( username, password, callback ) {
	return dispatch => {
		dispatch( createActionUpdateError( "" ) );
		apiPost( dispatch, {
			url: "/connect/token",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: Querystring.stringify( {
				username,
				password,
				client_id: "WebUI",
				grant_type: "password",
				scope: "WebAPI"
			} ),
			onSuccess: response => {
				response.data.timestamp = new Date().getTime();
				localStorage.setItem( "loginInfo", JSON.stringify({timestamp: [response.data.timestamp], expires_in: [response.data.expires_in] }) );
				dispatch( createActionLogin( response.data ) );
				callback( response.data );
			},
			onError: error => {
				dispatch( createActionUpdateError(error) );
			},
			message: ""
		} );
	};
}

export function postRequestResetPassword( email ){
	return dispatch => {
		dispatch( createActionUpdateError( "" ) );
		apiPost( dispatch, {
			url: "api/useraccount/RequestResetPassword",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: Querystring.stringify( {
				email
			} )
		} );
	};
}

export function postResetPassword( newPassword, hashCode ){
	return dispatch => {
		dispatch( createActionUpdateError( "" ) );
		apiPost( dispatch, {
			url: "api/useraccount/ResetPassword",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: Querystring.stringify( {
				newPassword,
				hashCode
			} ),
			onSuccess: () => {
				dispatch( createActionClearURL( true ) );
				dispatch( doLogout() );
			}
		} );
	};
}

export function postChangePassword( user, oldPassword, newPassword ){
	return dispatch => {
		dispatch( createActionUpdateError( "" ) );
		apiPost( dispatch, {
			url: "api/useraccount/ChangedPassword",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: Querystring.stringify( {
				user,
				oldPassword,
				newPassword
			} ),
			onSuccess: () => {
				dispatch( createActionClearURL( true ) );
				dispatch( doLogout() );
			}
		} );
	};
}

export function getAccess(userId) {
	return dispatch => {
		dispatch( createActionUpdateError( "" ) );
		apiGet( dispatch, {
			url: `api/access/menus?userId=${userId}`,
			onSuccess: (response) => {
				localStorage.setItem("menus", JSON.stringify(response.data));
				dispatch( createActionSetMenus( response.data ) );
			}
		} );
	};
}

export function getMenus() {
	var menus = store.getState().auth.login.menus;
	if (menus == null) {
		return [];
	}
	return menus;
}

export function getUserIdByToken(onSuccess) {
	return dispatch => {
		dispatch( createActionUpdateError( "" ) );
		apiGet( dispatch, {
			url: `api/token/getUserID`,
			onSuccess: ( response ) => {
				localStorage.setItem( "userID", JSON.stringify(response.data) );
				dispatch( createActionSetUserID( response.data ) );
				onSuccess?.( response.data )
			}
		} );
	};
}

export function checkIsLoggedIn(onSuccess, onError) {
	return dispatch => {
		dispatch( createActionUpdateError( "" ) );
		apiGet( dispatch, {
			url: `api/token/isLoggedIn`,
			onSuccess: (response) => {
				dispatch( createActionSetIsLoggedIn(response.data) );
			},
		} );
	};
};

export function checkUserHasPermission( url ) {
	const parsed = getMenus();
	return checkHasPermission( parsed, url );
};

function checkHasPermission( menus, url ) {
	var index = 0;
	var hasPermission = false;
	for(index = 0; index < menus.length; index++)
	{
		if(hasPermission)
			return true;
		var menu = menus[index];
		if(menu.url === "")
			hasPermission = checkHasPermission(menu.subAccess, url);
		else  if( menu.url === url.substring(1) )
			return true;
	}
	return hasPermission;
};

export function doLogout() {
	localStorage.removeItem( "loginInfo" );
	return dispatch => {
		dispatch( createActionUpdateError( "" ) );
		apiGet( dispatch, {
			url: `api/token/logout`,
			onSuccess: () => {
				dispatch( createActionLogout() );
			},
		} );
	};
}

export function checkPasswordIsValid() {
	return dispatch => {
		apiGet( dispatch, {
			url: `api/token/checkPasswordIsValid`,
			onSuccess: (response) => {
				dispatch( createActionCheckPasswordIsValid(response.data) );
			},
		} );
	};
}

export function clearToken() {
	localStorage.removeItem( "loginInfo" );
	return createActionClearToken();
}

export function createActionLogin( user ) {
	return {
		type: Auth_Login_ActionTypes.Login,
		payload: user
	};
}

function createActionUpdateError( error ) {
	return {
		type: Auth_Login_ActionTypes.UpdateError,
		payload: error
	};
}

function createActionLogout() {
	return {
		type: Auth_Login_ActionTypes.Logout
	};
}

function createActionClearToken() {
	return {
		type: Auth_Login_ActionTypes.ClearToken
	};
}

export function createActionSetCompanyProfileCompleted() {
	return {
		type: Auth_Login_ActionTypes.SetCompanyProfileCompleted
	};
}

export function createActionSetUsername( username ) {
	return {
		type: Auth_Login_ActionTypes.SetUsername,
		payload: username
	};
}

export function createActionSetMenus( menus ) {
	return {
		type: Auth_Login_ActionTypes.SetMenus,
		payload: menus
	};
}

export function createActionSetUserID( userID ) {
	return {
		type: Auth_Login_ActionTypes.SetUserID,
		payload: userID
	};
}

export function createActionSetIsLoggedIn( isLoggedIn ) {
	return {
		type: Auth_Login_ActionTypes.SetIsLoggedIn,
		payload: isLoggedIn
	};
}

export function createActionCheckPasswordIsValid( checkPasswordIsValid ) {
	return {
		type: Auth_Login_ActionTypes.CheckPasswordIsValid,
		payload: checkPasswordIsValid
	};
}
