import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
	deleteFacilityLimit,
	deleteFacilityLimitMonthly,
	deleteFacilityLimitPurchase,
	saveFacilityLimit,
	saveFacilityLimitMonthly,
	saveFacilityLimitPurchase,
	downloadFacilityLimitReportExcel,
	loadLenders,
	loadFacilityLimitList,
	loadFacilityLimitListMonthly,
	loadFacilityLimitListPurchase,
	facilityLimitModel,
	facilityLimitPurchaseModel
} from "./actions";

// import FacilityLimitReport from "../facilityLimit/FacilityLimitReport";
import StoreFacilityLimit from "../facilityLimit/StoreFacilityLimit";
import StoreFacilityLimitPurchase from "../facilityLimit/StoreFacilityLimitPurchase";
import { Paper, Divider } from "@material-ui/core";

const mapDispatchToProps = {
	deleteFacilityLimit,
	deleteFacilityLimitMonthly,
	deleteFacilityLimitPurchase,
	saveFacilityLimit,
	saveFacilityLimitMonthly,
	saveFacilityLimitPurchase,
	downloadFacilityLimitReportExcel,
	loadLenders,
	loadFacilityLimitList,
	loadFacilityLimitListMonthly,
	loadFacilityLimitListPurchase
};

const styleTypography = {lineHeight: "56px", fontSize: 20};

class FacilityLimit extends Component {
	state = {
		facilityLimit: facilityLimitModel,
		facilityLimitMonthly: facilityLimitModel,
		facilityLimitPurchase: facilityLimitPurchaseModel,
		lenderList: [],
		facilityLimitList: [],
		facilityLimitMonthlyList: [],
		facilityLimitPurchaseList: []
	};

	constructor(props) {
		super(props);
		this.props.loadLenders(this);
		this.props.loadFacilityLimitList(this);
		this.props.loadFacilityLimitListMonthly(this);
		this.props.loadFacilityLimitListPurchase(this);
	}

	handleUpdate = (model, field, value) => {
		this.setState({
			[model]:{
				...this.state[model],
				[field]: value
			}
		});
	};

	handleSaveFacilityLimit = (item) => {this.props.saveFacilityLimit(this,item)};
	handleSaveFacilityLimitMonthly = (item) => {this.props.saveFacilityLimitMonthly(this,item)};
	handleSaveFacilityLimitPurchase = (item) => {this.props.saveFacilityLimitPurchase(this,item)};

	handleDeleteFacilityLimit = (id) => {this.props.deleteFacilityLimit(this,id)};
	handleDeleteFacilityLimitMonthly = (id) => {this.props.deleteFacilityLimitMonthly(this,id)};
	handleDeleteFacilityLimitPurchase = (id) => {this.props.deleteFacilityLimitPurchase(this,id)};

	render() {
		const {lenderList, facilityLimit, facilityLimitList, facilityLimitMonthly, facilityLimitMonthlyList, facilityLimitPurchase, facilityLimitPurchaseList} = this.state
		return (
			<Paper style={ { padding: 15 } }>
				{/* <FacilityLimitReport
					title={ "Facility Limit" }
					downloadFacilityLimitReportExcel={ this.props.downloadFacilityLimitReportExcel }
					lenders={ lenderList }
					styleTypography= { styleTypography }
				/>
				<br/>
				<Divider /> */}
				<StoreFacilityLimit
					title={ "Store Facility Limit" }
					newItem={facilityLimit}
					list={ facilityLimitList }
					deleteItem={ this.handleDeleteFacilityLimit.bind(this) }
					saveItem={ this.handleSaveFacilityLimit.bind(this) }
					onFieldChanged={this.handleUpdate.bind(this.handleUpdate, "facilityLimit")}
					styleTypography= { styleTypography }
					lenders={ lenderList }
				/>
				<br/>
				<Divider />
				<StoreFacilityLimit
					title={ "Store Facility Limit Monthly" }
					newItem={facilityLimitMonthly}
					list={facilityLimitMonthlyList }
					deleteItem={ this.handleDeleteFacilityLimitMonthly.bind(this) }
					saveItem={ this.handleSaveFacilityLimitMonthly.bind(this) }
					onFieldChanged={this.handleUpdate.bind(this.handleUpdate, "facilityLimitMonthly")}
					styleTypography= { styleTypography }
					lenders={ lenderList }
				/>
				<br/>
				<Divider />
				<StoreFacilityLimitPurchase
					title={ "Store Facility Limit Purchase" }
					newItem={facilityLimitPurchase}
					list={ facilityLimitPurchaseList }
					deleteItem={ this.handleDeleteFacilityLimitPurchase.bind(this) }
					saveItem={ this.handleSaveFacilityLimitPurchase.bind(this) }
					onFieldChanged={this.handleUpdate.bind(this.handleUpdate, "facilityLimitPurchase")}
					styleTypography= { styleTypography }
					lenders={ lenderList }
				/>
			</Paper>

		);
	}
}

FacilityLimit.propTypes = {
	deleteFacilityLimit: PropTypes.func,
	deleteFacilityLimitMonthly: PropTypes.func,
	deleteFacilityLimitPurchase: PropTypes.func,
	downloadFacilityLimitReportExcel: PropTypes.func,
	loadFacilityLimitList: PropTypes.func,
	loadFacilityLimitListMonthly: PropTypes.func,
	loadFacilityLimitListPurchase: PropTypes.func,
	loadLenders: PropTypes.func,
	saveFacilityLimit: PropTypes.func,
	saveFacilityLimitMonthly: PropTypes.func,
	saveFacilityLimitPurchase: PropTypes.func
};

export default connect(
	null,
	mapDispatchToProps,
)( FacilityLimit );
