import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageBase from "../../../commons/components/PageBase";
import StylizedModal from "../../../commons/components/StylizedModal";
import SearchNotData from "../../../commons/components/SearchNotData";
import FundingReportTable from "./FundingReportTable"
import FundingReportFilters from "./FundingReportFilters";
import {
	submit,
	sendEmail,
	downloadDealerReport,
	downloadDealerAssociationReport,
	initialFilters
 } from "./actions";

const mapDispatchToProps = {
	submit,
	sendEmail,
	downloadDealerReport,
	downloadDealerAssociationReport,
};

class FundingReport extends React.Component {
	constructor( props ) {
		super( props );
		this.state = {
			listGrid: [],
			selectSendDealer: [],
			isSubmitted: false,
			validationError: false,
			filters: initialFilters,
			currentFilters: initialFilters
		};
	}

	componentWillUnmount() {
		this.handleReset();
	}

	handleDate = (field, date) => {
		this.setState({
			filters: {
				...this.state.filters,
				[field]:date
			}
		});
	};

	handleCheckbox = ( event, checked ) => {
		this.setState({
			filters: {
				...this.state.filters,
				[event.target.name]:checked,
			}
		});
	};

	handleSubmit = () => {
		let filters = this.getFilters();

		if(filters.fromFundingDate === null || filters.toFundingDate === null) {
			this.setState({validationError: true});
			return;
		}

		this.props.submit(this, filters);
		this.setState({
			currentFilters: this.state.filters
		});
	};

	handleSubmitInfinity = (params) => {
		this.props.submit(this, params);
	};

	handleCloseAlert = () => {
		this.setState({validationError: false});
	}

	handleReset = () => {
		this.setState({
			listGrid: [],
			selectSendDealer: [],
			isSubmitted: false,
			validationError: false,
			filters: initialFilters,
			currentFilters: initialFilters
		});
	};

	handleSendEmail = () => {
		const ignoreSent = this.state.filters.ignoreSents;
		this.props.sendEmail(this.state.selectSendDealer, ignoreSent);
	};

	handleDownloadDealerReport = (dealerName, dealerNumber, date, ignoreSent) => {
		this.props.downloadDealerReport(dealerName, dealerNumber, date, ignoreSent);
	};

	handleDownloadDealerAssociationReport = (dealerAssociation, date, ignoreSent) => {
		this.props.downloadDealerAssociationReport(dealerAssociation, date, ignoreSent);
	};

	handleSelectSendDealer = (event, index, data) => {
		let updateList = this.state.selectSendDealer;

		if(updateList[index] !== undefined)
			updateList[index] = undefined;
		else
			updateList[index] = {...data, selected: true};

		this.setState({ selectSendDealer: updateList });
	};

	getFilters = () => this.state.filters;
	getFiltersForDetails = () => this.state.currentFilters;

	render() {
		const { listGrid, isSubmitted, validationError, selectSendDealer } = this.state;
		return (
			<PageBase title="Funding Report" >
				<div>
					<StylizedModal
						openModal={validationError}
						onClose={this.handleCloseAlert.bind(this)}
					>
						You must select a date.
					</StylizedModal>
					<FundingReportFilters
						filters={this.getFilters()}
						listGrid={listGrid}
						selectSendDealer={selectSendDealer}
						handleDate={this.handleDate.bind(this)}
						handleCheckbox={this.handleCheckbox}
						handleSubmit={this.handleSubmit}
						handleReset={this.handleReset}
						handleSendEmail={this.handleSendEmail}
					/>
					<div style={{marginTop:15}}>
						<SearchNotData
							isSubmitted={isSubmitted}
							listGrid={listGrid}
						>
							<FundingReportTable
								listGrid={listGrid}
								selectSendDealer={selectSendDealer}
								filters={this.getFiltersForDetails()}
								handleSubmit={this.handleSubmitInfinity.bind(this)}
								handleDownloadDealerAssociationReport={this.handleDownloadDealerAssociationReport}
								handleDownloadDealerReport={this.handleDownloadDealerReport}
								handleSelectSendDealer={this.handleSelectSendDealer}
							/>
						</SearchNotData>
					</div>
				</div>
			</PageBase>
		);
	}
}

FundingReport.propTypes = {
	filters: PropTypes.object,
	FundingReport: PropTypes.object,
	listGrid: PropTypes.object
};

export default connect(
	null,
	mapDispatchToProps,
)(FundingReport);
