import { apiGet } from "../../../actions";
import Moment from "moment";
import fileDownload from "js-file-download";
export const initialFilters = {
	reportDate: new Date(),
	lenderCode: "BL5"
};

const defaultPath = "api/FundingManagement/ConcentrationLimitsReport"

export function generateReport(reportDate, lenderCode) {
	var date =  Moment(new Date(reportDate))
	return dispatch => {
			apiGet(dispatch , {
				responseType: "blob",
				url: defaultPath+ "/DownloadReport",
				params: {
					reportDate:date.format( "MM-DD-YYYY" ),
					lender: lenderCode
				},
				onSuccess: response => {
					fileDownload(response.data,  `ConcentrationLimits${lenderCode}${date.format("YYYYMMDDHHmmss")}.docx`);
				},

			});
	};
}