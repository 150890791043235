import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageBase from "../../../commons/components/PageBase";
import { Grid, Paper, Icon, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import MultiSelectChip from "../../../commons/components/MultiSelectChip";
import NetworkButton from "../../../commons/components/NetworkButton";
import { KeyboardDatePicker } from '@material-ui/pickers';
import DealerTrackingSummaryTable from "./DealerTrackingSummaryTable"
import Moment from "moment";
import {
	loadFilters,
	loadRMSGroup,
	submit,
	downloadExcelReport,
	initialFilters
 } from "./actions";
import SingleScroll from "../../../commons/components/SingleScroll";

const mapDispatchToProps = {
	loadFilters,
	loadRMSGroup,
	submit,
	downloadExcelReport
};

const singleScrollName = "DealerTrackingSummaryReport";

class DealerTrackingSummaryReport extends React.Component {
	constructor(props) {
		super(props);
		this.props.loadFilters(this);
		this.props.loadRMSGroup(this);
		this.state = {
			isSubmitted: false,
			filters: initialFilters,
			currentFilters: initialFilters,
			rmsGroup:[],
			listGrid: [],
			sfcSalespersonList:[]
		};
    }

	handleMultiSelectFilter = (field, value) => {
		const { rmsGroup } = this.state;
		value = (value instanceof Array && value.includes("All")) ? ["All"] : value;
		this.setState({
			filters: {
				...this.state.filters,
				[field]:value,
				selectedRMSGroup: value.length === rmsGroup.length && value.every(function(value) { return rmsGroup.includes(value)})
			}
		});
	};

	handleDate(field, event, date){
		const temp =  Moment(date, "MM-DD-YYYY", true);
		if(( event !== null && event.isValid()) || temp.isValid() ){
			const result = event.isValid() ? event._d : date;
			this.setState({ filters: { ...this.state.filters, [field]:result } });
		}

		if(event === null && date === null)
			this.setState({ filters: { ...this.state.filters, [field]:null } });
	};

	handleChange(event, value){
		const { filters, rmsGroup } = this.state;

		this.setState({
			filters: {
				...this.state.filters,
				[event.target.name]:value,
				sfcSalesperson: value ? [...rmsGroup, ...filters.sfcSalesperson] : filters.sfcSalesperson.filter(o => rmsGroup.find(group => group.id === o.id) === undefined)
			}
		});
	};

	handleSubmit = () => {
		this.props.submit(this, this.state.filters);
		this.setState({
			currentFilters: this.state.filters
		});
	};

	handleSubmitInfinity = (params) => {
		this.props.submit(this, params);
	};

	handleReset = () => {
		this.setState({
			filters: initialFilters,
			currentFilters: initialFilters,
			listGrid: [],
			isSubmitted: false
		});
	};

	handleExcel = () => {
		this.props.downloadExcelReport(this.getFiltersForDetails());
	};

	getFilters = () => this.state.filters;
	getFiltersForDetails = () => this.state.currentFilters;
	getOptionLabel = item => typeof item === "string" ? item : item.value;

	createFilters = () => {
		const { filters, sfcSalespersonList } = this.state;
		return (
			<Grid container style={{marginTop:10}}>
				<Grid container item xs={12} spacing={3}>
					<Grid item xs={2}>
						<FormControlLabel
							control={<Checkbox checked={filters.selectedRMSGroup} onChange={this.handleChange.bind(this)} name="selectedRMSGroup" color="primary" />}
							label="Selected RMS Group"
							style={{marginTop:35, marginLeft:10}}
							disabled={ this.props.onNetwork }
						/>
					</Grid>
					<Grid item xs={5}>
						<MultiSelectChip name={"sfcSalesperson"} label={"SFC Salesperson"} options={sfcSalespersonList} values={filters.sfcSalesperson} onChange={ this.handleMultiSelectFilter.bind(this, "sfcSalesperson") } getOptionLabel={this.getOptionLabel}/>
					</Grid>
					<Grid item xs={2}>
						<KeyboardDatePicker
							id="submitDate"
							variant="inline"
							format="MM-DD-YYYY"
							label="Submit Date"
							InputAdornmentProps={{ position: "start" }}
							onChange={(event, date) => this.handleDate( "submitDate", event, date)}
							value={filters.submitDate}
							autoOk
							style={{marginTop:16}}
						/>
					</Grid>
					<Grid container item xs={3} style={{marginTop:16}}>
						<NetworkButton text={ "Submit" } primary={ true } style={{margin: 10, height: 36}} onClick={ this.handleSubmit } />
						<NetworkButton text={ "Reset" }  primary={ true } style={{margin: 10, height: 36}} onClick={ this.handleReset } />
						<NetworkButton text={ "Excel" }  primary={ true } style={{margin: 10, height: 36}} onClick={ this.handleExcel } />
					</Grid>
				</Grid>
			</Grid>
		);
    }

	createTable = () => {
		const { isSubmitted, listGrid } = this.state;
		return(
			isSubmitted ?
			listGrid.length === 0 ?
			<Paper>
				<div style={{textAlign:"center"}}><Icon color="secondary" style={{fontSize:"15rem"}}>search</Icon></div>
				<Typography variant="h3" component="h2" align="center" color="secondary">No Data Found</Typography>
			</Paper>:
			<DealerTrackingSummaryTable
				listGrid={listGrid}
				filters={this.getFiltersForDetails()}
				handleSubmit={this.handleSubmitInfinity.bind(this)}
				forSingleScroll={singleScrollName}
			/>
			:
			<div/>
		);
	};

    render() {
		return (
			<PageBase title="Dealer Tracking Summary Report" >
				<SingleScroll name={singleScrollName}>
					<SingleScroll.Scrollable for={singleScrollName}>
						{this.createFilters()}
						<div style={{height:20}} />
						{this.createTable()}
					</SingleScroll.Scrollable>
				</SingleScroll>
			</PageBase>
		);
	}
}

DealerTrackingSummaryReport.propTypes = {
	downloadExcelReport: PropTypes.func,
	loadFilters: PropTypes.func,
	loadRMSGroup: PropTypes.func,
	onNetwork: PropTypes.bool,
	submit: PropTypes.func
};

export default connect(
	null,
	mapDispatchToProps,
)( DealerTrackingSummaryReport );