import { apiGet } from "../../../actions";
import fileDownload from "js-file-download";
import Moment from "moment";

export const initialFilters = {
	applicationNumber: "",
	fromSubmitDate: null,
	toSubmitDate: null
};

const defaultPath = "api/FundingManagement/AdverseActionLetter";

export function submit( props, filters ) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath,
			params: {
				applicationNumber: filters.applicationNumber,
				fromSubmitDate: filters.fromSubmitDate !== null ? Moment( new Date( filters.fromSubmitDate ) ).format( "MM-DD-YYYY" ) : null,
				toSubmitDate: filters.toSubmitDate !== null ? Moment( new Date( filters.toSubmitDate ) ).format( "MM-DD-YYYY" ) : null
			},
			onSuccess: response => {
				props.setState({
					commercialDownload: response.data["existCommercialType"],
					noCommercialDownload: response.data["existNoCommercialType"],
				});
			}
		} );
	};
}

export function downloadReport( filters, isCommercial ) {
	return dispatch => {
		apiGet( dispatch, {
			responseType: "blob",
			url: defaultPath + `/downloadReport`,
			params: {
				applicationNumber: filters.applicationNumber,
				fromSubmitDate: filters.fromSubmitDate !== null ? Moment( new Date( filters.fromSubmitDate ) ).format( "MM-DD-YYYY" ) : null,
				toSubmitDate: filters.toSubmitDate !== null ? Moment( new Date( filters.toSubmitDate ) ).format( "MM-DD-YYYY" ) : null,
				isCommercial: isCommercial
			},
			onSuccess: response => {
				fileDownload( response.data, "AdverseActionLetter.pdf" );
			}
		} );
	};
}