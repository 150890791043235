import { Layout_ActionTypes as ActionTypes } from "./actions";

const initialState = {
	consentCheckbox: false,
	navDrawerOpen: true,
	clearURL: false
};

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
	case ActionTypes.ConsentCheckbox_Update:
		return { ...state, consentCheckbox: action.payload };
	case ActionTypes.NavBar_Open:
		return { ...state, navDrawerOpen: action.payload };
	case ActionTypes.ClearURL:
		return { ...state, clearURL: action.payload };
	default:
		return { ...state };
	}
}
