import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, withStyles } from "@material-ui/core/styles";
import { FixedSizeList } from "react-window";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";

const materialTheme = theme => createTheme({
	...theme,
	overrides: {
		MuiAutocomplete: {
			option:{
				padding: "0px !important",
				marginLeft: 10
			}
		},
		MuiFormControl: {
			marginDense:{ marginTop: "0px", marginBottom: "0px" }
		}
	}
});


class DropdownWithSearch extends React.Component {

	state = {
		renderValues: [],
		renderOptions: [],
		allOptions: []
	};

	static getDerivedStateFromProps(props, state) {
		const { renderOptions, allOptions } = state;
		const first =  renderOptions.length === 0 && allOptions.length === 0 && props.options !== 0;
		const initialList = first ? props.options : allOptions;

		return {
			renderValues: props.values,
			renderOptions: DropdownWithSearch.mapList(initialList),
			allOptions: initialList
		};
	}

	static mapList(list){
		if(list.length === 0) {
			return [];
		}
		const cleanedOptions = list.filter(o => o !== "");
		return ["", ...cleanedOptions];
	};

	handleChange = (event, values) => {
		this.props.onChange(values === null ? "" : values);
	}

	renderGroup = params => [
		<ListSubheader key={params.key} component="div">
			{params.key}
		</ListSubheader>,
		params.children
	  ];

	render(){
		const { name, label, marginInput } = this.props;
		const { renderValues, renderOptions } = this.state;

		return (
			<ThemeProvider theme={materialTheme}>
				<Autocomplete
					id={name}
					disableListWrap
					disableClearable
					ListboxComponent={ListboxComponent}
					options={renderOptions}
					value={renderValues}
					onChange={ this.handleChange }
					renderGroup={this.renderGroup}
					renderInput={params => (
					<TextField
						{...params}
						variant="standard"
						label={label}
						placeholder={"Select " + (label || "")}
						margin={marginInput}
						fullWidth
					/>
					)}
				/>
			</ThemeProvider>
		);
	};
};

DropdownWithSearch.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func,
	options: PropTypes.array,
	theme: PropTypes.object,
	values: PropTypes.string,
	marginInput: PropTypes.string
};

export default withStyles( {withTheme : true})(DropdownWithSearch);

function renderRow(props) {
	const { data, index, style } = props;

	return React.cloneElement(data[index], {
		style: {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			display: 'block',
			...style,
		},
	});
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
	const outerProps = React.useContext(OuterElementContext);

	return <div ref={ref} {...props} {...outerProps} />;
});

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
	const { children, ...other } = props;
	const itemData = React.Children.toArray(children);
	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
	const itemCount = itemData.length;
	const itemSize = smUp ? 36 : 48;

	return (
		<div ref={ref}>
			<OuterElementContext.Provider value={other}>
				<FixedSizeList
					style={{ padding: 0, height: Math.min(8, itemCount) * itemSize, maxHeight: 'auto', overflowX: 'hidden' }}
					itemData={itemData}
					height={250}
					outerElementType={OuterElementType}
					innerElementType="ul"
					itemSize={itemSize}
					overscanCount={5}
					itemCount={itemCount}
				>
					{renderRow}
				</FixedSizeList>
			</OuterElementContext.Provider>
		</div>
	);
});

ListboxComponent.propTypes = {
	children: PropTypes.node
};