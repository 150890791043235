export function getRequestedPage( routing ) {
	//todo: see if need to treat the path to extract the correct page name
	const pathname = routing.pathname;
	return {
		name: pathname.startsWith( "/" ) ? pathname.substring( 1 ) : pathname,
		query: routing.query
	};
}

/**
 * Changes the value of a parameter on the query string
 *
 * @param {*} router react-router
 * @param {string} queryParam the param of the query string to change
 * @param {string} newValue the desired new value for the query paramater
 */
export function queryParamChange( router, queryParam, newValue ) {
	const currentLocation = router.getCurrentLocation();
	const newLocation = {
		...currentLocation,
		query: { ...currentLocation.query, [ queryParam ]: newValue }
	};
	const routerNewLocation = router.createLocation( newLocation );
	return (
		routerNewLocation.pathname +
		( routerNewLocation.search ? routerNewLocation.search : "" )
	);
}

export class QueryParamListener {
	constructor( router, onChange ) {
		const routerListener = page => {
			if ( page.action !== "POP" ) {
				return;
			}
			onChange( page );
		};
		this.cancelHandle = router.listen( routerListener );
	}
	dispose() {
		this.cancelHandle();
	}
}
