import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageBase, {
	TypeOfActionButton
} from "../../../commons/components/PageBase";
import UserAccountForm from "../userAccount/UserAccountForm";
import UserAccountTable from "../userAccount/UserAccountTable";
import {
	loadList,
	loadRoleList,
	loadLenderList,
	deleteItem,
	saveItem,
	model
} from "./actions";

const mapDispatchToProps = {
	loadList,
	loadRoleList,
	loadLenderList,
	deleteItem,
	saveItem
};

class UserAccount extends React.Component {
	state = {
		item: model,
		dataList: [],
		lenderList: [],
		roleList: [],
		typeOfActionButton: "ADD",
	};
	componentDidMount() {
		this.props.loadList(this);
		this.props.loadRoleList(this);
		this.props.loadLenderList(this);
	}

	handleChangeActionButton = () => {
		if ( TypeOfActionButton.ADD === this.state.typeOfActionButton) {
			this.setState({typeOfActionButton: TypeOfActionButton.CANCEL});
		} else {
			this.cleanFields();
		}
	};

	cleanFields = () => {
		this.setState({
			typeOfActionButton: TypeOfActionButton.ADD,
			item: model
		});
	};

	handleLoadItem = item => {
		this.setState({
			typeOfActionButton: TypeOfActionButton.CANCEL,
			item: item
		});
	};

	handleSaveItem = (item) => {
		this.props.saveItem(this, item);
	};

	handleDeleteItem = (id) => {
		this.props.deleteItem(this, id);
	};

	handleUpdateField = (field, value) => {
		this.setState({
			item:{
				...this.state.item,
				[field]: value
			}
		});
	};

	showPanel = () => {
		const { typeOfActionButton, dataList, lenderList, roleList, item } = this.state;
		if (TypeOfActionButton.ADD === typeOfActionButton)
			return (
				<UserAccountTable
					list={ dataList }
					loadItem={ this.handleLoadItem }
					deleteItem={ this.handleDeleteItem.bind(this) } 
				/>
			);
		else {
			return (
				<UserAccountForm
					item={ item }
					roleList={ roleList }
					lenderList={ lenderList }
					changeInput={ this.handleUpdateField }
					saveItem={ this.handleSaveItem.bind(this) }
					cleanForm={ this.cleanFields }
					isNewItem={ this.isNewItem() }
				/>
			);
		}
	};

	isNewItem = () => this.state.item.ID === 0;

	render() {
		return (
			<PageBase
				title="User Account"
				showActions={ true }
				typeOfActionButton={ this.state.typeOfActionButton }
				changeActionButton={ this.handleChangeActionButton }
			>
				{this.showPanel()}
			</PageBase>
		);
	}
}

UserAccount.propTypes = {
	deleteItem: PropTypes.func,
	loadList: PropTypes.func,
	loadLenderList: PropTypes.func,
	loadRoleList: PropTypes.func,
	saveItem: PropTypes.func,
};

export default connect(
	null,
	mapDispatchToProps,
)( UserAccount )
