import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

const AlertDialogField = ( { message, open, onRequestClose } ) => ( 
	<Dialog 
		title="Alert"
		actions={ 
			<Button
				label="Cancel"
				primary={ true }
				onClick={ onRequestClose }
			/>
		}
		modal={ false }
		open={ open }
		onRequestClose={ onRequestClose }
	>
		{ message }
	</Dialog>
);

AlertDialogField.propTypes = {
	message: PropTypes.array,
	onRequestClose: PropTypes.func,
	open: PropTypes.bool
};

export default AlertDialogField