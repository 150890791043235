import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";

const styles = {
	margin: {
		margin: 5
	},
	stylePaperResult: {
		margin: 2,
		padding: 10
	},
	whiteColorStyle: { 
		color: "#FFFFFF"
	}
};

class ViewerFundingPackageDealerAdvanceResult extends Component {
	componentDidMount() {
		if (this.props.lender !== "") {
			this.props.checkIfAllFormsTemplateExists();
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.lender !== prevProps.lender || this.props.date !== prevProps.date) {
			this.props.checkIfAllFormsTemplateExists();
		}
	}

	render() {
		const icon = <Icon
						className="fa fa-download material-icons"
						style={ { verticalAlign: "middle", color: "white", marginRight: 5 } }
					/>
		const disableFormsButton = this.props.hasFormsTemplate || false;
		return (
			<div>
				<Paper style={ styles.stylePaperResult } elevation={1}>
					<Button
						variant="contained"
						style={ styles.margin }
						onClick={ this.props.downloadPendingCompletion }
						color={ "primary" }
						type="submit"
						disabled={ disableFormsButton }
						title={ disableFormsButton ? "This lender does not have a template registered. Please provide it to the support team." : undefined }
					>
						{icon}
						<Typography style={ styles.whiteColorStyle }>
							Pending Completion
						</Typography>
					</Button>
					<Button
						variant="contained"
						style={ styles.margin }
						onClick={ this.props.downloadDealerFundedSchedule }
						color={ "primary" }
						type="submit"
					>
						{icon}
						<Typography style={ styles.whiteColorStyle }>
							Dealer Funded Schedule
						</Typography>
					</Button>
				</Paper>
			</div>
		);
	}
}

ViewerFundingPackageDealerAdvanceResult.propTypes = {
	downloadPendingCompletion: PropTypes.func,
	downloadDealerFundedSchedule: PropTypes.func
};

export default ViewerFundingPackageDealerAdvanceResult;
