import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageBase, {
	TypeOfActionButton
} from "../../../commons/components/PageBase";
import SearchNotData from "../../../commons/components/SearchNotData"
import LenderLoanTypeFrom from "../lenderLoanType/LenderLoanTypeForm";
import LenderLoanTypeTable from "../lenderLoanType/LenderLoanTypeTable";
import LenderLoanTypeFilters from "../lenderLoanType/LenderLoanTypeFilters";
import {
	loadList,
	deleteItem,
	saveItem,
	loadAllOptionsForFilters,
	model,
	initialFilters
} from "./actions";

const mapDispatchToProps = {
	loadList,
	deleteItem,
	saveItem,
	loadAllOptionsForFilters
};

class LenderLoanType extends React.Component {
	state = {
		item: model,
		isSubmitted: false,
		dataList: [],
		typeOfActionButton: "ADD",
		lenderOptions: ["All"],
		loanTypeOptions: ["All"],
		filters: initialFilters
	};
	
	constructor(props) {
		super(props);
		this.props.loadList( this, initialFilters );
		this.props.loadAllOptionsForFilters(this);
	}

	handleChangeActionButton = () => {
		if ( TypeOfActionButton.ADD === this.state.typeOfActionButton) {
			this.setState({typeOfActionButton: TypeOfActionButton.CANCEL});
		} else {
			this.handleCleanFields()
		}
	};

	handleLoadItem = item => {
		this.setState({
			item: item,
			typeOfActionButton: TypeOfActionButton.CANCEL
		});
	};

	handleSelectFilter = ( event ) => {
		this.setState({ 
			filters: {
				...this.state.filters,
				[event.target.name]: event.target.value
			}
		});
	};

	handleChangeItem = ( event ) => {
		this.setState({ 
			item: {
				...this.state.item,
				[event.target.name]: event.target.value
			}
		});
	};

	handleCleanFields = () => {
		this.setState({
			typeOfActionButton: TypeOfActionButton.ADD,
			item: model
		});
	};

	handleSubmitForm = () => {
		this.props.loadList(this, this.state.filters );
	};

	handleSubmitInfinity = (params) => {
		this.props.loadList(this, params );
	};

	handleSaveItem = (item) => {
		this.props.saveItem(this, item, this.state.filters)
	}

	handleDeleteItem = (id) => {
		this.props.deleteItem(this, id, this.state.filters);
	}

	showPanel = () => {
		const { typeOfActionButton, dataList, isSubmitted, lenderOptions, loanTypeOptions, item, filters } = this.state;

		if (
			TypeOfActionButton.ADD === typeOfActionButton
		) {
			return (
				<SearchNotData
					isSubmitted={isSubmitted}
					listGrid={dataList}
				>
					<LenderLoanTypeTable
						list={ dataList }
						filters={ filters }
						loadItem={ this.handleLoadItem }
						handleSubmit={ this.handleSubmitInfinity.bind(this) }
						deleteItem={ this.handleDeleteItem.bind(this) }
					/>
				</SearchNotData>
			);
		}

		return (
			<LenderLoanTypeFrom
				lenderOptions={ lenderOptions }
				loanTypeOptions={ loanTypeOptions }
				item={ item }
				saveItem={ this.handleSaveItem.bind(this) }
				cleanForm={ this.handleCleanFields }
				changeInput={this.handleChangeItem.bind(this)}
			/>
		);
	};

	render() {
		const { lenderOptions, loanTypeOptions, typeOfActionButton, filters } = this.state;
		return (
			<PageBase
				title="Loan Type"
				showActions
				typeOfActionButton={ typeOfActionButton }
				changeActionButton={ this.handleChangeActionButton }
			>
				<div>
					<LenderLoanTypeFilters
						showFilters={TypeOfActionButton.ADD === typeOfActionButton}
						filters={filters}
						handleSelectFilter={this.handleSelectFilter}
						handleSubmitForm={this.handleSubmitForm}
						lenderOptions={lenderOptions}
						loanTypeOptions={loanTypeOptions}
					/>
					<br/>
					{this.showPanel()}
				</div>
			</PageBase>
		);
	}
}

LenderLoanType.propTypes = {
	deleteItem: PropTypes.func,
	loadAllOptionsForFilters: PropTypes.func,
	loadList: PropTypes.func,
	saveItem: PropTypes.func
};

export default connect(
	null,
	mapDispatchToProps,
)( LenderLoanType );