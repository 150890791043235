import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageBase from "../../../../commons/components/PageBase";
import CascadeTwoFilters from "../../../../commons/components/CascadeTwoFilters";
import DeleteFundingPackageResult from "./DeleteFundingPackageResult";

import {
	deleteAll,
	loadLenders,
	loadDates,
	loadList,
	deleteItem
} from "./actions";

const mapDispatchToProps = {
	deleteAll,
	loadLenders,
	loadDates,
	loadList,
	deleteItem
};

class DeleteFundingPackage extends Component {
	state = {
		dataList: [],
		lenders: [],
		dates: [],
		selectedLender: "",
		selectedDate: ""
	};

	constructor(props) {
		super(props);
		this.props.loadLenders(this);
	}

	componentWillUnmount() {
		this.clearFields();
	}

	clearFields = () => {
		this.setState({
			selectedLender: "",
			selectedDate: ""
		});
	};

	handleSelectLender = ( event ) => {
		let date = event.target.value;
		this.setState({selectedLender: date});
		this.props.loadDates( this, date );
	};

	handleSelectDate = ( event ) => {
		let date = event.target.value;
		this.setState({selectedDate: date});
		this.props.loadList(this, this.state.selectedLender, date);
	};

	handleDeleteAll = () => {
		let { selectedLender, selectedDate } = this.state;
		this.props.deleteAll(this, selectedLender, selectedDate);
	};

	renderFilters = () => {
		return (
			<CascadeTwoFilters
				dataSourceLenders={ this.state.lenders }
				dataSourceDates={ this.state.dates }
				handleChangeLender={ this.handleSelectLender.bind(this) }
				handleChangeDate={ this.handleSelectDate.bind(this) }
				valueSelectedLender={ this.state.selectedLender }
				valueSelectedDate={ this.state.selectedDate }
			/>
		);
	};

	handleDeleteItem = ( id ) => {
		this.props.deleteItem( this, id, this.state.selectedLender, this.state.selectedDate);
	};

	renderResults = () => {
		if ( this.state.dataList.length === 0 ) return "";
		return (
			<DeleteFundingPackageResult
				onDeleteItem={ this.handleDeleteItem }
				onDeleteAll={ this.handleDeleteAll }
				dataSource={ this.state.dataList }
			/>
		);
	};

	render() {
		return (
			<PageBase title="Delete" showActions={ false }>
				<div>
					{this.renderFilters()}
					<br />
					{this.renderResults()}
				</div>
			</PageBase>
		);
	}
}

DeleteFundingPackage.propTypes = {
	deleteAll: PropTypes.func,
	deleteItem: PropTypes.func,
	loadDates: PropTypes.func,
	loadLenders: PropTypes.func,
	loadList: PropTypes.func
};

export default connect(
	null,
	mapDispatchToProps,
)( DeleteFundingPackage );
