import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from '@material-ui/core';
import TableInfinityLoader, { TableInfinityRow, TableInfinityCell } from "./TableInfinityLoader"

const currencyWidth = 80;
const numberWidth = 54;

class OriginationSummaryTable extends React.Component {

	numberWithCommasAndLink(value, type, column) {
		if(value > 0 && this.props.loadDetails !== undefined)
			return (<Link
					component="button"
					style={{color:"blue", fontSize:11}}
					onClick={this.props.loadDetails.bind(this, type, column)}
				>
					{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
				</Link>);
		return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	currencyTableCell(value="0.0"){
		return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
	}

	percenatageTableCell(value="0.0"){
		return value.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2}); 
	}

	roundTableCell(value=0){
		return value.toFixed(2);
	}

	showAllColumns = () => {
		const { filters } = this.props;
		return filters.fromLoanFundingDate === null;
	}

	Row( event, row, parent ) {

		const fragment = parent.showAllColumns() ?
			[
				<TableInfinityCell key={"Application"} style={{width:numberWidth,textAlign:"right"}}>{parent.numberWithCommasAndLink(row["number"], row["type"], "# Application")}</TableInfinityCell>,
				<TableInfinityCell key={"Rejected"} style={{width:numberWidth,textAlign:"right"}}>{parent.numberWithCommasAndLink(row["rejected"], row["type"], "# Rejected")}</TableInfinityCell>,
				<TableInfinityCell key={"Approved"} style={{width:numberWidth,textAlign:"right"}}>{parent.numberWithCommasAndLink(row["approved"], row["type"], "# Approved")}</TableInfinityCell>,
				<TableInfinityCell key={"Funded"} style={{width:numberWidth,textAlign:"right"}}>{parent.numberWithCommasAndLink(row["funded"], row["type"], "# Funded")}</TableInfinityCell>,
				<TableInfinityCell key={"approvedPercentage"} style={{width:numberWidth,textAlign:"right"}}>{parent.percenatageTableCell(row["approvedPercentage"])}</TableInfinityCell>,
				<TableInfinityCell key={"fundedPercentage"} style={{width:numberWidth,textAlign:"right"}}>{parent.percenatageTableCell(row["fundedPercentage"])}</TableInfinityCell>,
				<TableInfinityCell key={"rejectedAmount"} style={{width:currencyWidth,textAlign:"right"}}>{parent.currencyTableCell(row["rejectedAmount"])}</TableInfinityCell>,
				<TableInfinityCell key={"originalBalance"} style={{width:currencyWidth,textAlign:"right"}}>{parent.currencyTableCell(row["originalBalance"])}</TableInfinityCell>,
				<TableInfinityCell key={"percentageTotalOriginalAmount"} style={{width:numberWidth,textAlign:"right"}}>{parent.percenatageTableCell(row["percentageTotalOriginalAmount"])}</TableInfinityCell>
			]
			:
			[
				<TableInfinityCell key={"funded"} style={{width:numberWidth,textAlign:"right"}}>{parent.numberWithCommasAndLink(row["funded"], row["type"], "# Funded")}</TableInfinityCell>,
			]

		return (
			<TableInfinityRow event={event} variant="body">
				<TableInfinityCell style={{width:120}}>{row["type"]}</TableInfinityCell>
				{fragment}
				<TableInfinityCell style={{width:currencyWidth,textAlign:"right"}}>{parent.currencyTableCell(row["grossFunded"])}</TableInfinityCell>
				<TableInfinityCell style={{width:currencyWidth,textAlign:"right"}}>{parent.currencyTableCell(row["averageTicket"])}</TableInfinityCell>
				<TableInfinityCell style={{width:numberWidth,textAlign:"right"}}>{parent.percenatageTableCell(row["percentageTotalFundedAmount"])}</TableInfinityCell>
				<TableInfinityCell style={{width:currencyWidth,textAlign:"right"}}>{parent.currencyTableCell(row["netFundedDealer"])}</TableInfinityCell>
				<TableInfinityCell style={{width:numberWidth,textAlign:"right"}}>{parent.roundTableCell(row["averageAdvancedRate"])}</TableInfinityCell>
				<TableInfinityCell style={{width:numberWidth,textAlign:"right"}}>{parent.roundTableCell(row["averageFICO"])}</TableInfinityCell>
				<TableInfinityCell style={{width:numberWidth,textAlign:"right"}}>{parent.roundTableCell(row["averageWAC"])}</TableInfinityCell>
				<TableInfinityCell style={{width:numberWidth,textAlign:"right"}}>{parent.roundTableCell(row["averageWAT"])}</TableInfinityCell>
			</TableInfinityRow>
		);
	}
	
	Header(event){
		const fragment = event.parent.showAllColumns() ?
			[
				<TableInfinityCell key={"Application"} style={{width:numberWidth,textAlign:"right"}}># Application</TableInfinityCell>,
				<TableInfinityCell key={"Rejected"} style={{width:numberWidth,textAlign:"right"}}># Rejected</TableInfinityCell>,
				<TableInfinityCell key={"Approved"} style={{width:numberWidth,textAlign:"right"}}># Approved</TableInfinityCell>,
				<TableInfinityCell key={"Funded"} style={{width:numberWidth,textAlign:"right"}}># Funded</TableInfinityCell>,
				<TableInfinityCell key={"approvedPercentage"} style={{width:numberWidth,textAlign:"right"}}>Approved %</TableInfinityCell>,
				<TableInfinityCell key={"fundedPercentage"} style={{width:numberWidth,textAlign:"right"}}>Funded %</TableInfinityCell>,
				<TableInfinityCell key={"rejectedAmount"} style={{width:currencyWidth,textAlign:"right"}}>Rejected Amt.</TableInfinityCell>,
				<TableInfinityCell key={"originalBalance"} style={{width:currencyWidth,textAlign:"right"}}>Orig. Amt</TableInfinityCell>,
				<TableInfinityCell key={"percentageTotalOriginalAmount"} style={{width:numberWidth,textAlign:"right"}}>% Total Orig. Amt.</TableInfinityCell>
			]
			:
			[
				<TableInfinityCell key={"Funded"} style={{width:numberWidth,textAlign:"right"}}># Funded</TableInfinityCell>,
			]
		return (
			<TableInfinityRow event={event} variant="head">
				<TableInfinityCell style={{width:120}}>Type</TableInfinityCell>
				{fragment}
				<TableInfinityCell style={{width:currencyWidth,textAlign:"right"}}>Gross Funded</TableInfinityCell>
				<TableInfinityCell style={{width:currencyWidth,textAlign:"right"}}>Average Funded Ticket</TableInfinityCell>
				<TableInfinityCell style={{width:numberWidth,textAlign:"right"}}>% Total Funded Amt.</TableInfinityCell>
				<TableInfinityCell style={{width:currencyWidth,textAlign:"right"}}>Net Funded Dealer</TableInfinityCell>
				<TableInfinityCell style={{width:numberWidth,textAlign:"right"}}>Adv. Rate</TableInfinityCell>
				<TableInfinityCell style={{width:numberWidth,textAlign:"right"}}>Avg. FICO</TableInfinityCell>
				<TableInfinityCell style={{width:numberWidth,textAlign:"right"}}>Avg. WAC</TableInfinityCell>
				<TableInfinityCell style={{width:numberWidth,textAlign:"right"}}>Avg. WAT</TableInfinityCell>
			</TableInfinityRow>
		);
	}
	
	render () {
		return(
			<TableInfinityLoader
				listGrid={this.props.listGrid}
				drawRow={this.Row}
				drawHeader={this.Header}
				params={this.props.filters}
				parent={this}
				handleSubmit={this.props.handleSubmit}
				rowSizeHeader={35}
				forSingleScroll={this.props.forSingleScroll}
			/>
		);
	}
}

OriginationSummaryTable.propTypes = {
	filters: PropTypes.object,
	listGrid: PropTypes.array,
	loadDetails: PropTypes.func,
	handleSubmit: PropTypes.func,
	forSingleScroll: PropTypes.string,
};

export default connect(
	null,
)( OriginationSummaryTable )
