import { applyMiddleware, createStore } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware  } from "connected-react-router";

import { createLogger } from "redux-logger";
import thunk from "redux-thunk";

import reducer from "./reducers.js";

import { createActionLogin, doLogout} from "./pages/auth/login/actions";
import { createActionNetworkLoadMessage } from "./actions";

export const isEnvModeProduction = process.env.NODE_ENV === "production";

const middlewares = [];

if ( !isEnvModeProduction ) {
	middlewares.push( createLogger({}) );
}

export const history = createBrowserHistory();
const middleware = applyMiddleware( ...middlewares, routerMiddleware(history), thunk);
const store = createStore( 
		reducer,
		middleware
	);
export { store };

const isUserDifferent = ( storageUser, stateUser ) => {
	return storageUser.userID !== stateUser.userID;
};

//this function keeps user data synchronized; data from localStorage takes priority
const updateUserIfNecessary = () => {
	try {
		const storageUser = getLoginInfo();
		const stateUser = getUserState();
		checkIfNeedReLogin( storageUser, stateUser );
		if ( storageUser && isUserDifferent( storageUser, stateUser ) ) {
			store.dispatch( createActionLogin( storageUser ) );
		}
	} catch ( err ) {
		store.dispatch( doLogout() );
	}
};

const loginInfoIsValid = storageUser => {
	return (
		storageUser !== null &&
		isTokenInTime( storageUser.timestamp, storageUser.expires_in )
	);
};

const getLoginInfo = () => {
	const storageUser = localStorage.getItem( "loginInfo" )
		? JSON.parse( localStorage.getItem( "loginInfo" ) )
		: null;
	if (storageUser != null) {
		storageUser.menus = JSON.parse(localStorage.getItem("menus"));
		storageUser.userID = JSON.parse(localStorage.getItem("userID"));
	}
	return storageUser;
};

const getUserState = () => {
	const stateUser = {
		userID: store.getState().auth.login.userID,
		menus: store.getState().auth.login.menus,
	};
	return stateUser;
};

const isTokenInTime = ( timeStamp, expiresTime ) => {
	const milliseconds = 1000;
	const resultTimestamp = ( new Date().getTime() - timeStamp ) / milliseconds;
	return resultTimestamp < expiresTime;
};

updateUserIfNecessary();
const timeToRecheck = 5000;
setInterval( () => {
	updateUserIfNecessary();
}, timeToRecheck );

function checkIfNeedReLogin( storageUser ) {
	if ( !loginInfoIsValid( storageUser ) && storageUser) {
		const needsUpdatePassword = store.getState().auth.login.isPasswordValid;
		if(needsUpdatePassword)
		{
			store.dispatch( createActionNetworkLoadMessage( "Your password expires." ) );
			history.push( '/resetpassword' );
			store.dispatch( doLogout() );
		}
		else
		{
			store.dispatch( createActionNetworkLoadMessage( "Your session expires." ) );
			store.dispatch( doLogout() );
		}
	}
}