import React from "react";
import PropTypes from "prop-types";
import { Grid } from '@material-ui/core';
import NetworkButton from "../../../commons/components/NetworkButton";
import DatePickerRange from "../../../commons/components/DatePickerRange";

class SearchFundingManagementFilters extends React.Component {

	handleDate= (field, date) => {
		this.props.changeFilter(field, date);
	}

	render(){
		const { filters } = this.props;
		return (
			<Grid container style={{marginTop:10}}>
				<Grid container item xs={12} spacing={3}>
					<Grid item xs={12} md={6} lg={4}>
						<DatePickerRange
							label={"Application Submit Date"}
							fromDate={filters.fromSubmitDate} 
							fromDateId="fromSubmitDate" 
							toDate={filters.toSubmitDate} 
							toDateId="toSubmitDate" 
							onDatesChange={this.handleDate}
						/>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<Grid item xs>
							<DatePickerRange
								label={"Loan Funding Date"}
								fromDate={filters.fromLoanFundingDate} 
								fromDateId="fromLoanFundingDate" 
								toDate={filters.toLoanFundingDate} 
								toDateId="toLoanFundingDate" 
								onDatesChange={this.handleDate}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<Grid item xs>
							<DatePickerRange
								label={"Dealer Advanced Date"}
								fromDate={filters.fromDealerAdvancedDate} 
								fromDateId="fromDealerAdvancedDate" 
								toDate={filters.toDealerAdvancedDate} 
								toDateId="toDealerAdvancedDate" 
								onDatesChange={this.handleDate}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<Grid item xs>
							<DatePickerRange
								label={"Skeps Date"}
								fromDate={filters.fromSkepsDate} 
								fromDateId="fromSkepsDate" 
								toDate={filters.toSkepsDate} 
								toDateId="toSkepsDate" 
								onDatesChange={this.handleDate}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<Grid item xs>
							<DatePickerRange
								label={"Deposit Date"}
								fromDate={filters.fromDepositDate} 
								fromDateId="fromDepositDate" 
								toDate={filters.toDepositDate} 
								toDateId="toDepositDate" 
								onDatesChange={this.handleDate}
							/>
						</Grid>
					</Grid>
					<Grid container item xs={12} md={6} lg={4} alignItems="flex-end">
						<NetworkButton text={ "Reset" }  primary={ true } style={{margin: 10, height: 36}} onClick={ this.props.handleReset } />
						<NetworkButton text={ "Excel" }  primary={ true } style={{margin: 10, height: 36}} onClick={ this.props.handleExcel } />
					</Grid>
				</Grid>
			</Grid>
		);
	};
};

SearchFundingManagementFilters.propTypes = {
	changeFilter: PropTypes.func,
	filters: PropTypes.object,
	handleReset: PropTypes.func,
	handleExcel: PropTypes.func,
};

export default SearchFundingManagementFilters;
