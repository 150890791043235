import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const mapStateToProps = ( store ) => ({
	onNetwork: store.network.onNetwork
})

const whiteColorStyle = { color: "#FFFFFF" };

class NetworkButton extends React.Component {
	render() {
		const {
			primary,
			secondary,
			onNetwork,
			text,
			disabled,
			onClick,
			type,
			style,
			icon
		} = this.props;

		let color = "default";
		if(primary)
			color="primary";
		if(secondary)
			color="secondary";
		return (
			<Button
				variant="contained" 
				disabled={ onNetwork || disabled }
				style={ style }
				type={ type }
				onClick={ onClick }
				color= { color }
			>
				{ icon }
				<Typography style={ whiteColorStyle }>
					{ text }
				</Typography>
			</Button>
		);
	}
}

NetworkButton.propTypes = {
	disabled: PropTypes.bool,
	icon: PropTypes.object,
	onClick: PropTypes.func,
	onNetwork: PropTypes.bool,
	primary: PropTypes.bool,
	secondary: PropTypes.bool,
	style: PropTypes.object,
	text: PropTypes.string,
	type: PropTypes.string
};

export default connect(
	mapStateToProps,
)( NetworkButton )
