import { Auth_Login_ActionTypes as ActionTypes } from "./actions";

const initialState = {
	form: {
		loginName: undefined,
		password: undefined,
		confirmPassword: undefined,
		oldPassword: undefined
	},
	loggedUser: {},
	userID: null,
	isLoggedIn: false,
	menus: [],
	isPasswordValid: false,
};

const reducers = {};
export default function reducer( state = initialState, action ) {
	const reducer = reducers[ action.type ];
	return reducer ? reducer( state, action ) : state;
}

reducers[ ActionTypes.UpdateError ] = function UpdateError( state, action ){
	return {
		...state,
		form: {
			...state.form,
			error: action.payload
		}
	};
}

reducers[ ActionTypes.UpdateField ] = function UpdateField( state, action ){
	return {
		...state,
		form: {
			...state.form,
			[ action.payload.field ]: action.payload.value
		}
	};
}

reducers[ ActionTypes.Login ] = function Login( state, action ){
	return {
		...state,
		loggedUser: action.payload,
		isLoggedIn: true,
		menus: action.payload.menus || state.menus || [],
		error: ""
	};
}

reducers[ ActionTypes.Logout ] = function Logout( ){
	return initialState;
}

reducers[ ActionTypes.SetCompanyProfileCompleted ] = function SetCompanyProfileCompleted( state ){
	return {
		...state,
		loggedUser: { ...state.loggedUser, isCompanyProfileCompleted: true }
	};
}

reducers[ ActionTypes.SetUsername ] = function SetUsername( state, action ){
	return { ...state, form: { ...state.form, username: action.payload } };
}

reducers[ ActionTypes.ClearToken ] = function ClearToken( state ){
	return {
		...state,
		form: {
			...state.form,
			password: null
		}
	};
}

reducers[ ActionTypes.SetMenus ] = function SetMenus( state, action ){
	return {
		...state,
		menus: action.payload,
	};
}

reducers[ ActionTypes.SetUserID ] = function SetUserID( state, action ){
	return {
		...state,
		userID: action.payload
	};
}

reducers[ ActionTypes.SetIsLoggedIn ] = function SetIsLoggedIn( state, action ){
	return {
		...state,
		isLoggedIn: action.payload
	};
}

reducers[ ActionTypes.CheckPasswordIsValid ] = function CheckPasswordIsValid( state, action ){
	return {
		...state,
		isPasswordValid: action.payload
	};
}