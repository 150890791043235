import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from "moment";
import PageBase from "../../../commons/components/PageBase";
import { Grid, Paper, Icon, Typography } from '@material-ui/core';
import MultiSelect from "../../../commons/components/MultiSelect";
import NetworkButton from "../../../commons/components/NetworkButton";
import DatePickerRange from "../../../commons/components/DatePickerRange";
import DealerTrackingSubmittedAppTable from "./DealerTrackingSubmittedAppTable"
import {
	loadFilters,
	submit,
	downloadExcelReport,
	initialFilters
 } from "./actions";
import IsolateEvent from "../../../commons/components/IsolateEvent";
import SingleScroll from "../../../commons/components/SingleScroll";

const mapDispatchToProps = {
	loadFilters,
	submit,
	downloadExcelReport
};

const singleScrollName = "DealerTrackingReportSubmittedApp";

class DealerTrackingReportSubmittedApp extends React.Component {
	constructor(props) {
		super(props);
		this.props.loadFilters(this);
		this.state = {
			isSubmitted: false,
			filters: initialFilters,
			currentFilters: initialFilters,
			listGrid: [],
			dealerAssociationList:[],
			sfcCultivationList:[],
			sfcSalespersonList:[],
			stateList:[]
		};
    }

	handleMultiSelectFilter = (field, value) => {
		this.setState({
			filters: {
				...this.state.filters,
				[field]:value
			}
		});
	};

	handleDate(field, date){
		this.setState({
			filters: {
				...this.state.filters,
				[field]:date
			}
		});
	};

	handleSubmit = () => {
		this.props.submit(this, this.state.filters);
		this.setState({
			isSubmitted: false,
			listGrid: [],
			currentFilters: this.state.filters
		});
	};

	handleSubmitInfinity = (params) => {
		this.props.submit(this, params);
	};

	handleReset = () => {
		this.setState({
			filters: initialFilters,
			currentFilters: initialFilters,
			listGrid: [],
			isSubmitted: false
		});
	};

	handleExcel = () => {
		this.props.downloadExcelReport(this.getFiltersForDetails());
	};

	handleOpenSummaryCultivation = (dealerID) => {
		const { filters } = this.state;
		let fromDate = filters.fromSubmitDate !== null ? Moment( new Date( filters.fromSubmitDate ) ).format( "MM-DD-YYYY" ) : null;
		let toDate = filters.toSubmitDate !== null ? Moment( new Date( filters.toSubmitDate ) ).format( "MM-DD-YYYY" ) : null;
		window.open("SummaryCultivation?dealerID=" + dealerID + "&fromSubmitDate=" + fromDate + "&toSubmitDate=" + toDate, "_blank")
	}

	getFilters = () => this.state.filters;
	getFiltersForDetails = () => this.state.currentFilters;

	createFilters = () => {
		const { filters, dealerAssociationList, sfcCultivationList, stateList, sfcSalespersonList } = this.state;
		return (
			<Grid container style={{marginTop:10}}>
				<Grid container item xs={12} spacing={3}>
					<Grid item xs={3}>
						<IsolateEvent wheel scroll>
							<MultiSelect name={"dealerAssociation"} label={"Dealer Association"} options={dealerAssociationList} values={filters.dealerAssociation} changeInputSelect={ this.handleMultiSelectFilter } height={300} />
						</IsolateEvent>
					</Grid>
					<Grid item xs={3}>
						<IsolateEvent wheel scroll>
							<MultiSelect name={"sfcCultivation"} label={"SFC Cultivation"} options={sfcCultivationList} values={filters.sfcCultivation} changeInputSelect={ this.handleMultiSelectFilter } height={300} />
						</IsolateEvent>
					</Grid>
					<Grid item xs={3}>
						<IsolateEvent wheel scroll>
							<MultiSelect name={"sfcSalesperson"} label={"SFC Salesperson"} options={sfcSalespersonList} values={filters.sfcSalesperson} changeInputSelect={ this.handleMultiSelectFilter } height={300} />
						</IsolateEvent>
					</Grid>
					<Grid item xs={2}>
						<IsolateEvent wheel scroll>
							<MultiSelect name={"state"} label={"State"} options={stateList} values={filters.state} changeInputSelect={ this.handleMultiSelectFilter } height={300} />
						</IsolateEvent>
					</Grid>
				</Grid>
				<Grid container item xs={12} spacing={3}>
					<Grid container item xs={3}>
						<Grid item xs>
							<DatePickerRange
								label={"Submit Date"}
								fromDate={filters.fromSubmitDate} 
								fromDateId="fromSubmitDate" 
								toDate={filters.toSubmitDate} 
								toDateId="toSubmitDate" 
								onDatesChange={this.handleDate.bind(this)}
								onSubmit={this.handleSubmit.bind(this)}
							/>
						</Grid>
					</Grid>
					<Grid container item xs={3} alignItems="flex-end">
						<NetworkButton text={ "Submit" } primary={ true } style={{margin: 10, height: 36}} onClick={ this.handleSubmit } />
						<NetworkButton text={ "Reset" }  primary={ true } style={{margin: 10, height: 36}} onClick={ this.handleReset } />
						<NetworkButton text={ "Excel" }  primary={ true } style={{margin: 10, height: 36}} onClick={ this.handleExcel } />
					</Grid>
				</Grid>
			</Grid>
		);
    }

	createTable = () => {
		const { isSubmitted, listGrid } = this.state;
		return(
			isSubmitted ?
			listGrid.length === 0 ?
			<Paper>
				<div style={{textAlign:"center"}}><Icon color="secondary" style={{fontSize:"15rem"}}>search</Icon></div>
				<Typography variant="h3" component="h2" align="center" color="secondary">No Data Found</Typography>
			</Paper>:
			<DealerTrackingSubmittedAppTable
				listGrid={listGrid}
				openSummaryCultivation={this.handleOpenSummaryCultivation}
				handleSubmit={this.handleSubmitInfinity.bind(this)}
				filters={this.getFiltersForDetails()}
				forSingleScroll={singleScrollName}
			/>
			:
			<div/>
		);
	};

    render() {
		return (
			<PageBase title="Dealer Tracking Report Submitted App" >
				<SingleScroll name={singleScrollName}>
					<SingleScroll.Scrollable for={singleScrollName}>
						{this.createFilters()}
						<div style={{height:20}} />
						{this.createTable()}
					</SingleScroll.Scrollable>
				</SingleScroll>
			</PageBase>
		);
	}
}

DealerTrackingReportSubmittedApp.propTypes = {
	downloadExcelReport: PropTypes.func,
	loadFilters: PropTypes.func,
	submit: PropTypes.func
};

export default connect(
	null,
	mapDispatchToProps,
)( DealerTrackingReportSubmittedApp );