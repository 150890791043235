import { apiGet, apiPost, apiDelete } from "../../../actions";
import fileDownload from "js-file-download";

export const facilityLimitModel = {
	lender: "",
	amount: "0.0"
};
export const facilityLimitPurchaseModel = {
	lenderTo: "",
	lenderFrom: "",
	amount: "0.0"
};

const defaultPath = "api/FundingManagement/FacilityLimit";

export function loadLenders(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: "api/CommonFilter/loadFacilityLimitFilter",
			onSuccess: response => {
				props.setState({lenderList: response.data});
			}
		} );
	};
}

export function downloadFacilityLimitReportExcel( lender ) {
	return dispatch => {
		apiGet( dispatch, {
			responseType: "blob",
			headers: { "content-type": "application/json" },
			url: defaultPath + `/${ lender }`,
			onSuccess: response => {
				fileDownload( response.data, `FacilityLimit-${ lender }.xlsx` );
			}
		} );
	};
}

export function loadFacilityLimitList(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath,
			onSuccess: response => {
				props.setState({facilityLimitList: response.data });
			}
		} );
	};
}

export function saveFacilityLimit( props, item ) {
	const newItem = Object.create(item);
	newItem.amount = parseFloat( item.amount.replace( "$", "" ).replace(",", "") );
	newItem.lender = item.lender;
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath,
			body: newItem,
			onSuccess: () => {
				props.setState({facilityLimit: facilityLimitModel});
				dispatch( loadFacilityLimitList(props) );
			}
		} );
	};
}

export function deleteFacilityLimit( props, obj ) {
	return dispatch => {
		apiDelete( dispatch, {
			url: `${ defaultPath }/${ obj }`,
			body: obj,
			onSuccess: () => {
				dispatch( loadFacilityLimitList(props) );
			}
		} );
	};
}

export function loadFacilityLimitListMonthly(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath + "/facilitLimitMonthly",
			onSuccess: response => {
				props.setState({facilityLimitMonthlyList: response.data });
			}
		} );
	};
}

export function saveFacilityLimitMonthly( props, item ) {
	const newItem = Object.create(item);
	newItem.amount = parseFloat( item.amount.replace( "$", "" ).replace(",", "") );
	newItem.lender = item.lender;
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath + "/facilitLimitMonthly",
			body: newItem,
			onSuccess: () => {
				props.setState({facilitLimitMonthly: facilityLimitModel});
				dispatch( loadFacilityLimitListMonthly() );
			}
		} );
	};
}

export function deleteFacilityLimitMonthly( props, obj ) {
	return dispatch => {
		apiDelete( dispatch, {
			url: defaultPath + "/facilitLimitMonthly/"  +obj,
			body: obj,
			onSuccess: () => {
				dispatch( loadFacilityLimitListMonthly(props) );
			}
		} );
	};
}

export function loadFacilityLimitListPurchase(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath + "/facilityLimitPurchase",
			onSuccess: response => {
				props.setState({facilityLimitPurchaseList: response.data });
			}
		} );
	};
}

export function saveFacilityLimitPurchase( props, item ) {
	const newItem = Object.create(item);
	newItem.lenderFromId = parseInt( item.lenderFromId );
	newItem.lenderToId = parseInt( item.lenderToId );
	newItem.amount = parseFloat( item.amount.replace( "$", "" ).replace(",", "") );
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath + "/facilityLimitPurchase",
			body: newItem,
			onSuccess: () => {
				props.setState({facilityLimitPurchase: facilityLimitPurchaseModel});
				dispatch( loadFacilityLimitListPurchase(props) );
			}
		} );
	};
}

export function deleteFacilityLimitPurchase( props, obj ) {
	return dispatch => {
		apiDelete( dispatch, {
			url: defaultPath + "/facilityLimitPurchase/" + obj,
			body: obj,
			onSuccess: () => {
				dispatch( loadFacilityLimitListPurchase(props) );
			}
		} );
	};
}
