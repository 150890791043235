import { apiGet, apiPost } from "../../../../actions";

const defaultPath = "api/FundingManagement/DiscountRebate";

export const initialFilter = {
	startIndex: 0,
	stopIndex: 100
}

export function loadList(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath,
			onSuccess: response => {
				const emptyEntity = {
					dealerAssociation: "",
					dealerAssociationSplit: "",
					useFundingAmountFormula: false,
					...response.data.headers.reduce( (acc, ap) => {	acc[ap.id] = 0.0; return acc; }, {})
				};
				const rows = response.data.data.map( (row) =>
					({
						dealerAssociation: row.dealerAssociation,
						dealerAssociationSplit: row.dealerAssociationSplit,
						useFundingAmountFormula: row.useFundingAmountFormula,
						totalCount: row.totalCount,
						...row.loanTypeValues.reduce( (acc, ap) => {
							acc[ap.loanType] = ap.value;
							return acc;
						}, {}),
						source: row
					})
				);
				
				props.setState({
					isSubmitted: true,
					listGrid: rows,
					headers: response.data.headers,
					emptyEntity: emptyEntity
				});
			}
		} );
	};
}

export function loadDealerAssociationLists(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: "api/CommonFilter/loadDealerAssociation",
			onSuccess: response => {
				props.setState({dealerAssociationList: response.data});
			}
		} );
	};
}

export function saveItem( props, index, source, newItem ) {
	let loanTypeValues = Object.keys(newItem)
		.filter( key => key.indexOf("loanType") !== -1)
		.map(key => {
			return { loanType: key, value: newItem[key] };
	});
	let dealerAssociation = null;
	let dealerAssociationSplit = null;
	let useFundingAmountFormula = null;

	if(source !== undefined) {
		dealerAssociation = source.dealerAssociation;
		dealerAssociationSplit = source.dealerAssociationSplit;
		useFundingAmountFormula = source.useFundingAmountFormula;

		source.dealerAssociation = newItem.dealerAssociation;
		source.dealerAssociationSplit = newItem.dealerAssociationSplit;
		source.useFundingAmountFormula = newItem.useFundingAmountFormula;
		source.loanTypeValues = source.loanTypeValues.map( app => {
			const appTemp = loanTypeValues.filter( t => t.loanType === app.loanType)[0];
			app.value = appTemp.value;
			return app;
		});
	} 
	else {
		source = {
			dealerAssociation: newItem.dealerAssociation,
			dealerAssociationSplit: newItem.dealerAssociationSplit,
			useFundingAmountFormula: newItem.useFundingAmountFormula,
			loanTypeValues: loanTypeValues
		};
	}	

	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath,
			body: { source, dealerAssociation, dealerAssociationSplit, useFundingAmountFormula },
			onSuccess: () => {
				let temp = [...props.editedRebate];
				temp[index] = null;
				props.setState({
					listGrid:[],
					editedRebate: temp
				});
				dispatch( loadList(props) );
			}
		} );
	};
}

export function deleteItem( props, item ) {
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath + "/delete",
			body: item,
			message: "Deleted!",
			onSuccess: () => {
				props.setState({
					listGrid: [],
					itemToDelete: {},
					statusShowDeleteDialog: false
				});
				dispatch( loadList(props) );
			}
		} );
	};
}
