import React from "react";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";

import { Network_ActionTypes } from "./actions";

const ErrorMessage = ( { showMessage, text, onRequestClose } ) => {
	return (
		<Snackbar
			open={ showMessage }
			message={ text }
			autoHideDuration={ 5000 }
			onClose={ onRequestClose }
			style={{ whiteSpace: "pre-wrap" }}
		/>
	);
};

ErrorMessage.propTypes = {
	showMessage: PropTypes.bool,
	text: PropTypes.string,
	onRequestClose: PropTypes.func
};

export default ErrorMessage;

export function createActionSetError( response ) {
	return {
		type: Network_ActionTypes.End,
		payload: response
	};
}
