import ContentDisposition from "content-disposition";

import { apiGet, apiPost } from "../../../actions";

const defaultPath = "api/DealerManagement/DealerPerformanceReport";

export function loadFilters(onSuccess) {
	return dispatch => {
		apiGet( dispatch, {
			url: `${defaultPath}/Filters`,
			onSuccess: response => {
				onSuccess?.(response.data);
			}
		} );
	}
}

export function handleExcel(filters, onSuccess) {
	return dispatch => {
		apiPost( dispatch, {
			url: `${defaultPath}/Excel`,
			responseType: "blob",
			body: filters,
			onSuccess: response => {
				var filename = "Dealer Performance Report";
				if (response.headers && response.headers['content-disposition']) {
					const cd = ContentDisposition.parse(response.headers['content-disposition']);
					if (cd && cd.parameters && typeof cd.parameters.filename === "string") {
						filename = cd.parameters.filename;
					}
				}
				onSuccess?.(response.data, filename);
			}
		} );
	}
}