/**
 * @typedef ScrollElement
 * @prop {HTMLElement} element
 * @prop {string} id
 * @prop {boolean} scrolling
 * @prop {Function} function
 */

/** @type {Map<string, ScrollElement[]>} */
var scrollElements = new Map();

/** @param {string} id */
function unsynchronizeScroll(id) {
	if (!scrollElements.has(id)) {
		return;
	}

	const items = scrollElements.get(id);
	if (!(items instanceof Array)) {
		return;
	}

	for (const item of items) {
		if ((item.element).addEventListener) {
			item.element.removeEventListener("scroll", item.function);
		} else {
			item.element.onscroll = null;
		}
	}

	scrollElements.delete(id);
}

/**
 * @param {string} id
 * @param {HTMLElement[]} elements
 */
function synchronizeScroll(id, elements) {
	unsynchronizeScroll(id);
	elements.forEach(
		(element) => {
			const fn = () => {
				syncScroll(element, id);
			};
			/** @type {ScrollElement} */
			const item = {
				element: element,
				id: id,
				scrolling: false,
				function: fn
			};
			addElement(id, item);
			element.addEventListener('scroll', fn);
		}
	);
}

/**
 * @param {HTMLElement} element
 * @param {string} id
 */
function syncScroll(element, id){
	const elements = [];
	const items = scrollElements.get(id);
	for (const item of items) {
		if (item.element === element) {
			continue;
		}
		elements.push(item);
	}

	const elementsScrolling = elements.map(x => x.scrolling);
	if (!elementsScrolling.includes(true)) {
		(scrollElements.get(id) || []).forEach(x => x.scrolling = true);

		const top = element.scrollTop;
		const left = element.scrollLeft;

		elements.forEach(x => {
			x.element.scrollTop = top;
			x.element.scrollLeft = left;
		});
	}

	(scrollElements.get(id) || []).forEach(item => item.scrolling = false);
}

/**
 * @param {string} id
 * @param {ScrollElement} item
 */
function addElement(id, item) {
	let items = scrollElements.get(id);
	if (!(items instanceof Array)) {
		items = [];
	}
	items.push(item);
	scrollElements.set(id, items);
}

export { synchronizeScroll, unsynchronizeScroll };
