import { apiGet, apiPost } from "../../../../actions";
import fileDownload from "js-file-download";
import Moment from "moment";

const defaultPath = "api/ViewerFundingPackage";

export function loadLenders(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath + "/lenders",
			onSuccess: response => {
				props.setState({lenders: response.data});
			}
		} );
	};
}

export function loadDates( props, lender ) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath + `/dates?lender=${ lender }`,
			onSuccess: response => {
				props.setState({dates: response.data});
			}
		} );
	};
}

export function checkIfAllFormsTemplateExists( component, lender ) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath + `/hasTemplate?lender=${ lender }`,
			onSuccess: response => {
				component.setState( { hasFormsTemplate: response.data } );
			}
		} );
	};
}

export function downloadForms( lender, date ) {
	return dispatch => {
		apiGet( dispatch, {
			responseType: "blob",
			url: defaultPath + `/allforms?lender=${ lender }&date=${ Moment( new Date( date ) ).format( "MM-DD-YYYY" ) }`,
			onSuccess: response => {
				fileDownload( response.data, `Documents${ lender }.docx` );
			}
		} );
	};
}

export function downloadExhibitAToAssignmentAndConveyance( lender, date ) {
	return dispatch => {
		apiGet( dispatch, {
			responseType: "blob",
			url: defaultPath + `/exhibitAToAssignmentAndConveyance?lender=${ lender }&date=${ Moment( new Date( date ) ).format( "MM-DD-YYYY" ) }`,
			onSuccess: response => {
				fileDownload( response.data, `ExhibitAToAssignmentAndConveyance${ lender }.xlsx` );
			}
		} );
	};
}

export function downloadExhibitAToFormOfSaleNotice( lender, date ) {
	return dispatch => {
		apiGet( dispatch, {
			responseType: "blob",
			url: defaultPath + `/exhibitAToFormOfSaleNotice?lender=${ lender }&date=${ Moment( new Date( date ) ).format( "MM-DD-YYYY" ) }`,
			onSuccess: response => {
				fileDownload( response.data, `ExhibitAToFormOfSaleNotice${ lender }.xlsx` );
			}
		} );
	};
}

export function downloadCustodialLoanSchedule( lender, date ) {
	return dispatch => {
		apiGet( dispatch, {
			responseType: "blob",
			url: defaultPath + `/custodialLoanSchedule?lender=${ lender }&date=${ Moment( new Date( date ) ).format( "MM-DD-YYYY" ) }`,
			onSuccess: response => {
				fileDownload( response.data, `CustodialLoanSchedule${ lender }.xlsx` );
			}
		} );
	};
}

export function downloadLenderPreFundReport( lender, date ) {
	return dispatch => {
		apiGet( dispatch, {
			responseType: "blob",
			url: defaultPath + `/lenderPreFundReport?lender=${ lender }&date=${ Moment( new Date( date ) ).format( "MM-DD-YYYY" ) }`,
			onSuccess: response => {
				fileDownload( response.data, "LenderPreFundReport.xlsx" );
			}
		} );
	};
}

export function downloadPendingCompletion( lender ) {
	return dispatch => {
		apiPost( dispatch, {
			responseType: "arraybuffer",
			url: defaultPath + `/pendingCompletion`,
			body: {
				lender,
				includePCDealerAdvanced: true,
				includePCProgressPayment: true,
				includePPOutstandingByDealer: true,
				includePPPPRequestsOnHold: true,
				includeDashboard: true,
				includePrePayCreditLine: true,
			},
			onSuccess: response => {
				fileDownload( response.data, `PendingCompletion${ lender }.xlsx` );
			}
		} );
	};
}

export function downloadDealerFundedSchedule( lender, date ){
	return dispatch => {
		apiGet( dispatch, {
			responseType: "blob",
			url: defaultPath + `/dealerFundedSchedule?lender=${ lender }&date=${ Moment( new Date( date ) ).format( "MM-DD-YYYY" ) }`,
			onSuccess: response => {
				fileDownload( response.data, `DealerFundedSchedule${ lender }.xlsx` );
			}
		} );
	};
}

export function downloadFundingSchedule( lender, date ){
	return dispatch => {
		apiGet( dispatch, {
			responseType: "blob",
			url: defaultPath + `/fundingSchedule?lender=${ lender }&date=${ Moment( new Date( date ) ).format( "MM-DD-YYYY" ) }`,
			onSuccess: response => {
				fileDownload( response.data, `FundingSchedule${ lender }.xlsx` );
			}
		} );
	};
}
