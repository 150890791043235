import React from "react";
import PropTypes from "prop-types";
import { InputLabel, FormControl, FormControlLabel, FormLabel, Select, Grid, Radio, RadioGroup, Input } from '@material-ui/core';
import NetworkButton from "../../../commons/components/NetworkButton";
import { renderMenuItems } from "../../../commons/utils";
import IsolateEvent from "../../../commons/components/IsolateEvent";
import MultiSelect from "../../../commons/components/MultiSelect";
import DateMonthTextField from "../../../commons/components/DateMonthTextField";

class RollforwardFilters extends React.Component {

    handleMultiSelectFilter = (field, value) => {
        if (value instanceof Array && value.includes("All")) {
            this.props.changeFilter(field, ["All"]);
        } else {
            this.props.changeFilter(field, value);
        }
    }

    handleToggle = (field, event) => {
        this.props.changeFilter(field, event.target.value);
    }

    render(){
        const { lenderList, loanTypeList, asOfDateList, filters} = this.props;
        return (
            <Grid container spacing={3} style={{marginTop: 10}}>
                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={4}>
                        <IsolateEvent wheel scroll>
                            <MultiSelect
                                options={ lenderList}
                                label={"Lender"}
                                values={ filters.lender }
                                name={ "lender" }
                                changeInputSelect={ this.handleMultiSelectFilter }
                                height={300}
                                idProperty={"key"}
                            />
                        </IsolateEvent>
                    </Grid>
                    <Grid item xs={4}>
                        <IsolateEvent wheel scroll>
                            <MultiSelect options={ loanTypeList} label={"Loan Type"} values={ filters.loanType } name={ "loanType" } changeInputSelect={ this.handleMultiSelectFilter } height={300} />
                        </IsolateEvent>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid>
                            <FormControl style={{width:'100%'}}>
                                <InputLabel>As of Date</InputLabel>
                                <Select inputProps={{ name: "asOfDate" }} value={filters.asOfDate} onChange={this.props.handleUpdateFilter}>
                                    { renderMenuItems(asOfDateList, false) }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid>
                            <FormControl style={{width:'100%'}}>
                                <InputLabel shrink>Funding Month</InputLabel>
                                <DateMonthTextField
                                    value={filters.fundingMonth}
                                    placeholder="MM/yyyy"
                                    onChange={this.props.handleUpdateFilter}
                                    style={{marginTop:12}}
                                    id="fundingMonth"
                                    name="fundingMonth"
                                />
                            </FormControl>
                        </Grid>
                        <Grid>
                            <FormControl style={{width:'100%'}}>
                                <InputLabel>Max. previous delinquecy</InputLabel>
                                <Input
                                    name={'maxPreviousDelinquecy'}
                                    value={filters.maxPreviousDelinquecy}
                                    onChange={this.props.handleUpdateFilter}
                                    margin="dense"
                                    inputProps={{ step: 1, min: 0, max: 100, type: 'number', 'aria-labelledby': 'input-slider', }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid>
                            <FormControl style={{width:'100%'}}>
                                <InputLabel>Max. current delinquecy</InputLabel>
                                <Input
                                    name={'maxCurrentDelinquecy'}
                                    value={filters.maxCurrentDelinquecy}
                                    onChange={this.props.handleUpdateFilter}
                                    margin="dense"
                                    inputProps={{ step: 1, min: 0, max: 100, type: 'number', 'aria-labelledby': 'input-slider', }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">TU 144 Override</FormLabel>
                                <RadioGroup name="stageLoan" value={filters.tu144Override} row onChange={ this.handleToggle.bind(this, "tu144Override")} >
                                    <FormControlLabel value="all" control={<Radio color="primary"/>} label="All" />
                                    <FormControlLabel value="yes" control={<Radio color="primary"/>} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio color="primary"/>} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid>
                            <NetworkButton text={"Submit"} primary={true} style={{margin: 10, height: 36}} onClick={this.props.handleSubmit}/>
                            <NetworkButton text={"Reset"} primary={true} style={{margin: 10, height: 36}} onClick={this.props.handleReset}/>
                            <NetworkButton text={"Excel"} primary={true} style={{margin: 10, height: 36}} onClick={this.props.handleExcel}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };
}

RollforwardFilters.propTypes = {
    asOfDateList: PropTypes.array,
    changeFilter: PropTypes.func,
    loanTypeList: PropTypes.array,
    filters: PropTypes.object,
    handleExcel: PropTypes.func,
    handleUpdateFilter: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleReset: PropTypes.func,
    lenderList: PropTypes.array
};

export default RollforwardFilters;