import { apiGet } from "../../../../actions";
import fileDownload from "js-file-download";
import Moment from "moment";

const defaultPath = "api/FundingManagement/Reconciliation/ReconciliationReport";

export function loadLenders(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: "api/commonFilter/loadReconciliationFilters",
			onSuccess: response => {
				props.setState({lenderList: response.data});
			}
		} );
	};
}

export function downloadReconciliationReportExcel( lender, fromDate, toDate ) {
	return dispatch => {
		apiGet( dispatch, {
			responseType: "blob",
			url: defaultPath + "/excel",
			params: {
				lender: lender,
				fromDate: fromDate !== null ? Moment( new Date( fromDate ) ).format( "MM-DD-YYYY" ) : null,
				toDate: toDate !== null ? Moment( new Date( toDate ) ).format( "MM-DD-YYYY" ) : null,
			},
			onSuccess: response => {
				fileDownload( response.data, `Reconciliation-${ lender }.xlsx` );
			}
		} );
	};
}