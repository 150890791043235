import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import Paper from "@material-ui/core/Paper";
import NetworkButton from "../../../commons/components/NetworkButton";
import PasswordValidator from "../../../commons/components/PasswordValidator";
import { ValidatorForm, TextValidator} from "react-material-ui-form-validator";

import { Auth_Login_ActionTypes as ActionTypes, postChangePassword } from "./actions";
import { dispatchFieldChanged } from "../../fields";

const styles = {
	loginContainer: {
		minWidth: 320,
		maxWidth: 400,
		height: "auto",
		position: "absolute",
		top: "20%",
		left: 0,
		right: 0,
		margin: "auto"
	},
	paper: {
		padding: 20,
		overflow: "auto"
	},
	rightBtn: {
		float: "right",
		fontFamily: "Cochin",
		fontSize: 20,
		fontWeight: "Heavy"
	}
};
 
const mapStateToProps = ( store ) => ({
	login: store.auth.login,
	routing: store.routing.locationBeforeTransitions,
	message: store.auth.message,
	authenticated: store.auth.authenticated
} )

const mapDispatchToProps = {
	postChangePassword,
	dispatchFieldChanged
}

class ChangePassword extends Component {

	handleSubmit = () => {
		var user = this.props.login.form.email;
		var oldPassword = this.props.login.form.oldPassword;
		var password = this.props.login.form.password;
		this.props.postChangePassword( user, oldPassword, password );
	};

	render() {
		const onFieldChanged = this.props.dispatchFieldChanged.bind( this, ActionTypes.UpdateField );

		return (
			<div>
				<div style={ styles.loginContainer }>
					<Paper style={ styles.paper }>
						<ValidatorForm
							onSubmit={this.handleSubmit}
						>
							<TextValidator
								ref={ input => {
									this.password = input;
								} }
								name="oldPassword"
								label="Old Password"
								fullWidth={ true }
								value={ this.props.login.form.oldPassword }
								onChange={ onFieldChanged }
								type="password"
								validators={ ["required"] }
								errorMessages={ ["Password cannot be blank."] }
							/>

							<PasswordValidator
								form={ this.props.login.form }
								onChange={ onFieldChanged }
							/>

							<div>
								<br />
								<NetworkButton
									text={ "Submit" }
									primary={ true }
									type="submit"
									style={ styles.rightBtn }
								/>
							</div>
						</ValidatorForm>
					</Paper>
				</div>
			</div>
		);
	}
}

ChangePassword.propTypes = {
	dispatchFieldChanged: PropTypes.func,
	errorMessage: PropTypes.string,
	login: PropTypes.object,
	postChangePassword: PropTypes.func,
	router: PropTypes.object
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter( ChangePassword ))
