import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageBase from "../../../../../commons/components/PageBase";
import NetworkButton from "../../../../../commons/components/NetworkButton";
import { renderMenuItems } from "../../../../../commons/utils";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { loadLenders, saveItem, model } from "./actions";
import ValidatorForm from "react-form-validator-core/lib/ValidatorForm";
import SelectValidator from "react-material-ui-form-validator/lib/SelectValidator";
import CurrencyAmountField from "../../../../../commons/components/CurrencyAmountField";
import Moment from "moment";

const mapDispatchToProps = {
	loadLenders,
	saveItem
};

class ServicingAdviceSend extends Component {
	state = {
		form: model,
		dataList: [],
		lenders: []
	};

	constructor(props) {
		super(props);
		this.props.loadLenders(this);
	}

	componentWillUnmount = () => this.clearFields();

	clearFields = () => {
		this.setState({
			form: model,
			dataList: [],
			lenders: []
		});
	};

	handleUpdateField = ( event ) => {
		this.setState({
			form:{
				...this.state.form,
				[event.target.name]: event.target.value
			}
		});
	};

	handleUpdateDate = ( event, value ) => {
		if(event.isValid()){
			this.setState({
				form:{
					...this.state.form,
					date: Moment(value, "MM-DD-YYYY")
				}
			});
		}
	};

	handleSubmitForm = () => {
		const { form } = this.state;
		form.totalFunds = form.totalFunds.split(",").join("");
		form.principal = form.principal.split(",").join("");
		form.interest = form.interest.split(",").join("");
		this.props.saveItem( this, form );
	};

	
	render() {
		const {lenders, form} = this.state;
		return (
			<PageBase title="Send Servicing Advice" showActions={ false }>
				<ValidatorForm onSubmit={ this.handleSubmitForm } noValidate>
					<SelectValidator
						name="lender"
						label="Select a lender"
						value={ form.lender }
						onChange={ this.handleUpdateField }
						validators={ ["required"] }
						errorMessages={ ["It is mandatory."] }
						fullWidth
					>
						{renderMenuItems( lenders, false )}
					</SelectValidator>
					<CurrencyAmountField
						onChange={ this.handleUpdateField }
						label="Total Funds"
						name="totalFunds"
						value={ form.totalFunds }
						validators={ ["required"] }
						errorMessages={ ["It's mandatory"] }
				
					/>
					<CurrencyAmountField
						onChange={ this.handleUpdateField }
						label="Principal"
						name="principal"
						value={ form.principal }
						validators={ ["required"] }
						errorMessages={ ["It's mandatory"] }
				
					/>
					<CurrencyAmountField
						onChange={ this.handleUpdateField }
						label="Interest"
						name="interest"
						value={ form.interest }
						validators={ ["required"] }
						errorMessages={ ["It's mandatory"] }
					
					/>
					<KeyboardDatePicker
						name="date"
						label="Date"
						value={form.date}
						onChange={ (event, date) => this.handleUpdateDate( event, date)}
						InputAdornmentProps={{ position: "start" }}
						variant="inline"
						style={{width:"100%"}}
						format={"MM-DD-YYYY"}
						autoOk
					/>
					<div>
						<br />
						<NetworkButton
							text={ "Send" }
							primary={ true }
							type="submit"
						/>
					</div>
				</ValidatorForm>
			</PageBase>
		);
	}
}

ServicingAdviceSend.propTypes = {
	loadLenders: PropTypes.func,
	saveItem: PropTypes.func
};

export default connect(
	null,
	mapDispatchToProps,
)( ServicingAdviceSend );
