import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageBase from "../../../commons/components/PageBase";
import { Grid, Typography } from '@material-ui/core';
import NetworkButton from "../../../commons/components/NetworkButton";
import DatePickerRange from "../../../commons/components/DatePickerRange";
import RiskBasedPrincingNoticeTabs from "./RiskBasedPrincingNoticeTabs"
import ValidatorForm from "react-form-validator-core/lib/ValidatorForm";

import {
	submit,
	downloadReport,
    initialFilters
} from "./actions";

const mapDispatchToProps = {
    submit,
    downloadReport
};

class RiskBasedPrincingNotice extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
            filters: initialFilters,
            currentFilters: initialFilters,
            loanTypeList:[],
			fhaUnsecuredList:[],
			fhaSecuredList:[],
			isSubmitted: false
		};
    }
    
    handleUpdateField(field, value){
		this.setState({
			filters: {
				...this.state.filters,
				[field]:value
			}
		});
    };
    
    handleSubmit = () => {
		this.props.submit(this, this.state.filters);
		this.setState({
			currentFilters: this.state.filters
		});
	};

	handleSubmitInfinity = (params) => {
		this.props.submit(this, params);
	};

	handleReset = () => {
		this.setState({
			filters: initialFilters,
			currentFilters: initialFilters,
			loanTypeList: [],
			fhaUnsecuredList: [],
			fhaSecuredList: [],
			isSubmitted: false
		});
	};

	handleDownload = (reportType) => {
		this.props.downloadReport(this.getFiltersForDetails(), reportType);
	}
    
    getFilters = () => this.state.filters;
	getFiltersForDetails = () => this.state.currentFilters;


    createFilters() {
		const { filters } = this.state;
		return (
			<ValidatorForm onSubmit={ this.handleSubmit } noValidate>
				<Grid container item style={{marginTop:10}} xs={5} spacing={3}>
					<Grid container item xs={7}>
							<DatePickerRange
								label={"Application Submit Date"}
								fromDate={filters.fromSubmitDate}
								fromDateId="fromSubmitDate"
								toDate={filters.toSubmitDate}
								toDateId="toSubmitDate"
								onDatesChange={this.handleUpdateField.bind(this)}
								validate={true}
								onSubmit={this.handleSubmit.bind(this)}
							/>
					</Grid>
					<Grid container item xs={5} alignItems="flex-end">
						<NetworkButton text={ "Submit" } primary={ true } style={{margin: 10, height: 36}} type="submit" />
						<NetworkButton text={ "Reset" }  primary={ true } style={{margin: 10, height: 36}} onClick={ this.handleReset } />
					</Grid>
				</Grid>
			</ValidatorForm>
		);
	}

	createdonwloadButtons() {
		const { isSubmitted, loanTypeList, fhaUnsecuredList, fhaSecuredList } = this.state;

		if(!isSubmitted)
			return(<div/>);

		return (
			<fieldset style={{maxWidth: "36%", marginTop:20}}>
				<legend><Typography style={{color: 'rgba(0,0,0,0.47)'}}>Donwload PDF's</Typography></legend>
					<NetworkButton text={ "FHA Unsecured" } primary={ true } style={{margin: 10, height: 36}} onClick={ this.handleDownload.bind(this, "FHA Unsecured") } disabled={fhaUnsecuredList.length === 0} />
					<NetworkButton text={ "FHA Secured" }  primary={ true } style={{margin: 10, height: 36}} onClick={ this.handleDownload.bind(this, "FHA Secured") } disabled={fhaSecuredList.length === 0}/>
					<NetworkButton text={ "Loan Types" }  primary={ true } style={{margin: 10, height: 36}} onClick={ this.handleDownload.bind(this, "Loan Type") } disabled={loanTypeList.length === 0}/>
			</fieldset>
		);
	}

    render() {
		const { isSubmitted, loanTypeList, fhaUnsecuredList, fhaSecuredList} = this.state;

		return (
			<PageBase title="Risk Based Pricing Notice" >
				<div>
					{this.createFilters()}
					{this.createdonwloadButtons()}
					<RiskBasedPrincingNoticeTabs
						isSubmitted={isSubmitted}
						loanTypeList={loanTypeList}
						fhaUnsecuredList={fhaUnsecuredList}
						fhaSecuredList={fhaSecuredList}
						filters={this.getFiltersForDetails()}
						handleSubmit={this.handleSubmitInfinity.bind(this)}
					/>
				</div>
			</PageBase>
		);
	}
}

RiskBasedPrincingNotice.propTypes = {
	downloadReport: PropTypes.func,
	submit: PropTypes.func
};

export default connect(null, mapDispatchToProps)( RiskBasedPrincingNotice );