import { apiGet, apiPost } from "../../../actions";
import fileDownload from "js-file-download";
import Moment from "moment";

export const initialFilters = {
	dealerAssociation: [],
	sfcSalesperson: [],
	dealerName: [],
	fromSubmitDate: null,
	toSubmitDate: null,
	startIndex: 0,
	stopIndex: 100
};

const defaultPath = "api/DealerManagement/DealerBDRatioReport";

export function loadFilters(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: "api/CommonFilter/loadDealerBDRatioFilters",
			onSuccess: response => {
				let {dealerAssociations, sfcSalesperson, dealerNames } = response.data;
				props.setState({
					dealerAssociationList: dealerAssociations,
					sfcSalespersonList: sfcSalesperson,
					dealerNameList: dealerNames
				});
			}
		} );
	}
}

export function submit( props, filters ) {
	return dispatch => {
		apiPost( dispatch, {
			url: defaultPath,
			body: convertFilterToParams(filters),
			onSuccess: response => {
				props.setState({
					listGrid: response.data,
					isSubmitted: true,
				});
			}
		} );
	};
}

export function downloadExcelReport( filters ) {
	return dispatch => {
		apiPost( dispatch, {
			responseType: "blob",
			url: defaultPath + '/downloadExcel',
			body: convertFilterToParams(filters),
			onSuccess: response => {
				var now = new Date();
				fileDownload( response.data, `Dealer BD Ratio Report - ${now.getMonth() + 1}-${now.getDate()}-${now.getFullYear()}.xlsx` );
			}
		} );
	};
}

function convertFilterToParams(filters) {
	return {
		dealerAssociationIds: filters.dealerAssociation.map(a => a.id),
		sfcSalespersonIds: filters.sfcSalesperson.map(a => a.id),
		dealerIds: filters.dealerName.map(a => a.id),
		fromSubmitDate: filters.fromSubmitDate !== null ? Moment( new Date( filters.fromSubmitDate ) ).format( "MM-DD-YYYY" ) : null,
		toSubmitDate: filters.toSubmitDate !== null ? Moment( new Date( filters.toSubmitDate ) ).format( "MM-DD-YYYY" ) : null,
		startIndex: filters.startIndex,
		stopIndex: filters.stopIndex,
	}
}