import Moment from "moment";
import fileDownload from "js-file-download";
import { apiGet } from "../../../actions";

const url = "api/FundingManagement/DeterminationDateIssue";
const lenderNumbers = [520, 523]; // "STB", "TRU"


export function loadAsOfDates(props) {
	return dispatch => {
		const params = lenderNumbers.map(number => `lenderNumbers=${number}`).join("&");
		apiGet( dispatch, {
			url: `${url}/getAsOfDates?${params}`,
			onSuccess: response => {
				let dates = response.data.map( d => Moment(new Date(d)).format( "MM/DD/YYYY" ));
				props.setState({
					asOfDates: dates,
					selectedAsOfDate: dates.length > 0 ? dates[0] : ""
				});
			}
		} );
	};
}

export function downloadWord( asOfDate ) {
	return dispatch => {
		apiGet( dispatch, {
			responseType: "blob",
			url: `${url}/downloadWord`,
			params:
			{
				asOfDate: asOfDate,
				lenderNumbers: JSON.stringify(lenderNumbers)
			},
			onSuccess: response => {
				const date = new Date( asOfDate );
				fileDownload( response.data, `Determination Date Issue - ${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}.docx` );
			},
		} );
	};
}

export function downloadExcel( asOfDate ) {
	return dispatch => {
		apiGet( dispatch, {
			responseType: "blob",
			url: `${url}/downloadExcel`,
			params:
			{
				asOfDate: asOfDate,
				lenderNumbers: JSON.stringify(lenderNumbers)
			},
			onSuccess: response => {
				const date = new Date( asOfDate );
				fileDownload( response.data, `Determination Date Issue - ${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}.xlsx` );
			},
		} );
	};
}