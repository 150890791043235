import React from "react";
import PropTypes from "prop-types";

import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { Menu, PowerSettingsNew as Power } from "@material-ui/icons";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";

const whiteColorStyle = { color: "#FFFFFF" };

const appBar = {
	height: 57,
	paddingTop: 0,
	titleStyle: {
		textAlign: "center"
	}
};

class Header extends React.Component {
	handleLogout( event ) {
		event.preventDefault();
		this.props.logout();
	}

	render() {
		const { styles, handleChangeRequestNavDrawer } = this.props;

		const style = {
			menuButton: {
				marginLeft: 10,
				padding: 0
			},
			iconsRightContainer: {
				marginLeft: 20
			},
			titleStyle: {
				textAlign: "center",
				flex: "1 1 0%",
				fontSize: 24,
				fontWeight: 400
			}
		};

		return (
			<div>
				<AppBar position="fixed" style={ { ...styles, ...appBar } } >
					<Toolbar style={{padding: 0}}>
						<IconButton style={ style.menuButton } color="inherit" aria-label="Menu" onClick={ handleChangeRequestNavDrawer }>
							<Menu style={ whiteColorStyle } />
						</IconButton>
						<Typography component="h2" variant="h6" style={ { ...style.titleStyle, ...whiteColorStyle} }>
							Service Finance Company, LLC
						</Typography>
						<div style={ style.iconsRightContainer }>
							<Tooltip title="Logout" placement="bottom-start">
								<IconButton onClick={ this.handleLogout.bind( this ) } >
									<Power style={ whiteColorStyle } />
								</IconButton>
							</Tooltip>
						</div>
					</Toolbar>
				</AppBar>
			</div>
		);
	}
}

Header.propTypes = {
	handleChangeRequestNavDrawer: PropTypes.func,
	logout: PropTypes.func,
	styles: PropTypes.object
};

export default Header;
