import { Button, Grid } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import React, { Component } from 'react'
import { connect } from "react-redux";
import PageBase from '../../../commons/components/PageBase';
import { initialFilters, generateReport } from './actions';
const mapDispatchToProps = {
	generateReport
};

class FinalDealerPackageReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			reportDate: initialFilters.reportDate,
		};
	}

	handleDate = (date) => {
		this.setState({
			reportDate: date,
		});
	}

	generate = async () => {
		const { reportDate } = this.state;
		this.props.generateReport(reportDate);
	}
	

	render() {
		return (
			<PageBase title="Final Dealer Package Report">
				<Grid
					container
					spacing={2}
					style={{ margin: 2, padding: 15, width: "40%" }}
				>
					<Grid item xs={12}>
						<KeyboardDatePicker
							label="Report Date"
							value={this.state.reportDate}
							onChange={this.handleDate}
							format="MM-DD-YYYY"
							inputVariant="outlined"
							fullWidth
						/>
					</Grid>
					<Grid container wrap="nowrap" justifyContent="space-evenly">
						<Grid item>
							<Button
								variant="contained"
								color="primary"
								onClick={this.generate}
							>
								DOWNLOAD REPORT
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</PageBase>
		);
	}
}

export default connect(null, mapDispatchToProps)(FinalDealerPackageReport);
