import { apiGet } from "../../../actions";
import fileDownload from "js-file-download";
import Moment from "moment";

export const initialFilters = {
	fromSubmitDate: Moment(new Date()).add(-1, 'days').toDate(),
	toSubmitDate: Moment(new Date()).add(-1, 'days').toDate(),
	startIndex: 0,
	stopIndex: 100
};

const defaultPath = "api/FundingManagement/RiskBasedPrincingNotice";

export function submit( props, filters ) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath,
			params: {
				fromSubmitDate: filters.fromSubmitDate !== null ? Moment( new Date( filters.fromSubmitDate ) ).format( "MM-DD-YYYY" ) : null,
				toSubmitDate: filters.toSubmitDate !== null ? Moment( new Date( filters.toSubmitDate ) ).format( "MM-DD-YYYY" ) : null,
				startIndex: filters.startIndex,
				stopIndex: filters.stopIndex
			},
			onSuccess: response => {
				props.setState({
					fhaSecuredList: response.data.filter(r => r["loanType"] === "FHA Secured"),
					fhaUnsecuredList: response.data.filter(r => r["loanType"]  === "FHA Unsecured"),
					loanTypeList: response.data,
					isSubmitted: true
				});
			}
		} );
	};
}

export function downloadReport( filters, reportType ) {
	return dispatch => {
		apiGet( dispatch, {
			responseType: "blob",
			url: defaultPath + `/downloadReport`,
			params: {
				fromSubmitDate: filters.fromSubmitDate !== null ? Moment( new Date( filters.fromSubmitDate ) ).format( "MM-DD-YYYY" ) : null,
				toSubmitDate: filters.toSubmitDate !== null ? Moment( new Date( filters.toSubmitDate ) ).format( "MM-DD-YYYY" ) : null,
				reportType: reportType
			},
			onSuccess: response => {
				fileDownload( response.data, `All${reportType}.pdf` );
			}
		} );
	};
}