import { apiGet, apiPost } from "../../../actions";
import fileDownload from "js-file-download";
import Moment from "moment";

export const initialFilters = {
	ignoreFico: "yes",
	ignoreDuplicatedDeclined: "no",
	ignoreSkeps: "no",
	dealerAssociation: [],
	dealerId: [],
	fromLoanFundingDate: null,
	toLoanFundingDate: null,
	fromSubmitDate: null,
	toSubmitDate: null,
	selectWrenchGroup: false,
	contactMethod: "All",
};

const defaultPath = "api/FundingManagement/ChuckReport";

export function loadFilters(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: "api/CommonFilter/loadChuckReportFilters",
			onSuccess: response => {
				let { dealerAssociations, dealerNames, contactMethods } = response.data;
				props.setState({
					dealerAssociationList: dealerAssociations,
					dealerNameList: dealerNames,
					contactMethodList: contactMethods
				});
			}
		});
	}
}

export function downloadExcelReport( filters ) {
	return dispatch => {
		apiPost( dispatch, {
			responseType: "blob",
			url: defaultPath + '/downloadExcel',
			body: convertFilterToParams(filters),
			onSuccess: response => {
				fileDownload( response.data, "ChuckReport.xlsx" );
			}
		} );
	};
}

export function dealersFromWrenchGroup(dealerList) {
	var wrenchGroupList = [];

	for(var dealer in dealerList) {
		if(dealerList[dealer].value === "All About Family Home Services LLC dba Collins Comfort Masters" ||
			dealerList[dealer].value === "Baker Brothers LLC dba Baker Brothers Plumbing Air & Electric" ||
			dealerList[dealer].value === "Berkeys Air Conditioning, Plumbing and Electrical" ||
			dealerList[dealer].value === "Coolray Heating and Cooling LLC dba BriteBox dba Mr Plumber Inc dba BritBox Elec" ||
			dealerList[dealer].value === "Donovan Acquisition LLC dba Donovan Heat & Air" ||
			dealerList[dealer].value === "Environmental Conditioning Inc dba Parker & Sons HVAC Service" ||
			dealerList[dealer].value === "Environmental Conditioning Inc dba Parker & Sons Inc" ||
			dealerList[dealer].value === "N&M Cooling and Heating Inc dba Cool Today dba Plumbing Today" ||
			dealerList[dealer].value === "New Abacus LLC" ||
			dealerList[dealer].value === "On Time Air Conditioning  Heating Inc dba Service Champions" ||
			dealerList[dealer].value === "Plumbline Services LLC" ||
			dealerList[dealer].value === "Ragsdale Heating and Air LLC" ||
			dealerList[dealer].value === "Red Cap Plumbing & Air LLC" ||
			dealerList[dealer].value === "Champion Residential Services Inc dba Morris-Jenkins") {
				wrenchGroupList.push(dealerList[dealer]);
		}
	}
	return wrenchGroupList;
}

function convertFilterToParams(filters) {
	return {
		ignoreFico: filters.ignoreFico === "yes",
		ignoreDuplicatedDeclined: filters.ignoreDuplicatedDeclined === "yes",
		ignoreSkeps: filters.ignoreSkeps === "yes",
		dealerAssociationIDs: filters.dealerAssociation.map(a => a.id),
		dealerIDs: filters.dealerId.map(a => a.id),
		fromLoanFundingDate: filters.fromLoanFundingDate !== null ? Moment( new Date( filters.fromLoanFundingDate ) ).format( "MM-DD-YYYY" ) : null,
		toLoanFundingDate: filters.toLoanFundingDate !== null ? Moment( new Date( filters.toLoanFundingDate ) ).format( "MM-DD-YYYY" ) : null,
		fromSubmitDate: filters.fromSubmitDate !== null ? Moment( new Date( filters.fromSubmitDate ) ).format( "MM-DD-YYYY" ) : null,
		toSubmitDate: filters.toSubmitDate !== null ? Moment( new Date( filters.toSubmitDate ) ).format( "MM-DD-YYYY" ) : null,
		contactMethod: filters.contactMethod,
	}
}