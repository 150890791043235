import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageBase from "../../../commons/components/PageBase";
import DealerTrendTable from "./DealerTrendTable";
import DealerTrendFilters from "./DealerTrendFilters";
import ListInfinityLoader, { ListInfinityDiv } from "../../../commons/components/ListInfinityLoader"
import SearchNotData from "../../../commons/components/SearchNotData";
import SingleScroll from "../../../commons/components/SingleScroll";
import {
	loadFilters,
	submit,
	downloadExcelReport,
	downloadSaveExcelInPathReport,
	initialFilters
 } from "./actions";

const mapDispatchToProps = {
	loadFilters,
	submit,
	downloadExcelReport,
	downloadSaveExcelInPathReport
};

class DealerTrendReport extends React.Component {
	constructor(props) {
		super(props);
		this.props.loadFilters(this);
		this.state = {
			filters: initialFilters,
			currentFilters: initialFilters,
			isSubmitted: false,
			listGrid: [],
			dealerAssociationList:[],
			sfcCultivationList:[],
			dealerList:[]
		};
	}

	handleMultiSelectFilter = (field, value) => {
		this.setState({
			filters: {
				...this.state.filters,
				[field]:value
			}
		});
	};

	handleDate(field, date){
		this.setState({
			filters: {
				...this.state.filters,
				[field]:date
			}
		});
	};

	handleSubmit = () => {
		this.props.submit(this, this.state.filters);
		this.setState({
			currentFilters: this.state.filters
		});
	};

	handleSubmitInfinity = (params) => {
		this.props.submit(this, params);
	};

	handleReset = () => {
		this.setState({
			filters: initialFilters,
			currentFilters: initialFilters,
			listGrid: [],
			isSubmitted: false
		});
	};

	handleExcel = () => {
		this.props.downloadExcelReport(this.getFiltersForDetails());
	};

	handleSaveExcel = () => {
		this.props.downloadExcelReport();
		this.props.downloadSaveExcelInPathReport(this.getFiltersForDetails());
	};

	getFilters = () => this.state.filters;
	getFiltersForDetails = () => this.state.currentFilters;

	createFilters = () => {
		return(
			<DealerTrendFilters
				filters={this.state.filters}
				dealerAssociationList={this.state.dealerAssociationList}
				dealerList={this.state.dealerList}
				sfcCultivationList={this.state.sfcCultivationList}
				handleDate={this.handleDate.bind(this)}
				handleMultiSelectFilter={this.handleMultiSelectFilter}
				handleExcel={this.handleExcel}
				handleSubmit={this.handleSubmit}
				handleReset={this.handleReset}
				isSubmitted={this.state.isSubmitted}
			/>
		);
	};

	Row( event, row, parent ) {
		return (
			<ListInfinityDiv event={event} style={{marginBottom:20}}>
				<DealerTrendTable key={event.index} dealer={row} /> 
			</ListInfinityDiv>
		);
	};

    render() {
		const { listGrid, isSubmitted } = this.state;
		const singleScrollName = "DealerTrendReport";

		return (
			<PageBase title="Dealer Trend Report" >
				<SingleScroll name={singleScrollName}>
					<SingleScroll.Scrollable for={singleScrollName}>
						{this.createFilters()}
						<SearchNotData
							isSubmitted={isSubmitted}
							listGrid={listGrid}
						>
							<ListInfinityLoader
								rowHeight={185}
								listGrid={listGrid}
								drawRow={this.Row}
								handleSubmit={this.handleSubmitInfinity}
								params={this.getFiltersForDetails()}
								parent={this}
								forSingleScroll={singleScrollName}
							/>
						</SearchNotData>
					</SingleScroll.Scrollable>
				</SingleScroll>
			</PageBase>
		);
	}
}

DealerTrendReport.propTypes = {
	downloadExcelReport: PropTypes.func,
	downloadSaveExcelInPathReport: PropTypes.func,
	loadFilters: PropTypes.func,
	submit: PropTypes.func
};

export default connect(
	null,
	mapDispatchToProps,
)( DealerTrendReport );