import React, { Component } from "react";
import AmountField from "./AmountField";

class CurrencyAmountField extends Component {

	render() {
		return (
			<AmountField 
				{...this.props}
				prefix="$"
				thousandSeparator={true}
				decimalScale={2}
			/>
		);
	}
}

export default CurrencyAmountField;
