import React from "react";
import PropTypes from "prop-types";
import TableInfinityLoader, { TableInfinityRow, TableInfinityCell } from "../../../commons/components/TableInfinityLoader"
import Moment from "moment";

class RiskBasedPrincingNoticeTable extends React.Component {
    
    Row( event, row, parent ) {
		return (
			<TableInfinityRow event={event} variant="body">
				<TableInfinityCell style={{width:50}}>{event.index + 1}</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>{row["generateBy"]}</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>{row["applicationNumber"]}</TableInfinityCell>
				<TableInfinityCell style={{width:200}}>{row["borrowerName"]}</TableInfinityCell>
				<TableInfinityCell style={{width:200}}>{row["borrowerAddress"]}</TableInfinityCell>
				<TableInfinityCell style={{width:150}}>{row["borrowerCity"]}</TableInfinityCell>
				<TableInfinityCell style={{width:50}}>{row["borrowerState"]}</TableInfinityCell>
				<TableInfinityCell style={{width:75}}>{row["borrowerZip"]}</TableInfinityCell>
				<TableInfinityCell style={{width:75}}>{row["fico"]}</TableInfinityCell>
				<TableInfinityCell style={{width:75}}>{row["submitDate"] === null ? "" : Moment( new Date( row["submitDate"] ) ).format( "MM-DD-YYYY" )}</TableInfinityCell>
				<TableInfinityCell style={{width:75}}>{row["bureau"]}</TableInfinityCell>
				<TableInfinityCell style={{width:75}}>{(row["percentFico"]).toFixed(2) + "%" }</TableInfinityCell>
				<TableInfinityCell style={{width:150}}>{row["loanType"]}</TableInfinityCell>
			</TableInfinityRow>
		);
	}

	Header(event){
		return (
			<TableInfinityRow event={event} variant="head">
				<TableInfinityCell style={{width:50}}>Count</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>Last Generated By</TableInfinityCell>
				<TableInfinityCell style={{width:100}}>Application Number</TableInfinityCell>
				<TableInfinityCell style={{width:200}}>Borrower Name</TableInfinityCell>
				<TableInfinityCell style={{width:200}}>Borrower Address</TableInfinityCell>
				<TableInfinityCell style={{width:150}}>Borrower City</TableInfinityCell>
				<TableInfinityCell style={{width:50}}>Borrower State</TableInfinityCell>
				<TableInfinityCell style={{width:75}}>Borrower Zip</TableInfinityCell>
				<TableInfinityCell style={{width:75}}>Borrower Fico</TableInfinityCell>
				<TableInfinityCell style={{width:75}}>Submit Date</TableInfinityCell>
				<TableInfinityCell style={{width:75}}>Borrower Bureau</TableInfinityCell>
				<TableInfinityCell style={{width:75}}>Percent</TableInfinityCell>
				<TableInfinityCell style={{width:150}}>Loan Type</TableInfinityCell>
			</TableInfinityRow>
		);
    }
    
    render(){
		return (
			<TableInfinityLoader
				listGrid={this.props.listGrid}
				drawRow={this.Row}
				drawHeader={this.Header}
				handleSubmit={this.props.handleSubmit}
				params={this.props.filters}
				parent={this}
			/>
		);
    }
}

RiskBasedPrincingNoticeTable.propTypes = {
	filters: PropTypes.object,
	handleSubmit: PropTypes.func,
	listGrid: PropTypes.array
};

export default RiskBasedPrincingNoticeTable;