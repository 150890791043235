import React from "react";
import PropTypes from "prop-types";
import { Table, TableBody, TableHead, TableCell, TableRow, Link } from '@material-ui/core';

const styles = {
	column: {
		padding: 0,
		width: "100px"
    },
    columnPreviousDelinquecy:{
        padding: 0,
		width: "110px"
    },
	columnCurrentDelinquecy: {
		padding: 0,
		width: "85px"
    },
    columnNumberOfLoans:{
        textAlign:"right",
        paddingRight: 15
    },
    columnTotal:{
        textAlign:"right"
    },
    subHeader:{
        padding: 0,
        height: "24px"
    }
};

class RollforwardTable extends React.Component {

    formatMoney = (value) => {
        return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
    };

    numberWithCommasAndLink(value, previousDelinquecy) {
		if(value > 0 && this.props.loadDetails !== undefined)
			return (<Link
					component="button"
					style={{color:"blue", fontSize:11}}
					onClick={this.props.loadDetails.bind(this, previousDelinquecy)}
				>
					{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
				</Link>);
		return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

    render(){
        const { listGrid } = this.props;
        let maxCurrentDelinquecy = Object.keys(listGrid[0].currentsDelinquecy).length;
        const maxWidth = 410 + maxCurrentDelinquecy * 85;
        const total = listGrid.reduce( (result, row ) => result + row["total"], 0 );
        const totalLoans = listGrid.reduce( (result, row ) => result + row["numberOfLoans"], 0 );
        return (
            <Table aria-label="spanning table" style={{maxWidth:maxWidth}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={styles.columnPreviousDelinquecy}>Previous Delinquecy</TableCell>
                        <TableCell colSpan={maxCurrentDelinquecy} align="center" style={styles.columnCurrentDelinquecy}>Current Delinquecy</TableCell>
                        <TableCell style={styles.column}>PIF</TableCell>
                        <TableCell style={styles.column}>Number of Loans</TableCell>
                        <TableCell style={{...styles.column,...{textAlign:"right"}}}>Total</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={styles.subHeader}></TableCell>
                        {Array(maxCurrentDelinquecy).fill(1).map((el, i) =>
                            <TableCell style={styles.subHeader} key={i}>{i}</TableCell>
                        )}
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {listGrid.map( (row, index) =>
                        <TableRow key={index}>
                            <TableCell style={{textAlign:"center"}}>{row["previousDelinquecy"]}</TableCell>
                            {Object.entries( row["currentsDelinquecy"]).map( col => 
                                <TableCell key={col[0]}>{(col[1] * 100).toFixed(2) + "%"}</TableCell>
                                )}
                            <TableCell>{(row["pif"] * 100).toFixed(2) + "%"}</TableCell>
                            <TableCell style={styles.columnNumberOfLoans}>
                                {this.numberWithCommasAndLink(row["numberOfLoans"], row["previousDelinquecy"])}
                            </TableCell>
                            <TableCell style={styles.columnTotal}>{this.formatMoney(row["total"])}</TableCell>
                        </TableRow>
                    )}
                     <TableRow>
                        <TableCell colSpan={maxCurrentDelinquecy+2} style={{textAlign:"right"}}>Total</TableCell>
                        <TableCell style={styles.columnNumberOfLoans}>
                            {this.numberWithCommasAndLink(totalLoans, null)}
                        </TableCell>
                        <TableCell style={styles.columnTotal}>{this.formatMoney(total)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    };
}

RollforwardTable.propTypes = {
    listGrid: PropTypes.array,
    loadDetails: PropTypes.func
};

export default RollforwardTable;