import React from "react";
import PropTypes from "prop-types";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Tooltip from '@material-ui/core/Tooltip';

class PasswordValidator extends React.Component {

	componentDidMount() {
		ValidatorForm.addValidationRule( "isPasswordMatch", ( value ) => {
			if ( value !== this.props.form.password ) {
				return false;
			}
			return true;
		});

		ValidatorForm.addValidationRule( "strenghPassword", (value) => {
			var matchedCase = [/[$@!%*#?&]/, /[A-Z]/, /[0-9]/, /[a-z]/];

			let ctr = 0;
			for (var i = 0; i < matchedCase.length; i++) {
				if (matchedCase[i].test(value)) {
					ctr++;
				}
			}

			return ctr === 4 && value.length >= 8;
		});
	}

	render() {
		const {  form, onChange } = this.props;

		const rulesPassword = <div style={{whiteSpace: 'pre-line'}}>
			{'Your password must have: \n - Minimum 8 characters in length \n - Uppercase Letters \n - Lowercase Letters \n - Numbers \n - Symbols'}
		</div>;

		return (
			<div>
				<Tooltip title={ rulesPassword } >
					<TextValidator
						ref={ input => {
							this.password = input;
						} }
						name="password"
						label="Password"
						fullWidth={ true }
						value={ form.password }
						onChange={ onChange }
						type="password"
						validators={ ["required", "strenghPassword"] }
						errorMessages={ ["Password cannot be blank.", "Password should be contains minimum requirements"] }
					/>
				</Tooltip>
				<TextValidator
					ref={ input => {
						this.confirmpassword = input;
					} }
					name="confirmpassword"
					label="Confirm Password"
					fullWidth={ true }
					value={ form.confirmpassword }
					onChange={ onChange }
					type="password"
					validators={ ["required", "isPasswordMatch"] }
					errorMessages={ ["Confirm Password cannot be blank.", "Your password and confirmation password do not match."] }
				/>
			</div>
		);
	}
}

PasswordValidator.propTypes = {
	form: PropTypes.object,
	onChange: PropTypes.func
};

export default PasswordValidator;
