import { apiGet } from "../../../../../actions";
import Moment from "moment";

export const model = {
	lender: "",
	initialDate: null,
	finalDate: null
};

const defaultPath =
	"api/FundingManagement/FundingPackage/ServicingAdvice/ServicingAdviceReport";

export function loadLenders(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath + "/lenders",
			onSuccess: response => {
				props.setState({lenders: response.data });
			}
		} );
	};
}

export function loadList( props, form ) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath,
			params: {
				lender: form.lender,
				initialDate: !!form.initialDate ? Moment( new Date( form.initialDate ) ).format( "MM-DD-YYYY" ) : null,
				finalDate: !!form.finalDate ? Moment( new Date( form.finalDate ) ).format( "MM-DD-YYYY" ) : null
			},
			onSuccess: response => {
				props.setState({dataList: response.data, isSubmitted: true });
			}
		} );
	};
}
