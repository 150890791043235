import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import {  Modal, Backdrop, Fade } from "@material-ui/core";

const useStyles = theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #7cb342',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(3, 3, 3, 3),
	},
});

class StylizedModal extends Component {

	render() {
		const { classes } = this.props;

		return(
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={this.props.openModal}
				onClose={this.props.onClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
				timeout: 500,
				}}
			>
				<Fade in={this.props.openModal}>
				<div className={classes.paper} style={this.props.style}>
					{this.props.children}
				</div>
				</Fade>

			</Modal>
		);
	}
}

StylizedModal.propTypes = {
	decimalScale: PropTypes.number,
	label: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func,
	prefix: PropTypes.string,
	value: PropTypes.string
};

export default withStyles( useStyles, {withTheme : true})(StylizedModal);
