export const Layout_ActionTypes = {
	ConsentCheckbox_Update: "Layout.ConsentCheckbox.Update",
	NavBar_Open: "Layout.NavBar.Open",
	ClearURL: "Layout.ClearURL"
};

export function createActionUpdateConsentCheckbox( aprove ) {
	return {
		type: Layout_ActionTypes.ConsentCheckbox_Update,
		payload: aprove
	};
}

export function useNavDrawer( condition ) {
	return dispatch => {
		dispatch( {
			type: Layout_ActionTypes.NavBar_Open,
			payload: condition
		} );
	};
}

export function createActionClearURL( clear ) {
	return {
		type: Layout_ActionTypes.ClearURL,
		payload: clear
	};
}
