import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import { withTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import IconDelete from "@material-ui/icons/Delete";
import IconUpdate from "@material-ui/icons/Create";
import DialogYesNo from "../components/DialogYesNo";
import NetworkButton from '../components/NetworkButton';

const styles = {
	data:{
		width: "450px"
	},
	column: {
		width: "200px"
	},
	iconsColumn:{
		width: "100px"
	},
	usrNameColumn:{
		width: "180px"
	},
	dateColumn:{
		width: "140px"
	},
	cmdButton:{
		padding: 0
	},
	buttons: {
		marginTop: 2,
		float: "right",
		marginLeft: 5
	},
	saveButton: {
		marginLeft: 5
	}
};
const mapMainData = [
	{ label: "Application Number", data: "applicationNumber" },
	{ label: "UnderWriter", data: "underWriteName" },
	{ label: "Submit Date", data: "submitDate" },
	{ label: "Canceled Date", data: "canceledDate" },
	{ label: "Loan Type", data: "loanType" },
	{ label: "Application Class", data: "applicationClass" },
	{ label: "Product Type", data: "productType" },
	{ label: "DTI", data: "dti" },
	{ label: "ROR", data: "rorDate" },
	{ label: "ROA", data: "roaDate" },
];
const mapBorrowerData = [
	{ label: "Borrower's Name", data: "borrowerName" },
	{ label: "Borrower's Address", data: "borrowerAddress" },
	{ label: "SSN", data: "borrowerSSN" },
	{ label: "Borrower FICO", data: "borrowerFico" }
];

const mapCoBorrowerData = [
	{ label: "Co Borrower's Name", data: "coBorrowerName" },
	{ label: "Co Borrower's Address", data: "coBorrowerAddress" },
	{ label: "SSN", data: "coBorrowerSSN" },
	{ label: "Co Borrower FICO", data: "coBorrowerFico" },
];
const mapPropertyData = [
	{ label: "Dealer Name", data: "dealerName" },
	{ label: "Dealer Address", data: "dealerAddress" },
	{ label: "Dealer Phone number", data: "phone" },
	{ label: "Dealer Wire Bank", data: "bankName" },
	{ label: "Dealer ABA Number", data: "abaNumber" },
	{ label: "Dealer Wire Account #", data: "accounterNumber" },
	{ label: "Dealer Association", data: "dealerAssociation" },
];
const mapLockData = [
	{ label: "Funding Date", data: "fundedDate" },
	{ label: "Funding Price", data: "fundingPrice" },
	{ label: "Funding Amount", data: "fundingAmount" },
	{ label: "Original Balance", data: "originalBalance" },
	{ label: "Advanced Rate", data: "rate" },
	{ label: "Original Term", data: "term" },
	{ label: "First Payment Date", data: "fpDate" },
];

class ApplicationDetailTable extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			currNoteValue : "",
			currNoteId: 0,
			isEditting: false,
			showDeleteDialogConfirmation: false,
			itemToDelete: -1
		};
	}

	resetState(){
		this.setState({
			currNoteValue : "",
			currNoteId: 0,
			isEditting: false,
			showDeleteDialogConfirmation: false,
			itemToDelete: -1
		});
	}

	currencyTableCell(value="0.0"){
		return <TableCell>{value.toLocaleString("en-US", { style: "currency", currency: "USD" })}</TableCell>;
	}

	getTableBody( mapDatas ) {
		const { applicationDetails } = this.props;

		return (
			<TableBody>
				{mapDatas.map( (map, index) =>
					<TableRow key={index}>
						<TableCell style={styles.column}> { map.label } </TableCell>
						<TableCell align="right" style={styles.data}> { applicationDetails[map.data] } </TableCell>
					</TableRow>
				)}
			</TableBody>
		);
	}

	getTable(title, mapData){
		return (
			<Table style={{width:"50%"}}>
				<TableHead>
					<TableRow key="rowHeader">
						<TableCell style={{fontSize: 14}}>
							{title}
						</TableCell>
					</TableRow>
				</TableHead>
				{ this.getTableBody(mapData)}
			</Table>
		);
	}

	formatDate(dateAndTime){
		const onlyDate = dateAndTime.split('T')[0];
		const dateValues = onlyDate.split('-');
		return dateValues[1] + '/' + dateValues[2] + '/' + dateValues[0];
	}

	handleEditItem(noteObj){
		this.setState({
			currNoteId:noteObj.id,
			currNoteValue: noteObj.note,
			isEditting: true
		});
	}

	getItemIndex(index, len){
		return Math.abs(index - len);
	}

	handleShowDeleteDialog(state){
		this.setState({showDeleteDialogConfirmation: state});
	}

	handleDeleteDialog(){
		this.props.onDelete(this.state.itemToDelete);
		this.resetState();
	}

	handleDelete(state, index){
		this.setState({
			showDeleteDialogConfirmation: state,
			itemToDelete: index
		});
	}

	getNotes(notes){
		if(!notes) return <div />;
		return (
			notes.map((noteObj, index)=>
			<Fragment key={index}>
				<TableRow key={index}>
					<TableCell style={styles.iconsColumn}>
						<IconButton style={ styles.cmdButton } tooltip="Edit" onClick={ this.handleEditItem.bind(this, noteObj) }>
							<IconUpdate />
						</IconButton>
						<IconButton style={ styles.cmdButton } tooltip="Delete" onClick={ this.handleDelete.bind(this, true, index) }>
							<IconDelete />
						</IconButton>
					</TableCell>
					<TableCell style={styles.usrNameColumn}>{noteObj["userFullName"]}</TableCell>
					<TableCell style={styles.dateColumn}>{this.formatDate(noteObj["date"])}</TableCell>
					<TableCell>{noteObj["note"]}</TableCell>
				</TableRow>
			</Fragment>
			)
		);
	}

	getNotesTable(notes){
		if(!this.props.showNotes) return <div/>;

		return(
			<Paper style={{overflow:'auto', height:'260px', width:"100%", marginTop:"50px", padding:0}}>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell style={styles.iconsColumn}></TableCell>
							<TableCell style={styles.usrNameColumn}>User Name</TableCell>
							<TableCell style={styles.dateColumn}>Date</TableCell>
							<TableCell>Note</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<DialogYesNo
							onShow = {this.handleShowDeleteDialog.bind(this, false)}
							onHide = {this.handleShowDeleteDialog.bind(this, false)}
							status = {this.state.showDeleteDialogConfirmation}
							onConfirmation={this.handleDeleteDialog.bind(this)}
							text={ "Are you sure you want to delete this item?" }
						/>
						{this.getNotes(notes)}
					</TableBody>
				</Table>
			</Paper>
		);
	}

	getNotesValue = event =>{
		this.setState({currNoteValue: event.target.value});
	}

	handleSaveNotes(){
		this.resetState();
		this.props.onSaveNotes( this.state.currNoteId ,this.state.currNoteValue);
	}

	getNoteField(){
		if(this.props.onSaveNotes === undefined)
			return <div/>;

		return (
			<Fragment>
				<Typography variant="h6" align="left" style={{padding:"20px 0px 20px 0px"}}>Application Notes</Typography>
				<TextareaAutosize
					aria-label="minimum height"
					rows={3}
					placeholder="Add notes"
					style={{width:"100%", maxWidth:"100%"}}
					onChange={this.getNotesValue}
					value={this.state.currNoteValue}
				/>
				<div style={styles.buttons}>
					<Fade in={this.state.isEditting}>
						<NetworkButton text={ "Cancel" } onClick={ this.resetState.bind(this) } />
					</Fade>
					<NetworkButton
						onClick={this.handleSaveNotes.bind(this)}
						style={styles.saveButton}
						primary={ true }
						type="submit"
						text={"Save note"}
					/>
				</div>
			</Fragment>
		);
	}

	render() {
		const { applicationDetails, onClose } = this.props;
		if(applicationDetails === undefined)
		return (<div/>);

		return (
			<div style={{maxWidth:1350}}>
				<NetworkButton text={ "Close" } onClick={onClose} style={ { float: "right" } }/>
				<Typography variant="h6" align="center">Application Details</Typography>
				<div style={{display:"inline-flex", width:"100%"}}>
					{this.getTable("Application Information", mapMainData)}
				<div style={{width:20}} />
					{this.getTable("Dealer Information", mapPropertyData)}
				</div>
				<div style={{display:"inline-flex", width:"100%"}}>
					{this.getTable("Borrower Information", mapBorrowerData)}
				<div style={{width:20}} />
					{this.getTable("Lock Information", mapLockData)}
				</div>
				<div style={{display:"inline-flex", width:"100%"}}>
					{this.getTable("Co-Borrower Information", mapCoBorrowerData)}
				<div style={{width:20}} />
				</div>
				<div>
					{this.getNoteField()}
				</div>
				{this.getNotesTable(applicationDetails.notes)}
			</div>
		);
	}
}

ApplicationDetailTable.propTypes = {
	applicationDetails: PropTypes.object,
	onClose: PropTypes.func,
	onDelete: PropTypes.func,
	onSaveNotes: PropTypes.func,
	showNotes: PropTypes.bool
};

export default withTheme(ApplicationDetailTable);