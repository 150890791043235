import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { doLogout, checkIsLoggedIn, checkUserHasPermission, getUserIdByToken, checkPasswordIsValid } from "../pages/auth/login/actions";
import { useNavDrawer, createActionClearURL } from "./actions";

import Login from "../pages/auth/login/Login";
import RequestResetPassword from "../pages/auth/login/RequestResetPassword";
import ResetPassword from "../pages/auth/login/ResetPassword";
import ChangePassword from "../pages/auth/login/ChangePassword";

import "../css/common.css";
import "font-awesome/css/font-awesome.css";

import { MuiThemeProvider } from "@material-ui/core/styles";
import ThemeDefault from "../theme-default";
import Header from "../commons/components/Header";
import NavBar from "../commons/components/NavBar";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import LoadingBar from "react-redux-loading-bar";
import ErrorMessage, { createActionSetError } from "../errors";
import { createActionNetworkClearMessage } from "../actions";

import { Route, Switch, Redirect } from "react-router-dom";

import Home from "../pages/home/Home";
import PageNotFound from "../pages/PageNotFound";

import Access from "../pages/auth/access/Access";
import Lender from "../pages/auth/lender/Lender";
import Role from "../pages/auth/role/Role";
import UserAccount from "../pages/auth/userAccount/UserAccount";

import CreateFundingPackage from "../pages/fundingManagement/fundingPackage/createFundingPackage/CreateFundingPackage";
import ViewerFundingPackage from "../pages/fundingManagement/fundingPackage/viewerFundingPackage/ViewerFundingPackage";
import DeleteFundingPackage from "../pages/fundingManagement/fundingPackage/deleteFundingPackage/DeleteFundingPackage";
import ServicingAdviceSend from "../pages/fundingManagement/fundingPackage/servicingAdvice/send/ServicingAdviceSend";
import ServicingAdviceReport from "../pages/fundingManagement/fundingPackage/servicingAdvice/report/ServicingAdviceReport";
import ReconciliationReport from "../pages/fundingManagement/reconciliation/report/Reconciliation";
import AvailableFundsReconciliation from "../pages/fundingManagement/reconciliation/availableFunds/AvailableFunds";
import LenderLoanType  from "../pages/fundingManagement/lenderLoanType/LenderLoanType";
import FacilityLimitReport from "../pages/fundingManagement/facilityLimit/FacilityLimit";
import DeterminationDateIssue from "../pages/fundingManagement/determinationDateIssue/DeterminationDateIssue";
import OriginationSummary from "../pages/fundingManagement/originationSummary/OriginationSummary";
import FundingReport from "../pages/fundingManagement/fundingReport/FundingReport";
import ChuckReport from "../pages/fundingManagement/chuckReport/ChuckReport";
import SearchFundingManagement from "../pages/fundingManagement/searchFundingManagement/SearchFundingManagement";

import DiscountRebates from "../pages/fundingManagement/rebates/discount/DiscountRebates";
import DiscountPromotionRebates from "../pages/fundingManagement/rebates/promotion/DiscountRebatePromotion";
import DiscountPromotionDealerRebates from "../pages/fundingManagement/rebates/promotionDealer/DiscountRebatePromotionDealer";
import AdverseActionLetter from "../pages/fundingManagement/adverseActionLetter/AdverseActionLetter";
import RiskBasedPrincingNotice from "../pages/fundingManagement/riskBasedPrincingNotice/RiskBasedPrincingNotice"

import PtHud from "../pages/fundingManagement/pthud/PtHud";
import InventoryQuery from "../pages/assetManager/inventoryQuery/InventoryQuery";
import InventorySummary from "../pages/assetManager/inventorySummary/InventorySummary";
import RollforwardReport from "../pages/assetManager/rollforwardReport/RollforwardReport";

import DealerTrendReport from "../pages/dealerManagement/dealerTrend/DealerTrendReport";
import DealerReport from "../pages/dealerManagement/dealerReport/DealerReport";
import DealerTrackingReport from "../pages/dealerManagement/dealerTrackingReport/DealerTrackingReport";
import DealerTrackingLennoxReport from "../pages/dealerManagement/dealerTrackingLennoxReport/DealerTrackingLennoxReport";
import DealerTrackingReportSubmittedApp from "../pages/dealerManagement/dealerTrackingReportSubmittedApp/DealerTrackingReportSubmittedApp";
import DealerTrackingSummaryReport from "../pages/dealerManagement/dealerTrackingSummaryReport/DealerTrackingSummaryReport";
import SummaryCultivation from "../pages/dealerManagement/summaryCultivation/SummaryCultivation";
import DealerProductionReport from "../pages/dealerManagement/dealerProductionReport/DealerProductionReport";
import DealerBDRatioReport from "../pages/dealerManagement/dealerBDRatioReport/DealerBDRatioReport";
import FinalDealerPackageReport from "../pages/dealerManagement/finalDealerPackageReport/FinalDealerPackageReport";
import ConcentrationLimits from "../pages/fundingManagement/concentrationLimits/ConcentrationLimits";
import DealerPerformanceReport from "../pages/dealerManagement/dealerPerformanceReport/DealerPerformanceReport";

const mapStateToProps = (store) => ({
	layout: store.layout,
	auth: store.auth,
	network: store.network,
	routing: store.routing.locationBeforeTransitions
} );
const mapDispatchToProps = {
	useNavDrawer,
	createActionClearURL,
	doLogout,
	createActionSetError,
	createActionNetworkClearMessage,
	checkIsLoggedIn,
	getUserIdByToken,
	checkPasswordIsValid
};
const LARGE = "lg";
const SMALL = "sm";
class Layout extends React.Component {

	state = {
		showErrorMessage: false,
		messages: [],
	};

	componentDidMount() {
		this.props.checkIsLoggedIn();
		this.props.getUserIdByToken();
		this.props.checkPasswordIsValid();
	}

	componentDidUpdate(prevProps, prevState) {
		if ( prevProps.width !== this.props.width ) {
			this.props.useNavDrawer( this.props.width === LARGE );
		}

		if( this.props.layout.clearURL ) {
			this.props.history.push('/');
			this.props.createActionClearURL( true );
		}

		if( this.props.network.message !== "" ) {
			this.setState( {
				showErrorMessage: true,
				messages: [ ...this.state.messages, this.props.network.message ]
			} );
			this.props.createActionNetworkClearMessage();
		}

		if( this.state.messages.length > 0 && !this.state.showErrorMessage ) {
			this.setState({
				...this.state,
				showErrorMessage: true
			});
		}
	}

	getFirstMessage = () => {
		if( this.state.messages.length > 0 ) {
			return this.state.messages[0];
		}
		return "";
	}

	handleRequestClose = () => {
		this.props.createActionSetError( "" );
		this.setState( {
			showErrorMessage: false,
			messages: this.state.messages.slice(1)
		} );
	};

	handleChangeRequestNavDrawer() {
		this.props.useNavDrawer( !this.props.layout.navDrawerOpen );
	}

	handleDoLogout = () => {
		this.props.doLogout();
		this.setState({showErrorMessage:false});
		this.props.history.push("/");
	};

	getContentPage( isLoggedIn, needsPasswordUpdate) {
		try {
			if ( !isLoggedIn || needsPasswordUpdate ) 
			{
				return (
					<Switch>
						<Route path="/ForgotPassword" component={ RequestResetPassword } />
						<Route path="/resetpassword/:hashcode" component={ ResetPassword } />
						<Route path="/resetpassword" component={ ChangePassword } />
						<Route path="/" component={ Login } />
					</Switch>
				);
			}
			else {
				return (
					<LoggedIn
						logout={ this.handleDoLogout }
						width={ this.props.width }
						menus={ this.props.auth.login.menus }
						navDrawerOpen={ this.props.layout.navDrawerOpen }
						handleChangeRequestNavDrawer={ this.handleChangeRequestNavDrawer.bind(
							this
						) }
					/>
				);
			}
		} catch ( e ) {
			this.props.doLogout();
			return <Login />;
		}
	}

	render() {
		return (
			<MuiThemeProvider theme={ ThemeDefault }>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<LoadingBar
						style={ { position: "fixed", backgroundColor: "red", zIndex: "9999", height: 5 } }
						maxProgress={ 99 }
						progressIncrease={ 1 }
					/>
					{this.getContentPage( this.props.auth.login.isLoggedIn, this.props.auth.login.needsPasswordUpdate )}
					<ErrorMessage
						showMessage={ this.state.showErrorMessage }
						text={ this.getFirstMessage() }
						onRequestClose={ this.handleRequestClose }
					/>
				</MuiPickersUtilsProvider>
			</MuiThemeProvider>
		);
	}
}

const PrivateRoute = ( { component: Component, ...rest } ) => (
	<Route
		{ ...rest }
		render={ ( props ) => {
			if (checkUserHasPermission( props.match.url )) {
				return <Component { ...props } />;
			} else {
				return <Redirect to={ { pathname: "/", state: { from: props.location } } } />;
			}
		} }
	/>
);

const LoggedIn = ( {
	logout,
	navDrawerOpen,
	width,
	handleChangeRequestNavDrawer,
	menus
} ) => {
	const paddingLeftDrawerOpen = 236;
	const styles = {
		header: {
			paddingLeft: navDrawerOpen ? paddingLeftDrawerOpen : 0
		},
		container: {
			margin: "70px 8px 8px 8px",
			paddingLeft: navDrawerOpen && width !== SMALL ? paddingLeftDrawerOpen : 0
		}
	};


	return (
		<div>
			<Header
				logout={ logout }
				styles={ styles.header }
				handleChangeRequestNavDrawer={ handleChangeRequestNavDrawer }
			/>
			<NavBar navDrawerOpen={ navDrawerOpen } menus={ menus } username="" />
			<div style={ styles.container }>
				<Switch>
					<Route exact path="/" component={ Home } />
					<PrivateRoute path="/Access" component={ Access } />
					<PrivateRoute path="/Lender" component={ Lender } />
					<PrivateRoute path="/Role" component={ Role } />
					<PrivateRoute path="/UserAccount" component={ UserAccount } />
			
					<PrivateRoute path="/CreateFundingPackage" component={ CreateFundingPackage } />
					<PrivateRoute path="/ViewerFundingPackage" component={ ViewerFundingPackage } />
					<PrivateRoute path="/DeleteFundingPackage" component={ DeleteFundingPackage } />
					<PrivateRoute path="/FundingManagement/Reconciliation/Report" component={ ReconciliationReport } />
					<PrivateRoute path="/FundingManagement/Reconciliation/AvailableFunds" component={ AvailableFundsReconciliation } />
					<PrivateRoute path="/FundingManagement/LenderLoanType" component={ LenderLoanType } />
					<PrivateRoute path="/FundingManagement/FacilityLimit" component={ FacilityLimitReport } />
					<PrivateRoute exact path="/FundingManagement/FundingPackage/ServicingAdvice/Send" component={ ServicingAdviceSend } />
					<PrivateRoute exact path="/FundingManagement/FundingPackage/ServicingAdvice/Report" component={ ServicingAdviceReport } />
					<PrivateRoute exact path="/FundingManagement/Rebates/DiscountRebate" component={ DiscountRebates } />
					<PrivateRoute exact path="/FundingManagement/Rebates/DiscountRebatePromotion" component={ DiscountPromotionRebates } />
					<PrivateRoute exact path="/FundingManagement/Rebates/DiscountRebatePromotionDealer" component={ DiscountPromotionDealerRebates } />
					<PrivateRoute exact path="/FundingManagement/DeterminationDateIssue" component={ DeterminationDateIssue } />
					<PrivateRoute exact path="/FundingManagement/OriginationSummary" component={ OriginationSummary } />
					<PrivateRoute exact path="/FundingManagement/FundingReport" component={ FundingReport } />
					<PrivateRoute exact path="/FundingManagement/AdverseActionLetter" component={ AdverseActionLetter } />
					<PrivateRoute exact path="/FundingManagement/RiskBasedPrincingNotice" component={ RiskBasedPrincingNotice } />
					<PrivateRoute exact path="/FundingManagement/ChuckReport" component={ ChuckReport } />
					<PrivateRoute exact path="/FundingManagement/SearchFundingManagement" component={ SearchFundingManagement } />
					<PrivateRoute exact path="/FundingManagement/PTHUD" component={ PtHud }/>
					<PrivateRoute exact path="/FundingManagement/ConcentrationLimits" component={ConcentrationLimits}/>
					<PrivateRoute exact path="/AssetManager/InventorySummary" component={ InventorySummary } />
					<PrivateRoute exact path="/AssetManager/RollforwardReport" component={ RollforwardReport } />
					<PrivateRoute exact path="/AssetManager/InventoryQuery" component={ InventoryQuery } />
					<PrivateRoute exact path="/DealerManagement/DealerTrendReport" component={ DealerTrendReport } />
					<PrivateRoute exact path="/DealerManagement/DealerReport" component={ DealerReport } />
					<PrivateRoute exact path="/DealerManagement/DealerTrackingReport" component={ DealerTrackingReport } />
					<PrivateRoute exact path="/DealerManagement/DealerTrackingLennoxReport" component={ DealerTrackingLennoxReport } />
					<PrivateRoute exact path="/DealerManagement/DealerTrackingReportSubmittedApp" component={ DealerTrackingReportSubmittedApp } />
					<PrivateRoute exact path="/DealerManagement/DealerTrackingSummaryReport" component={ DealerTrackingSummaryReport } />
					<PrivateRoute exact path="/DealerManagement/SummaryCultivation" component={ SummaryCultivation } />
					<PrivateRoute exact path="/DealerManagement/DealerProductionReport" component={ DealerProductionReport } />
					<PrivateRoute exact path="/DealerManagement/DealerBDRatioReport" component={ DealerBDRatioReport } />
					<PrivateRoute exact path="/DealerManagement/FinalDealerPackageReport" component={ FinalDealerPackageReport } />
					<PrivateRoute exact path="/DealerManagement/DealerPerformanceReport" component={ DealerPerformanceReport } />

					<Route component={ props => <PageNotFound {...props} /> } />
				</Switch>
			</div>
		</div>
	);
};

LoggedIn.propTypes = {
	handleChangeRequestNavDrawer: PropTypes.func,
	logout: PropTypes.func,
	menus: PropTypes.array,
	navDrawerOpen: PropTypes.bool,
	width: PropTypes.number
};

Layout.propTypes = {
	auth: PropTypes.object,
	checkIsLoggedIn: PropTypes.func,
	checkUserHasPermission: PropTypes.func,
	createActionClearURL: PropTypes.func,
	createActionSetError: PropTypes.func,
	doLogout: PropTypes.func,
	getMenus: PropTypes.func,
	history: PropTypes.object,
	layout: PropTypes.object,
	network: PropTypes.object,
	useNavDrawer: PropTypes.func,
	width: PropTypes.number
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)( withRouter( Layout ));