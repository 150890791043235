import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from '@material-ui/core';

const styles = {
    note: {
		fontSize: 15,
		textAlign: "center"
	},
	visible: {
		display: "none"
	}
};

class TabFinish extends Component {
    render(){
        return(
            <div>
                <div style={ !this.props.onNetwork ? styles.visible : null }>
                    <div style={ styles.note }>Sending Pre-Fund Report...</div>
                    <div style={ styles.note }>Creating ACH File...</div>
                    <div style={ styles.note }>Creating Package...</div>
                    <div style={ styles.note }>
                        Please, wait some seconds until I finish these tasks.
                    </div>
                    <div style={ styles.note }>Thanks for your patience.</div>
                </div>
                <div style={ !this.props.onNetwork ? null : styles.visible }>
                    <div style={ styles.note }>
                        <i
                            className="fa fa-check"
                            style={ { verticalAlign: "middle", color: "green" } }
                        >
                            {" "}
                        </i>{" "}
                        Pre-Fund Report sent.
                    </div>
                    <div style={ styles.note }>
                        <i
                            className="fa fa-check"
                            style={ { verticalAlign: "middle", color: "green" } }
                        >
                            {" "}
                        </i>{" "}
                        ACH File created.
                    </div>
                    <div style={ styles.note }>
                        <i
                            className="fa fa-check"
                            style={ { verticalAlign: "middle", color: "green" } }
                        >
                            {" "}
                        </i>{" "}
                        Package created.
                    </div>
                    <div style={ styles.note }>
                        <div style={ !this.props.onNetwork ? null : styles.visible }>
                            <a
                                href="/CreateFundingPackage"
                                onClick={ this.handleResetPackage }
                            >
                                {" "}
                                Click here{" "}
                            </a>{" "}
                            to go back to the beginning.
                        </div>
                    </div>
                    <div style={ styles.note }>
                        <div style={ !this.props.onNetwork ? null : styles.visible }>
                            <Link
                                component="button"
                                style={{color:"blue", fontSize:15, margin:"-3px 3px 0px 0px", textDecoration:"underline"}}
                                onClick={this.props.handleSelectLender.bind(this)}
                            >
                                Click here 
                            </Link>
                            to select a new lender.
                        </div>
                    </div>
                    <div style={ styles.note }>
                        <div style={ !this.props.onNetwork ? null : styles.visible }>
                            <a
                                href={`/ViewerFundingPackage?Lender=${this.props.lender}`}
                                onClick={ this.handleResetPackage }
                            >
                                {" "}
                                Click here{" "}
                            </a>{" "}
                            to go to Viewer Funding Package.
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

TabFinish.propTypes = {
    lender: PropTypes.number,
    handleSelectLender: PropTypes.func,
    handleResetPackage: PropTypes.func,
    onNetwork: PropTypes.bool
};

export default TabFinish;