import fileDownload from "js-file-download";
import { apiGet } from "../../../actions";

const defaultPath = "api/AssetManager/InventorySummary";

export const InventorySummaryActionTypes = {
    LoadList: "InventorySummary.LoadList"
};

export function loadGrid(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath,
			onSuccess: response => {
				props.setState({list: response.data});
			}
		} );
	};
};

export function downloadPrepayExcel( reportName ) {
	return dispatch => {
		apiGet( dispatch, {
			responseType: "blob",
			url:defaultPath + "/Prepay/" + reportName,
			onSuccess: response => {
				fileDownload( response.data, "Prepay_" + reportName + ".xlsx" );
			}
		} );
	};
};

export function downloadStratReport( reportName, asOfDate ) {
	return dispatch => {
		apiGet( dispatch, {
			responseType: "blob",
			url:defaultPath + "/Strat/" + reportName,
			onSuccess: response => {
				const d = new Date(asOfDate);
				fileDownload( response.data, `${d.getFullYear()}${String(d.getMonth() + 1).padStart(2, "0")}${String(d.getDate()).padStart(2, "0")}_` + reportName + "_strat.xlsx" );
			}
		} );
	};
};