import React from "react";
import DealerPerformanceReportFilter from "./DealerPerformanceReportFilter";
import SingleScroll from "../../../commons/components/SingleScroll";
import PageBase from "../../../commons/components/PageBase";

const singleScrollName = "DealerPerformanceReport";

class DealerPerformanceReport extends React.Component {
	render() {
		return (
			<PageBase title="Dealer Performance Report" >
				<SingleScroll name={singleScrollName}>
					<SingleScroll.Scrollable for={singleScrollName}>
						<DealerPerformanceReportFilter />
					</SingleScroll.Scrollable>
				</SingleScroll>
			</PageBase>
		);
	}
}

export default DealerPerformanceReport;