import React from "react";
import PropTypes from "prop-types";
import {FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Checkbox, Select, InputLabel} from '@material-ui/core';
import NetworkButton from "../../../commons/components/NetworkButton";
import DatePickerRange from "../../../commons/components/DatePickerRange";
import MultiSelect from "../../../commons/components/MultiSelect";
import IsolateEvent from "../../../commons/components/IsolateEvent";
import { renderMenuItems } from "../../../commons/utils";
class ChuckReportFilters extends React.Component {

	handleMultiSelectFilter = (field, value) => {
		if (value instanceof Array && value.includes("All")) {
			this.props.changeFilter(field, ["All"]);
		} else {
			this.props.changeFilter(field, value);
		}
	}

	handleSelectFilter = (event) => {
		this.props.changeFilter(event.target.name, event.target.value);
	}
	
	handleToggle = (field, event) => {
		this.props.changeFilter(field, event.target.value);
	}

	handleDate= (field, date) => {
		this.props.changeFilter(field, date);
	}

	render(){
		const { filters, dealerAssociationList, dealerNameList, contactMethodList } = this.props;
		return (
			<Grid container style={{marginTop:10}}>
				<Grid container item xs={12} spacing={3}>
					<Grid item>
						<FormControl component="fieldset">
							<FormLabel component="legend">{"Ignore fico < 600"}</FormLabel>
							<RadioGroup name="ignoreFico" value={filters.ignoreFico} row onChange={ this.handleToggle.bind(this, "ignoreFico")} >
								<FormControlLabel value="yes" control={<Radio color="primary"/>} label="Yes" />
								<FormControlLabel value="no" control={<Radio color="primary"/>} label="No" />
							</RadioGroup>
						</FormControl>
					</Grid>
					<Grid item>
						<FormControl component="fieldset">
							<FormLabel component="legend">Ignore duplicated declined</FormLabel>
							<RadioGroup name="ignoreDuplicatedDeclined" value={filters.ignoreDuplicatedDeclined} row onChange={ this.handleToggle.bind(this, "ignoreDuplicatedDeclined")} >
								<FormControlLabel value="yes" control={<Radio color="primary"/>} label="Yes" />
								<FormControlLabel value="no" control={<Radio color="primary"/>} label="No" />
							</RadioGroup>
						</FormControl>
					</Grid>
					<Grid item>
						<FormControl component="fieldset">
							<FormLabel component="legend">Ignore Skeps</FormLabel>
							<RadioGroup name="ignoreSkeps" value={filters.ignoreSkeps} row onChange={ this.handleToggle.bind(this, "ignoreSkeps")} >
								<FormControlLabel value="yes" control={<Radio color="primary"/>} label="Yes" />
								<FormControlLabel value="no" control={<Radio color="primary"/>} label="No" />
							</RadioGroup>
						</FormControl>
					</Grid>
					<Grid item xs={2}>
						<FormControl style={{width:'100%'}}>
							<InputLabel>Contact Method</InputLabel>
							<Select inputProps={{ name: "contactMethod" }} value={filters.contactMethod} onChange={this.handleSelectFilter}>
								{ renderMenuItems(contactMethodList, true) }
							</Select>
						</FormControl>
					</Grid>
					<Grid item>
						<FormControlLabel
							control={
								<Checkbox
									checked={filters.selectWrenchGroup}
									onChange={this.props.handleCheckbox}
									name="selectWrenchGroup"
									color="primary"
								/>
							}
							label="Select Wrench Group"
						/>
					</Grid>

				</Grid>
				<Grid container item xs={12} spacing={3}>
					<Grid item xs>
						<IsolateEvent wheel scroll>
							<MultiSelect options={ dealerAssociationList} label={"Dealer Association"} values={ filters.dealerAssociation } name={ "dealerAssociation" } changeInputSelect={ this.handleMultiSelectFilter } height={300} />
						</IsolateEvent>
					</Grid>
					<Grid item xs>
						<IsolateEvent wheel scroll>
							<MultiSelect options={ dealerNameList} label={"Dealer Name"} values={ filters.dealerId } name={ "dealerId" } changeInputSelect={ this.handleMultiSelectFilter } height={300}/>
						</IsolateEvent>
					</Grid>
				</Grid>				
				<Grid container item xs={12} spacing={3}>
					<Grid container item xs={6}>
						<Grid item xs>
							<DatePickerRange
								label={"Loan Funding Date"}
								fromDate={filters.fromLoanFundingDate} 
								fromDateId="fromLoanFundingDate" 
								toDate={filters.toLoanFundingDate} 
								toDateId="toLoanFundingDate" 
								onDatesChange={this.handleDate}
							/>
						</Grid>
						<Grid item xs>
							<DatePickerRange
								label={"Application Submit Date"}
								fromDate={filters.fromSubmitDate} 
								fromDateId="fromSubmitDate" 
								toDate={filters.toSubmitDate} 
								toDateId="toSubmitDate" 
								onDatesChange={this.handleDate}
							/>
						</Grid>
					</Grid>
					<Grid container item xs={6} alignItems="flex-end">
						<NetworkButton text={ "Reset" }  primary={ true } style={{margin: 10, height: 36}} onClick={ this.props.handleReset } />
						<NetworkButton text={ "Excel" }  primary={ true } style={{margin: 10, height: 36}} onClick={ this.props.handleExcel } />
					</Grid>
				</Grid>
			</Grid>
		);
	};
};

ChuckReportFilters.propTypes = {
	changeFilter: PropTypes.func,
	dealerAssociationList: PropTypes.array,
	dealerNameList: PropTypes.array,
	filters: PropTypes.object,
	handleReset: PropTypes.func,
	handleExcel: PropTypes.func,
	handleCheckbox: PropTypes.func,
	contactMethodList: PropTypes.array,
};

export default ChuckReportFilters;
