import React from "react";
import PropTypes from "prop-types";
import { Link, Typography } from "@material-ui/core";
import ApplicationDetailTable from "./ApplicationDetailTable";
import StylizedModal from "./StylizedModal";
import NetworkButton from "./NetworkButton";
import TableInfinityLoader, { TableInfinityCell, TableInfinityRow } from "./TableInfinityLoader";
import IsolateEvent from "./IsolateEvent";

const currencyColumnWidth = 120;
const nameColumnWidth = 150;
const lenderColumnWidth = 40;
const bigNumberColumnWidth = 80;
const smallNumberColumnWidth = 60;

class ModalTableApplication extends React.Component {
	state = {
		openModal: false,
		applicationDetails: null,
	};

	currency(value="0.0"){
		return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
	}

	handleClose = () => {
		this.setState({ 
			openModal: false,
			applicationDetails: null
		});
	};

	handleDeleteNote(noteIndex){
		if(noteIndex < 0) return;
		const note = this.state.applicationDetails.notes[noteIndex];
		note.deletedBy = "user";
		this.props.saveApplicationNote(this, note, this.state.applicationDetails.applicationNumber);
	}

	handleSubmit = (params) => {
		if (this.props.onLoadPage) {
			this.props.onLoadPage(params);
		}
	}

	createNoteObject(id, applicationID, text){
		const noteObj = {
			id: id,
			applicationID: applicationID,
			deletedBy: null,
			note: text
		};
		return noteObj;
	}

	handleSaveNote(noteId, text){
		const note = this.createNoteObject(noteId, this.state.applicationDetails.applicationNumber, text);
		this.props.saveApplicationNote(this, note, note.applicationID);
	}

	handleLoadApplicationDetail = (applicationID) => {
		this.props.onLoadDetails(this, applicationID);
	};

	Header(event) {
		return (
			<TableInfinityRow event={event} variant="head">
				<TableInfinityCell style={{width: bigNumberColumnWidth}}>Application Number</TableInfinityCell>
				<TableInfinityCell style={{width: nameColumnWidth}}>Borrower</TableInfinityCell>
				<TableInfinityCell style={{width: lenderColumnWidth}}>Lender</TableInfinityCell>
				<TableInfinityCell style={{width: currencyColumnWidth}}>Current Balance</TableInfinityCell>
				<TableInfinityCell style={{width: smallNumberColumnWidth}}>Delinquency</TableInfinityCell>
				<TableInfinityCell style={{width: nameColumnWidth}}>Dealer Name</TableInfinityCell>
				<TableInfinityCell style={{width: smallNumberColumnWidth}}>FICO</TableInfinityCell>
				<TableInfinityCell style={{width: smallNumberColumnWidth}}>DTI</TableInfinityCell>
			</TableInfinityRow>
		);
	}

	Row(event, row, parent) {
		return (
			<TableInfinityRow event={event} variant="body">
				<TableInfinityCell style={{width: bigNumberColumnWidth}}><Link component="button" style={{color:"blue", fontSize: "10px"}} onClick={parent.handleLoadApplicationDetail.bind(parent, row["applicationNumber"])}>{row["applicationNumber"]}</Link></TableInfinityCell>
				<TableInfinityCell style={{width: nameColumnWidth}}>{row["borrowerName"]}</TableInfinityCell>
				<TableInfinityCell style={{width: lenderColumnWidth}}>{row["lender"]}</TableInfinityCell>
				<TableInfinityCell style={{width: currencyColumnWidth, textAlign: "right"}}>{parent.currency(row["currentBalance"])}</TableInfinityCell>
				<TableInfinityCell style={{width: smallNumberColumnWidth, textAlign: "right"}}>{row["daysDelinquecy"].toLocaleString()}</TableInfinityCell>
				<TableInfinityCell style={{width: nameColumnWidth}}>{row["dealerName"]}</TableInfinityCell>
				<TableInfinityCell style={{width: smallNumberColumnWidth, textAlign: "right"}}>{row["fico"].toLocaleString()}</TableInfinityCell>
				<TableInfinityCell style={{width: smallNumberColumnWidth, textAlign: "right"}}>{row["dti"]}</TableInfinityCell>
			</TableInfinityRow>
		);
	}

	createTableDetails(){
		const { detailsResult } = this.props;
		return (
			<fieldset>
				<legend><Typography style={{color: "rgba(0,0,0,0.47)"}}>{detailsResult.label}</Typography></legend>
				<div style={{overflow: "auto", maxHeight: "600px"}}>
					<TableInfinityLoader
						listGrid={detailsResult.list}
						drawRow={this.Row}
						drawHeader={this.Header}
						params={this.state.params}
						handleSubmit={this.handleSubmit}
						parent={this}
					/>
				</div>
			</fieldset>
		);
	}

	showApplicationDetails(){
		const { applicationDetails } = this.state;
		if(!applicationDetails) return <div/>;

		return(
			<StylizedModal
				openModal={this.state.openModal}
				onClose={this.handleClose}
				style={{height:"90%", overflow:"auto "}}
			>	
				<ApplicationDetailTable 
					onClose={this.handleClose} 
					applicationDetails={ applicationDetails }
					showNotes
					onSaveNotes = {this.handleSaveNote.bind(this)}
					onDelete = {this.handleDeleteNote.bind(this)}
				/>
			</StylizedModal>
		);
	};

	render() {
		if(this.props.detailsResult.list.length === 0)
			return <div/>;
			
		return (
			<IsolateEvent wheel scroll>
				<div style={{float:"right", marginLeft:"50%"}}>
					<NetworkButton  
						onClick={this.props.onDownloadExcel}
						style={{margin: 10, height: 36}}
						primary={ true }
						type="submit"
						text={"Excel"}
					/>
					<NetworkButton text={ "Close" } onClick={ this.props.onClose} />
				</div>
				{this.createTableDetails()}
				{this.showApplicationDetails()}
			</IsolateEvent>
		);
	}
}

ModalTableApplication.propTypes = {
	detailsResult: PropTypes.object,
	onClose: PropTypes.func,
	onLoadDetails: PropTypes.func,
	saveApplicationNote: PropTypes.func
};

export default ModalTableApplication;