import { apiGet, apiPost } from "../../../actions";
import Moment from "moment";

const defaultPath = "api/DealerManagement/SummaryCultivation";
const originationSummaryPath = "api/FundingManagement/OriginationSummary";

export function loadFilters(props) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath + "/loadFilters",
			onSuccess: response => {
				props.setState({
					typesList: response.data
				});
			}
		} );
	}
}

export function loadDealerDetail(props, dealerID) {
	return dispatch => {
		apiGet( dispatch, {
			url: defaultPath + "/loadDealerDetail/" + dealerID,
			onSuccess: response => {
				props.setState({
					dealerDetail: response.data,
					filters: {
						...props.state.filters,
						dealerId: [dealerID]
					}
				});
				dispatch(submit(props, props.state.filters));
			}
		} );
	}
}

export function submit( props, filters ) {
	return dispatch => {
		apiPost( dispatch, {
			url: originationSummaryPath,
			body: {
				lender: "SFC",
				dealerId: JSON.stringify(filters.dealerId.map(d => d)),
				types: JSON.stringify([filters.type]),
				fromSubmitDate: filters.fromSubmitDate !== null ? Moment( new Date( filters.fromSubmitDate ) ).format( "MM-DD-YYYY" ) : null,
				toSubmitDate: filters.toSubmitDate !== null ? Moment( new Date( filters.toSubmitDate ) ).format( "MM-DD-YYYY" ) : null,
				ignoreFico: true
			},
			onSuccess: response => {
				props.setState({
					listGrid: response.data
				});
			}
		} );
	};
}